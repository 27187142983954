import { asHttp } from '~/src/Configurations/WebHttp'

import { modifyOrderActions } from '../actions'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export type T_MODIFY_ORDER_PAYLOAD = {
  omsOrderId: string
  omsOrderSerialNumber: string
  segment: string
  exchange: string
  scriptId: string
  transactionType: string
  productType: string
  orderType: string
  totalQty?: number
  tradedQty: number
  openQty: number
  disclosedQty: number
  orderPrice: number
  triggerPrice: number
  validityType: string
  validityDays: number
  isAmo: boolean
}

async function modifyOrder(payload: T_MODIFY_ORDER_PAYLOAD) {
  const options = {
    url: `/trading/orders/modify-order`,
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const modifyOrderAction = serviceActionCreator(modifyOrderActions, modifyOrder)

export default modifyOrderAction
