import { createSelector } from '@reduxjs/toolkit'
export const SLICE_NAME = 'appState'

const select = (state: any) => state[SLICE_NAME]

export const getAppStateDialog = createSelector(
  select,
  appState => appState.dialog
)

export const getDeepLinkAttributes = createSelector(
  select,
  appState => appState.deepLinkData
)
