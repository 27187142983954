import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsStack, DsTypography } from '@am92/react-design-system'
import { changeOrderFormData } from '~/src/Redux/Orders/reducer'
import { FormikErrors } from 'formik'
import { FormValues } from './OrderFormSection'

type ActionTypes = {
  changeOrderFormData: (payload: { key: string; data: any }) => Promise<any>
}

export interface IValiditySectionProps extends IWithRouterProps {
  actions: ActionTypes
  validity: string
  orderBuyOrSell: string
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>
}

class ValiditySection extends Component<IValiditySectionProps> {
  constructor(props: IValiditySectionProps) {
    super(props)
  }

  handleChangeValidity = async (validity: string) => {
    const { actions } = this.props
    actions.changeOrderFormData({ key: 'validity', data: validity })
  }

  render() {
    const { validity, orderBuyOrSell, setFieldValue } = this.props
    const isBUY = orderBuyOrSell === 'BUY'
    const orderColor = isBUY ? 'supportPositive' : 'supportNegative'
    return (
      <DsStack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <DsTypography variant="bodyRegularMedium">Validity</DsTypography>
        <DsStack
          sx={{
            flexDirection: 'row',
            gap: 'var(--ds-spacing-glacial)'
          }}
        >
          <DsStack
            sx={{
              p: 'var(--ds-spacing-frostbite) var(--ds-spacing-glacial)',
              backgroundColor: `var(--ds-colour-${validity === 'Day' ? orderColor : 'surfaceBackground'
                })`,
              border: `${validity === 'Day'
                ? 'unset'
                : '1px solid var(--ds-colour-strokeDefault)'
                }`,
              borderRadius: 'var(--ds-radius-quickFreeze)',
              height: 'var(--ds-spacing-warm)',
              alignItems: 'center',
              justifyContent: 'center',
              width: 'var(--ds-spacing-superheated)',
              cursor: 'pointer'
            }}
            onClick={() => {
              setFieldValue('validity', 'Day')
            }}
          >
            <DsTypography
              variant={
                validity === 'Day' ? 'bodyBoldSmall' : 'bodyRegularSmall'
              }
              sx={{
                color: `var(--ds-colour-${validity === 'Day' ? 'typoOnSurface' : 'typoSecondary'
                  })`
              }}
            >
              Day
            </DsTypography>
          </DsStack>
          <DsStack
            sx={{
              p: 'var(--ds-spacing-frostbite) var(--ds-spacing-glacial)',
              backgroundColor: `var(--ds-colour-${validity === 'IOC' ? orderColor : 'surfaceBackground'
                })`,
              border: `${validity === 'IOC'
                ? 'unset'
                : '1px solid var(--ds-colour-strokeDefault)'
                }`,
              borderRadius: 'var(--ds-radius-quickFreeze)',
              height: 'var(--ds-spacing-warm)',
              alignItems: 'center',
              justifyContent: 'center',
              width: 'var(--ds-spacing-superheated)',
              cursor: 'pointer'
            }}
            onClick={() => {
              setFieldValue('validity', 'IOC')
              setFieldValue('triggerPrice', '')
            }}
          >
            <DsTypography
              variant={
                validity === 'IOC' ? 'bodyBoldSmall' : 'bodyRegularSmall'
              }
              sx={{
                color: `var(--ds-colour-${validity === 'IOC' ? 'typoOnSurface' : 'typoSecondary'
                  })`
              }}
            >
              IOC
            </DsTypography>
          </DsStack>
        </DsStack>
      </DsStack>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    changeOrderFormData: (payload: { key: string; data: any }) =>
      dispatch(changeOrderFormData(payload))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(ValiditySection)
