import traceActionsCreator from '../traceActionsCreator'

export const getExchangeMessagesServiceName = 'events/getExchangeMessages'
export const getExchangeMessagesActions = traceActionsCreator(
  getExchangeMessagesServiceName
)

export const getMarketStatusServiceName = 'events/getMarketStatus'
export const getMarketStatusActions = traceActionsCreator(
  getMarketStatusServiceName
)
