import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import {
  closeAppStateDialogActions,
  openAppStateDialogActions,
  setAppStateDialogActions
} from './actions'
import { SLICE_NAME } from './selectors'

export type TDeepLink = {
  path: string
  parameterList: string[]
}
interface IAppState {
  dialog: object
  deepLinkData: TDeepLink | null
}

const INITIAL_STATE: IAppState = {
  dialog: {},
  deepLinkData: null
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setDeepLinkData: (state, action) => {
      const { payload } = action
      state.deepLinkData = payload
    },
    resetDeepLinkData: state => {
      state.deepLinkData = INITIAL_STATE.deepLinkData
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    // builder.addCase(clearAppStateDialogActions, (state, { payload }) => {
    //   const { key, data } = payload
    //   state.dialog[key] = data
    // })
    builder.addCase(setAppStateDialogActions, (state, { payload }) => {
      const { key, data } = payload
      state.dialog[key] = data
    })
    builder.addCase(openAppStateDialogActions, (state, { payload }) => {
      state.dialog[payload] = { open: true }
    })
    builder.addCase(closeAppStateDialogActions, (state, { payload }) => {
      state.dialog[payload] = { open: false }
    })
  }
}

const slice = createSlice(sliceOptions)
export const { setDeepLinkData, resetDeepLinkData } = slice.actions
export default slice.reducer
