import React, { Component } from 'react'
// Ds Components
import {
  DsBox,
  DsButton,
  DsCheckbox,
  DsDivider,
  DsLink,
  DsModal,
  DsRemixIcon,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
//Lib
import { ThunkDispatch } from '@reduxjs/toolkit'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
// Constants
import { EMARGIN_LABEL } from '~/src/Constants/LABEL'

export interface IEmarginTNCProps extends IWithRouterProps {
  showTNCLModal: boolean
  setShowTNCLModal: any
  setActivateModal: any
}

class EmarginTNC extends Component<IEmarginTNCProps> {
  state = { checked: false }
  render() {
    const { setShowTNCLModal, setActivateModal } = this.props
    const { checked } = this.state

    return (
      <>
        <DsModal sx={{ display: { md: 'none', xs: 'flex' } }} open={true}>
          <DsBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: {
                md: 'calc(var(--ds-spacing-whiteHot) + var(--ds-spacing-whiteHot) + var(--ds-spacing-whiteHot) + var(--ds-spacing-whiteHot) + var(--ds-spacing-whiteHot))',
                xs: '100vw'
              },
              height: {
                md: 'calc(var(--ds-spacing-superheated) + var(--ds-spacing-superheated) + var(--ds-spacing-superheated) + var(--ds-spacing-superheated))',
                xs: '100vh'
              },
              position: 'absolute',
              top: { md: '50%', xs: '0' },
              left: { md: '50%', xs: '0' },
              transform: { md: 'translate(-50%, -50%)', xs: '' },
              backgroundColor: 'var(--ds-colour-surfaceBackground)',
              animation: 'reverse',
              overflow: 'hidden',
              overflowY: 'scroll'
            }}
          >
            {/* Header  */}
            <DsStack
              sx={{
                flexDirection: 'row',
                gap: 'var(--ds-spacing-bitterCold)',
                p: 'var(--ds-spacing-bitterCold)',
                alignItems: 'center',
                backgroundColor: 'var(--ds-colour-surfaceBackground)',
                position: 'sticky',
                top: 0
              }}
            >
              <DsRemixIcon
                className="ri-close-line"
                fontSize="mild"
                onClick={() => {
                  setShowTNCLModal(false)
                }}
              />
              <DsTypography variant="headingBoldSmall">
                {EMARGIN_LABEL.EMARGIN_TNC.HEADER}
              </DsTypography>
            </DsStack>
            {/* Tnc Heading  */}
            <DsStack
              sx={{
                flexDirection: 'column',
                gap: 'var(--ds-spacing-frostbite)',
                p: 'var(--ds-spacing-bitterCold)'
              }}
            >
              <DsTypography variant="bodyBoldMedium">
                {EMARGIN_LABEL.EMARGIN_TNC.TNC}
              </DsTypography>
              <DsTypography variant="bodyRegularSmall" color={'text.secondary'}>
                {EMARGIN_LABEL.EMARGIN_TNC.DESCRIPTION}
              </DsTypography>
              <DsDivider />
            </DsStack>
            {/* TNC  */}
            <DsStack
              sx={{
                flexDirection: 'column',
                gap: 'var(--ds-spacing-bitterCold)',
                p: 'var(--ds-spacing-bitterCold)'
              }}
            >
              <DsTypography variant="bodyBoldMedium">
                {EMARGIN_LABEL.EMARGIN_TNC.BSE}
              </DsTypography>
              <DsTypography variant="bodyRegularSmall" color={'text.secondary'}>
                {EMARGIN_LABEL.EMARGIN_TNC.TNC_1}
              </DsTypography>
              <DsTypography variant="bodyRegularSmall" color={'text.secondary'}>
                {EMARGIN_LABEL.EMARGIN_TNC.TNC_2}
              </DsTypography>
              <DsTypography variant="bodyRegularSmall" color={'text.secondary'}>
                {EMARGIN_LABEL.EMARGIN_TNC.TNC_3}
              </DsTypography>
              <DsTypography variant="bodyRegularSmall" color={'text.secondary'}>
                {EMARGIN_LABEL.EMARGIN_TNC.TNC_4}
              </DsTypography>
              <DsTypography variant="bodyRegularSmall" color={'text.secondary'}>
                {EMARGIN_LABEL.EMARGIN_TNC.TNC_5}
              </DsTypography>
            </DsStack>
            {/* Bottom Container */}
            <DsStack
              sx={{
                flexDirection: 'column',
                position: 'absolute',
                bottom: 0,
                width: '100%',
                py: 'var(--ds-spacing-bitterCold)',
                border: '1px solid var(--ds-colour-strokeDefault)'
              }}
            >
              {' '}
              <DsStack
                sx={{
                  flexDirection: 'row',
                  gap: 'var(--ds-spacing-glacial)',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  mb: 'var(--ds-spacing-frostbite)'
                }}
              >
                <DsCheckbox
                  onChange={() => this.setState({ checked: !checked })}
                />
                <DsTypography variant="bodyRegularSmall">
                  {EMARGIN_LABEL.EMARGIN_TNC.ACCEPTANCE}{' '}
                  <DsLink>{EMARGIN_LABEL.EMARGIN_TNC.TNC}</DsLink>{' '}
                </DsTypography>
              </DsStack>
              <DsButton
                sx={{ mx: 'var(--ds-spacing-bitterCold)' }}
                onClick={() => {
                  setShowTNCLModal(false)
                  setActivateModal(false)
                }}
                disabled={!checked}
              >
                {EMARGIN_LABEL.EMARGIN_TNC.ACCEPTANCE}
              </DsButton>
            </DsStack>
          </DsBox>
        </DsModal>
      </>
    )
  }
}

const mapStateToProps = (state: any) => { }

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {}
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(EmarginTNC)
)
