import AppStore from '~/src/Configurations/AppStore'
import { setReseacrhData } from '~/src/Redux/ResearchAndRecommendation/Reducers'

export const sortRecommendation = (recommendationData: any, sortBy: string) => {
  let newArray = [...recommendationData]
  if (sortBy === 'NEW_TO_OLD') {
    newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
      const { timeOfCall: timeOfCallA } = ITEM_A
      const { timeOfCall: timeOfCallB } = ITEM_B

      return new Date(timeOfCallB) - new Date(timeOfCallA)
    })
  }
  if (sortBy === 'OLD_TO_NEW') {
    newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
      const { timeOfCall: timeOfCallA } = ITEM_A
      const { timeOfCall: timeOfCallB } = ITEM_B

      return new Date(timeOfCallA) - new Date(timeOfCallB)
    })
  }
  if (sortBy === 'ALPHA_A_TO_Z') {
    newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
      const { symbol: symbolA } = ITEM_A

      const { symbol: symbolB } = ITEM_B

      return symbolA.localeCompare(symbolB)
    })
  }
  if (sortBy === 'ALPHA_Z_TO_A') {
    newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
      const { symbol: symbolA } = ITEM_A

      const { symbol: symbolB } = ITEM_B

      return symbolB.localeCompare(symbolA)
    })
  }
  // AppStore.dispatch(setReseacrhData({ data: newArray }))
  return newArray
}
