import { DsSkeleton, DsStack } from '@am92/react-design-system'
import React, { PureComponent, ReactNode } from 'react'

class FundsSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <>
        <DsStack sx={{ width: '100%' }}>
          <DsSkeleton variant='text' sx={{ height: '40px' }} />
          <DsSkeleton variant='text' sx={{ height: '60px' }} />
          <DsStack sx={{ width: '100%', flexDirection: 'row', gap: '20px' }}>
            <DsSkeleton
              variant='text'
              sx={{
                minWidth: '400px',
                height: '600px',
                transform: 'none',
                marginTop: '20px'
              }}
            />
            <DsStack sx={{ flexDirection: 'column' }}>
              <DsSkeleton
                variant='text'
                sx={{ width: '500px', height: '100px' }}
              />
              <DsSkeleton
                variant='text'
                sx={{ width: '500px', height: '100px' }}
              />
            </DsStack>
          </DsStack>
        </DsStack>
      </>
    )
  }
}

export default FundsSkeleton
