import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsStack, DsBox, DsTypography } from '@am92/react-design-system'
import { tickerContext } from '~/src/Configurations/TickerStore'
import { getCurrentValueAndUnrealisedPL } from '~/src/Redux/StockTicker/Selectors'

type ActionTypes = {}

export interface IHoldingsPopUpContentProps extends IWithRouterProps {
  holdings: number | string
  dmatfreeQty: number | string
  collateralQty: number | string
  emarginNetQuantity: number | string
  avgPrice: number | string
  investedValue: number | string
  scriptId: string
  currentValue: number
  unrealisedPL: number
  netChangePercent: number
}

type IHoldingsPopUpContentState = {}

class HoldingsPopUpContent extends Component<
  IHoldingsPopUpContentProps,
  IHoldingsPopUpContentState
> {
  constructor(props: IHoldingsPopUpContentProps) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      holdings,
      dmatfreeQty,
      collateralQty,
      emarginNetQuantity,
      avgPrice,
      investedValue,
      currentValue,
      unrealisedPL,
      netChangePercent
    } = this.props

    const revisedDmatFreeQty = !dmatfreeQty ? 0 : dmatfreeQty
    return (
      <>
        <DsStack spacing={4} justifyContent={'flex-end'}>
          <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DsTypography variant="bodyRegularSmall">Quantity</DsTypography>
            <DsTypography variant="bodyRegularSmall">{holdings}</DsTypography>
          </DsBox>

          <DsStack spacing={4} sx={{ width: '96%', ml: '1rem !important' }}>
            <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <DsTypography variant="bodyRegularSmall">DEMAT</DsTypography>
              <DsTypography variant="bodyRegularSmall">
                {revisedDmatFreeQty}
              </DsTypography>
            </DsBox>
            <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <DsTypography variant="bodyRegularSmall">
                Collateral (Pledged){' '}
              </DsTypography>
              <DsTypography variant="bodyRegularSmall">
                {collateralQty}
              </DsTypography>
            </DsBox>
            <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <DsTypography variant="bodyRegularSmall">BMPL</DsTypography>
              <DsTypography variant="bodyRegularSmall">
                {emarginNetQuantity}
              </DsTypography>
            </DsBox>
          </DsStack>

          <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DsTypography variant="bodyRegularSmall">
              Average Price
            </DsTypography>
            <DsTypography variant="bodyRegularSmall">{avgPrice}</DsTypography>
          </DsBox>

          <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DsTypography variant="bodyRegularSmall">
              Unrealised P&L
            </DsTypography>
            <DsTypography
              variant="bodyRegularSmall"
              color={
                netChangePercent > 0
                  ? 'var(--ds-colour-iconPositive)'
                  : 'var(--ds-colour-iconNegative)'
              }
            >
              {`${netChangePercent > 0 ? '+' : ''}${unrealisedPL?.toFixed(
                2
              )}  (${netChangePercent?.toFixed(2)}%)`}
            </DsTypography>
          </DsBox>

          <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DsTypography variant="bodyRegularSmall">
              Invested Amount
            </DsTypography>
            <DsTypography variant="bodyRegularSmall">
              {investedValue}
            </DsTypography>
          </DsBox>

          <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DsTypography variant="bodyRegularSmall">
              Current Value
            </DsTypography>
            <DsTypography
              variant="bodyRegularSmall"
              color={
                unrealisedPL > 0
                  ? 'var(--ds-colour-iconPositive)'
                  : 'var(--ds-colour-iconNegative)'
              }
            >
              {`${unrealisedPL > 0 ? '+' : ''}${currentValue?.toFixed(2)}`}
            </DsTypography>
          </DsBox>
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { holdings, investedValue, scriptId } = ownProps
  const { currentValue, unrealisedPL, netChangePercent } =
    getCurrentValueAndUnrealisedPL(scriptId, holdings, investedValue)(state)
  return {
    currentValue,
    unrealisedPL,
    netChangePercent
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(HoldingsPopUpContent)
