import React from 'react'
import {
  NavigateFunction,
  NavigateOptions,
  Params,
  SetURLSearchParams,
  To,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'

/**
 * Basic Interface to extend in components wrapping the below HOC.
 *
 * @export
 * @interface IWithRouterProps
 */

export interface LocationExtended extends Location {
  state: any
}
export interface IWithRouterProps {
  searchParams: URLSearchParams
  setSearchParams: SetURLSearchParams
  navigate: NavigateFunction
  location: LocationExtended
  params: Readonly<Params<string>>
  navigateTo: (route: To | number, options?: NavigateOptions) => void
}

/**
 * HOC to provide routing features
 *
 * @export
 * @param {React.ComponentType<any>} Child
 * @return {*}  {React.ComponentType<any>}
 */
export default function withRouter(
  Child: React.ComponentType<any>
): React.ComponentType<any> {
  return (props: any): React.JSX.Element => {
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const params = useParams()
    const navigateTo = (route: To | number, options?: NavigateOptions) => {
      if (typeof route === 'number') {
        navigate(route)
      } else {
        navigate(route, options)
      }
    }

    return (
      <Child
        {...props}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
        navigate={navigate}
        location={location}
        params={params}
        navigateTo={navigateTo}
      />
    )
  }
}
