import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { renameWatchlistActions, renameWatchlistServiceName } from '../Actions'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export interface renameWatchlistPayload {
  params: {
    watchlistId: string
  }
  dataPayload: {
    watchlistName: string
  }
}

async function renameWatchlist(reqData: renameWatchlistPayload) {
  const { params, dataPayload } = reqData
  const { watchlistId } = params

  const options = {
    url: `/watch-lists/${watchlistId}/update`,
    method: 'POST',
    data: dataPayload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const renameWatchlistAction = serviceActionCreator(
  renameWatchlistActions,
  renameWatchlist
)

export default renameWatchlistAction
export { renameWatchlistServiceName }
