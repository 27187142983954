import { DsSkeleton, DsStack } from "@am92/react-design-system";
import React, { ReactNode } from "react";

class OrderbookSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <>
        <DsStack spacing={"var(--ds-spacing-warm)"} sx={{ width: "100%" }}>
          <DsStack spacing={"var(--ds-spacing-cool)"} sx={{ width: "100%" }}>
            <DsSkeleton variant="rounded" sx={{ height: "50px" }} />
          </DsStack>
          <DsStack spacing={"var(--ds-spacing-frostbite)"} sx={{ width: "100%" }}>
            <DsStack sx={{ flexDirection: "row", gap: "12px" }}>
              <DsSkeleton variant="text" sx={{ height: "20px", width: "120px" }} />
              <DsSkeleton variant="text" sx={{ height: "20px", width: "120px" }} />
              <DsSkeleton variant="text" sx={{ height: "20px", width: "120px" }} />
              <DsSkeleton variant="text" sx={{ height: "20px", width: "120px" }} />
            </DsStack>
            <DsSkeleton variant="rounded" sx={{ height: "280px" }} />
          </DsStack>
        </DsStack>
      </>
    )
  }
}

export default OrderbookSkeleton