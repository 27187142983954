import {
  DsBox,
  DsButton,
  DsButtonBase,
  DsDivider,
  DsRemixIcon,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { getSipInfo } from '~/src/Redux/Orders/selectors'
import SipScriptHeader from './SipScriptHeader'
import { openModifySip } from '~/src/Redux/Indicators/Selectors'
import { getCurrencyFormat } from '~/src/Utils/global'
import TermsandConditions from './TermsandConditions'
import SipHeader from './SipHeader'
import { updateModifySipPopup } from '~/src/Redux/Indicators/Reducer'

export class ReviewSip extends Component<any> {
  state = {
    termsAccepted: false,
    showDetails: true
  }

  setTermsAccepted = (e: boolean) => {
    this.setState({ termsAccepted: e })
  }

  handleModifySip = () => {
    console.log('modify clicked')
    const { actions } = this.props
    actions.updateOpenModify(false)
  }

  getFilteredScripts = (scripts: any[]) => {
    return scripts.filter(item => item.action !== 'Delete')
  }
  render() {
    const { sipInfo, openModify, handleConfirmSip, handleClose } = this.props
    const { termsAccepted, showDetails } = this.state
    const { selectedScripts = [], basedOn } = sipInfo
    const totalValue = selectedScripts?.reduce(
      (total: number, item: any) => total + (parseFloat(item?.amount) || 0),
      0
    )
    const fomattedBasedOn = basedOn === 'amount' ? 'AMOUNT' : 'QUANTITY'

    const formattedValue = (item: any) =>
      getCurrencyFormat(item, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    return (
      <>
        <DsBox sx={{ display: { xs: 'flex', md: 'none' } }}>
          <SipHeader type={'Review'} handleModifySip={this.handleModifySip} />
        </DsBox>
        <DsStack
          gap={'var(--ds-spacing-bitterCold)'}
          sx={{ position: 'relative' }}
        >
          <SipScriptHeader sipInfo={sipInfo} openModify={openModify} />
          <DsBox
            sx={{ padding: { xs: 'var(--ds-spacing-bitterCold)', md: '0' } }}
          >
            <DsStack
              sx={{
                border: '1px solid var(--ds-colour-strokeDefault)',
                borderRadius: 'var(--ds-spacing-glacial)',
                padding: 'var(--ds-spacing-bitterCold)',
                display: 'flex',
                gap: 'var(--ds-spacing-mild)'
              }}
            >
              <DsBox
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <DsTypography variant='subheadingSemiboldDefault'>{`Total Scrips (${selectedScripts.length})`}</DsTypography>
                <DsButtonBase
                  onClick={() => this.setState({ showDetails: !showDetails })}
                  sx={{
                    background: 'var(--ds-colour-surfaceSecondary)',
                    borderRadius: 'var(--ds-spacing-bitterCold)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 'var(--ds-spacing-quickFreeze)'
                  }}
                >
                  <DsRemixIcon className='ri-arrow-up-s-line' />
                </DsButtonBase>
              </DsBox>
              <DsBox
                sx={{
                  background: 'var(--ds-colour-surfaceSecondary)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 'var(--ds-spacing-deepFreeze)',
                  padding: 'var(--ds-spacing-glacial)',
                  width: '100%',
                  borderRadius: 'var(--ds-spacing-quickFreeze)'
                }}
              >
                <DsTypography variant='supportRegularInfo'>
                  Total Approximate Amount
                </DsTypography>

                <DsTypography variant='supportRegularInfo' color={'green'}>
                  {getCurrencyFormat(totalValue)}
                </DsTypography>
                <DsButtonBase>
                  <DsRemixIcon
                    className='ri-information-line'
                    fontSize='bitterCold'
                  />
                </DsButtonBase>
              </DsBox>
              {showDetails && (
                <>
                  <DsDivider />
                  <DsStack gap={'var(--ds-spacing-bitterCold)'}>
                    <DsBox
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <DsTypography variant='supportRegularMetadata'>
                        Stock
                      </DsTypography>
                      <DsTypography variant='supportRegularMetadata'>
                        {fomattedBasedOn}
                      </DsTypography>
                    </DsBox>
                    {this.getFilteredScripts(selectedScripts).map(
                      (item: any) => (
                        <DsBox
                          key={item.coname}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <DsTypography variant='supportRegularMetadata'>
                            {item.coname}
                          </DsTypography>
                          <DsTypography variant='supportRegularMetadata'>
                            {formattedValue(item?.[basedOn.toLowerCase()])}
                          </DsTypography>
                        </DsBox>
                      )
                    )}
                  </DsStack>
                </>
              )}
            </DsStack>
            <DsBox
              sx={{
                border: '1px solid var(--ds-colour-strokeDefault)',
                borderRadius: 'var(--ds-spacing-glacial)',
                padding: 'var(--ds-spacing-bitterCold)',
                display: 'flex',
                gap: 'var(--ds-spacing-bitterCold)',
                alignItems: 'flex-start',
                mt: 'var(--ds-spacing-mild)'
              }}
            >
              <DsBox
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  background: 'var(--ds-colour-neutral2)',
                  padding: 'var(--ds-spacing-glacial)'
                }}
              >
                <DsRemixIcon
                  className='ri-lightbulb-flash-fill'
                  color='success'
                  fontSize='bitterCold'
                />
              </DsBox>
              <DsTypography variant='bodyRegularSmall'>
                If your SIP falls on a weekend or holiday, it will automatically
                happen on the next business day, with the final amount based on
                stock prices on that day.
              </DsTypography>
            </DsBox>
            <DsBox
              sx={{
                display: { xs: 'none', md: 'flex' },
                width: '100%',
                mt: 'var(--ds-spacing-bitterCold)'
              }}
            >
              <TermsandConditions
                accepted={termsAccepted}
                setAccepted={this.setTermsAccepted}
              />
            </DsBox>
            <DsStack
              sx={{
                position: { xs: 'fixed', md: 'static' },
                bottom: { xs: '0', md: 'static' },
                left: { xs: '0', md: 'static' },
                p: { xs: 'var(--ds-spacing-bitterCold)', md: 'static' },
                width: '100%',
                height:
                  'calc(var(--ds-spacing-whiteHot)) + var(--ds-spacing-warm)',
                mt: 'var(--ds-spacing-bitterCold)',
                gap: 'var(--ds-spacing-mild)'
              }}
            >
              <DsBox
                sx={{ display: { xs: 'flex', md: 'none' }, width: '100%' }}
              >
                <TermsandConditions
                  accepted={termsAccepted}
                  setAccepted={this.setTermsAccepted}
                />
              </DsBox>
              <DsBox
                sx={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: { xs: 'auto', md: 'auto auto' },
                  gap: 'var(--ds-spacing-bitterCold)',
                  padding: '0'
                }}
              >
                <DsButton
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                  size='medium'
                  color='secondary'
                  onClick={handleConfirmSip}
                >
                  Modify SIP
                </DsButton>
                <DsButton
                  sx={{ display: 'flex' }}
                  fullWidth
                  size='medium'
                  disabled={!termsAccepted}
                  onClick={handleConfirmSip}
                >
                  Confirm SIP
                </DsButton>
              </DsBox>
            </DsStack>
          </DsBox>
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const sipInfo = getSipInfo(state)
  const openModify = openModifySip(state)

  return {
    sipInfo,
    openModify
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateOpenModify: (requestData: boolean) =>
      dispatch(updateModifySipPopup(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(ReviewSip)
)
