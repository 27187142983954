import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsFab, DsRemixIcon, withBreakpoints } from '@am92/react-design-system'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import {
  T_StockWatchlistMasterData,
  setTemporaryStockWatchlistData
} from '~/src/Redux/StockWatchlistMaster/Reducer'
import {
  T_SCRIPTS_OBJ,
  setAddScriptToParticularWatchlist
} from '~/src/Redux/WatchList/Reducer'
import { getWatchlist } from '~/src/Redux/WatchList/Selectors'

type ActionTypes = {
  setAddScriptToParticularWatchlist: (reqData: boolean) => Promise<any>
  setTemporaryStockWatchlistData: (requestData: any) => Promise<any>
}

export interface IFabIconProps extends IWithRouterProps {
  actions: ActionTypes
  breakpoints: any
  selectedWatchlistTab: number | string
  currentWatchlistId: string
  currentWatchlistSecurities: T_SCRIPTS_OBJ[] | null
}

class FabIcon extends Component<IFabIconProps> {
  state = {}

  handleAddScriptsClick = async () => {
    const {
      actions,
      currentWatchlistSecurities: watchlistSecurities,
      currentWatchlistId: watchlistId,
      navigateTo
    } = this.props

    const tempStockWatchlistData: T_StockWatchlistMasterData = {}

    watchlistSecurities?.forEach((stockObj: T_SCRIPTS_OBJ) => {
      const { scriptId } = stockObj
      if (!tempStockWatchlistData[scriptId]) {
        tempStockWatchlistData[scriptId] = [watchlistId]
        return
      }
      tempStockWatchlistData[scriptId] = [
        ...tempStockWatchlistData[scriptId],
        watchlistId
      ]
    })

    await actions.setAddScriptToParticularWatchlist(true)
    await actions.setTemporaryStockWatchlistData(tempStockWatchlistData)
    navigateTo(APP_ROUTES.MOBILE_SEARCH.pathname)
  }

  render() {
    return (
      <DsFab size="medium">
        <DsRemixIcon
          className="ri-add-line"
          onClick={this.handleAddScriptsClick}
        />
      </DsFab>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { selectedWatchlistTab } = ownProps
  const { currentWatchlistId, currentWatchlistSecurities } =
    getWatchlist(selectedWatchlistTab)(state) || {}

  return {
    currentWatchlistId,
    currentWatchlistSecurities
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setAddScriptToParticularWatchlist: (requestData: boolean) =>
      dispatch(setAddScriptToParticularWatchlist(requestData)),
    setTemporaryStockWatchlistData: (requestData: any) =>
      dispatch(setTemporaryStockWatchlistData(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(FabIcon)
)
