import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'error'

const select = (state: any) => state[SLICE_NAME]
const errorColdeSelect = (state: any) => state[SLICE_NAME].error.errorCode

export const getErrorObjSelector = createSelector(select, error => error?.error)

export const getErrorCodeSelector = createSelector(
  errorColdeSelect,
  errorCode => errorCode
)
