import React from 'react'
import withRouter from './withRouter'

const withRouterForwardRef = (
  WrappedComponent: React.ForwardRefExoticComponent<
    Omit<any, 'ref'> & React.RefAttributes<HTMLDivElement>
  >
) => {
  class InnerComponentWithRef extends React.Component {
    render() {
      const { forwardRef, ...rest } = this.props
      return <WrappedComponent {...rest} ref={forwardRef} />
    }
  }
  const ComponentWithRouter = withRouter(InnerComponentWithRef)
  return React.forwardRef((props, ref) => {
    return <ComponentWithRouter {...props} forwardRef={ref} />
  })
}

export default withRouterForwardRef
