import { DsBox, DsImage, DsStack, DsTypography, IwithBreakpoints, withBreakpoints } from '@am92/react-design-system'
import React, { Component } from 'react'
import { NOTIFICATIONS_ZERO_SCREEN } from '~/src/Constants/ASSET_MAP'

interface INotificationZeroScreenProps extends IwithBreakpoints {

}

export class NotificationZeroScreen extends Component<INotificationZeroScreenProps> {
  render() {
    return (
      <DsStack alignItems="center" gap="2rem" justifyContent="center" width="100%" sx={{ height: { md: "26rem", xs: "85vh" }, overflowY: "scroll" }}
      // bgcolor="var(--ds-colour-surfacePrimary)"
      >
        <DsBox>
          <DsImage srcSet={NOTIFICATIONS_ZERO_SCREEN} />
        </DsBox>
        <DsStack alignItems="center" gap="1rem" justifyContent="center">
          <DsTypography variant='subheadingSemiboldDefault'>
            Nothing NEW here
          </DsTypography>
          <DsStack alignItems="center" justifyContent="center">
            <DsTypography variant='bodyRegularMedium'>
              Looks like you are all caught up!
            </DsTypography>
            <DsTypography variant='bodyRegularMedium'>
              Check back later for any notifications.
            </DsTypography>
          </DsStack>
        </DsStack>
      </DsStack>
    )
  }
}

export default withBreakpoints(NotificationZeroScreen)