import {
  DsBox,
  DsButton,
  DsImage,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { NOTRADES } from '~/src/Constants/ASSET_MAP'

export interface IAPIErrorScreenProps extends IWithRouterProps {
  breakpoints: any
  description: any
  handleOnClick: any
  heading: any
  view: string
  sx?: { [key: string]: string };
}

class APIErrorScreen extends Component<IAPIErrorScreenProps> {
  render() {
    const { breakpoints, description, heading, handleOnClick } = this.props
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false
    const { view } = this.props
    const renderBorder = view === "table" ? false : isDesktop
    return (
      <DsStack
        sx={{
          display: "flex",
          flexDirection: 'column',
          border: view === "overview" || renderBorder ? '1px solid var(--ds-colour-strokeDefault)' : "0",
          padding: 'var(--ds-spacing-frostbite)',
          borderRadius: 'var(--ds-radius-glacial)',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          width: '100%',
          height: { xs: view === "inPage" ? "50vh" : 'var(--ds-spacing-plasma)', md: 'calc(var(--ds-spacing-plasma)+var(--ds-spacing-pleasant)+var(--ds-spacing-deepFreeze))' },
          p: 'var(--ds-spacing-warm)',
          mb: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-warm)'
          },
          mt: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-bitterCold)'
          },
          textAlign: "center",
          gap: "var(--ds-spacing-bitterCold)",
          background: "var(--ds-colour-surfaceBackground)",
        }}
      >
        <DsBox><DsImage srcSet={NOTRADES} /></DsBox>
        <DsTypography variant='bodyRegularMedium'>
          {heading}
        </DsTypography>
        <DsTypography variant='bodyRegularSmall'>
          {description}
        </DsTypography>
        {handleOnClick && <DsButton color='secondary' onClick={handleOnClick}>RETRY</DsButton>}
      </DsStack>
    )
  }
}

const mapStateToProps = (state: any) => {
}

const mapDispatchToProps = (dispatch: any) => ({ actions: {} })

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(APIErrorScreen)
)
