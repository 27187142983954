import { WEB_HTTP_CONTEXT, WebHttpError } from '@am92/web-http'
import { ThunkDispatch } from '@reduxjs/toolkit'

import { TraceActions } from './serviceActionCreator'
import loginWithRefreshTokenService from '~/src/Redux/Auth/Services/loginWithRefreshToken.Service'
import { asHttp } from '../Configurations/WebHttp'
import AppStore from '../Configurations/AppStore'

export default function serviceActionCreator<RequestData = void>(
  traceActions: TraceActions,
  service: (data: RequestData) => Promise<any>
) {
  return (data: RequestData) => {
    return async (
      dispatch: ThunkDispatch<any, any, any>,
      getState: () => unknown
    ): Promise<any | WebHttpError> => {
      if (traceActions.loading && typeof traceActions.loading === 'function') {
        dispatch(traceActions.loading())
      }

      let response = await service(data).catch(async (error: WebHttpError) => {
        console.log(error)

        const { code } = error?.error
        if (code === 1082 || code === '1082') {
          return await retryWithTokenRotation<RequestData>(
            traceActions,
            service,
            dispatch,
            getState,
            data
          )
        } else if (code === 1200 || code === '1200') {
          return await retryWithPublicKeyRotation<RequestData>(
            traceActions,
            service,
            dispatch,
            data,
            error
          )
        } else {
          if (traceActions.error && typeof traceActions.error === 'function') {
            dispatch(traceActions.error(error))
          }
          return error
        }
      })
      if (
        !response?._isCustomError &&
        traceActions.success &&
        typeof traceActions.success === 'function'
      ) {
        dispatch(traceActions.success(response))
      }

      if (response?._isCustomError) {
        response = {
          ...response,
          error: {
            ...response.error,
            code: isNaN(response.error.code)
              ? response.error.code
              : Number(response.error.code)
          }
        }
      }
      return response
    }
  }
}

async function retryWithTokenRotation<RequestData = void>(
  traceActions: TraceActions,
  service: (data: RequestData) => Promise<any>,
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => unknown,
  data: RequestData
): Promise<Response | WebHttpError> {
  const { subAccountId } = AppStore.getState().sso
  const loginWithRefreshTokenRequestData = { subAccountId }

  const loginWithRefreshTokenServiceDispatcher = loginWithRefreshTokenService(
    loginWithRefreshTokenRequestData
  )

  const tokenRotationResponse = await loginWithRefreshTokenServiceDispatcher(
    dispatch,
    getState
  )

  if (tokenRotationResponse instanceof WebHttpError ||
    tokenRotationResponse._isCustomError) {
    return tokenRotationResponse
  }

  let response = await service(data).catch((error: WebHttpError) => {
    if (traceActions.error && typeof traceActions.error === 'function') {
      dispatch(traceActions.error(error))
    }
    return error
  })

  if (
    !response?._isCustomError &&
    traceActions.success &&
    typeof traceActions.success === 'function'
  ) {
    dispatch(traceActions.success(response))
  }

  if (response?._isCustomError) {
    response = {
      ...response,
      error: {
        ...response.error,
        code: +response.error.code
      }
    }
  }

  return response
}

async function retryWithPublicKeyRotation<RequestData = void>(
  traceActions: TraceActions,
  service: (data: RequestData) => Promise<any>,
  dispatch: ThunkDispatch<any, any, any>,
  data: RequestData,
  error: WebHttpError
): Promise<Response | WebHttpError> {
  const { data: DATA } = error.error
  const { publicKey } = DATA
  asHttp.context.set(WEB_HTTP_CONTEXT.PUBLIC_KEY, publicKey)
  let response = await service(data).catch((error: WebHttpError) => {
    if (traceActions.error && typeof traceActions.error === 'function') {
      dispatch(traceActions.error(error))
    }
    return error
  })

  if (
    !response?._isCustomError &&
    traceActions.success &&
    typeof traceActions.success === 'function'
  ) {
    dispatch(traceActions.success(response))
  }

  if (response?._isCustomError) {
    response = {
      ...response,
      error: {
        ...response.error,
        code: isNaN(response.error.code)
          ? response.error.code
          : Number(response.error.code)
      }
    }
  }

  return response
}
