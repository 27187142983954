import * as React from 'react'

import { DsBox } from '@am92/react-design-system'
import { Outlet } from 'react-router-dom'

export interface IAuthenticationLayoutProps {}

export default class AuthenticationLayout extends React.Component<IAuthenticationLayoutProps> {
  render() {
    return (
      <DsBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: {
            md: 'center',
            sx: 'start'
          },
          height: '100vh'
        }}
      >
        <DsBox>
          <Outlet />
        </DsBox>
      </DsBox>
    )
  }
}
