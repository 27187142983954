import {
  DsBox,
  DsDivider,
  DsGrid,
  DsIconButton,
  DsImage,
  DsListItemButton,
  DsMenu,
  DsMenuItem,
  DsPopup,
  DsPopup,
  DsRemixIcon,
  DsStack,
  DsTextField,
  DsTypography,
  enqueueNotistack,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import { To } from 'react-router-dom'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { LIST_DRAGGER } from '~/src/Constants/ASSET_MAP'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { removeWatchlistIdFromStocksAction } from '~/src/Redux/StockWatchlistMaster/Actions'
import {
  T_SCRIPTS_OBJ,
  selectWatchlist,
  selectWatchlistTab,
  setWatchlist,
  setWatchlistIndex,
  watchlistObj
} from '~/src/Redux/WatchList/Reducer'
import {
  getSelectedWatchlist,
  getWatchListData
} from '~/src/Redux/WatchList/Selectors'
import deleteWatchlistAction, {
  DeleteWatchlistParams
} from '~/src/Redux/WatchList/Services/DeleteWatchlist.Service'
import renameWatchlistAction, {
  renameWatchlistPayload
} from '~/src/Redux/WatchList/Services/RenameWatchlist.Service'
import updateWatchlistSequenceAction, {
  updateWatchListSequenceObj,
  updateWatchlistsSequencePayload
} from '~/src/Redux/WatchList/Services/UpdateWatchlistsSequence.Service'
import { odinWorkerManager } from '~/src/Workers/Odin/OdinWorkerManager'
import CreateWatchlist from '../../ManageWatchlist/CreateWatchlist'
import { triggerCleverTapEvent } from '~/src/Utils/global'
import { title } from 'process'

export interface IsetWatchlistReqData {
  Watchlist: watchlistObj[]
}

type ActionTypes = {
  updateWatchlistSequence: (
    reqData: updateWatchlistsSequencePayload
  ) => Promise<any>
  setWatchlist: (reqData: IsetWatchlistReqData) => Promise<any>
  renameWatchlist: (reqData: renameWatchlistPayload) => Promise<any>
  deleteWatchlist: (param: DeleteWatchlistParams) => Promise<any>
  setSelectedWatchlistTab: (reqData: number | string) => Promise<any>
  setSelectedWatchlist: (selectedWatchlist: watchlistObj) => Promise<any>
  setWatchlistIndex: (requestData: any) => Promise<any>
  removeWatchlistIdFromStocks: (requestData: {
    watchListIdToDelete: string
    scriptIdArray: T_SCRIPTS_OBJ[]
  }) => Promise<any>
}

export interface IManageWatchlistProps extends IWithRouterProps {
  actions: ActionTypes
  watchListData: watchlistObj[]
  breakpoints: any
  isAnySelectedWatchlist: watchlistObj
}

class ManageWatchlist extends Component<IManageWatchlistProps> {
  odinWorkerManager = null as any

  state = {
    watchList: this.props.watchListData || [],
    selectedWatchList:
      this.props.isAnySelectedWatchlist || ({} as watchlistObj),
    renameId: '',
    deleteId: '',
    watchlistName: '',
    watchlistNameError: false,
    watchlistNameHelperText: '',
    showMenu: false,
    anchorEl: null,
    openRenameWatchListNameModal: false,
    openDeleteWatchlistModal: false
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  componentDidMount(): void {
    const { watchList } = this.state
    const { isAnySelectedWatchlist } = this.props
    const { actions } = this.props
    if (Object.keys(isAnySelectedWatchlist).length === 0) {
      for (let i = 0; i < watchList.length; i++) {
        if (watchList[i].watchlistType === 'CUSTOM') {
          this.setState({ selectedWatchList: watchList[i] })
          actions.setSelectedWatchlist(watchList[i])
          break
        }
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<IManageWatchlistProps>): void {
    const { watchListData } = this.props
    if (prevProps.watchListData !== watchListData) {
      this.setState({
        watchList: watchListData
      })
    }
  }

  setShowMenu = (value: boolean, selectedWatchList?: watchlistObj) => {
    this.setState({ showMenu: value, selectedWatchList })
  }

  setAnchorEl = (value: any) => {
    this.setState({ anchorEl: value.currentTarget })
  }

  handleDragEnd = async (result: DropResult) => {
    const source = result.source.index
    const destination = result.destination?.index
    const customAttributes = {
      'Event Status': 'Pass',
      Action: 'Sequence Changed'
    }
    triggerCleverTapEvent('Watchlist_Manage', customAttributes)
    if (
      destination === source ||
      destination === null ||
      destination === undefined
    ) {
      return
    }

    if (
      source === 0 ||
      source === 1 ||
      destination === 0 ||
      destination === 1
    ) {
      enqueueNotistack({
        message: 'Can not update the position of this watchlist.'
      })
      return
    }
    const { watchList } = this.state
    const { actions } = this.props
    const newItems = Array.from(watchList)
    const currentWatchlist = Array.from(watchList)

    const [removed] = newItems.splice(source, 1)
    newItems.splice(destination, 0, removed)

    let payloadArray: updateWatchListSequenceObj[] = []
    let newWatchlistArray: watchlistObj[] = []
    let newWatchListIndex: {
      [key: string]: number
    } = {}

    newItems.forEach((item: any, index: number) => {
      newWatchListIndex[item.watchlistId] = index + 1
      payloadArray.push({
        watchlistId: item.watchlistId,
        sequenceNumber: index + 1
      })
      newWatchlistArray.push({
        ...item,
        watchlistSequenceNumber: index + 1
      })
    })

    // changing sequence at local/redux
    actions.setWatchlist({
      Watchlist: newWatchlistArray
    })
    actions.setWatchlistIndex({
      watchListIndex: newWatchListIndex
    })

    // changing sequence in db/backend
    const requestData: updateWatchlistsSequencePayload = {
      watchlists: payloadArray
    }

    const updateWatchlistSequenceResponse =
      await actions.updateWatchlistSequence(requestData)
    if (updateWatchlistSequenceResponse?._isCustomError) {
      // if api fails then keeping the previous sequence ready
      actions.setWatchlist({
        Watchlist: currentWatchlist
      })
      console.log('failed API - updateWatchlistSequence')

      enqueueNotistack({
        message: 'Request failed!'
      })
      return
    }

    enqueueNotistack({
      message: 'Watchlist Sequence Updated!'
    })
  }

  validateWatchlistName = (name: string) => {
    if (name === '') {
      this.setState({
        watchlistNameError: true,
        watchlistNameHelperText: 'Name can’t be empty'
      })
    } else if (name.length >= 18) {
      this.setState({
        watchlistNameError: true,
        watchlistNameHelperText: 'Watchlist name should be 1-18 characters long'
      })
    } else {
      this.setState({
        watchlistNameError: false,
        watchlistNameHelperText: ''
      })
    }
  }

  handleWatchlistName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const watchlistName = e.target.value
    this.setState({ watchlistName })
    this.validateWatchlistName(watchlistName.trim())
  }

  // Rename Watchlist set
  handleOpenRenameWatchlistModal = () => {
    const { selectedWatchList } = this.state
    const { watchlistId, watchlistName } = selectedWatchList

    this.setState({
      openRenameWatchListNameModal: true,
      renameId: watchlistId,
      watchlistName
    })

    this.setShowMenu(false, selectedWatchList)
  }

  handleCloseRenameWatchlistNameModal = () => {
    this.setState({
      openRenameWatchListNameModal: false,
      renameId: '',
      watchlistName: '',
      watchlistNameError: false,
      watchlistNameHelperText: ''
    })
  }

  handleRenameWatchlist = async () => {
    const { watchList, watchlistName, renameId, selectedWatchList } = this.state
    const { actions } = this.props

    let duplicateFound = false
    let duplicateFoundId

    for (let i = 0; i < watchList.length; i++) {
      const watchListObj = watchList[i]
      const { watchlistName: WATCHLIST_NAME, watchlistId } = watchListObj
      if (WATCHLIST_NAME === watchlistName.trim()) {
        duplicateFoundId = watchlistId
        duplicateFound = true
        break
      }
    }

    if (duplicateFound && duplicateFoundId !== renameId) {
      this.setState({
        watchlistNameError: true,
        watchlistNameHelperText: 'Watchlist name should be unique'
      })
      return
    }

    if (!duplicateFound) {
      const requestData: renameWatchlistPayload = {
        params: {
          watchlistId: renameId
        },
        dataPayload: {
          watchlistName: watchlistName.trim()
        }
      }

      const renameWatchlistResponse = await actions.renameWatchlist(requestData)
      if (renameWatchlistResponse?._isCustomError) {
        enqueueNotistack({
          message: 'Request failed!'
        })
        console.log('failed API - renameWatchlist')

        return
      }
      const customAttributes = {
        'Event Status': 'Pass',
        Watchlist: 'Rename'
      }
      triggerCleverTapEvent('Watchlist_Manage', customAttributes)
      enqueueNotistack({
        message: 'Watchlist renamed!'
      })

      // remove prev name of the watchlist and insert entry inside pre kept names if name is of pattern Watchlist 1

      this.setState({
        selectedWatchList: {
          ...selectedWatchList,
          watchlistName
        }
      })
    }

    this.handleCloseRenameWatchlistNameModal()
    window.scrollTo(0, 0)
  }

  // Delete Watchlist set
  handleOpenDeleteWatchlistModal = () => {
    const { selectedWatchList } = this.state
    const { watchlistId } = selectedWatchList
    this.setState({
      openDeleteWatchlistModal: true,
      deleteId: watchlistId
    })
    this.setShowMenu(false, selectedWatchList)
  }

  handleCloseDeleteWatchlistModal = () => {
    this.setState({
      openDeleteWatchlistModal: false,
      deleteId: ''
    })
  }

  handleDeleteWatchlist = async () => {
    // update watchlist sequence logic post deletion and update redux, thus UI
    const { actions, breakpoints = {} } = this.props
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false
    const { deleteId, selectedWatchList } = this.state
    const deletedSecuritiesArray = selectedWatchList.watchlistSecurities

    const deleteWatchlistResponse = await actions.deleteWatchlist({
      watchlistId: deleteId
    })
    if (deleteWatchlistResponse?._isCustomError) {
      enqueueNotistack({
        message: 'Request failed!'
      })
      console.log('failed API - deleteWatchlist')

      return
    }
    const customAttributes = {
      'Event Status': 'Pass',
      Watchlist: 'Delete'
    }
    triggerCleverTapEvent('Watchlist_Manage', customAttributes)
    // write logic to remove watchlistIds from stockWatchlistMaster
    if (deletedSecuritiesArray?.length) {
      await actions.removeWatchlistIdFromStocks({
        watchListIdToDelete: deleteId,
        scriptIdArray: deletedSecuritiesArray
      })
    }

    await this.unsubscribeWatchListStocks()
    isDesktop && actions.setSelectedWatchlistTab(0)
    this.handleCloseDeleteWatchlistModal()
    window.scrollTo(0, 0)
    enqueueNotistack({
      message: 'Watchlist Deleted!'
    })
  }

  unsubscribeWatchListStocks = async () => {
    const { selectedWatchList } = this.state
    const deletedSecuritiesArray = selectedWatchList.watchlistSecurities
    this.odinWorkerManager = odinWorkerManager
    if (deletedSecuritiesArray && deletedSecuritiesArray.length > 0) {
      const scriptsArray: T_SCRIPTS_OBJ[] = []
      deletedSecuritiesArray.forEach((stock: T_SCRIPTS_OBJ) => {
        scriptsArray.push(stock)
      })
      await this.odinWorkerManager.unSubscribeStocks(scriptsArray)
    }
  }

  handleMobileSelectWatchlist = (item: watchlistObj) => {
    const { actions } = this.props
    const { selectedWatchList } = this.state
    if (selectedWatchList === item) {
      actions.setSelectedWatchlist({} as watchlistObj)
      this.setState({ selectedWatchList: -1 })
      return
    }
    this.setState({ selectedWatchList: item })
    actions.setSelectedWatchlist(item)
  }

  handleEdit = (item: watchlistObj) => {
    const { watchlistSecurities } = item
    const { actions } = this.props
    if (
      !watchlistSecurities ||
      (watchlistSecurities && watchlistSecurities.length === 0)
    ) {
      enqueueNotistack({
        message: 'No stocks added!'
      })
      return
    }
    actions.setSelectedWatchlist(item)
    this.navigateTo(APP_ROUTES.MOBILE_EDIT_WATCHLIST.pathname)
  }

  render() {
    const { breakpoints = {} } = this.props
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false
    const {
      showMenu,
      anchorEl,
      watchList,
      watchlistName,
      watchlistNameError,
      watchlistNameHelperText,
      openRenameWatchListNameModal,
      openDeleteWatchlistModal,
      selectedWatchList
    } = this.state

    return (
      <>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <DsBox
            sx={{
              p: 'var(--ds-spacing-bitterCold)',
              position: 'sticky',
              top: {
                xs: 'calc(var(--ds-spacing-molten) + var(--ds-spacing-molten) - var(--ds-spacing-glacial))',
                md: 'calc(var(--ds-spacing-blazing) + var(--ds-spacing-glacial))'
              },
              backgroundColor: 'var(--ds-colour-surfaceBackground)',
              zIndex: 2,
            }}
          >
            <DsBox
              sx={{
                border: '1px dashed var(--ds-colour-strokeDefault)',
                borderRadius: 'var(--ds-radius-glacial)',
                p: 'var(--ds-spacing-bitterCold)'
              }}
            >
              <CreateWatchlist componentType='manageWatchlist' />
            </DsBox>
          </DsBox>
          <DsBox
            sx={{
              p: 'var(--ds-spacing-bitterCold) var(--ds-spacing-bitterCold) var(--ds-spacing-bitterCold) var(--ds-spacing-bitterCold)',
              paddingBottom: {
                xs: '50px',
                md: 'var(--ds-spacing-zero)',
                height: '100vh'
              },
              overflowY: "auto"
            }}
          >
            <DsBox
              sx={{
                mb: {
                  md: 'calc(var(--ds-spacing-plasma) + var(--ds-spacing-plasma))',
                  xs: 'unset'
                },
              }}
            >
              <Droppable droppableId='list'>
                {provided => (
                  <DsBox {...provided.droppableProps} ref={provided.innerRef}>
                    {watchList.map((item: watchlistObj, i) => (
                      <Draggable
                        key={item.watchlistId}
                        draggableId={item.watchlistId}
                        index={i}
                        isDragDisabled={item.watchlistType === 'PRE-DEFINED'}
                      >
                        {provided => (
                          <DsBox
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            sx={{
                              position: 'relative',
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              alignItems: 'center',
                              backgroundColor:
                                'var(--ds-colour-surfaceBackground)',
                              border: {
                                md: 'unset',
                                xs: '1px solid var(--ds-colour-strokeDefault)'
                              },
                              borderRadius: {
                                md: 'unset',
                                xs: 'var(--ds-spacing-glacial)'
                              },
                              mb: {
                                md: 'unset',
                                xs: 'var(--ds-spacing-bitterCold)'
                              }
                            }}
                          >
                            <DsBox
                              {...(!isDesktop && {
                                onClick: () =>
                                  this.handleMobileSelectWatchlist(item)
                              })}
                              sx={{
                                width: '100%',
                                borderBottom:
                                  selectedWatchList.watchlistId ===
                                    item.watchlistId &&
                                    !isDesktop &&
                                    item.watchlistType !== 'PRE-DEFINED'
                                    ? '1px solid var(--ds-colour-strokeDefault)'
                                    : 'var(--ds-spacing-zero)',
                                p: !isDesktop
                                  ? 'var(--ds-spacing-bitterCold)'
                                  : 'var(--ds-spacing-glacial) 0'
                              }}
                            >
                              <DsGrid
                                container
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  alignItems: 'center'
                                }}
                              >
                                {isDesktop && (
                                  <DsGrid item xs={1}>
                                    {item.watchlistType !== 'PRE-DEFINED' && (
                                      <DsBox>
                                        <DsImage
                                          srcSet={LIST_DRAGGER}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        />
                                      </DsBox>
                                    )}
                                  </DsGrid>
                                )}

                                <DsGrid
                                  item
                                  xs={5}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                  }}
                                >
                                  <DsTypography
                                    sx={{ fontWeight: 700 }}
                                    variant='bodyRegularSmall'
                                  >
                                    {item.watchlistName}
                                  </DsTypography>
                                </DsGrid>
                                <DsGrid
                                  item
                                  xs={
                                    item.watchlistType !== 'PRE-DEFINED'
                                      ? 5
                                      : !isDesktop
                                        ? 7
                                        : 6
                                  }
                                  textAlign={'end'}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <DsTypography
                                    variant='bodyRegularSmall'
                                    color='var(--ds-colour-typoSecondary)'
                                  >
                                    {item.watchlistSecurities?.length
                                      ? item.watchlistSecurities.length
                                      : 0}{' '}
                                    stocks
                                  </DsTypography>
                                </DsGrid>
                                {item.watchlistType !== 'PRE-DEFINED' ? (
                                  <DsGrid
                                    item
                                    xs={isDesktop ? 1 : 2}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'flex-end'
                                    }}
                                    textAlign={'end'}
                                  >
                                    {isDesktop &&
                                      item.watchlistType !== 'PRE-DEFINED' ? (
                                      <DsIconButton>
                                        <DsBox
                                          key={item.watchlistSequenceNumber}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                          }}
                                          onClick={event => {
                                            this.setShowMenu(true, item)
                                            this.setAnchorEl(event)
                                          }}
                                        >
                                          <DsRemixIcon
                                            className='ri-more-2-line'
                                            fontSize='mild'
                                          />
                                        </DsBox>
                                        <DsMenu
                                          key={item.watchlistId}
                                          sx={{
                                            maxWidth: '100%',
                                            '& .MuiPaper-root': {
                                              minWidth: 300,
                                              boxShadow:
                                                '0px 4px 6px 0px rgba(0, 0, 0, 0.08)'
                                            }
                                          }}
                                          anchorEl={anchorEl}
                                          transformOrigin={{
                                            horizontal: 'right',
                                            vertical: 'top'
                                          }}
                                          anchorOrigin={{
                                            horizontal: 'right',
                                            vertical: 'bottom'
                                          }}
                                          id='basic-menu'
                                          open={showMenu}
                                          onClose={() =>
                                            this.setShowMenu(false, item)
                                          }
                                        >
                                          <DsMenuItem
                                            onClick={
                                              this
                                                .handleOpenRenameWatchlistModal
                                            }
                                          >
                                            Rename
                                          </DsMenuItem>
                                          <DsMenuItem
                                            onClick={
                                              this
                                                .handleOpenDeleteWatchlistModal
                                            }
                                          >
                                            Delete
                                          </DsMenuItem>
                                        </DsMenu>
                                      </DsIconButton>
                                    ) : (
                                      ''
                                    )}
                                    {!isDesktop &&
                                      item.watchlistType !== 'PRE-DEFINED' ? (
                                      <DsBox>
                                        <DsImage
                                          srcSet={LIST_DRAGGER}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        />
                                      </DsBox>
                                    ) : (
                                      ''
                                    )}
                                  </DsGrid>
                                ) : null}
                              </DsGrid>
                              {isDesktop && (
                                <DsGrid
                                  container
                                  sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    mt: 'var(--ds-spacing-bitterCold)'
                                  }}
                                >
                                  <DsGrid item xs={1}>
                                    <DsBox></DsBox>
                                  </DsGrid>
                                  <DsGrid item xs={11}>
                                    <DsDivider />
                                  </DsGrid>
                                </DsGrid>
                              )}
                            </DsBox>

                            {!isDesktop &&
                              selectedWatchList.watchlistId ===
                              item.watchlistId &&
                              item.watchlistType !== 'PRE-DEFINED' && (
                                <DsBox
                                  sx={{
                                    width: '100%',
                                    borderBottomLeftRadius:
                                      'var(--ds-spacing-glacial)',
                                    borderBottomRightRadius:
                                      'var(--ds-spacing-glacial)',
                                    backgroundColor:
                                      'var(--ds-colour-surfaceSecondary)'
                                  }}
                                >
                                  <DsGrid
                                    sx={{
                                      p: 'var(--ds-spacing-glacial) var(--ds-spacing-bitterCold)',
                                      display: 'flex',
                                      flexDirection: 'row'
                                    }}
                                    container
                                  >
                                    <DsGrid
                                      sx={{ alignSelf: 'center' }}
                                      item
                                      xs={
                                        item.watchlistType !== 'PRE-DEFINED'
                                          ? 3
                                          : 12
                                      }
                                    >
                                      <DsListItemButton
                                        sx={{
                                          display: 'block',
                                          p: 0,
                                          py: 'var(--ds-spacing-glacial) '
                                        }}
                                        onClick={() => this.handleEdit(item)}
                                      >
                                        <DsBox
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                            gap: 'var(--ds-spacing-frostbite)'
                                          }}
                                        >
                                          <DsRemixIcon
                                            className='ri-split-cells-vertical'
                                            fontSize='bitterCold'
                                            sx={{
                                              color:
                                                'var(--ds-colour-iconActionTertiary)'
                                            }}
                                          />
                                          <DsTypography
                                            variant='bodyBoldSmall'
                                            color={
                                              'var(--ds-colour-iconActionTertiary)'
                                            }
                                          >
                                            Edit
                                          </DsTypography>
                                        </DsBox>
                                      </DsListItemButton>
                                    </DsGrid>
                                    {item.watchlistType !== 'PRE-DEFINED' ? (
                                      <>
                                        <DsGrid
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                          }}
                                          item
                                          xs={1.5}
                                        >
                                          <DsDivider
                                            sx={{
                                              height: '100%',
                                              width: '1px'
                                            }}
                                            orientation='vertical'
                                          />
                                        </DsGrid>
                                        <DsGrid
                                          sx={{ alignSelf: 'center' }}
                                          item
                                          xs={3}
                                        >
                                          <DsListItemButton
                                            sx={{
                                              display: 'block',
                                              p: 0,
                                              py: 'var(--ds-spacing-glacial) '
                                            }}
                                            onClick={
                                              this
                                                .handleOpenRenameWatchlistModal
                                            }
                                          >
                                            <DsStack
                                              sx={{
                                                flexDirection: 'row',
                                                gap: 'var(--ds-spacing-frostbite)',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                              }}
                                            >
                                              <DsRemixIcon
                                                className='ri-pencil-line'
                                                fontSize='bitterCold'
                                                sx={{
                                                  color:
                                                    'var(--ds-colour-iconActionTertiary)'
                                                }}
                                              />

                                              <DsTypography
                                                variant='bodyBoldSmall'
                                                color={
                                                  'var(--ds-colour-iconActionTertiary)'
                                                }
                                              >
                                                Rename
                                              </DsTypography>
                                            </DsStack>
                                          </DsListItemButton>
                                        </DsGrid>
                                        <DsGrid
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                          }}
                                          item
                                          xs={1.5}
                                        >
                                          <DsDivider
                                            sx={{
                                              height: '100%',
                                              width: '1px'
                                            }}
                                            orientation='vertical'
                                          />
                                        </DsGrid>
                                        <DsGrid
                                          sx={{ alignSelf: 'center' }}
                                          item
                                          xs={3}
                                        >
                                          <DsListItemButton
                                            sx={{
                                              display: 'block',
                                              p: 0,
                                              py: 'var(--ds-spacing-glacial) '
                                            }}
                                            onClick={
                                              this
                                                .handleOpenDeleteWatchlistModal
                                            }
                                          >
                                            <DsStack
                                              sx={{
                                                flexDirection: 'row',
                                                gap: 'var(--ds-spacing-frostbite)',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                              }}
                                            >
                                              <DsRemixIcon
                                                className='ri-delete-bin-line'
                                                fontSize='bitterCold'
                                                sx={{
                                                  color:
                                                    'var(--ds-colour-iconActionTertiary)'
                                                }}
                                              />

                                              <DsTypography
                                                variant='bodyBoldSmall'
                                                color={
                                                  'var(--ds-colour-iconActionTertiary)'
                                                }
                                              >
                                                Delete
                                              </DsTypography>
                                            </DsStack>
                                          </DsListItemButton>
                                        </DsGrid>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </DsGrid>
                                </DsBox>
                              )}
                          </DsBox>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </DsBox>
                )}
              </Droppable>
            </DsBox>
          </DsBox>
        </DragDropContext>

        {/* POPUP FOR RENAME */}
        <DsPopup
          // DsDialogProps={{
          //   open: openRenameWatchListNameModal,
          //   onClose: this.handleCloseRenameWatchlistNameModal
          // }}
          open={openRenameWatchListNameModal}
          onClose={this.handleCloseRenameWatchlistNameModal}
          primaryButtonText='Save'
          secondaryButtonText='Cancel'
          showClose={!isDesktop ? true : false}
          primaryButtonProps={{
            onClick: this.handleRenameWatchlist,
            disabled: watchlistName.length === 0 || watchlistNameError
          }}
          secondaryButtonProps={{
            onClick: this.handleCloseRenameWatchlistNameModal
          }}
          title={WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.NAME_YOUR_WATCHLIST}
        >
          <DsTextField
            autoFocus
            fullWidth
            type='text'
            value={watchlistName}
            error={watchlistNameError}
            helperText={watchlistNameHelperText}
            onChange={this.handleWatchlistName}
          />
        </DsPopup>

        {/* POPUP FOR DELETE */}
        <DsPopup
          // DsDialogProps={{
          //   open: openDeleteWatchlistModal,
          //   onClose: this.handleCloseDeleteWatchlistModal
          // }}
          open={openDeleteWatchlistModal}
          onClose={this.handleCloseDeleteWatchlistModal}
          primaryButtonText='Yes'
          secondaryButtonText='No'
          showClose={!isDesktop ? true : false}
          primaryButtonProps={{
            onClick: this.handleDeleteWatchlist
          }}
          secondaryButtonProps={{
            onClick: this.handleCloseDeleteWatchlistModal
          }}
          title={WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.DELETE_YOUR_WATCHLIST}
        >
          <DsTypography variant='bodyRegularMedium'>
            {WATCHLIST_LABEL.MANAGE_WATCHLIST_SECTION.DELETE_SURE}
          </DsTypography>
        </DsPopup>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const watchListData = getWatchListData(state)
  const isAnySelectedWatchlist = getSelectedWatchlist(state)
  return {
    watchListData,
    isAnySelectedWatchlist
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateWatchlistSequence: (requestData: updateWatchlistsSequencePayload) =>
      dispatch(updateWatchlistSequenceAction(requestData)),
    setWatchlist: (requestData: IsetWatchlistReqData) =>
      dispatch(setWatchlist(requestData)),
    renameWatchlist: (requestData: renameWatchlistPayload) =>
      dispatch(renameWatchlistAction(requestData)),
    deleteWatchlist: (requestData: DeleteWatchlistParams) =>
      dispatch(deleteWatchlistAction(requestData)),
    setSelectedWatchlistTab: (selectedWatchlistTab: number | string) =>
      dispatch(
        selectWatchlistTab({
          watchlistTab: selectedWatchlistTab
        })
      ),
    setWatchlistIndex: (requestData: any) =>
      dispatch(setWatchlistIndex(requestData)),
    setSelectedWatchlist: (selectedWatchlist: watchlistObj) =>
      dispatch(selectWatchlist(selectedWatchlist)),
    removeWatchlistIdFromStocks: (requestData: {
      watchListIdToDelete: string
      scriptIdArray: T_SCRIPTS_OBJ[]
    }) => dispatch(removeWatchlistIdFromStocksAction(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(ManageWatchlist)
)
