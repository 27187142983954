import {
  DsDialog,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import NriContent from './NriContent'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'
import HeaderRightMenuAction from '../Header/HeaderRightMenuAction'
import { Subtract } from 'utility-types'
import withErrorConnect from '~/src/Lib/withErrorConnect'

export interface INriDialogProps extends IWithRouterProps {
  breakpoints: any
  onClick: () => void
  open: boolean
}

type ActionTypes = {}

type INriDialogStates = {}

class NriDialog extends React.Component<INriDialogProps, INriDialogStates> {
  render() {
    const { breakpoints = {}, onClick, open } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <DsDialog
        className='ApiError'
        sx={{
          '.MuiPaper-root': {
            flex: '1',
            display: 'flex',
            flexGrow: '1',
            minWidth: { xs: '100%', md: '894px' },
            minHeight: { xs: '100%', md: '523px' },
            padding: '0',
            borderRadius: {
              xs: 'var(--ds-spacing-zero)',
              md: 'var(--ds-spacing-bitterCold)'
            },
            '& .MuiStack-root': {
              flexGrow: '1',
              flex: '1',
              justifyContent: 'center',
              '& .MuiBox-root': {}
            },
            '& .MuiBox-root': {
              flexGrow: '1',
              display: 'flex'
            }
          }
        }}
        showClose={false}
        onClose={onClick}
        open={open}
        fullScreen={!isDesktop}
      >
        <NriContent onClick={onClick} isDesktop={isDesktop} />
      </DsDialog>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (
  dispatch: (props: Subtract<any, IwithBreakpoints>) => React.JSX.Element
) => ({
  actions: {}
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(NriDialog)
)
