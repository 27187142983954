import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { fetchWatchlistsActions, fetchWatchlistsServiceName } from '../Actions'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

async function fetchWatchlists() {
  const options = {
    url: '/watch-lists',
    method: 'GET',
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const fetchWatchlistsAction = serviceActionCreator(
  fetchWatchlistsActions,
  fetchWatchlists
)

export default fetchWatchlistsAction
export { fetchWatchlistsServiceName }
