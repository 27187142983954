import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import {
  _chain,
  _get,
  _groupBy,
  _map,
  _mapValues,
  _reduce,
  _set,
  _sortBy
} from '~/src/Utils/lodash'

const INITIAL_STATE = {
  selectedDate: '',
  groupedOptionData: {}
  // selectedDateOptions: {}
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    storeDerivativeIndex: (state, action) => {
      state.derivativeIndex = action?.payload
    },

    setSelectedDate: (state, action) => {
      state.selectedDate = action?.payload
    },

    generateGroupedOptions: (state, action) => {
      const { payload } = action
      const { options, futures } = payload
      const scripts = action?.payload?.options?.map((scriptId: any) =>
        (window as any).secMaster.getByScriptId(scriptId)
      )

      const sortedScripts = _sortBy(scripts, ['strikePrice'])
      const groupedScripts = _reduce(
        sortedScripts,
        (groupedScripts, script, index) => {
          const { expiryDate, optionType } = script
          const key = `${expiryDate}.${optionType}`
          let value = _get(groupedScripts, key)
          if (value) {
            value.push(script)
          } else {
            value = [script]
          }
          _set(groupedScripts, key, value)
          return groupedScripts
        },
        {}
      )

      const selectedDate = _sortBy(scripts, ['expiryDate'])?.[0]?.expiryDate

      state.groupedOptionData = groupedScripts
      const futureScripts = action?.payload?.futures?.map((scriptId: any) =>
        (window as any).secMaster.getByScriptId(scriptId)
      )
      Object.keys(state.groupedOptionData).map((item, i) => {
        futureScripts.map((elem: any) => {
          if (item == elem.expiryDate) {
            state.groupedOptionData[item] = {
              ...state.groupedOptionData[item],
              FUTURE: elem
            }
          }
        })
      })
      state.selectedDate = selectedDate
    },
    generateGroupedFutures: (state, action) => {
      // const { scriptId } = action?.payload
      // const scripts = (item: any) =>
      //   item.map((scriptId: any) =>
      //     (window as any).secMaster.getByScriptId(scriptId)
      //   )
      // console.log(state?.derivativeIndex, 'fut')
      // const groupedFutureData = _groupBy(
      //   state?.derivativeIndex?.[scriptId]?.['FUTURES'],
      //   scripts(state?.derivativeIndex?.[scriptId]?.['FUTURES'])
      // )
      // state.groupedFutureData = groupedFutureData
    }
  },

  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {}
}

const slice = createSlice(sliceOptions)

export const {
  storeDerivativeIndex,
  setSelectedDate,
  generateGroupedOptions,
  generateGroupedFutures
} = slice.actions

export default slice.reducer
