import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'

import {
  getAvailableCommodityLimitsActions,
  getAvailableCommodityLimitsServiceName
} from '../Actions'

import { getRequiredDataForHeaders } from '~/src/Utils/global'

async function getAvailableCommodityLimits(payload: any) {
  const options = {
    url: '/funds/inquiry/get-limits',
    method: 'POST',
    data: payload,
    headers: getRequiredDataForHeaders()
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getAvailableCommodityLimitsAction = serviceActionCreator(
  getAvailableCommodityLimitsActions,
  getAvailableCommodityLimits
)

export default getAvailableCommodityLimitsAction
export { getAvailableCommodityLimitsServiceName }
