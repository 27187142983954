import {
  DsBox,
  DsSkeleton,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import StockTickerOnHeader from './StockTickerOnHeader'

interface IStockDataOnHeaderProps extends IWithRouterProps {
  scriptId: string
  stockDetails: T_SCRIPTDATA
  showAddToWatchlist?: boolean
  showHoldings?: boolean
  handleOnClickOpenAddToWatchlist?: (scripId: string) => void
}

class StockDataOnHeader extends Component<IStockDataOnHeaderProps> {
  state = {}

  render() {
    const { stockDetails = {}, scriptId }: any = this.props
    const { coname, closeprice, segment, exchangeSymbol } = stockDetails || {}

    if (coname == null || coname == undefined) {
      return <DsSkeleton variant='text' width={80} height={30} />
    }
    return (
      <DsBox
        key={scriptId}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <DsBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 'var(--ds-spacing-glacial)',
            alignItems: 'center'
          }}
        >
          <DsTypography
            variant='subheadingSemiboldDefault'
            sx={{
              color: 'var(--ds-colour-surfaceBackground)'
            }}
          >
            {exchangeSymbol}
          </DsTypography>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 'var(--ds-spacing-quickFreeze)'
            }}
          >
            <StockTickerOnHeader
              scriptId={scriptId}
              closeprice={closeprice}
              segment={segment}
              type='changePer'
            />
          </DsStack>
        </DsBox>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const stockDetails = getStockMasterData(scriptId)(state)
  return {
    stockDetails
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(StockDataOnHeader)
