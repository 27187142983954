import { DsBox } from '@am92/react-design-system'
import React from 'react'
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError
} from 'react-router-dom'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import SomethingWentWrong from '~/src/Pages/SomethingWentWrong/SomethingWentWrong.Page'
import Loader from '../Loader'

export const ErrorBoundary = () => {
  const error = useRouteError()
  if (isRouteErrorResponse(error)) {
    const { status = '' } = error || {}
    if (status === 401) {
      const navigate = useNavigate()
      const location = window.location.pathname
      setTimeout(() => {
        navigate(APP_ROUTES.LOGIN.pathname, {
          state: { path: location }
        })
      }, 2000)
      return <Loader></Loader>
    }
  }

  return (
    <DsBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: {
          md: 'center',
          sx: 'start'
        },
        height: '100vh'
      }}
    >
      <DsBox>
        <SomethingWentWrong />
      </DsBox>
    </DsBox>
  )
}
