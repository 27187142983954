import React, { Component, Suspense } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { WEB_HTTP_CONTEXT, WEB_HTTP_REQ_HEADERS } from '@am92/web-http'
import Bowser from 'bowser'

import Loader from './Components/Loader'

import AppRouter from './AppRouter'

import performHandshake from './Services/performHandshake'

import { appContext, TAppDispatch, TAppStore } from './Configurations/AppStore'
import { asHttp } from './Configurations/WebHttp'
import { createOdinWorker, setDeviceDetails } from './Redux/Indicators/Reducer'
import {
  getCustomerName,
  getSSOAccessTokenSelector,
  getSSORefreshTokenSelector,
  getSubAccountIdSelector
} from './Redux/SSO/Selectors'
import {
  isSecurityMasterLoadedIndicator,
  isOdinWorkerCreated
} from './Redux/Indicators/Selectors'
import { odinWorkerManager } from './Workers/Odin/OdinWorkerManager'
import {
  modifyOrderServiceName,
  placeOrderServiceName
} from './Redux/Orders/actions'
import { getServiceSelector } from './Redux/ServiceTracker/Selectors'
import { DsFab, DsRemixIcon } from '@am92/react-design-system'

export interface IAppInitializerProps extends PropsFromRedux {}

export interface IAppInitializerState {
  hasError: boolean
  isLoading: boolean
  handshakeComplete: boolean
}

const DEFAULT_STATE: IAppInitializerState = {
  hasError: false,
  isLoading: false,
  handshakeComplete: false
}

class AppInitializer extends Component<
  IAppInitializerProps,
  IAppInitializerState
> {
  state = DEFAULT_STATE
  odinWorkerManager = null as any

  async componentDidMount() {
    this.setTokensIfExist()
    this.setDeviceDetails()
    await this.initialize()
  }

  async componentDidUpdate(prevProps: Readonly<IAppInitializerProps>) {
    if (
      prevProps.isSecurityMasterLoaded !== this.props.isSecurityMasterLoaded
    ) {
      const { handshakeComplete } = this.state
      if (this.props.isSecurityMasterLoaded && handshakeComplete) {
        this.setState(
          {
            isLoading: false
          },
          async () => {
            const { isOdinWorkerCreatedAlready } = this.props
            if (!isOdinWorkerCreatedAlready) {
              await this.createWorker()
            }
          }
        )
      }
    }
  }

  initialize = async () => {
    const { isOdinWorkerCreatedAlready, isSecurityMasterLoaded } = this.props
    this.setState({ isLoading: true })
    try {
      await performHandshake()
      this.setState({
        handshakeComplete: true
      })
      if (isSecurityMasterLoaded) {
        this.setState(
          {
            isLoading: false
          },
          async () => {
            // add this in callback
            if (!isOdinWorkerCreatedAlready) {
              await this.createWorker()
            }
          }
        )
      }
    } catch (error) {
      this.setState({ isLoading: false, hasError: true })
    }
  }

  setTokensIfExist = () => {
    const { accessToken, refreshToken } = this.props
    if (accessToken && refreshToken) {
      asHttp.context.set(WEB_HTTP_CONTEXT.ACCESS_TOKEN, accessToken)
      asHttp.context.set(
        WEB_HTTP_CONTEXT.AUTHENTICATION_TOKEN_KEY,
        WEB_HTTP_REQ_HEADERS.AUTH_TOKEN
      )
      asHttp.context.set(WEB_HTTP_CONTEXT.REFRESH_TOKEN, refreshToken)
    }
  }

  setDeviceDetails = () => {
    const { actions } = this.props
    actions.setDeviceDetails({
      deviceName:
        Bowser.parse(window.navigator.userAgent).browser.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).browser.version ||
        'Not Fetched',
      osType:
        Bowser.parse(window.navigator.userAgent).os.name +
          ' ' +
          Bowser.parse(window.navigator.userAgent).os.version || 'Not Fetched'
    })
  }

  createWorker = async () => {
    const { actions } = this.props
    this.odinWorkerManager = odinWorkerManager
    await this.odinWorkerManager.socketInitiate({
      USER_ID: 'API TEST',
      TOKEN:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklkIjoiMTQwNCIsImV4cCI6MTY4OTA0NDg4MCwiaWF0IjoxNjU3NTA4OTIxfQ.46QVfspRCeq9cz-lLai6panvyD8vOr6dxXCDCril7MM'
    })
    actions.createOdinWorkerIndicator(true)
    this.setState({ isLoading: false })
  }

  handleFeedBack = () => {
    const { customerName = 'failed', customerSubAccountId = 'failed' } =
      this.props

    window?.Tally?.openPopup('3lyEyN', {
      layout: 'modal',
      hiddenFields: {
        name: customerName,
        subAccountId: customerSubAccountId,
        platform: 'Web',
        version: process.env.APP_VERSION || '',
        network: ''
      }
    })
  }

  render() {
    const { handshakeComplete, hasError, isLoading } = this.state
    const { isModifyOrderLoading, isPlaceOrderLoading } = this.props
    return (
      <>
        {(isModifyOrderLoading || isPlaceOrderLoading) && <Loader />}
        <DsFab
          color='primary'
          onFocusVisible={function Ga() {}}
          sx={{
            position: 'fixed',
            bottom: 'var(--ds-spacing-bitterCold)',
            right: 'var(--ds-spacing-bitterCold)',
            display: { md: 'flex', xs: 'none' }
          }}
          onClick={this.handleFeedBack}
        >
          <DsRemixIcon className='ri-feedback-line' />
        </DsFab>
        <Suspense fallback={<Loader />}>
          {(!hasError && !isLoading && handshakeComplete && <AppRouter />) || (
            <Loader />
          )}
        </Suspense>
      </>
    )
  }
}

const mapStateToProps = (state: TAppStore) => {
  const accessToken = getSSOAccessTokenSelector(state)
  const refreshToken = getSSORefreshTokenSelector(state)
  const isSecurityMasterLoaded = isSecurityMasterLoadedIndicator(state)
  const isOdinWorkerCreatedAlready = isOdinWorkerCreated(state)
  const isPlaceOrderLoading =
    getServiceSelector(state, placeOrderServiceName) === 'LOADING'
  const isModifyOrderLoading =
    getServiceSelector(state, modifyOrderServiceName) === 'LOADING'
  const customerName = getCustomerName(state)
  const customerSubAccountId = getSubAccountIdSelector(state)

  return {
    accessToken,
    refreshToken,
    isSecurityMasterLoaded,
    isOdinWorkerCreatedAlready,
    isPlaceOrderLoading,
    isModifyOrderLoading,
    customerName,
    customerSubAccountId
  }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    setDeviceDetails: (data: any) => dispatch(setDeviceDetails(data)),
    createOdinWorkerIndicator: (requestData: boolean) =>
      dispatch(createOdinWorker(requestData))
  }
})

const connector = connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AppInitializer)
