import {
  DsPopup,
  DsBottomSheet,
  withBreakpoints,
  DsTypography
} from '@am92/react-design-system'
import React, { Component } from 'react'
import SipFormSection from './SipFormSection'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'

export class SipDiscardConfirmation extends Component<any> {
  render() {
    const { breakpoints, open, setOpen, handleDiscard } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    return (
      <>
        {isDesktop ? (
          <DsPopup
            DsDialogProps={{
              open: open,
              onClose: () => setOpen(!open)
            }}
            primaryButtonText='No'
            secondaryButtonText='Yes, Discard'
            showClose={true}
            primaryButtonProps={{
              onClick: () => setOpen(!open)
            }}
            secondaryButtonProps={{
              onClick: () => handleDiscard()
            }}
            title={'Discard Changes?'}
          >
            <DsTypography variant='bodyRegularMedium'>
              You have some unsaved changes. Are you sure you want to discard
              the changes?
            </DsTypography>
          </DsPopup>
        ) : (
          <DsBottomSheet
            open={open}
            onClick={() => setOpen(!open)}
            primaryButtonText='No'
            secondaryButtonText='Yes, Discard'
            showClose={true}
            primaryButtonProps={{
              onClick: () => setOpen(!open)
            }}
            secondaryButtonProps={{
              onClick: () => handleDiscard()
            }}
            title={'Discard Changes?'}
          >
            <DsTypography variant='bodyRegularMedium'>
              You have some unsaved changes. Are you sure you want to discard
              the changes?
            </DsTypography>
          </DsBottomSheet>
        )}
      </>
    )
  }
}

export default withBreakpoints(SipDiscardConfirmation)
