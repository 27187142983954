import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { withBreakpoints } from '@am92/react-design-system'
import {
  T_SCRIPTS_OBJ,
  setSecurityArray,
  setSortScriptSelectedOption,
  watchlistObj
} from '~/src/Redux/WatchList/Reducer'
import {
  getWatchListData,
  getSelectedWatchlistTab,
  getSortScriptOption,
  getDefaultWatchlistSecurityMapping
} from '~/src/Redux/WatchList/Selectors'
import { getFullStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import { StockMasterStore } from '~/src/Redux/StockMaster/Reducer'
import { T_SET_SECURITIES_REQ_DATA } from './WatchList'
import { T_SET_SORT_SCRIPT_OPTION_DATA } from '../Sidebar'
import SortWatchlistScripts from './SortWatchlistScripts'

export type WrapperActionTypes = {
  setSecurityArray: (reqData: T_SET_SECURITIES_REQ_DATA) => Promise<any>
  setSortScriptOption: (
    requestData: T_SET_SORT_SCRIPT_OPTION_DATA
  ) => Promise<any>
}

export interface ISortWatchlistScriptsWrapperProps extends IWithRouterProps {
  actions: WrapperActionTypes
  breakpoints: any
  watchListData: watchlistObj[]
  selectedWatchlistTab: string | number
  sortScriptSelectedOption: number
  stockMasterData: StockMasterStore
  defaultWatchlistSecurityMapping: {
    [key: string]: T_SCRIPTS_OBJ[]
  }
}

class SortWatchlistScriptsWrapper extends Component<ISortWatchlistScriptsWrapperProps> {
  state = {}

  render() {
    const {
      watchListData,
      selectedWatchlistTab,
      sortScriptSelectedOption,
      stockMasterData,
      defaultWatchlistSecurityMapping,
      actions
    } = this.props

    return (
      <SortWatchlistScripts
        watchListData={watchListData}
        selectedWatchlistTab={selectedWatchlistTab}
        sortScriptSelectedOption={sortScriptSelectedOption}
        stockMasterData={stockMasterData}
        defaultWatchlistSecurityMapping={defaultWatchlistSecurityMapping}
        wrapperActions={actions}
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  const watchListData = getWatchListData(state)
  const selectedWatchlistTab = getSelectedWatchlistTab(state)
  const currentWatchListId = watchListData?.[+selectedWatchlistTab]?.watchlistId
  const sortScriptSelectedOption =
    getSortScriptOption(currentWatchListId)(state) || 0
  const stockMasterData = getFullStockMasterData(state)
  const defaultWatchlistSecurityMapping =
    getDefaultWatchlistSecurityMapping(state)

  return {
    watchListData,
    selectedWatchlistTab,
    sortScriptSelectedOption,
    stockMasterData,
    defaultWatchlistSecurityMapping
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setSecurityArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setSecurityArray(requestData)),
    setSortScriptOption: (requestData: T_SET_SORT_SCRIPT_OPTION_DATA) =>
      dispatch(setSortScriptSelectedOption(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(
    mapStateToProps,
    mapDispatchToProps
  )(SortWatchlistScriptsWrapper)
)
