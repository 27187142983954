import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsFormControl,
  DsFormHelperText,
  DsInputBase,
  DsInputLabel,
  DsRemixIcon,
  DsStack,
  DsToggle,
  DsTypography
} from '@am92/react-design-system'
import ValiditySection from './ValiditySection'
import { changeOrderFormData } from '~/src/Redux/Orders/reducer'
import { FormikErrors } from 'formik'
import { FormValues } from './OrderFormSection'
import { isModifyOrderRequest } from '~/src/Redux/Orders/selectors'
import NumberFormatter from '../../NumberFormatter'
import { triggerCleverTapEvent } from '~/src/Utils/global'
import DisclosedQty from './DisclosedQty'

type ActionTypes = {
  changeOrderFormData: (payload: { key: string; data: any }) => Promise<any>
}

export interface IAdvanceOptionsNewState {
  showAdvanceOption: boolean
}

export interface IAdvanceOptionsNewProps extends IWithRouterProps {
  actions: ActionTypes
  triggerPriceError: boolean
  triggerPriceHelperText: string | undefined
  triggerPrice: number

  disclosedQtyError: boolean
  disclosedQtyHelperText: string | undefined
  disclosedQty: number

  validity: string
  afterMarketOrder: boolean

  handleChange: (e: React.ChangeEvent<any>) => void
  orderBuyOrSell: string

  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>

  orderScriptId: string
  isModifyOrder: boolean
  stockDetails: any
  formikBag: any
}

class AdvanceOptionsNew extends Component<
  IAdvanceOptionsNewProps,
  IAdvanceOptionsNewState
> {
  constructor(props: IAdvanceOptionsNewProps) {
    super(props)
    this.state = {
      showAdvanceOption: false
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<IAdvanceOptionsNewProps>
  ): Promise<void> {
    const { orderScriptId } = this.props
    const { orderScriptId: PREV_ORDERSCRIPTID } = prevProps

    if (PREV_ORDERSCRIPTID !== orderScriptId) {
      this.setState({ showAdvanceOption: false })
    }
  }

  handleOpenCloseAdvanceOption = () => {
    const { showAdvanceOption } = this.state
    const { orderBuyOrSell } = this.props
    if (!showAdvanceOption) {
      const customAttributes = {
        'Event Status': 'Pass',
        'Screen Name': 'Order Form',
        Action: orderBuyOrSell
      }
      triggerCleverTapEvent('Transaction_Advanced_Options', customAttributes)
    }
    this.setState({ showAdvanceOption: !showAdvanceOption })
  }

  handleAMOToggle = (name: string, value: boolean) => {
    const { actions } = this.props
    actions.changeOrderFormData({
      key: name,
      data: value
    })
  }

  renderAdvanceOptionForm = () => {
    const {
      triggerPriceError,
      triggerPriceHelperText,
      triggerPrice,
      disclosedQtyError,
      disclosedQtyHelperText,
      disclosedQty,
      validity,
      afterMarketOrder,
      handleChange,
      orderBuyOrSell,
      setFieldValue,
      isModifyOrder,
      stockDetails,
      formikBag
    } = this.props
    const { segment = 'EQ' } = stockDetails || {}

    const isBuyOrder = orderBuyOrSell === 'BUY'
    const isActive =
      document.getElementById('triggerPrice') === document.activeElement
    return (
      <DsStack
        sx={{
          marginTop: {
            xs: 'var(--ds-spacing-frostbite)',
            md: 'var(--ds-spacing-bitterCold)'
          }
        }}
        spacing={8}
      >
        {/*Trigger Price Section*/}
        <DsFormControl error={isActive && triggerPriceError}>
          <DsStack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <DsInputLabel
              label={
                <DsTypography variant='bodyRegularMedium'>
                  Trigger Price
                </DsTypography>
              }
              sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
            />
            <DsInputBase
              id='triggerPrice'
              inputComponent={NumberFormatter}
              name='triggerPrice'
              onChange={handleChange}
              value={triggerPrice}
              placeholder='+ Add Price'
              disabled={validity === 'IOC'}
              inputProps={{
                inputMode: 'numeric'
              }}
            />
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 'var(--ds-spacing-quickFreeze)',
              marginLeft: 'var(--ds-spacing-quickFreeze)',
              marginTop: 'var(--ds-spacing-glacial)'
            }}
          >
            {(triggerPriceError && isActive && (
              <DsRemixIcon
                className='ri-error-warning-fill'
                fontSize='small'
                sx={{ color: 'var(--ds-colour-supportNegative)' }}
              />
            )) ||
              null}
            <DsFormHelperText
              error={isActive && triggerPriceError}
              sx={{
                marginTop: 'var(--ds-spacing-zero)',
                padding: 'var(--ds-spacing-zero)',
                minHeight: 'unset'
              }}
            >
              {isActive && triggerPriceHelperText}
            </DsFormHelperText>
          </DsStack>
        </DsFormControl>

        <ValiditySection
          validity={validity}
          orderBuyOrSell={orderBuyOrSell}
          setFieldValue={setFieldValue}
        />

        {/*Disclosed Quantity Section*/}
        {(validity !== 'IOC' && segment !== 'FO' && (
          <DisclosedQty
            handleChange={handleChange}
            disclosedQty={disclosedQty}
            optionalLabel={false}
            disclosedQtyError={disclosedQtyError}
            disclosedQtyHelperText={disclosedQtyHelperText}
          />
        )) ||
          null}

        {/*AMO Section*/}
        <DsStack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <DsTypography variant='bodyRegularMedium'>
            After Market Order
          </DsTypography>
          <DsToggle
            disabled={isModifyOrder}
            onChange={(name, value) => {
              return handleChange({ target: { name, value } } as any)
            }}
            name='afterMarketOrder'
            value={afterMarketOrder}
            sx={
              (isModifyOrder &&
                afterMarketOrder && {
                '.MuiSwitch-track': {
                  backgroundColor: `var(--ds-colour-${isBuyOrder ? 'supportPositive' : 'supportNegative'
                    }) !important`
                }
              }) ||
              (!isModifyOrder &&
                afterMarketOrder && {
                '.MuiSwitch-track': {
                  backgroundColor: `var(--ds-colour-${isBuyOrder ? 'supportPositive' : 'supportNegative'
                    }) !important`
                }
              }) ||
              {}
            }
          />
        </DsStack>
      </DsStack>
    )
  }

  renderClosedAdvanceOptionDiv = () => {
    let { triggerPrice, disclosedQty, orderBuyOrSell, stockDetails = {} } = this.props
    const { segment } = stockDetails
    const isBUY = orderBuyOrSell === 'BUY'
    const orderColor = isBUY ? 'supportPositive' : 'supportNegative'

    if (!triggerPrice) {
      triggerPrice = 0
    }
    if (!disclosedQty) {
      disclosedQty = 0
    }

    return (
      <>
        <DsStack
          onClick={this.handleOpenCloseAdvanceOption}
          sx={{
            cursor: 'pointer'
          }}
        >
          {triggerPrice === 0 && disclosedQty === 0 ? (
            <DsTypography
              variant='bodyRegularSmall'
              sx={{ color: 'var(--ds-colour-typoSecondary)' }}
            >
              Trigger Price, Validity{segment === "EQ" ? ", Disclosed quantity" : ""}
            </DsTypography>
          ) : triggerPrice === 0 && disclosedQty !== 0 ? (
            <DsTypography
              variant='bodyRegularSmall'
              sx={{ color: 'var(--ds-colour-typoSecondary)' }}
            >
              <DsTypography
                variant='bodyRegularSmall'
                sx={{ color: `var(--ds-colour-${orderColor})` }}
              >
                Added
              </DsTypography>{' '}
              : Disclosed quantity {disclosedQty}
            </DsTypography>
          ) : triggerPrice !== 0 && disclosedQty === 0 ? (
            <DsTypography
              variant='bodyRegularSmall'
              sx={{ color: 'var(--ds-colour-typoSecondary)' }}
            >
              <DsTypography
                variant='bodyRegularSmall'
                sx={{ color: `var(--ds-colour-${orderColor})` }}
              >
                Added
              </DsTypography>{' '}
              : Trigger Price ₹{triggerPrice}
            </DsTypography>
          ) : (
            <DsTypography
              variant='bodyRegularSmall'
              sx={{ color: 'var(--ds-colour-typoSecondary)' }}
            >
              <DsTypography
                variant='bodyRegularSmall'
                sx={{ color: `var(--ds-colour-${orderColor})` }}
              >
                Added
              </DsTypography>{' '}
              : Trigger Price {triggerPrice} and more...
            </DsTypography>
          )}
        </DsStack>
      </>
    )
  }

  render() {
    const { showAdvanceOption } = this.state
    return (
      <DsStack
        sx={{
          borderTop: '1px solid var(--ds-colour-strokeDefault)',
          borderBottom: '1px solid var(--ds-colour-strokeDefault)',
          padding: {
            md: 'var(--ds-spacing-bitterCold) var(--ds-spacing-mild)',
            xs: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)'
          },
          backgroundColor: showAdvanceOption
            ? 'unset'
            : 'var(--ds-colour-surfaceSecondary)',
          gap: 'var(--ds-spacing-quickFreeze)'
        }}
      >
        <DsStack
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            cursor: 'pointer'
          }}
          onClick={this.handleOpenCloseAdvanceOption}
        >
          <DsTypography variant='bodyBoldMedium'>Advanced options</DsTypography>
          <DsRemixIcon
            className={`ri-arrow-${showAdvanceOption ? 'up' : 'down'}-s-line`}
          />
        </DsStack>
        <DsStack>
          {!showAdvanceOption
            ? this.renderClosedAdvanceOptionDiv()
            : this.renderAdvanceOptionForm()}
        </DsStack>
      </DsStack>
    )
  }
}

const mapStateToProps = (state: any) => {
  const isModifyOrder = isModifyOrderRequest(state)
  return {
    isModifyOrder
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    changeOrderFormData: (payload: { key: string; data: any }) =>
      dispatch(changeOrderFormData(payload))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(AdvanceOptionsNew)
