import React from 'react'

// DS Components
import {
  DsBottomNavigation,
  DsBottomNavigationAction,
  DsRemixIcon,
  withBreakpoints
} from '@am92/react-design-system'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'

// Constants
import { tabRoutes } from './BottonNavigation.constants'
import { tabIndicator } from '~/src/Redux/Indicators/Selectors'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { setTabIndicator } from '~/src/Redux/Indicators/Reducer'
import { triggerCleverTapEvent } from '~/src/Utils/global'

type ActionTypes = {
  setTabIndicator: (reqData: number) => any
}

export interface IBottomNavigationProps extends IWithRouterProps {
  actions: ActionTypes
  tabValue: number
}

class BottomNavigation extends React.Component<IBottomNavigationProps> {
  state = { value: this.props.tabValue }

  componentDidUpdate(prevProps: Readonly<IBottomNavigationProps>): void {
    const { tabValue } = this.props
    if (prevProps.tabValue !== tabValue) {
      this.setState({ value: tabValue })
    }
  }

  navigateTo = (newValue: number) => {
    if (newValue === 4) {
      const customAttributes = {
        "Event Status": 'Pass',
        "Action": "Bottom Navigation",
      }
      triggerCleverTapEvent('Watchlist', customAttributes)
    }
    const { navigateTo, actions } = this.props
    actions.setTabIndicator(newValue)
    navigateTo(tabRoutes[newValue])
  }

  render() {
    const { value } = this.state
    return (
      <DsBottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          this.navigateTo(newValue)
        }}
        sx={{
          position: 'fixed',
          bottom: 0,
          display: { md: 'none', xs: 'flex' },
          width: '-webkit-fill-available',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)'
        }}
      >
        <DsBottomNavigationAction
          sx={{ width: '20%', minWidth: '0' }}
          label="Dashboard"
          icon={
            <DsRemixIcon
              className={
                value === 0 ? 'ri-dashboard-fill' : 'ri-dashboard-line'
              }
              fontSize="mild"
              style={{
                padding: 'var(--ds-spacing-glacial)'
              }}
            />
          }
        />
        <DsBottomNavigationAction
          sx={{ width: '20%', minWidth: '0' }}
          label="Research"
          icon={
            <DsRemixIcon
              className={
                value === 1 ? 'ri-file-search-fill' : 'ri-file-search-line'
              }
              fontSize="mild"
              style={{ padding: 'var(--ds-spacing-glacial)' }}
            />
          }
        />
        <DsBottomNavigationAction
          sx={{ width: '20%', minWidth: '0' }}
          label="Orders"
          icon={
            <DsRemixIcon
              className={
                value === 2 ? 'ri-file-list-2-fill' : 'ri-file-list-2-line'
              }
              fontSize="mild"
              style={{ padding: 'var(--ds-spacing-glacial)' }}
            />
          }
        />
        <DsBottomNavigationAction
          sx={{ width: '20%', minWidth: '0' }}
          label="Portfolio"
          icon={
            <DsRemixIcon
              className={value === 3 ? 'ri-handbag-fill' : 'ri-handbag-line'}
              fontSize="mild"
              style={{ padding: 'var(--ds-spacing-glacial)' }}
            />
          }
        />
        <DsBottomNavigationAction
          sx={{ width: '20%', minWidth: '0' }}
          label="Watchlist"
          icon={
            <DsRemixIcon
              className={
                value === 4 ? 'ri-bookmark-fill' : 'ri-bookmark-line'
              }
              fontSize="mild"
              style={{ padding: 'var(--ds-spacing-glacial)' }}
            />
          }
        />
      </DsBottomNavigation>
    )
  }
}

const mapStateToProps = (state: any) => {
  const tabValue = tabIndicator(state)
  return {
    tabValue
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setTabIndicator: (tab: number) => dispatch(setTabIndicator(tab))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(BottomNavigation)
)
