import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { withBreakpoints } from '@am92/react-design-system'
import ContainerTab from '../../RenderTabs/ContainerTabs'
import { getRenderTabsList } from '~/src/Redux/WatchList/Selectors'
import { IrenderTabObj } from '../Sidebar'

type ActionTypes = {}

export interface ISidebarContainerProps extends IWithRouterProps {
  actions: ActionTypes
  breakpoints: any
  selectedWatchlistTab: number | string
  watchlistCreated: boolean
  handleSelectedWatchlistTab: (selectedTab: string) => void
  renderTabs: IrenderTabObj[]
}

class SidebarContainer extends Component<ISidebarContainerProps> {
  state = {}

  render() {
    const {
      selectedWatchlistTab,
      watchlistCreated,
      handleSelectedWatchlistTab,
      breakpoints = {},
      renderTabs
    } = this.props

    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false

    return (
      <>
        <ContainerTab
          renderTabs={renderTabs}
          returnValue="id"
          enableArrow={true}
          containedTabs={isDesktop}
          value={+selectedWatchlistTab}
          onChange={e => {
            handleSelectedWatchlistTab(e)
          }}
          watchlistCreated={watchlistCreated}
          isLowerCasingNeeded={true}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const renderTabs = getRenderTabsList(state)

  return {
    renderTabs
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SidebarContainer)
)
