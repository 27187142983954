import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import {
  updateScriptsSequenceActions,
  updateScriptsSequenceServiceName
} from '../Actions'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export interface updateScriptsSequenceObj {
  scriptId: string
  sequenceNumber: number
}

export interface updateScriptsSequencePayload {
  params: {
    watchlistId: string
  }
  dataPayload: {
    watchlistSecurities: updateScriptsSequenceObj[] | null
  }
}

async function updateScriptsSequence(reqData: updateScriptsSequencePayload) {
  const { params, dataPayload } = reqData
  const { watchlistId } = params

  const options = {
    url: `/watch-lists/${watchlistId}/update`,
    method: 'POST',
    data: dataPayload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const updateScriptsSequenceAction = serviceActionCreator(
  updateScriptsSequenceActions,
  updateScriptsSequence
)

export default updateScriptsSequenceAction
export { updateScriptsSequenceServiceName }
