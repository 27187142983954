import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { SLICE_NAME } from './Selectors'
import { stockDetailsActions } from './Actions'

const INITIAL_STATE = {}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(stockDetailsActions.success, (state, { payload }) => {
      state.selected = payload
    })
  }
}

const SelectedStockSlice = createSlice(sliceOptions)
export default SelectedStockSlice.reducer
