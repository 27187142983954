import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { PureComponent } from 'react';
import { DsFormControl } from '../DsFormControl';
import { DsTextField } from '../DsTextField';
import { DsTypography } from '../DsTypography';
import { DsTextAreaDefaultState } from './DsTextArea.Types';
export class DsTextArea extends PureComponent {
    state = DsTextAreaDefaultState;
    areRef = React.createRef();
    handleChange = (event) => {
        const { onChange } = this.props;
        const { target } = event;
        const { value = '' } = target;
        let count = value.length;
        this.setState({ count });
        if (onChange && typeof onChange === 'function') {
            onChange(event);
        }
    };
    componentDidMount() {
        const { value = '' } = this.props;
        const count = value.length;
        this.setState({ count });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { count: currentStateCount } = this.state;
        const { value } = this.props;
        const count = value?.length;
        if (count && currentStateCount !== count) {
            this.setState({ count });
        }
    }
    render() {
        const { maxLength, hideCharacterCount, helperText, inputProps, ref, fullWidth, ...rest } = this.props;
        const { count } = this.state;
        // Provide counter if MaxLength Provided
        const hasMaxLength = !!maxLength;
        const maxLengthHandlingInputStyle = hasMaxLength
            ? { mb: 'var(--ds-spacing-bittercold)' }
            : {};
        const counterStyle = {
            position: 'absolute',
            bottom: '8px',
            right: '8px',
            overflow: 'auto'
        };
        if (helperText) {
            // Adjust the helper text size
            counterStyle.bottom = 'calc(8px + 36px)';
        }
        return (_jsxs(DsFormControl, { fullWidth: fullWidth, children: [_jsx(DsTextField, { maxRows: 3, minRows: 3, ...rest, fullWidth: fullWidth, onChange: this.handleChange, multiline: true, inputProps: {
                        ref: ref || this.areRef,
                        sx: {
                            minWidth: '288px',
                            ...inputProps?.sx,
                            ...maxLengthHandlingInputStyle
                        },
                        ...inputProps,
                        maxLength: maxLength || ''
                    }, helperText: helperText, 
                    // TODO: To be fixed when we fix the sx passing to inputbase
                    style: { paddingBottom: 'var(--ds-spacing-mild)' } }), hasMaxLength && !hideCharacterCount && (_jsx(DsTypography, { sx: counterStyle, variant: "supportRegularFootnote", children: `${count}/${maxLength}` }))] }));
    }
}
