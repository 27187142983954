import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'

import { SLICE_NAME } from './Selectors'
import { updateStockTickerAction } from './Actions'

export interface ITicker {
  LTP: number
  NET_CHANGE: string
  PERCENT_CHANGE: string
  LTT: number
  B5BUY: any
  B5SELL: any
  VOLUME: any
  CLOSE_PRICE: any
  OPEN_PRICE: any
  AVERAGE_TRADE_PRICE: any
  LOW_PRICE: any
  HIGH_PRICE: any
  LIFE_TIME_HIGH: any
  LIFE_TIME_LOW: any
  TOTAL_BUY_QTY: any
  TOTAL_SELL_QTY: any
  DAILY_PRICE_RANGE: any
  OPEN_INTEREST: any
}

export interface TickerStore {
  [key: number | string]: ITicker
}

const INITIAL_STATE: TickerStore = {}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    //updateStockTicker
    builder.addCase(updateStockTickerAction, (state, { payload }) => {
      const {
        NET_CHANGE,
        LTP,
        PERCENT_CHANGE,
        scriptId,
        LTT,
        B5BUY,
        B5SELL,
        VOLUME,
        OPEN_PRICE,
        CLOSE_PRICE,
        AVERAGE_TRADE_PRICE,
        LOW_PRICE,
        HIGH_PRICE,
        LIFE_TIME_HIGH,
        LIFE_TIME_LOW,
        TOTAL_BUY_QTY,
        TOTAL_SELL_QTY,
        DAILY_PRICE_RANGE,
        OPEN_INTEREST
      } = payload

      state[scriptId] = {
        LTP: LTP?.toFixed(2),
        NET_CHANGE,
        PERCENT_CHANGE,
        LTT,
        B5BUY,
        B5SELL,
        VOLUME,
        AVERAGE_TRADE_PRICE,
        LOW_PRICE,
        HIGH_PRICE,
        LIFE_TIME_LOW,
        LIFE_TIME_HIGH,
        CLOSE_PRICE,
        OPEN_PRICE,
        TOTAL_BUY_QTY,
        TOTAL_SELL_QTY,
        DAILY_PRICE_RANGE,
        OPEN_INTEREST
      }
    })
  }
}

const ticketSlice = createSlice(sliceOptions)
export default ticketSlice.reducer
