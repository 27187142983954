export const DSTYPOGRAPHY_TOKENS = [
    'displayBoldLarge',
    'displayBoldMedium',
    'displayBoldSmall',
    'displayBoldItalicLarge',
    'displayBoldItalicMedium',
    'displayBoldItalicSmall',
    'headingBoldExtraLarge',
    'headingBoldLarge',
    'headingBoldMedium',
    'headingBoldSmall',
    'headingBoldExtraSmall',
    'subheadingSemiboldLarge',
    'subheadingSemiboldDefault',
    'headingBoldItalicExtraLarge',
    'headingBoldItalicLarge',
    'headingBoldItalicMedium',
    'headingBoldItalicSmall',
    'headingBoldItalicExtraSmall',
    'bodyRegularLarge',
    'bodyRegularMedium',
    'bodyRegularSmall',
    'bodyBoldLarge',
    'bodyBoldMedium',
    'bodyBoldSmall',
    'supportRegularInfo',
    'supportRegularFootnote',
    'supportBoldTextButton',
    'supportRegularMetadata'
];
