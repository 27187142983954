{
  "v": "5.7.4",
  "fr": 29.9700012207031,
  "ip": 0,
  "op": 75.0000030548126,
  "w": 220,
  "h": 224,
  "nm": "Comp 5",
  "ddd": 0,
  "assets": [
    {
      "id": "comp_0",
      "layers": [
        {
          "ddd": 0,
          "ind": 2,
          "ty": 3,
          "nm": "Null 1",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 0, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [160, 194, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [82, 82, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "ip": 0,
          "op": 378.000015396256,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "dash-1",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-25.241, 51.614, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [4.302, 4.302, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [182.927, 182.927, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [3.303, 3.302],
                        [-3.303, -3.302]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 2, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [4.302, 4.302], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.302], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 21.706,
                    "s": [100]
                  },
                  { "t": 35.4425014436026, "s": [0] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 21.706,
                    "s": [100]
                  },
                  { "t": 35.4425014436026, "s": [0] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 22.0000008960784,
          "op": 877.100035725015,
          "st": -22.0000008960784,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "dash-2",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [44.467, 0.795, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [11.358, 11.257, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [182.927, 182.927, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [10.357, -10.258],
                        [-10.357, 10.258]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 2, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [11.358, 11.257], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.302], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 29.199,
                    "s": [100]
                  },
                  { "t": 40.4375016470531, "s": [0] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 29.199,
                    "s": [100]
                  },
                  { "t": 40.4375016470531, "s": [0] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 29.0000011811942,
          "op": 877.100035725015,
          "st": -22.0000008960784,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          "nm": "Shape Layer 4",
          "parent": 6,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [-6.559, 15.172, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
            "s": { "a": 0, "k": [123.387, 123.387, 100], "ix": 6, "l": 2 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [-17.136, 27.185]
                      ],
                      "o": [
                        [13.086, 7.272],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-46.074, -6.315],
                        [-6.815, 15.5],
                        [44.593, -66.056]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 20, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [-4, 7], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 9.672, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.148], "y": [1] },
                    "o": { "x": [0.471], "y": [0.002] },
                    "t": 18,
                    "s": [0]
                  },
                  { "t": 38.0000015477717, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 0, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 18.000000733155,
          "op": 396.000016129411,
          "st": 18.000000733155,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          "nm": "Shape Layer 3",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [0, -0.5, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-4, -8.5, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.471, 0.471, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.48, 0.48, 0.333], "y": [0, 0, 0] },
                  "t": 7,
                  "s": [0, 0, 100]
                },
                { "t": 25.0000010182709, "s": [78.151, 78.151, 100] }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "d": 1,
                  "ty": "el",
                  "s": { "a": 0, "k": [238, 238], "ix": 2 },
                  "p": { "a": 0, "k": [0, 0], "ix": 3 },
                  "nm": "Ellipse Path 1",
                  "mn": "ADBE Vector Shape - Ellipse",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0, 0.352941176471, 0.294117647059, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [-4, -8.5], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Ellipse 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 7.00000028511585,
          "op": 385.000015681372,
          "st": 7.00000028511585,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 4,
          "nm": "Shape Layer 2",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [0, -0.5, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-4, -8.5, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.471, 0.471, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.48, 0.48, 0.333], "y": [0, 0, 0] },
                  "t": 3,
                  "s": [0, 0, 100]
                },
                { "t": 21.0000008553475, "s": [96.639, 96.639, 100] }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "d": 1,
                  "ty": "el",
                  "s": { "a": 0, "k": [238, 238], "ix": 2 },
                  "p": { "a": 0, "k": [0, 0], "ix": 3 },
                  "nm": "Ellipse Path 1",
                  "mn": "ADBE Vector Shape - Ellipse",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.156862745098, 0.454901990704, 0.403921598547, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [-4, -8.5], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Ellipse 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 3.00000012219251,
          "op": 381.000015518448,
          "st": 3.00000012219251,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 4,
          "nm": "Shape Layer 1",
          "parent": 2,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": { "a": 0, "k": [0, -0.5, 0], "ix": 2, "l": 2 },
            "a": { "a": 0, "k": [-4, -8.5, 0], "ix": 1, "l": 2 },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": { "x": [0.471, 0.471, 0.667], "y": [1, 1, 1] },
                  "o": { "x": [0.48, 0.48, 0.333], "y": [0, 0, 0] },
                  "t": 0,
                  "s": [0, 0, 100]
                },
                {
                  "i": { "x": [0.833, 0.833, 0.833], "y": [1, 1, 1] },
                  "o": { "x": [0.333, 0.333, 0.333], "y": [0, 0, 0] },
                  "t": 18,
                  "s": [110.084, 110.084, 100]
                },
                {
                  "i": {
                    "x": [0.833, 0.833, 0.833],
                    "y": [0.833, 0.833, 0.833]
                  },
                  "o": {
                    "x": [0.167, 0.167, 0.167],
                    "y": [0.167, 0.167, 0.167]
                  },
                  "t": 141,
                  "s": [110.084, 110.084, 100]
                },
                {
                  "i": {
                    "x": [0.833, 0.833, 0.833],
                    "y": [0.833, 0.833, 0.833]
                  },
                  "o": {
                    "x": [0.167, 0.167, 0.167],
                    "y": [0.167, 0.167, 0.167]
                  },
                  "t": 143,
                  "s": [94.582, 94.582, 100]
                },
                { "t": 146.000005946702, "s": [0, 0, 100] }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "d": 1,
                  "ty": "el",
                  "s": { "a": 0, "k": [238, 238], "ix": 2 },
                  "p": { "a": 0, "k": [0, 0], "ix": 3 },
                  "nm": "Ellipse Path 1",
                  "mn": "ADBE Vector Shape - Ellipse",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [0.58431372549, 0.725490196078, 0.701960784314, 1],
                    "ix": 4
                  },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [-4, -8.5], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Ellipse 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 378.000015396256,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "Check_Mark 6",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [110, 202, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [160, 284, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "w": 320,
      "h": 568,
      "ip": 0,
      "op": 378.000015396256,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
