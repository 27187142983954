import {
  DsBox,
  DsButton,
  DsDatePicker,
  DsFormControl,
  DsFormControlLabel,
  DsImage,
  DsInputLabel,
  DsPopup,
  DsRadio,
  DsRadioGroup,
  DsSelect,
  DsStack,
  DsTab,
  DsTabs,
  DsTextField,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import { addDays, addYears, format, parse } from 'date-fns'
import React, { Component } from 'react'
import { SIP_HEADER } from '~/src/Constants/ASSET_MAP'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { updateSipInfo } from '~/src/Redux/Orders/reducer'
import { getSelectedScripts, getSipInfo } from '~/src/Redux/Orders/selectors'
import {
  updateIsEditingSip,
  updateIsReactivating,
  updateOrderSipPageModalIndicator
} from '~/src/Redux/Indicators/Reducer'
import { To } from 'react-router-dom'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { getDeviceActiveScreen } from '~/src/Utils/deviceDetails'
import CustomDuration from './CustomDuration'
import { isModifingSelector } from '~/src/Redux/Indicators/Selectors'

export const FREQUENCY = [
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Daily', value: 'Daily' },
  { label: 'Fortnightly', value: 'Fortnightly' }
]
export const DURATION = [
  { label: '1 Year', value: '1 Year' },
  { label: '2 Year', value: '2 Year' },
  { label: '3 Year', value: '3 Year' },
  { label: 'Custom Duration', value: 'Custom Duration' }
]

type ActionTypes = {
  updateSipNameIndicator: (iten: any) => any
  updateSipOpenState: (iten: any) => any
  updateSipInfo: (iten: any) => any
  updateIsEditingSip: (iten: boolean) => any
  updateIsReactivating: (iten: boolean) => any
}
export interface ISipFormSectionProps extends IWithRouterProps {
  actions: ActionTypes
  setShowSelecetStock: any
  showSelecetStock: any
  sipInfo: any
  type?: string
  breakpoints: any
  isModifing: any
  selectedSipScripts: any
  setShowSearch: (item: boolean) => any
}

export class SipFormSection extends Component<ISipFormSectionProps> {
  state = {
    showCustomModal: false,
    showFrequency: false,
    showDuration: false,
    customError: false,
    sipNameError: false,
    sipNameHelperText: ''
  }

  validateSipName = (name: string) => {
    const response: any = { _isCustomError: false }
    const specialCharRegex = /[^a-zA-Z0-9]/

    this.setState({
      sipNameError: false,
      sipNameHelperText: ''
    })

    if (name === '') {
      this.setState({
        sipNameError: true,
        sipNameHelperText: 'Name can’t be empty'
      })
      return false
    } else if (specialCharRegex.test(name)) {
      this.setState({
        sipNameError: true,
        sipNameHelperText: 'Special characters are not allowed'
      })
      return false
    } else if (name.length >= 18) {
      const errorMessage = 'Sip name should be 1-18 characters long'
      this.setState({
        sipNameError: true,
        sipNameHelperText: errorMessage
      })
      return false
    } else if (response?._isCustomError) {
      const errorMessage =
        'This name is already taken. Try a different SIP name.'
      this.setState({
        sipNameError: true,
        sipNameHelperText: errorMessage
      })
      return false
    } else {
      this.setState({
        sipNameError: false,
        sipNameHelperText: ''
      })
      return true
    }
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  handleSubmitSip = () => {
    const { setShowSelecetStock, actions, selectedSipScripts, setShowSearch } =
      this.props
    actions.updateIsEditingSip(false)
    actions.updateIsReactivating(false)
    if (selectedSipScripts?.length > 0) {
      setShowSearch(false)
      return
    }
    // setShowSelecetStock(true)
    setShowSearch(true)
  }

  handleChange = (e: any, item?: any) => {
    const { name, value } = e.target
    const { actions, breakpoints = {} } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)

    if (name === 'sipName') {
      const validatedName = this.validateSipName(value)
      if (!validatedName) {
        return
      }
    }

    if (name === 'customBottomsheet') {
      this.setState({ customError: false })

      if (value > 60) {
        this.setState({ customError: true })
        return
      }

      const formattedValue = this.convertToMonthsOrYears(value)
      this.setState({ value })
      actions.updateSipInfo({
        name: 'duration',
        value: formattedValue
      })
      return
    }
    if (value === 'Custom Duration') {
      this.setState({ showCustomModal: true })
    }
    if (name === 'duration') {
      actions.updateSipInfo({
        name: 'duration',
        value: isDesktop ? value : item
      })
      return
    }
    if (name === 'frequency') {
      actions.updateSipInfo({
        name: [name],
        value: isDesktop ? value : item
      })
      return
    }

    actions.updateSipInfo({
      name: [name],
      value
    })
  }

  convertToMonthsOrYears = (input: any) => {
    const isString = typeof input === 'string'
    const isNumber = typeof input === 'number'

    if (isString || isNumber) {
      const strInput = input.toString()
      if (strInput.includes('Year') || strInput.includes('Months')) {
        return input
      }
    }

    if (isNumber && input < 12) {
      return `${input} Months`
    }

    const years = Math.floor(input / 12)
    return input % 12 === 0 ? `${years} Year` : `${input} Months`
  }

  handleUpdateDate = (e: number | Date) => {
    const { actions } = this.props
    actions.updateSipInfo({ name: 'startDate', value: format(e, 'dd/MM/yy') })
  }

  handleBasedChange = (e: any, value: any) => {
    const { actions } = this.props
    actions.updateSipInfo({
      name: 'basedOn',
      value
    })
  }

  handleOnClickFrequency = () => {
    const { isModifing } = this.props

    const { showFrequency } = this.state
    if (isModifing) {
      return null
    }
    return this.setState({ showFrequency: !showFrequency })
  }

  render() {
    const { sipInfo, type, breakpoints, isModifing } = this.props
    const {
      showCustomModal,
      showFrequency,
      showDuration,
      customError,
      sipNameHelperText,
      sipNameError
    } = this.state
    const { startDate, frequency, duration, basedOn, sipName } = sipInfo
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const disableStartDate =
      isModifing &&
      new Date(parse(startDate, 'dd/MM/yy', new Date())) <
        addDays(new Date(), 1)

    return (
      <>
        <DsStack
          sx={{
            background: '#F1F4F7',
            width: '100%',
            borderRadius: 'var(--ds-spacing-glacial)'
          }}
        >
          {type !== 'edit' && (
            <DsBox
              sx={{
                justifyContent: 'space-between',
                borderRadius:
                  'var(--ds-spacing-glacial) var(--ds-spacing-glacial) 0 0',
                background: '#165964',
                display: 'flex',
                alignItems: 'flex-start',
                padding: 'var(--ds-spacing-bitterCold)',
                color: 'var(--ds-colour-surfaceBackground)'
              }}
            >
              <DsBox sx={{ width: 'fit-content', height: 'fit-content' }}>
                <DsImage height={'fit-content'} srcSet={SIP_HEADER} />
              </DsBox>
              <DsTypography variant='bodyBoldLarge'>
                Start Your Stock SIP
              </DsTypography>

              <DsBox sx={{ width: 'fit-content', height: 'fit-content' }}>
                <DsImage height={'fit-content'} srcSet={SIP_HEADER} />
              </DsBox>
            </DsBox>
          )}
          <DsStack
            gap={'var(--ds-spacing-glacial)'}
            sx={{
              justifyContent: 'center',
              padding: 'var(--ds-spacing-bitterCold)',
              borderRadius:
                'var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-glacial) var(--ds-spacing-glacial)',
              background: 'var(--ds-colour-surfaceBackground)'
            }}
          >
            <DsBox
              sx={{
                display: 'grid',
                gridTemplateColumns: '40% 49%',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <DsInputLabel
                label={
                  <DsTypography variant='bodyRegularMedium'>
                    SIP Name
                  </DsTypography>
                }
                sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
              />
              <DsTextField
                autoFocus
                fullWidth
                type='text'
                value={sipName}
                name='sipName'
                onChange={this.handleChange}
                inputProps={{ maxLength: 12 }}
                error={sipNameError}
                helperText={sipNameHelperText}
                disabled={isModifing}
                placeholder='Enter Name'
              />
            </DsBox>
            <DsBox
              sx={{
                display: 'grid',
                gridTemplateColumns: '40% 49%',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <DsInputLabel
                label={
                  <DsTypography variant='bodyRegularMedium'>
                    Start Date
                  </DsTypography>
                }
                sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
              />
              <DsDatePicker
                name='startDate'
                onAccept={(e: any) => this.handleUpdateDate(e)}
                minDate={!disableStartDate && addDays(new Date(), 1)}
                maxDate={!disableStartDate && addYears(new Date(), 5)}
                disablePast={!disableStartDate}
                closeOnSelect={isDesktop}
                defaultValue={parse(startDate, 'dd/MM/yy', new Date())}
                value={parse(startDate, 'dd/MM/yy', new Date())}
                readOnly={false}
                disabled={disableStartDate}
              />
            </DsBox>
            <DsBox
              sx={{
                display: 'grid',
                gridTemplateColumns: '40% 49%',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <DsInputLabel
                label={
                  <DsTypography variant='bodyRegularMedium'>
                    Frequency
                  </DsTypography>
                }
                sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
              />

              <DsSelect
                onClick={this.handleOnClickFrequency}
                fullWidth
                size='small'
                value={frequency}
                defaultValue={frequency}
                name='frequency'
                onChange={this.handleChange}
                disabled={isModifing}
                options={FREQUENCY}
                onClose={() => this.setState({ showFrequency: false })}
                open={isDesktop ? showFrequency : isDesktop}
              />
            </DsBox>
            <DsBox
              sx={{
                display: 'grid',
                gridTemplateColumns: '40% 49%',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <DsInputLabel
                label={
                  <DsTypography variant='bodyRegularMedium'>
                    Duration
                  </DsTypography>
                }
                sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
              />
              <DsSelect
                onClick={() => this.setState({ showDuration: !showDuration })}
                fullWidth
                size='small'
                value={duration}
                defaultValue={duration}
                placeholder={duration}
                name='duration'
                onChange={this.handleChange}
                options={DURATION}
                onClose={() => this.setState({ showDuration: false })}
                open={isDesktop ? showDuration : isDesktop}
                renderValue={selected => {
                  return selected || duration
                }}
              />
            </DsBox>

            <DsBox sx={{ width: '100%' }}>
              <DsTabs
                ds-variant='container'
                onChange={this.handleBasedChange}
                value={basedOn.toLowerCase()}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <DsTab
                  disabled={isModifing}
                  sx={{ width: '49%' }}
                  label='QUANTITY BASED'
                  value={'quantity'}
                />
                <DsTab
                  disabled={isModifing}
                  sx={{ width: '48%' }}
                  label='AMOUNT BASED'
                  value={'amount'}
                />
              </DsTabs>
            </DsBox>
          </DsStack>
        </DsStack>
        {type !== 'edit' && (
          <DsButton
            onClick={this.handleSubmitSip}
            fullWidth
            sx={{ mt: '1rem' }}
            size='medium'
            disabled={sipNameError || !sipName}
          >
            Continue
          </DsButton>
        )}

        <CustomDuration
          open={showCustomModal}
          onClose={() => this.setState({ showCustomModal: false })}
          secondaryClick={() => this.setState({ showCustomModal: false })}
          updateSipInfo={this.props.actions.updateSipInfo}
          isDesktop={isDesktop}
          showDuration={showDuration}
          onCloseDuration={() => this.setState({ showDuration: false })}
          handleChangeDuration={this.handleChange}
          duration={duration}
        />

        {!isDesktop && showFrequency && (
          <DsPopup
            open={showFrequency}
            onClose={() => this.setState({ showFrequency: false })}
            showClose={true}
            title={'Select Frequency'}
          >
            <DsFormControl
              sx={{
                px: 'var(--ds-spacing-bitterCold)'
              }}
            >
              <DsRadioGroup defaultValue={frequency}>
                {FREQUENCY.map((item, index) => (
                  <DsFormControlLabel
                    key={index}
                    name='frequency'
                    control={
                      <DsRadio
                        onChange={e => this.handleChange(e, item.value)}
                      />
                    }
                    label={
                      <DsTypography variant={'bodyRegularMedium'}>
                        {item.label}
                      </DsTypography>
                    }
                    value={index}
                    sx={{
                      '.MuiFormControlLabel-root': {
                        marginRight: 'var(--ds-spacing-zero) !important'
                      }
                    }}
                  />
                ))}
              </DsRadioGroup>
            </DsFormControl>
          </DsPopup>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const sipInfo = getSipInfo(state)
  const isModifing = isModifingSelector(state)
  const selectedSipScripts = getSelectedScripts(state)

  return {
    sipInfo,
    isModifing,
    selectedSipScripts
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateSipInfo: (requestData: any) => dispatch(updateSipInfo(requestData)),
    updateSipOpenState: (requestData: any) =>
      dispatch(updateOrderSipPageModalIndicator(requestData)),
    updateIsEditingSip: (requestData: any) =>
      dispatch(updateIsEditingSip(requestData)),
    updateIsReactivating: (requestData: any) =>
      dispatch(updateIsReactivating(requestData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SipFormSection)
)
