import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { getPledgeDataActions, setPledgeMarginsActions } from './actions'
import { SLICE_NAME } from './selectors'

const INITIAL_STATE = {
  pledgeData: [
    {
      exchange: 'NSE',
      preferredExchange: 'NSE',
      scriptId: '225900',
      preferredScriptId: '225900',
      product: 'INTRADAY',
      symbol: 'AXISBANK',
      series: 'EQ',
      lotSize: 1,
      genNum: 1,
      genDen: 1,
      priceNum: 1,
      priceDen: 1,
      instrument: 'Equity',
      buyQty: 1,
      avgBuyPrice: 1110.1,
      buyValue: 1110.1,
      netQty: 1,
      netPrice: 1110.1,
      netValue: -1110.1,
      multiplier: 1,
      dailyOrExpiry: 'EXPIRY'
    }
  ],
  pledgeMargin: {}
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getPledgeDataActions.success, (state, { payload }) => {
      state.pledgeData = [
        {
          exchange: 'NSE',
          preferredExchange: 'NSE',
          scriptId: '225900',
          preferredScriptId: '225900',
          product: 'INTRADAY',
          symbol: 'AXISBANK',
          series: 'EQ',
          lotSize: 1,
          genNum: 1,
          genDen: 1,
          priceNum: 1,
          priceDen: 1,
          instrument: 'Equity',
          buyQty: 1,
          avgBuyPrice: 1110.1,
          buyValue: 1110.1,
          netQty: 1,
          netPrice: 1110.1,
          netValue: -1110.1,
          multiplier: 1,
          dailyOrExpiry: 'EXPIRY'
        },
        {
          exchange: 'NSE',
          preferredExchange: 'NSE',
          scriptId: '225900',
          preferredScriptId: '225900',
          product: 'INTRADAY',
          symbol: 'TCS',
          series: 'EQ',
          lotSize: 1,
          genNum: 1,
          genDen: 1,
          priceNum: 1,
          priceDen: 1,
          instrument: 'Equity',
          buyQty: 1,
          avgBuyPrice: 1110.1,
          buyValue: 1110.1,
          netQty: 1,
          netPrice: 1110.1,
          netValue: -1110.1,
          multiplier: 1,
          dailyOrExpiry: 'EXPIRY'
        }
      ]
    })

    builder.addCase(setPledgeMarginsActions.success, (state, { payload }) => {
      state.pledgeMargin = payload.data
    })
  }
}

const slice = createSlice(sliceOptions)
export default slice.reducer
