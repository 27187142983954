import { asHttp } from '~/src/Configurations/WebHttp'

import { modifySipOrderActions } from '../actions'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export type T_GET_MODIFY_SIP_PAYLOAD = {
  segment: string
  basketName: string
  sipAction: string
  sipFrequency: string
  sipPeriod: number
  sipType: string
  sipReferenceNumber: number
  startDate: string
  securities: any
}

async function modifySipOrder(payload: T_GET_MODIFY_SIP_PAYLOAD) {
  const options = {
    url: '/trading/sip/modify',
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const modifySipOrderAction = serviceActionCreator(
  modifySipOrderActions,
  modifySipOrder
)

export default modifySipOrderAction
