import traceActionsCreator from '../traceActionsCreator'

export const getCustomersPortfolioDetailsServiceName =
  'dashboard/getCustomersPortfolioDetails'
export const getCustomersPortfolioDetailsActions = traceActionsCreator(
  getCustomersPortfolioDetailsServiceName
)

export const getHoldingsSummaryServiceName = 'dashboard/getHoldingsSummary'
export const getHoldingsSummaryActions = traceActionsCreator(
  getHoldingsSummaryServiceName
)

export const getHoldingsDetailsServiceName = 'dashboard/getHoldingsDetails'
export const getHoldingsDetailsActions = traceActionsCreator(
  getHoldingsDetailsServiceName
)

export const getTradingHistoryServiceName = 'dashboard/getTradingHistory'
export const getTradingHistoryActions = traceActionsCreator(
  getTradingHistoryServiceName
)
