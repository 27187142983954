import { createAction } from '@reduxjs/toolkit'

export const updateOdinConnectionIndicatorAction = createAction<
  boolean,
  string
>('indicators/updateOdinConnectionIndicator')

export const updateOrderSipPageModalIndicatorServiceName =
  'appstate/openAppStateSipDialog'
export const updateOrderSipPageModalIndicatorActions = createAction<any>(
  updateOrderSipPageModalIndicatorServiceName
)

export const updateModifySipIndicatorServiceName =
  'appstate/updateModifySipPopup'
export const updateModifySipIndicatorActions = createAction<any>(
  updateModifySipIndicatorServiceName
)
