import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsBox,
  DsImage,
  DsLink,
  DsPopup,
  DsRemixIcon,
  DsStack,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import { WARNINGS } from '~/src/Constants/ASSET_MAP'

type ActionTypes = {}

export interface IScriptSurveillanceProps extends IWithRouterProps {
  actions: ActionTypes
  breakpoints: any
  primaryButtonOnClick: () => void
  secondaryButtonOnClick: () => void
  openSurveillanceAlert: boolean
  surveillanceFlagName: string
}

class ScriptSurveillance extends Component<IScriptSurveillanceProps> {
  render() {
    const {
      primaryButtonOnClick,
      secondaryButtonOnClick,
      breakpoints,
      openSurveillanceAlert,
      surveillanceFlagName
    } = this.props
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false

    return (
      <>
        <DsPopup
          open={openSurveillanceAlert}
          onClose={secondaryButtonOnClick}
          sx={{
            '.MuiDialogContent-root': {
              marginTop: 'var(--ds-spacing-zero)'
            }
          }}
          // DsDialogProps={{
          //   open: openSurveillanceAlert,
          //   onClose: secondaryButtonOnClick,
          //   sx: {
          //     '.MuiDialogContent-root': {
          //       marginTop: 'var(--ds-spacing-zero)'
          //     }
          //   }
          // }}
          primaryButtonText='Order Anyway'
          secondaryButtonText='Cancel'
          showClose={isDesktop ? false : true}
          primaryButtonProps={{
            onClick: primaryButtonOnClick
          }}
          secondaryButtonProps={{
            onClick: secondaryButtonOnClick
          }}
        >
          <DsStack
            spacing={'var(--ds-spacing-frostbite)'}
            textAlign={{ md: 'center', xs: 'unset' }}
          >
            <DsBox sx={{ display: { md: 'block', xs: 'none' } }}>
              <DsImage srcSet={WARNINGS} />
            </DsBox>
            <DsStack
              spacing={4}
              sx={{ alignItems: { md: 'center', xs: 'unset' } }}
            >
              <DsStack
                sx={{
                  flexDirection: { md: 'unset', xs: 'row' },
                  alignItems: { md: 'unset', xs: 'center' }
                }}
                gap={'var(--ds-spacing-frostbite)'}
              >
                <DsRemixIcon
                  className='ri-alert-fill'
                  sx={{
                    display: { md: 'none', xs: 'block' },
                    color: 'var(--ds-colour-supportWarning)'
                  }}
                />
                <DsTypography
                  variant={isDesktop ? 'headingBoldLarge' : 'headingBoldMedium'}
                >
                  Scrip under surveillance
                </DsTypography>
              </DsStack>
              <DsBox
                sx={{
                  background: {
                    md: 'unset',
                    xs: 'var(--ds-colour-surfaceSecondary)'
                  },
                  padding: {
                    md: 'unset',
                    xs: 'var(--ds-spacing-glacial) var(--ds-spacing-frostbite)'
                  }
                }}
              >
                <DsTypography
                  variant={isDesktop ? 'bodyRegularLarge' : 'bodyRegularSmall'}
                  color={'var(--ds-colour-typoSecondary)'}
                >
                  Caution: This security is under surveillance measure "
                  {surveillanceFlagName}" surveillance.
                </DsTypography>
              </DsBox>
            </DsStack>
          </DsStack>
        </DsPopup>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(ScriptSurveillance)
)
