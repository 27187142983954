import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsGrid,
  DsMenu,
  DsBox,
  DsTypography,
  DsMenuItem,
  DsListItemButton,
  DsSvgIcon,
  DsStack,
  DsBadge,
  DsFormControl,
  DsFormControlLabel,
  DsPopup,
  DsRadio,
  DsRadioGroup,
  withBreakpoints,
  DsImage,
  DsFade
} from '@am92/react-design-system'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import { T_SCRIPTS_OBJ, watchlistObj } from '~/src/Redux/WatchList/Reducer'
import { TickerStore } from '~/src/Redux/StockTicker/Reducer'
import { getFullTickerData } from '~/src/Redux/StockTicker/Selectors'
import { calculateNetChange } from '~/src/Utils/global'
import { StockMasterStore } from '~/src/Redux/StockMaster/Reducer'
import { SORT_SCRIPT } from '~/src/Constants/ASSET_MAP'
import { tickerContext } from '~/src/Configurations/TickerStore'
import { WrapperActionTypes } from './SortWatchListScriptsWrapper'

type ActionTypes = {}

export interface ISortWatchlistScriptsProps extends IWithRouterProps {
  actions: ActionTypes
  wrapperActions: WrapperActionTypes
  breakpoints: any
  watchListData: watchlistObj[]
  selectedWatchlistTab: string | number
  stockTickerData: TickerStore
  sortScriptSelectedOption: number
  stockMasterData: StockMasterStore
  defaultWatchlistSecurityMapping: {
    [key: string]: T_SCRIPTS_OBJ[]
  }
}

const SORT_SCRIPT_OPTIONS = [
  'Last Added (Default)',
  'LTP: High to low',
  'LTP: Low to High',
  'LTP%: High to low',
  'LTP%: Low to High',
  'Alphabetical: A to Z',
  'Alphabetical: Z to A'
]

const SORT_SCRIPT_MAPPING: T_SORT_SCRIPT_MAPPING = {
  1: ['LTP', 1],
  2: ['LTP', -1],
  3: ['PERCENT_CHANGE', 1],
  4: ['PERCENT_CHANGE', -1],
  5: ['label', -1],
  6: ['label', 1]
}

const alphabeticallySortKeys = ['label']

type T_SORT_SCRIPT_MAPPING = {
  [key: number]: [string, 1 | -1]
}

class SortWatchlistScripts extends Component<ISortWatchlistScriptsProps> {
  state = {
    anchorEl: null,
    openSortByBottomSheet: false
  }

  handleOpenSortScriptsMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleCloseSortScriptsMenu = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleClickOnSortScriptOptions = async (selectedSortOption: number) => {
    const {
      wrapperActions,
      defaultWatchlistSecurityMapping,
      selectedWatchlistTab,
      watchListData
    } = this.props

    const currentSecuritiesArray =
      watchListData[+selectedWatchlistTab].watchlistSecurities || []
    const currentWatchListId = watchListData[+selectedWatchlistTab].watchlistId
    if (!currentSecuritiesArray.length) {
      return
    }
    this.handleCloseSortScriptsMenu()
    this.setState({
      openSortByBottomSheet: false
    })

    // update the default securities array in WatchlistToSecuritiesArray redux
    wrapperActions.setSortScriptOption({
      sortScriptOption: selectedSortOption,
      watchlistId: currentWatchListId
    })

    if (selectedSortOption === 0) {
      const defaultSecuritiesArray =
        defaultWatchlistSecurityMapping[currentWatchListId]
      // set default one again
      await wrapperActions.setSecurityArray({
        securitiesArray: defaultSecuritiesArray,
        watchlistId: currentWatchListId
      })
      return
    }

    const sortKey = SORT_SCRIPT_MAPPING[selectedSortOption][0]
    const sortWay = SORT_SCRIPT_MAPPING[selectedSortOption][1]

    const sortedSecuritiesArray = this.sortSecuritiesArray(
      currentSecuritiesArray,
      sortWay,
      sortKey
    )

    const newSortedSecuritiesArray: T_SCRIPTS_OBJ[] = []
    sortedSecuritiesArray.forEach((item: T_SCRIPTS_OBJ, index: number) => {
      newSortedSecuritiesArray.push({
        ...item,
        sequenceNumber: index
      })
    })

    // replace this new security array in given watchlistId
    await wrapperActions.setSecurityArray({
      securitiesArray: newSortedSecuritiesArray,
      watchlistId: currentWatchListId
    })
  }

  sortSecuritiesArray = (
    arrayToSort: any[],
    sortWay: 1 | -1,
    sortKey: string
  ) => {
    const { stockMasterData, stockTickerData } = this.props
    const sortedArray = [...arrayToSort].sort((STOCK_OBJ_1, STOCK_OBJ_2) => {
      // a data
      const { scriptId } = STOCK_OBJ_1
      const { exchangeSymbol, closeprice, label, segment } =
        stockMasterData[scriptId]
      const { LTP, PERCENT_CHANGE, NET_CHANGE } = stockTickerData[scriptId]
      const NEW_STOCK_OBJ_1 = {
        ...STOCK_OBJ_1,
        LTP,
        PERCENT_CHANGE: Number(
          NET_CHANGE === '+0.00'
            ? calculateNetChange(LTP, closeprice, segment)[1]
            : PERCENT_CHANGE
        ),
        exchangeSymbol,
        label
      }

      // b data
      const { scriptId: SCRIPTID_B } = STOCK_OBJ_2
      const {
        exchangeSymbol: EXCHANGE_SYMBOL_B,
        closeprice: CLOSE_PRICE_B,
        label: LABEL_B,
        segment: SEGMENT_B
      } = stockMasterData[SCRIPTID_B]
      const {
        LTP: LTP_B,
        PERCENT_CHANGE: PERCENT_CHANGE_B,
        NET_CHANGE: NET_CHANGE_B
      } = stockTickerData[SCRIPTID_B]
      const NEW_STOCK_OBJ_2 = {
        ...STOCK_OBJ_2,
        LTP: LTP_B,
        PERCENT_CHANGE: Number(
          NET_CHANGE_B === '+0.00'
            ? calculateNetChange(LTP_B, CLOSE_PRICE_B, SEGMENT_B)[1]
            : PERCENT_CHANGE_B
        ),
        exchangeSymbol: EXCHANGE_SYMBOL_B,
        label: LABEL_B
      }

      if (alphabeticallySortKeys.includes(sortKey)) {
        if (NEW_STOCK_OBJ_1[sortKey] < NEW_STOCK_OBJ_2[sortKey]) {
          return -1 * sortWay
        }
        if (NEW_STOCK_OBJ_1[sortKey] > NEW_STOCK_OBJ_2[sortKey]) {
          return 1 * sortWay
        }
        return 0
      }

      return sortWay * (NEW_STOCK_OBJ_1[sortKey] - NEW_STOCK_OBJ_2[sortKey])
    })
    return sortedArray
  }

  handleOpenCloseSortScriptBottomSheet = () => {
    const { openSortByBottomSheet } = this.state
    this.setState({
      openSortByBottomSheet: !openSortByBottomSheet
    })
  }

  render() {
    const { anchorEl, openSortByBottomSheet } = this.state
    const openSortScriptsMenu = Boolean(anchorEl)
    const { sortScriptSelectedOption, watchListData, selectedWatchlistTab } =
      this.props
    const stockList =
      watchListData[Number(selectedWatchlistTab)]?.watchlistSecurities || []
    const isAnyStockAvailable = stockList.length
    const { breakpoints = {} } = this.props
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false

    return (
      <>
        {isDesktop && (
          <DsGrid item xs={4}>
            <DsMenu
              sx={{
                maxWidth: '100%',
                '& .MuiPaper-root': {
                  minWidth: 288,
                  boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.08)',
                  marginLeft: 'calc(var(--ds-spacing-mild) - 1px)'
                }
              }}
              anchorEl={anchorEl}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
              }}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'top'
              }}
              open={openSortScriptsMenu}
              onClose={this.handleCloseSortScriptsMenu}
              MenuListProps={{
                'aria-labelledby': 'fade-button'
              }}
              id='fade-menu'
              aria-labelledby='fade-menu'
              variant='menu'
              TransitionComponent={DsFade}
            >
              <DsBox
                sx={{
                  padding: 'var(--ds-spacing-bitterCold)'
                }}
              >
                <DsTypography variant='headingBoldExtraSmall'>
                  Sort by
                </DsTypography>
              </DsBox>
              {SORT_SCRIPT_OPTIONS.map((sortOption: string, index: number) => (
                <DsMenuItem
                  key={index}
                  onClick={() => this.handleClickOnSortScriptOptions(index)}
                >
                  <DsTypography
                    variant={
                      sortScriptSelectedOption === index
                        ? 'bodyBoldMedium'
                        : 'bodyRegularMedium'
                    }
                  >
                    {sortOption}
                  </DsTypography>
                </DsMenuItem>
              ))}
            </DsMenu>
            <DsListItemButton
              sx={{
                display: 'block',
                p: 'var(--ds-spacing-frostbite) var(--ds-spacing-zero) var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)'
              }}
              onClick={this.handleOpenSortScriptsMenu}
              id='fade-button'
            >
              <DsBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  textAlign: 'center',
                  gap: 'var(--ds-spacing-frostbite)',
                  borderRight: '1px solid var(--ds-colour-strokeDefault)'
                }}
              >
                {/* NOTE - TODO - To revisit later */}
                <DsSvgIcon
                  sx={{
                    fill: 'var(--ds-colour-iconDefault)',
                    fontSize: 'var(--ds-typo-fontSizeBitterCold)'
                  }}
                >
                  <svg
                    width='18'
                    height='16'
                    viewBox='0 0 18 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M8 14C8.55229 14 9 14.4477 9 15C9 15.5523 8.55229 16 8 16H1C0.447715 16 0 15.5523 0 15C0 14.4477 0.447715 14 1 14H8ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55229 12.5523 9 12 9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H12ZM17 0C17.5523 0 18 0.447715 18 1C18 1.55228 17.5523 2 17 2H1C0.447716 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H17Z' />
                  </svg>
                </DsSvgIcon>
                <DsStack
                  sx={{
                    flexDirection: 'row'
                  }}
                >
                  <DsTypography variant='bodyRegularLarge'>
                    {isAnyStockAvailable > 1
                      ? WATCHLIST_LABEL.BOTTOM_BUTTONS.SORTS
                      : WATCHLIST_LABEL.BOTTOM_BUTTONS.SORT}
                  </DsTypography>
                  {sortScriptSelectedOption ? (
                    <DsBadge
                      variant='dot'
                      sx={{
                        marginTop:
                          'calc(var(--ds-spacing-quickFreeze) + var(--ds-spacing-deepFreeze))',
                        marginLeft: 'var(--ds-spacing-glacial)'
                      }}
                    />
                  ) : null}
                </DsStack>
              </DsBox>
            </DsListItemButton>
          </DsGrid>
        )}

        {!isDesktop && (
          <DsGrid item xs={6}>
            <DsListItemButton
              sx={{
                display: 'block',
                p: 0,
                borderRight: '1px solid var(--ds-colour-strokeDefault)'
              }}
              onClick={this.handleOpenCloseSortScriptBottomSheet}
            >
              <DsBox
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)',
                  cursor: 'pointer',
                  textAlign: 'center'
                }}
              >
                <DsBox>
                  <DsImage
                    srcSet={SORT_SCRIPT}
                    style={{
                      marginRight: 'var(--ds-spacing-frostbite)'
                    }}
                  />
                </DsBox>
                <DsTypography
                  variant='bodyBoldSmall'
                  color={'var(--ds-colour-iconActionTertiary)'}
                  textTransform={'uppercase'}
                >
                  {isAnyStockAvailable > 1
                    ? WATCHLIST_LABEL.BOTTOM_BUTTONS.SORTS
                    : WATCHLIST_LABEL.BOTTOM_BUTTONS.SORT}
                </DsTypography>
                {sortScriptSelectedOption ? (
                  <DsBadge
                    variant='dot'
                    sx={{
                      marginBottom: 'var(--ds-spacing-glacial)',
                      marginLeft: 'var(--ds-spacing-glacial)'
                    }}
                  />
                ) : null}
              </DsBox>
            </DsListItemButton>
          </DsGrid>
        )}
        <DsPopup
          // DsDialogProps={{
          //   open: openSortByBottomSheet,
          //   onClose: this.handleOpenCloseSortScriptBottomSheet
          // }}
          open={openSortByBottomSheet}
          onClose={this.handleOpenCloseSortScriptBottomSheet}
          showClose={true}
          title={'Sort by'}
        >
          <DsFormControl
            sx={{
              px: 'var(--ds-spacing-bitterCold)'
            }}
          >
            <DsRadioGroup defaultValue={sortScriptSelectedOption}>
              {SORT_SCRIPT_OPTIONS.map((sortOption: string, index: number) => (
                <DsFormControlLabel
                  key={index}
                  control={
                    <DsRadio
                      onChange={() =>
                        this.handleClickOnSortScriptOptions(index)
                      }
                    />
                  }
                  label={
                    <DsTypography
                      variant={
                        sortScriptSelectedOption === index
                          ? 'bodyBoldMedium'
                          : 'bodyRegularMedium'
                      }
                    >
                      {sortOption}
                    </DsTypography>
                  }
                  value={index}
                  sx={{
                    '.MuiFormControlLabel-root': {
                      marginRight: 'var(--ds-spacing-zero) !important'
                    }
                  }}
                />
              ))}
            </DsRadioGroup>
          </DsFormControl>
        </DsPopup>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const stockTickerData = getFullTickerData(state)

  return {
    stockTickerData
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
    context: tickerContext
  })(SortWatchlistScripts)
)
