import React, { Component } from 'react'
import { To } from 'react-router-dom'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import {
  DsBox,
  DsButton,
  DsDivider,
  DsImage,
  DsList,
  DsListItem,
  DsListItemButton,
  DsRemixIcon,
  DsTooltip,
  DsTypography,
  enqueueNotistack
} from '@am92/react-design-system'
import {
  ADD_SCRIPT_ILLUSTRATION,
  DELETE,
  LIST_DRAGGER,
  MARKET_DEPTH,
  MORE_OPTIONS,
  OPTION_CHAIN_ICON
} from '~/src/Constants/ASSET_MAP'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import StockData from './StockData'
import {
  isOdinConnectionIstablished,
  isWatchlistFetched
} from '~/src/Redux/Indicators/Selectors'
import fetchWatchlistsAction from '~/src/Redux/WatchList/Services/FetchWatchlists.Service'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import {
  getWatchListData,
  getWatchListIndex,
  getWatchlist,
  shouldSubscribePostUpdate
} from '~/src/Redux/WatchList/Selectors'
import {
  selectWatchlistTab,
  setAddScriptToParticularWatchlist,
  setSecurityArray,
  setWatchlist,
  setWatchlistToSecuritiesArray,
  subscribe,
  T_SCRIPTS_OBJ,
  watchlistObj
} from '~/src/Redux/WatchList/Reducer'
import updateScriptsSequenceAction, {
  updateScriptsSequenceObj,
  updateScriptsSequencePayload
} from '~/src/Redux/WatchList/Services/UpdateScriptsSequence.Service'
import { IsetWatchlistReqData } from './ManageWatchlist'
import { odinWorkerManager } from '~/src/Workers/Odin/OdinWorkerManager'
import { watchlistFetched } from '~/src/Redux/Indicators/Reducer'
import {
  T_StockWatchlistMasterData,
  setTemporaryStockWatchlistData
} from '~/src/Redux/StockWatchlistMaster/Reducer'
import {
  removeWatchlistIdFromStocksAction,
  updateStockWatchlistMasterAction
} from '~/src/Redux/StockWatchlistMaster/Actions'
import {
  setSelectedExchange,
  updateOrderPageModalIndicator
} from '~/src/Redux/Orders/reducer'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '~/src/Pages/Orderbook/Orderbook.Page'
import { isNonTradableStock } from '~/src/Lib/SecMaster/helpers'
import { openAppStateDialogActions } from '~/src/Redux/AppState/actions'
import { getAllDerivativeSelector } from '~/src/Redux/Derivatives/Selectors'
import { DEEP_LINK_KEYS } from '~/src/Constants/APP_CONSTANTS'
import { TDeepLink, resetDeepLinkData } from '~/src/Redux/AppState/reducer'
import { getDeepLinkAttributes } from '~/src/Redux/AppState/selectors'

export type T_SET_SECURITIES_REQ_DATA = {
  securitiesArray: T_SCRIPTS_OBJ[] | null
  watchlistId: string
}

type ActionTypes = {
  fetchWatchlist: () => Promise<any>
  updateScriptsSequence: (reqData: updateScriptsSequencePayload) => Promise<any>
  setSecurityArray: (reqData: T_SET_SECURITIES_REQ_DATA) => Promise<any>
  setWatchlist: (reqData: IsetWatchlistReqData) => Promise<any>
  setAddScriptToParticularWatchlist: (reqData: boolean) => Promise<any>
  setTemporaryStockWatchlistData: (requestData: any) => Promise<any>
  watchlistFetched: (reqData: boolean) => Promise<any>
  subscribe: (reqData: boolean) => Promise<any>
  updateOrderPageModalIndicator: (
    reqData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
  updateStockWatchlistMaster: (
    requestData: T_StockWatchlistMasterData
  ) => Promise<any>
  setWatchlistToSecuritiesArray: (
    reqData: T_SET_SECURITIES_REQ_DATA
  ) => Promise<any>
  removeWatchlistIdFromStocks: (requestData: {
    watchListIdToDelete: string
    scriptIdArray: T_SCRIPTS_OBJ[]
  }) => Promise<any>
  setSelectedExchange: (requestData: any) => Promise<any>
  openAppStateDialog: (key: any) => void
  setSelectedWatchlistTab: (reqData: number | string) => Promise<any>
  resetDeepLinkData: () => Promise<any>
}

export interface IWatchListProps extends IWithRouterProps {
  isOdinConnected: boolean
  actions: ActionTypes
  selectedWatchlistTab: number
  isWatchlistFetchedAlready: boolean
  shouldSubscribe: boolean
  setOpen: any
  handleError: (res: any) => void
  getAllDerivative: () => any
  currentWatchlistId: string
  currentSubAccountId: string
  currentWatchlistName: string
  currentWatchlistType: string
  currentWatchlistSequenceNumber: number
  currentWatchlistSecurities: T_SCRIPTS_OBJ[] | null
  deepLinkAttributes: TDeepLink
  watchListIndex: {
    [key: string]: string
  }
}

interface IWatchListState {
  hover: number
  selectedScript: string
  disableMounseHover: boolean
}

class WatchList extends Component<IWatchListProps> {
  odinWorkerManager = null as any

  state: IWatchListState = {
    hover: -1,
    selectedScript: '0',
    disableMounseHover: false
  }

  async componentDidMount(): Promise<void> {
    const { actions, isOdinConnected, isWatchlistFetchedAlready } = this.props
    this.odinWorkerManager = odinWorkerManager
    if (isOdinConnected && !isWatchlistFetchedAlready) {
      await this.getWatchListData()
      actions.watchlistFetched(true)
      return
    }
    isOdinConnected &&
      isWatchlistFetchedAlready &&
      this.subscribeWatchListStocks()
  }

  async componentDidUpdate(
    prevProps: Readonly<IWatchListProps>
  ): Promise<void> {
    const {
      actions,
      isOdinConnected,
      selectedWatchlistTab,
      isWatchlistFetchedAlready,
      watchListIndex,
      deepLinkAttributes
    } = this.props

    const { currentWatchlistSecurities: PREV_SECURITY_ARRAY } = prevProps
    if (
      prevProps.isOdinConnected !== isOdinConnected &&
      !isWatchlistFetchedAlready
    ) {
      await this.getWatchListData()
      actions.watchlistFetched(true)
    }
    if (prevProps.selectedWatchlistTab !== selectedWatchlistTab) {
      await this.unsubscribeWatchListStocks(PREV_SECURITY_ARRAY)
      this.subscribeWatchListStocks()
    }

    //deep link handling for watchlist here
    if (deepLinkAttributes && prevProps.watchListIndex !== watchListIndex) {
      const { path, parameterList } = deepLinkAttributes
      if (path === DEEP_LINK_KEYS.WATCHLIST) {
        // get watchlist tab number based on watchlist id
        const watchlistId = parameterList[0]
        const currentWatchlistIndex = watchListIndex[watchlistId]
        // only take to that specific watchlist if id is present,
        // otherwise take it to first available watchlist by default
        if (currentWatchlistIndex) {
          actions.setSelectedWatchlistTab(Number(currentWatchlistIndex) - 1)
        }
        actions.resetDeepLinkData()
      }
    }
  }

  componentWillUnmount(): void {
    this.unsubscribeWatchListStocks()
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  navigateToSomethingWentWrong = () => {
    const { navigateTo } = this.props
    navigateTo(APP_ROUTES.SOMETHING_WENT_WRONG.pathname)
  }

  getWatchListData = async () => {
    const { actions, handleError, shouldSubscribe } = this.props
    const fetchWatchlistsResponse = await actions.fetchWatchlist()
    if (fetchWatchlistsResponse._isCustomError) {
      handleError(fetchWatchlistsResponse)
      console.log('failed API - fetchWatchlist')
      return
    }

    shouldSubscribe && this.subscribeWatchListStocks()
    actions.subscribe(true)

    const stockWatchlistData: T_StockWatchlistMasterData = {}

    fetchWatchlistsResponse.forEach((watchlist: watchlistObj) => {
      const { watchlistSecurities, watchlistId, watchlistType } = watchlist
      if (watchlistType === 'PRE-DEFINED') {
        return false
      }
      watchlistSecurities?.forEach((stockObj: T_SCRIPTS_OBJ) => {
        const { scriptId } = stockObj
        if (!stockWatchlistData[scriptId]) {
          stockWatchlistData[scriptId] = [watchlistId]
          return
        }
        stockWatchlistData[scriptId] = [
          ...stockWatchlistData[scriptId],
          watchlistId
        ]
      })
    })

    await actions.updateStockWatchlistMaster(stockWatchlistData)
  }

  subscribeWatchListStocks = () => {
    const { currentWatchlistSecurities } = this.props
    const securitiesArray = currentWatchlistSecurities
    if (securitiesArray && securitiesArray?.length > 0) {
      this.odinWorkerManager.subscribeStocks(securitiesArray)
    }
  }

  unsubscribeWatchListStocks = async (
    PREV_SECURITY_ARRAY: any = this.props.currentWatchlistSecurities
  ) => {
    if (PREV_SECURITY_ARRAY && PREV_SECURITY_ARRAY.length > 0) {
      this.odinWorkerManager.unSubscribeStocks(PREV_SECURITY_ARRAY)
    }
  }

  handleDragEnd = async (result: DropResult) => {
    const source = result.source.index
    const destination = result.destination?.index

    this.setState({
      disableMounseHover: false
    })

    if (
      destination === source ||
      destination === null ||
      destination === undefined
    ) {
      return
    }

    const {
      actions,
      currentWatchlistSecurities: securitiesArray,
      currentWatchlistId
    } = this.props

    const newItems = Array.from(securitiesArray as T_SCRIPTS_OBJ[])
    const [removed] = newItems.splice(source, 1)
    newItems.splice(destination, 0, removed)

    let payloadArray: updateScriptsSequenceObj[] = []

    newItems.reverse().forEach((item: T_SCRIPTS_OBJ, index: number) => {
      payloadArray.push({
        ...item,
        sequenceNumber: index
      })
    })

    actions.setSecurityArray({
      securitiesArray: payloadArray,
      watchlistId: currentWatchlistId
    })

    const requestData: updateScriptsSequencePayload = {
      params: {
        watchlistId: currentWatchlistId
      },
      dataPayload: {
        watchlistSecurities: payloadArray
      }
    }

    const updateScriptsSequenceResponse =
      await actions.updateScriptsSequence(requestData)

    if (updateScriptsSequenceResponse?._isCustomError) {
      actions.setSecurityArray({
        securitiesArray: securitiesArray?.length
          ? securitiesArray?.reverse()
          : [],
        watchlistId: currentWatchlistId
      })
      console.log('failed API - updateScriptsSequence')

      enqueueNotistack({
        message: 'Request failed!'
      })
      return
    }

    // update the default securities array in WatchlistToSecuritiesArray redux
    actions.setWatchlistToSecuritiesArray({
      securitiesArray: payloadArray,
      watchlistId: currentWatchlistId
    })

    enqueueNotistack({
      message: 'Script Sequence Updated!'
    })
  }

  handleHover = (sequenceNumber: number) => {
    this.setState({ hover: sequenceNumber })
  }

  handleLeaveHover = () => {
    this.setState({ hover: -1, selectedScript: '0' })
  }

  handleViewMore = (sequenceNumber: any) => {
    this.setState({ selectedScript: sequenceNumber })
  }

  handleDelete = async (index: number) => {
    const {
      actions,
      currentWatchlistSecurities: securitiesArray,
      currentWatchlistId
    } = this.props

    const toBeDeletedScript = securitiesArray?.[index]
    const arrayTillDeletedObj: T_SCRIPTS_OBJ[] =
      securitiesArray?.slice(0, index) || []
    const arrayFromDeletedObject: T_SCRIPTS_OBJ[] =
      securitiesArray?.slice(index + 1) || []

    const finalArrayAfterDeletion = [
      ...arrayTillDeletedObj,
      ...arrayFromDeletedObject
    ]

    const newSecurityArray: T_SCRIPTS_OBJ[] = []

    finalArrayAfterDeletion
      .reverse()
      .forEach((item: T_SCRIPTS_OBJ, index: number) => {
        newSecurityArray.push({
          ...item,
          sequenceNumber: index
        })
      })

    actions.setSecurityArray({
      securitiesArray: newSecurityArray,
      watchlistId: currentWatchlistId
    })

    const requestData: updateScriptsSequencePayload = {
      params: {
        watchlistId: currentWatchlistId
      },
      dataPayload: {
        watchlistSecurities: newSecurityArray
      }
    }

    const updateScriptsSequenceResponse =
      await actions.updateScriptsSequence(requestData)
    if (updateScriptsSequenceResponse?._isCustomError) {
      actions.setSecurityArray({
        securitiesArray: securitiesArray?.length
          ? securitiesArray?.reverse()
          : [],
        watchlistId: currentWatchlistId
      })

      console.log('failed API - updateScriptsSequence')

      enqueueNotistack({
        message: 'Request failed!'
      })
      return
    }

    // update the default securities array in WatchlistToSecuritiesArray redux
    actions.setWatchlistToSecuritiesArray({
      securitiesArray: newSecurityArray,
      watchlistId: currentWatchlistId
    })

    // update the scriptToWatchlistMaster redux
    if (toBeDeletedScript) {
      await actions.removeWatchlistIdFromStocks({
        watchListIdToDelete: currentWatchlistId,
        scriptIdArray: [toBeDeletedScript]
      })
    }

    enqueueNotistack({
      message: 'Script Deleted!'
    })

    this.odinWorkerManager = odinWorkerManager
    this.odinWorkerManager.unSubscribeStocks([toBeDeletedScript])
    this.setState({
      selectedScript: '0'
    })
  }

  handleSelectedStock = (item: any) => {
    const { actions } = this.props
    const { scriptId } = item
    const { exchange } = (window as any).secMaster.getByScriptId(scriptId) || {}
    actions.setSelectedExchange({
      selectedExchange: exchange
    })
    this.navigateTo(`/quotes/${scriptId}`)
  }

  handleBuySell = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string,
    selectedScript: string
  ) => {
    const { actions } = this.props
    e.stopPropagation()
    const { exchange } =
      (window as any).secMaster.getByScriptId(selectedScript) || {}
    actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: true,
      orderScriptId: selectedScript,
      orderBuyOrSell: value,
      selectedExchange: exchange,
      isModifyOrderRequest: false,
      isRepeatOrderRequest: false
    })
  }

  handleAddScriptsClick = async () => {
    const {
      setOpen,
      actions,
      currentWatchlistSecurities: watchlistSecurities,
      currentWatchlistId: watchlistId
    } = this.props

    const tempStockWatchlistData: T_StockWatchlistMasterData = {}

    watchlistSecurities?.forEach((stockObj: T_SCRIPTS_OBJ) => {
      const { scriptId } = stockObj
      if (!tempStockWatchlistData[scriptId]) {
        tempStockWatchlistData[scriptId] = [watchlistId]
        return
      }
      tempStockWatchlistData[scriptId] = [
        ...tempStockWatchlistData[scriptId],
        watchlistId
      ]
    })

    await actions.setAddScriptToParticularWatchlist(true)
    await actions.setTemporaryStockWatchlistData(tempStockWatchlistData)

    setOpen(true)
  }

  handleOptionChain = (e: any, item: any) => {
    const { actions } = this.props
    e.stopPropagation()
    e.preventDefault()
    this.handleSelectedStock(item)
    actions.openAppStateDialog('isOptionChain')
  }
  showOptionChain: (scriptId: any) => boolean = scriptId => {
    const { getAllDerivative } = this.props
    const mappedId = (window as any)?.secMaster?._getScripIdForBSEDerivative(
      scriptId
    )
    const derivatives: any = getAllDerivative
    const futures = derivatives?.[mappedId]?.['FUTURES'] || []
    const options = derivatives?.[mappedId]?.['OPTIONS'] || []
    const show: boolean =
      futures.length > 0 && options.length > 0 ? true : false
    return show
  }

  render() {
    const { hover, selectedScript, disableMounseHover } = this.state
    const {
      currentWatchlistSecurities: securitiesArray,
      currentWatchlistType: watchListType,
      isWatchlistFetchedAlready,
      getAllDerivative
    } = this.props
    if (!isWatchlistFetchedAlready) {
      return
    }
    const securitiesArrayLength = securitiesArray?.length
    return (
      <>
        {securitiesArrayLength !== 0 && securitiesArray !== null ? (
          <DragDropContext
            onDragEnd={this.handleDragEnd}
            onDragStart={() => {
              this.setState({
                disableMounseHover: true,
                hover: -1
              })
            }}
          >
            <DsBox
              sx={{
                borderTop: '1px solid var(--ds-colour-strokeDefault)'
              }}
            >
              <DsList
                sx={{
                  paddingBottom: '0px !important'
                }}
              >
                <Droppable droppableId='list'>
                  {provided => (
                    <DsBox {...provided.droppableProps} ref={provided.innerRef}>
                      {securitiesArray?.map((item: any, i: number) => {
                        const { aslAllowed } =
                          (window as any).secMaster.getByScriptId(
                            item.scriptId
                          ) || {}
                        const notTradable = isNonTradableStock(aslAllowed)
                        return (
                          item.scriptId && (
                            <Draggable
                              key={item.scriptId + i}
                              draggableId={item.scriptId + i}
                              index={i}
                              isDragDisabled={
                                item.sequenceNumber !== selectedScript
                              }
                            >
                              {provided => (
                                <DsBox
                                  sx={{
                                    marginBottom:
                                      securitiesArray.length - 1 === i
                                        ? 'var(--ds-spacing-molten)'
                                        : 'var(--ds-spacing-zero)'
                                  }}
                                >
                                  <DsListItem
                                    sx={{
                                      backgroundColor:
                                        'var(--ds-colour-surfaceBackground)'
                                    }}
                                    key={item.sequenceNumber}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    disablePadding
                                    onClick={() =>
                                      this.handleSelectedStock(item)
                                    }
                                    onMouseEnter={() => {
                                      !disableMounseHover &&
                                        this.handleHover(item?.sequenceNumber)
                                    }}
                                    onMouseLeave={() => {
                                      !disableMounseHover &&
                                        this.handleLeaveHover()
                                    }}
                                  >
                                    <DsListItemButton
                                      sx={{
                                        display: 'block',
                                        p: 'var(--ds-spacing-glacial) var(--ds-spacing-mild)'
                                      }}
                                    >
                                      <StockData scriptId={item.scriptId} />
                                    </DsListItemButton>
                                    {item.sequenceNumber === hover && (
                                      <DsBox
                                        sx={{
                                          width:
                                            item.sequenceNumber ===
                                              selectedScript
                                              ? '100%'
                                              : 'unset',
                                          position: 'absolute',
                                          right: 0,
                                          display: { md: 'flex', xs: 'none' },
                                          alignItems: 'center',
                                          justifyContent: 'space-between'
                                        }}
                                      >
                                        {item.sequenceNumber ===
                                          selectedScript && (
                                            <DsBox
                                              sx={{
                                                ml: 1
                                              }}
                                            >
                                              <DsImage
                                                srcSet={LIST_DRAGGER}
                                                style={{
                                                  width: '15px',
                                                  height: '15px'
                                                }}
                                              />
                                            </DsBox>
                                          )}

                                        <DsBox
                                          sx={{
                                            display: 'flex',
                                            gap: 1,
                                            mr: 2
                                          }}
                                        >
                                          {!notTradable && (
                                            <>
                                              <DsButton
                                                color='success'
                                                // sx={{
                                                //   backgroundColor:
                                                //     'var(--ds-colour-iconPositive)',
                                                //   '&:hover': {
                                                //     backgroundColor:
                                                //       'var(--ds-colour-iconPositive) !important'
                                                //   }
                                                // }}
                                                onClick={e => {
                                                  this.handleBuySell(
                                                    e,
                                                    'BUY',
                                                    item.scriptId
                                                  )
                                                }}
                                              >
                                                {WATCHLIST_LABEL.WATCHLIST.BUY}
                                              </DsButton>
                                              <DsButton
                                                color='error'
                                                // sx={{
                                                //   backgroundColor:
                                                //     'var(--ds-colour-iconNegative)',
                                                //   '&:hover': {
                                                //     backgroundColor:
                                                //       'var(--ds-colour-iconNegative) !important'
                                                //   }
                                                // }}
                                                onClick={e => {
                                                  this.handleBuySell(
                                                    e,
                                                    'SELL',
                                                    item.scriptId
                                                  )
                                                }}
                                              >
                                                {WATCHLIST_LABEL.WATCHLIST.SELL}
                                              </DsButton>
                                              <DsTooltip
                                                title='Market Depth'
                                                placement='bottom'
                                                arrow={false}
                                                disableTouchListener
                                                disableInteractive
                                              >
                                                <DsButton
                                                  color='secondary'
                                                  sx={{
                                                    minWidth:
                                                      'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
                                                  }}
                                                  onClick={e => {
                                                    e.stopPropagation()
                                                    this.handleSelectedStock(
                                                      item
                                                    )
                                                  }}
                                                >
                                                  <DsBox
                                                    sx={{
                                                      height:
                                                        'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))',
                                                      width:
                                                        'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
                                                    }}
                                                  >
                                                    <DsImage
                                                      srcSet={MARKET_DEPTH}
                                                    />
                                                  </DsBox>
                                                </DsButton>
                                              </DsTooltip>
                                            </>
                                          )}
                                          {this.showOptionChain(
                                            item.scriptId
                                          ) && (
                                              <DsTooltip
                                                title='Option chain'
                                                placement='bottom'
                                                arrow={false}
                                                disableTouchListener
                                                disableInteractive
                                              >
                                                <DsButton
                                                  color='secondary'
                                                  sx={{
                                                    minWidth:
                                                      'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
                                                  }}
                                                  onClick={(e: any) => {
                                                    this.handleOptionChain(
                                                      e,
                                                      item
                                                    )
                                                  }}
                                                >
                                                  <DsBox
                                                    sx={{
                                                      height:
                                                        'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))',
                                                      width:
                                                        'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
                                                    }}
                                                  >
                                                    <DsRemixIcon
                                                      className='ri-stock-line'
                                                      fontSize='bitterCold'
                                                      sx={{
                                                        fontWeight:
                                                          'var(--ds-typo-fontWeightRegular)'
                                                      }}
                                                    />
                                                  </DsBox>
                                                </DsButton>
                                              </DsTooltip>
                                            )}

                                          {item.sequenceNumber ===
                                            selectedScript &&
                                            watchListType !== 'PRE-DEFINED' ? (
                                            <DsButton
                                              color='secondary'
                                              sx={{
                                                minWidth:
                                                  'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
                                              }}
                                              onClick={e => {
                                                e.stopPropagation()
                                                this.handleDelete(i)
                                              }}
                                            >
                                              <DsBox
                                                style={{
                                                  height:
                                                    'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))',
                                                  width:
                                                    'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
                                                }}
                                              >
                                                <DsImage srcSet={DELETE} />
                                              </DsBox>
                                            </DsButton>
                                          ) : item.sequenceNumber !==
                                            selectedScript &&
                                            watchListType !== 'PRE-DEFINED' ? (
                                            <DsTooltip
                                              title='More'
                                              placement='bottom'
                                              arrow={false}
                                              disableTouchListener
                                              disableInteractive
                                            >
                                              <DsButton
                                                color='secondary'
                                                sx={{
                                                  minWidth:
                                                    'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
                                                }}
                                                onClick={e => {
                                                  e.stopPropagation()
                                                  this.handleViewMore(
                                                    item.sequenceNumber
                                                  )
                                                }}
                                              >
                                                <DsBox
                                                  style={{
                                                    height:
                                                      'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))',
                                                    width:
                                                      'calc(var(--ds-spacing-bitterCold) + var(--ds-spacing-deepFreeze))'
                                                  }}
                                                >
                                                  <DsImage
                                                    srcSet={MORE_OPTIONS}
                                                  />
                                                </DsBox>
                                              </DsButton>
                                            </DsTooltip>
                                          ) : (
                                            ''
                                          )}
                                        </DsBox>
                                      </DsBox>
                                    )}
                                  </DsListItem>
                                  <DsDivider
                                    sx={{
                                      margin:
                                        'var(--ds-spacing-zero) var(--ds-spacing-mild)'
                                    }}
                                  />
                                </DsBox>
                              )}
                            </Draggable>
                          )
                        )
                      })}
                      {provided.placeholder}
                    </DsBox>
                  )}
                </Droppable>
              </DsList>
            </DsBox>
          </DragDropContext>
        ) : (
          <DsBox
            sx={{
              height: { xs: '75vh', md: '60vh' },
              display: 'flex',
              p: 'var(--ds-spacing-tropical)',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              borderTop: '1px solid var(--ds-colour-strokeDefault)'
            }}
          >
            <DsBox>
              <DsImage srcSet={ADD_SCRIPT_ILLUSTRATION} />
            </DsBox>

            <DsTypography variant='bodyRegularLarge'>
              Your watchlist looks empty! <br />
              Did you know you can add upto <b>50 scrips</b>?
            </DsTypography>
            <DsButton
              sx={{
                display: { md: 'block', xs: 'none' },
                mt: 'var(--ds-spacing-pleasant)'
              }}
              onClick={this.handleAddScriptsClick}
            >
              ADD SCRIPS
            </DsButton>
            <DsButton
              sx={{
                display: { md: 'none', xs: 'block' },
                mt: 'var(--ds-spacing-pleasant)'
              }}
              onClick={() => {
                this.handleAddScriptsClick()
                this.navigateTo(APP_ROUTES.MOBILE_SEARCH.pathname)
              }}
            >
              ADD SCRIPS
            </DsButton>
          </DsBox>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { selectedWatchlistTab } = ownProps
  const isOdinConnected = isOdinConnectionIstablished(state)
  const isWatchlistFetchedAlready = isWatchlistFetched(state)
  const shouldSubscribe = shouldSubscribePostUpdate(state)
  const getAllDerivative = getAllDerivativeSelector(state)
  const {
    currentWatchlistId,
    currentSubAccountId,
    currentWatchlistName,
    currentWatchlistType,
    currentWatchlistSequenceNumber,
    currentWatchlistSecurities
  } = getWatchlist(selectedWatchlistTab)(state) || {}
  const deepLinkAttributes = getDeepLinkAttributes(state)
  const watchListIndex = getWatchListIndex(state)

  return {
    isOdinConnected,
    isWatchlistFetchedAlready,
    shouldSubscribe,
    currentWatchlistId,
    currentSubAccountId,
    currentWatchlistName,
    currentWatchlistType,
    currentWatchlistSequenceNumber,
    currentWatchlistSecurities,
    getAllDerivative,
    deepLinkAttributes,
    watchListIndex
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    fetchWatchlist: () => dispatch(fetchWatchlistsAction()),
    updateScriptsSequence: (requestData: updateScriptsSequencePayload) =>
      dispatch(updateScriptsSequenceAction(requestData)),
    setWatchlist: (requestData: IsetWatchlistReqData) =>
      dispatch(setWatchlist(requestData)),
    watchlistFetched: (reqData: boolean) => dispatch(watchlistFetched(reqData)),
    setSecurityArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setSecurityArray(requestData)),
    subscribe: (requestData: boolean) => dispatch(subscribe(requestData)),
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    updateStockWatchlistMaster: (requestData: T_StockWatchlistMasterData) =>
      dispatch(updateStockWatchlistMasterAction(requestData)),
    setWatchlistToSecuritiesArray: (requestData: T_SET_SECURITIES_REQ_DATA) =>
      dispatch(setWatchlistToSecuritiesArray(requestData)),
    removeWatchlistIdFromStocks: (requestData: {
      watchListIdToDelete: string
      scriptIdArray: T_SCRIPTS_OBJ[]
    }) => dispatch(removeWatchlistIdFromStocksAction(requestData)),
    setAddScriptToParticularWatchlist: (requestData: boolean) =>
      dispatch(setAddScriptToParticularWatchlist(requestData)),
    setTemporaryStockWatchlistData: (requestData: any) =>
      dispatch(setTemporaryStockWatchlistData(requestData)),
    setSelectedExchange: (requestData: any) =>
      dispatch(setSelectedExchange(requestData)),
    openAppStateDialog: (key: any) => dispatch(openAppStateDialogActions(key)),
    setSelectedWatchlistTab: (selectedWatchlistTab: number | string) =>
      dispatch(
        selectWatchlistTab({
          watchlistTab: selectedWatchlistTab
        })
      ),
    resetDeepLinkData: () => dispatch(resetDeepLinkData(null))
  }
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(WatchList)
