import * as React from 'react'
import MainLayout from './Layouts/Main.Layout'
import Random from './Components/ReverseFeedAlert/ReverseFeedAlert'
import { DsNotistackProvider } from '@am92/react-design-system'

export interface INotiStackWrapperProps {}

export default class NotiStackWrapper extends React.Component<INotiStackWrapperProps> {
  render() {
    return (
      <DsNotistackProvider
        preventDuplicate={true}
        Components={{
          custom: Random
        }}
      >
        <MainLayout />
      </DsNotistackProvider>
    )
  }
}
