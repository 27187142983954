export const SCRIPT_ALERT: any = {
  GT: {
    label: 'Price greater than Target Price',
    value: 'GT'
  },
  LT: {
    label: 'Price less than Target Price',
    value: 'LT'
  },
  GTE: {
    label: 'Price greater than equals to Target Price',
    value: 'GTE'
  },
  LTE: {
    label: 'Price less than equals to Target Price',
    value: 'LTE'
  }
}
