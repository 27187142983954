import * as React from 'react'
import {
  DsBox,
  DsButton,
  DsButtonGroup,
  DsButtonGroupProps,
  DsButtonProps,
  DsLink,
  DsStack,
  DsTypography,
  DsRemixIcon,
  DsPopup
} from '@am92/react-design-system'

interface IReachOut {
  contactNumbers: string[]
  emails: string[]
}

export interface IDialogProps {
  title: string
  descriptions: string[]
  primaryButtonLabel: string
  primaryButtonClick: () => void
  primaryButtonIcon?: string

  reachOut?: IReachOut
  secondaryButtonLabel?: string
  secondaryButtonClick?: () => void
  secondaryButtonIcon?: string

  tertiaryButtonLabel?: string
  tertiaryButtonClick?: () => void

  onClose?: () => void

  buttonGroupProps?: DsButtonGroupProps
  primaryButtonProps?: Omit<DsButtonProps, 'color' | 'ref' | 'onClick'>
  secondaryButtonProps?: Omit<DsButtonProps, 'color' | 'ref' | 'onClick'>
}

const DDialogProps = {
  buttonGroupProps: {},
  primaryButtonProps: {},
  secondaryButtonProps: {},
  onClose: () => undefined
}

export default class Dialog extends React.PureComponent<IDialogProps> {
  renderButtonGroup = () => {
    const {
      primaryButtonLabel,
      primaryButtonIcon,
      primaryButtonProps,
      primaryButtonClick,
      secondaryButtonLabel,
      secondaryButtonIcon,
      secondaryButtonProps,
      secondaryButtonClick
    } = this.props

    return (
      <DsButtonGroup fullWidth size='large' noPadding>
        {secondaryButtonLabel && (
          <DsButton
            color='secondary'
            {...secondaryButtonProps}
            onClick={secondaryButtonClick}
            {...(secondaryButtonIcon && {
              endIcon: <DsRemixIcon className={secondaryButtonIcon} />
            })}
          >
            {secondaryButtonLabel}
          </DsButton>
        )}
        <DsButton
          color='primary'
          {...primaryButtonProps}
          onClick={primaryButtonClick}
          {...(primaryButtonIcon && {
            endIcon: <DsRemixIcon className={primaryButtonIcon} />
          })}
        >
          {primaryButtonLabel}
        </DsButton>
      </DsButtonGroup>
    )
  }

  renderDescriptions = () => {
    const { descriptions } = this.props
    return descriptions.map(description => {
      return (
        <DsTypography
          color='text.secondary'
          variant='bodyRegularMedium'
          component={'div'}
        >
          {description.split('/').map(e => {
            return e.charAt(0) === '&' ? <b>{e.substring(1)}</b> : e
          })}
        </DsTypography>
      )
    })
  }

  renderReachOut = () => {
    const { reachOut } = this.props

    if (!reachOut) return null

    const { contactNumbers, emails } = reachOut
    const contactNumberJSX = contactNumbers.map((contactNumber, index) => (
      <>
        <DsLink underline='none' href={`tel:${contactNumber}`}>
          {contactNumber}
        </DsLink>
        {index < contactNumbers.length - 1 && (
          <DsTypography
            sx={{
              mx: 'var(--ds-spacing-frostbite)'
            }}
            variant='bodyRegularSmall'
          >
            |
          </DsTypography>
        )}
      </>
    ))
    const emailJSX = emails.map((email, index) => (
      <>
        <DsLink underline='none' href={`mailto:${email}`}>
          {email}
        </DsLink>
        {index < emails.length - 1 && (
          <DsTypography
            sx={{
              mx: 'var(--ds-spacing-frostbite)'
            }}
            variant='bodyRegularSmall'
          >
            |
          </DsTypography>
        )}
      </>
    ))
    return (
      <DsStack
        direction='row'
        spacing='var(--ds-spacing-frostbite)'
        sx={{ p: 'var(--ds-spacing-glacial)' }}
      >
        <DsStack
          spacing='var(--ds-spacing-quickFreeze)'
          sx={{ flexBasis: '50%' }}
        >
          <DsTypography color='text.secondary' variant='supportRegularMetadata'>
            Contact number
          </DsTypography>
          <DsBox sx={{ display: 'flex', justifyContent: 'center' }}>
            {contactNumberJSX}
          </DsBox>
        </DsStack>
        <DsStack
          spacing='var(--ds-spacing-quickFreeze)'
          sx={{ flexBasis: '50%' }}
        >
          <DsTypography color='text.secondary' variant='supportRegularMetadata'>
            Email Address
          </DsTypography>
          <DsTypography variant='bodyRegularSmall'>{emailJSX}</DsTypography>
        </DsStack>
      </DsStack>
    )
  }

  renderTertiaryButton = () => {
    const { tertiaryButtonLabel, tertiaryButtonClick } = this.props
    if (!tertiaryButtonLabel) return null

    return (
      <DsButton variant='text' color='secondary' onClick={tertiaryButtonClick}>
        {tertiaryButtonLabel}
      </DsButton>
    )
  }

  render() {
    const { title, onClose } = this.props
    return (
      <DsPopup
        showClose={false}
        open
        onClose={onClose}
        sx={{
          '.MuiDialogContent-root': {
            marginTop: 'var(--ds-spacing-zero)'
          },
          display: { xs: 'none', md: 'block' }
        }}
      // DsDialogProps={{
      //   open: true,
      //   onClose: onClose,
      //   sx: {
      //     '.MuiDialogContent-root': {
      //       marginTop: 'var(--ds-spacing-zero)'
      //     },
      //     display: { xs: 'none', md: 'block' }
      //   }
      // }}
      >
        <DsStack spacing='var(--ds-spacing-mild)' sx={{ textAlign: 'center' }}>
          <DsTypography variant='headingBoldLarge' component={'div'}>
            {title}
          </DsTypography>
          <DsStack spacing='var(--ds-spacing-cool)'>
            {this.renderDescriptions()}
          </DsStack>
          {this.renderReachOut()}
          {this.renderButtonGroup()}
        </DsStack>
      </DsPopup>
    )
  }
}
