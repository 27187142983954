import { DsBox, DsDivider, DsRemixIcon, DsStack, DsTypography, IwithBreakpoints, withBreakpoints } from '@am92/react-design-system'
import React, { Component } from 'react'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import EventsContainerTab from '../Events/EventsContainerTab'
import getNotificationsAction from '~/src/Redux/Notifications/Services/getNotifications.Service'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { getNotificationsSelector } from '~/src/Redux/Notifications/Selectors'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import NotificationZeroScreen from './NotificationZeroScreen'
import { isServiceError } from '~/src/Redux/ServiceTracker/Selectors'
import APIErrorScreen from '../APIErrorScreen/APIErrorScreen'
import { _sortBy, _flatMap } from '~/src/Utils/lodash'
import { format, parseISO } from 'date-fns'
import { NOTIFICATION_CONST } from '~/src/Constants/NOTIFICATION_CONSTANT'
import { generatePath } from 'react-router-dom'

type ActionTypes = {
  getNotifications: (data: any) => void
}
interface INotificationsProps extends IWithRouterProps, IwithBreakpoints {
  breakpoints: any
  actions: ActionTypes
  Notifications: any
  location: any
  isError: any
  setShowNotification: (data: any) => void
}
interface INotificationsState {
  selectedTab: string
}

export class Notifications extends Component<INotificationsProps, INotificationsState> {
  constructor(Props: INotificationsProps) {
    super(Props)
    this.state = {
      selectedTab: ''
    }
  }
  handleGetNotifications = async (e?: any) => {
    const { actions } = this.props
    await actions.getNotifications({ category: '' })
  }
  async componentDidMount(): Promise<void> {
    // this.handleGetNotifications()
    const { Notifications } = this.props
    this.setState({ selectedTab: Object.keys(Notifications)[0] })
  }
  handleTabChange = async (e: any) => {
    this.setState({ selectedTab: e })
    // this.setState({ isLoading: true })
  }
  componentDidUpdate(prevProps: Readonly<INotificationsProps>, prevState: Readonly<INotificationsState>, snapshot?: any): void {
    const { breakpoints, location, navigateTo } = this.props
    const { md, lg, xl } = breakpoints
    const { pathname } = location
    if (pathname == '/notifications' && (md || lg || xl)) {
      navigateTo('/')
    }
  }
  createTabs = () => {
    const { Notifications } = this.props
    let tabs: any = []
    for (let i = 0; i < Object.keys(Notifications).length; i++) {
      tabs.push({
        label: Object.keys(Notifications)[i], id: i, value: Object.keys(Notifications)[i]
      })
    }
    return tabs
  }
  handleReduceData = () => {
    const { selectedTab } = this.state
    const { Notifications } = this.props
    let data: any[] = []
    data = Notifications[selectedTab]
    return data || []
  }
  handleBack = () => {
    const { navigateTo } = this.props
    navigateTo(-1)
  }
  handleOnSelect = (item: any) => {
    const { navigateTo, setShowNotification, breakpoints } = this.props
    if (item.category == 'Alerts') {
      const { metaData } = item
      const { scriptId = '' } = metaData || {}
      if (scriptId) {
        const path = generatePath(`${NOTIFICATION_CONST[item.category].url}` as string, {
          scriptId: scriptId
        })
        navigateTo(path)
      } else {
        return false
      }
    } else {
      navigateTo(NOTIFICATION_CONST[item.category].url)
    }
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false
    isDesktop && setShowNotification(false)
  }
  render() {
    const { breakpoints, Notifications, isError } = this.props
    const { md, lg, xl } = breakpoints
    const data = this.handleReduceData()
    const tabs = this.createTabs()
    return (
      <>
        <DsBox sx={{ position: "fixed", background: "var(--ds-colour-surfacePrimary)", width: md || lg || xl ? "28rem" : '100%', zIndex: 1 }}>
          <DsBox sx={{
            p: md || lg || xl ? "1rem 0rem 1rem 0.75rem" : "1rem 0rem 1rem 0.75rem"
          }}>
            {md || lg || xl ? <DsTypography variant='headingBoldSmall'>All Notifications</DsTypography> :
              <DsStack flexDirection="row" justifyContent="space-between">
                <DsStack flexDirection="row" gap="0.5rem" >
                  <DsRemixIcon className="ri-arrow-left-line" onClick={this.handleBack} />
                  <DsTypography variant="headingBoldSmall">Notifications</DsTypography>
                </DsStack>
                {/* for future usecase */}
                {/* <DsRemixIcon
                className='ri-settings-4-line'
                fontSize='mild'
              /> */}
              </DsStack>
            }
          </DsBox>
          {!isError && data.length > 0 && <DsBox sx={{
            border: { md: "1px solid var(--ds-colour-strokeDefault)", sm: "" },
            borderBottom: 'hidden !important',
            p: md || lg || xl ? "1rem 0rem 1rem 0.75rem" : '1rem 0rem 1rem 0.75rem'
          }}>
            <EventsContainerTab
              renderTabs={tabs}
              enableArrow={Object.values(Notifications).length > 4 ? true : false}
              containedTabs={false}
              onChange={this.handleTabChange}
            />
          </DsBox>}
        </DsBox>
        <DsBox sx={{
          border: { md: "1px solid var(--ds-colour-strokeDefault)", sm: "" },
          borderRadius: { md: "0px 0px 4px 4px", sm: "" },
          p: "1rem 0.75rem 1rem 0.75rem"
        }}>
          {
            !isError
              ? Notifications ?
                <DsBox sx={{
                  overflowY: 'hidden', position: 'relative', mt: md || lg || xl ? "7rem" : '6rem', scrollBehavior: 'smooth',
                  '&::-webkit-scrollbar': { display: 'none !important' },
                  '-msOverflowStyle': 'none',
                  scrollbarWidth: 'none'
                }}>
                  {data.length > 0 ? data.map((item: any, i: number) => {
                    return (
                      <>
                        <DsStack flexDirection="row" justifyContent="space-between" p="1rem 0rem 1rem 0rem" onClick={() => this.handleOnSelect(item)} sx={{ cursor: "pointer" }}>
                          <DsStack flexDirection="row" gap="1rem">
                            <DsBox sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "0.25rem",
                              height: '2rem',
                              width: '2rem',
                              background: NOTIFICATION_CONST[item.category].background
                            }}>
                              <DsRemixIcon className={NOTIFICATION_CONST[item.category].className} />
                            </DsBox>
                            <DsStack flexDirection="column" gap="0.5rem">
                              <DsTypography variant='bodyBoldSmall'>{item.title}</DsTypography>
                              <DsTypography variant="bodyRegularSmall">{item.description}</DsTypography>
                            </DsStack>
                          </DsStack>
                          <DsTypography variant="supportRegularInfo" sx={{ width: "9vh" }} noWrap>{format(parseISO(item.createdAt), 'dd MMM')}</DsTypography>
                        </DsStack>
                        {i !== data.length - 1 && <DsDivider />}
                      </>
                    )
                  }) : <NotificationZeroScreen />}
                </DsBox>
                : <NotificationZeroScreen /> : <DsBox alignItems="center" display="flex" justifyContent="center" width="100%" sx={{ height: { md: "26rem", xs: "85vh" } }} ><APIErrorScreen heading={'Something went wrong.'}
                  description={
                    'We did not want you to face this. Please come try again'
                  }
                  handleOnClick={this.handleGetNotifications} view='table' /></DsBox>}
        </DsBox>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const Notifications = getNotificationsSelector(state)
  const isError = isServiceError(state, ["notifications/getNotifications"])
  return {
    Notifications,
    isError
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    getNotifications: (data: any) => dispatch(getNotificationsAction(data))
  }
})

export default withBreakpoints(withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications))
