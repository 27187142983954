import { createSelector } from '@reduxjs/toolkit'
import { THoldingsWithStockMasterDocument } from '~/src/Pages/Dashboard/Components/HoldingSummaryCard/HoldingSummaryCard'

export const SLICE_NAME = 'ticker'

const selectTicker = (state: any) => state[SLICE_NAME]

export const getTickerData = (scriptId: string) =>
  createSelector(selectTicker, ticker => ticker?.[scriptId])

export const getFullTickerData = createSelector(selectTicker, ticker => ticker)

export const getIndividualStockHoldingValues = (scriptIdObj: any) =>
  createSelector(selectTicker, ticker => {
    let currentValue = 0
    let overallPL = 0
    let todaysPL = 0
    const { scriptId, holdingQty, investedValue, CLOSE_PRICE = 0 } = scriptIdObj
    const { closeprice } =
      (window as any).secMaster.getByScriptId(scriptId) || {}
    const cp = closeprice || CLOSE_PRICE
    const tickerValue = (ticker && ticker[scriptId]) || {}
    const { LTP = 0 } = tickerValue || {}
    currentValue =
      Number(holdingQty) * Number(LTP) || Number(holdingQty) * Number(cp)
    overallPL = currentValue - Number(investedValue)
    todaysPL = currentValue - Number(holdingQty) * Number(cp)
    return { currentValue, overallPL, todaysPL }
  })

export const getHoldingValue = (
  scriptIdArray: THoldingsWithStockMasterDocument[]
) =>
  createSelector(selectTicker, ticker => {
    let currentValue = 0
    let overallPL = 0
    let todaysPL = 0
    scriptIdArray.forEach(scriptIdObj => {
      const { scriptId, holdingQty, investedValue, closeprice } = scriptIdObj
      const tickerValue = (ticker && ticker[scriptId]) || {}
      const { LTP = 0 } = tickerValue || {}
      let currentLtpIntoHoldingValue = Number(holdingQty) * Number(LTP)
      currentValue = currentValue + currentLtpIntoHoldingValue
      overallPL =
        overallPL + (currentLtpIntoHoldingValue - Number(investedValue))
      todaysPL =
        todaysPL +
        currentLtpIntoHoldingValue -
        Number(holdingQty) * Number(closeprice)
    })
    return { currentValue, overallPL, todaysPL }
  })

export const getPositionsValue = (scriptIdArray: any) =>
  createSelector(selectTicker, ticker => {
    let currentValue = 0
    let unrealisedPL = 0
    let todaysPL = 0
    let realisePL = 0
    let totalInvestedAmount = 0
    let totalOpenPL = 0
    let unrealisedOpenPL = 0
    const openPositions = scriptIdArray?.length
    scriptIdArray?.forEach((scriptIdObj: any) => {
      const {
        scriptId,
        buyQty = 0,
        sellQty = 0,
        realizedPL = 0,
        avgBuyPrice = 0,
        avgSellPrice = 0,
        positionSide,
        netPrice = 0,
        netQty = 0
      } = scriptIdObj
      const buy = Number(sellQty) - Number(buyQty)
      let investedValue = 0
      let avgPrice = positionSide === 'buy' ? avgBuyPrice : avgSellPrice
      if (avgPrice <= 0) avgPrice = netPrice
      investedValue = Number(netPrice) * Number(netQty)
      const tickerValue = (ticker && ticker[scriptId]) || {}
      const { LTP = 0 } = tickerValue || {}
      let currentLtpIntoPositionValue = Number(netQty) * Number(LTP)
      currentValue = currentValue + currentLtpIntoPositionValue
      realisePL = realisePL + realizedPL
      unrealisedPL =
        unrealisedPL + (currentLtpIntoPositionValue - Number(investedValue))
      todaysPL = realisePL + unrealisedPL
      totalInvestedAmount = totalInvestedAmount + investedValue
      totalOpenPL =
        totalOpenPL + (currentLtpIntoPositionValue - Number(investedValue))
      unrealisedOpenPL = totalOpenPL - realisePL
    })
    return {
      currentValue,
      unrealisedPL,
      todaysPL,
      realisePL,
      openPositions,
      totalInvestedAmount,
      totalOpenPL,
      unrealisedOpenPL
    }
  })

export const getHoldingsCalculatedOverallValueSelector = (scriptIdArray: any) =>
  createSelector(selectTicker, ticker => {
    let currentValue = 0
    let overallPL = 0
    let todaysPL = 0
    scriptIdArray.forEach((scriptIdObj: any) => {
      const { scriptId, holdingQty, investedValue, closeprice } = scriptIdObj
      const tickerValue = (ticker && ticker[scriptId]) || {}
      const { LTP = closeprice } = tickerValue || {}
      let currentLtpIntoHoldingValue = Number(holdingQty) * Number(LTP)
      currentValue = currentValue + currentLtpIntoHoldingValue
      overallPL =
        overallPL + (currentLtpIntoHoldingValue - Number(investedValue))
      todaysPL =
        todaysPL +
        currentLtpIntoHoldingValue -
        Number(holdingQty) * Number(closeprice)
    })
    return { currentValue, overallPL, todaysPL }
  })

export const getCurrentValueAndUnrealisedPL = (
  scriptId: string,
  holdings: number,
  investedValue: number
) =>
  createSelector(selectTicker, ticker => {
    const tickerValue = (ticker && ticker[scriptId]) || {}
    const { LTP = 0 } = tickerValue || {}
    const currentValue = Number(holdings) * Number(LTP)
    const unrealisedPL = currentValue - investedValue
    let netChangePercent = 0.0
    if (investedValue) {
      netChangePercent = (unrealisedPL / investedValue) * 100
    }
    return { currentValue, unrealisedPL, netChangePercent }
  })
