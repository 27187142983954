export const GRATIFICATION_CASES = {
  PLACE_ORDER_SUCCESS: {
    heading: 'Your order has been placed!',
    subHeading:
      'Please do not close this window while we redirect you to the order book'
  },
  MODIFY_ORDER_SUCCESS: {
    heading: 'Your order has been modified!',
    subHeading:
      'Please do not close this window while we redirect you to the order book'
  },
  GTD_PLACE_ORDER_SUCCESS: {
    heading: 'Your GTD order has been received!',
    subHeading:
      'Please do not close this window while we redirect you to the order book'
  }
}
