import traceActionsCreator from '../traceActionsCreator'

export const getRecentSearchServiceName = 'search/getRecentSearch'
export const getRecentSearchActions = traceActionsCreator(
  getRecentSearchServiceName
)

export const createRecentSearchName = 'search/createRecentSearch'
export const createRecentSearchActions = traceActionsCreator(
  createRecentSearchName
)

export const deleteRecentSearchName = 'search/deleteRecentSearch'
export const deleteRecentSearchActions = traceActionsCreator(
  deleteRecentSearchName
)
