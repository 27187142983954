import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { createWatchlistActions, createWatchlistServiceName } from '../Actions'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export interface createWatchlistPayload {
  watchlistName: string
  watchlistType: 'CUSTOM'
  watchlistSecurities: null
}

async function createWatchlist(reqData: createWatchlistPayload) {
  const options = {
    url: '/watch-lists',
    method: 'POST',
    data: reqData,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const createWatchlistAction = serviceActionCreator(
  createWatchlistActions,
  createWatchlist
)

export default createWatchlistAction
export { createWatchlistServiceName }
