import { asHttp } from '~/src/Configurations/WebHttp'

import { getOrderBookActions } from '../actions'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export type T_GET_ORDER_BOOK_PAYLOAD = {
  segment: string
  orderStatus: string
  omsOrderId: string
}

async function getOrderBook(payload: T_GET_ORDER_BOOK_PAYLOAD) {
  const options = {
    url: `/trading/orders/get-order-book`,
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getOrderBookAction = serviceActionCreator(
  getOrderBookActions,
  getOrderBook
)

export default getOrderBookAction
