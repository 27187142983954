import { createSelector } from '@reduxjs/toolkit'
import { T_SCRIPTS_OBJ, watchlistObj } from './Reducer'
import { formTabsArray } from '~/src/Utils/global'
export const SLICE_NAME = 'watchList'
const select = (state: any) => state[SLICE_NAME]

export const getWatchListData = createSelector(
  select,
  watchList => watchList.watchlist
)

export const getSelectedWatchlistTab = createSelector(
  select,
  watchList => watchList.watchlistTab
)

export const getSelectedWatchlist = createSelector(
  select,
  watchList => watchList.selectedWatchlist
)

export const shouldSubscribePostUpdate = createSelector(
  select,
  watchList => watchList.subscribe
)

export const getWatchListIndex = createSelector(
  select,
  watchList => watchList.watchListIndex
)

export const getDefaultWatchlistSecurityMapping = createSelector(
  select,
  watchList => watchList.watchListToSecuritiesArrayMapping
)

export const shouldAddScriptToParticularWatchlist = createSelector(
  select,
  watchList => watchList.addScriptToParticularWatchlist
)

export const getSortScriptOption = (watchListId: string) =>
  createSelector(
    select,
    watchList => watchList.sortScriptSelectedOption[watchListId]
  )

export const getPreKeptAvailableNames = createSelector(
  select,
  watchList => watchList.watchlistPreKeptAvailableNames
)

export const getWatchlist = (selectedWatchlistTab: number | string) =>
  createSelector(select, watchList => {
    const watchlistArray = watchList.watchlist
    let currentWatchlistSecurities: T_SCRIPTS_OBJ[] | null = []
    const {
      watchlistId: currentWatchlistId = '',
      subAccountId: currentSubAccountId = '',
      watchlistName: currentWatchlistName = '',
      watchlistType: currentWatchlistType = '',
      watchlistSequenceNumber: currentWatchlistSequenceNumber = 0,
      watchlistSecurities: currentWatchlistSecuritiesArray = []
    } = (watchlistArray as watchlistObj[])?.[+selectedWatchlistTab] || {}

    if (currentWatchlistSecuritiesArray?.length)
      currentWatchlistSecurities = [
        ...currentWatchlistSecuritiesArray
      ].reverse()

    return {
      currentWatchlistId,
      currentSubAccountId,
      currentWatchlistName,
      currentWatchlistType,
      currentWatchlistSequenceNumber,
      currentWatchlistSecurities
    }
  })

export const getWatchListLength = createSelector(
  select,
  watchList => watchList.watchlist.length
)

export const getRenderTabsList = createSelector(select, watchList => {
  const watchlistArray = watchList.watchlist
  return formTabsArray(watchlistArray) || []
})

export const getSecuritiesLength = (selectedWatchlistTab: number | string) =>
  createSelector(select, watchList => {
    const watchlistArray = watchList.watchlist
    const { watchlistSecurities: currentWatchlistSecurities = [] } =
      (watchlistArray as watchlistObj[])?.[+selectedWatchlistTab] || {}
    return currentWatchlistSecurities?.length || 0
  })

export const getWatchListLengthAndFirstWatchlistId = createSelector(
  select,
  watchList => {
    const watchlistData = watchList.watchlist
    const watchListLength = watchlistData.length
    let firstCustomWatchlistId = ''

    if (watchListLength === 3) {
      for (let i = 0; i < watchListLength; i++) {
        const watchlist = watchlistData[i]
        const watchlistId = watchlist.watchlistId
        const watchlistType = watchlist.watchlistType
        if (watchlistType === 'CUSTOM') {
          firstCustomWatchlistId = watchlistId
          break
        }
      }
    }

    const watchListData = {
      watchListLength,
      firstCustomWatchlistId
    }
    return watchListData
  }
)

export const getScrollingScripts = createSelector(
  select,
  watchList => watchList.scrollingScripts
)
