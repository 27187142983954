import React from 'react'

// worker
import { odinWorkerManager } from '~/src/Workers/Odin/OdinWorkerManager'

function withStockSubUnSub(
  Child: React.ComponentType<any>
): React.ComponentType<any> {
  class WithStockSubUnSub extends React.Component {
    odinWorkerManager = null as any

    //  subscribe function
    subscribeWatchListStocks = (securitiesArray: any) => {
      this.odinWorkerManager = odinWorkerManager
      if (securitiesArray && securitiesArray?.length > 0) {
        this.odinWorkerManager.subscribeStocks(securitiesArray)
      }
    }

    //  unsubscribe function
    unSubscribeWatchListStocks = (securitiesArray: any) => {
      this.odinWorkerManager = odinWorkerManager
      if (securitiesArray && securitiesArray.length > 0) {
        this.odinWorkerManager.unSubscribeStocks(securitiesArray)
      }
    }

    render() {
      return (
        <Child
          {...this.props}
          odinWorkerManager={this.odinWorkerManager}
          subscribeWatchListStocks={this.subscribeWatchListStocks}
          unSubscribeWatchListStocks={this.unSubscribeWatchListStocks}
        />
      )
    }
  }

  return WithStockSubUnSub
}

export default withStockSubUnSub
