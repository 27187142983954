import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { deleteWatchlistActions, deleteWatchlistServiceName } from '../Actions'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export interface DeleteWatchlistParams {
  watchlistId: string
}

async function deleteWatchlist(reqData: DeleteWatchlistParams) {
  const { watchlistId } = reqData

  const options = {
    url: `/watch-lists/${watchlistId}/delete`,
    method: 'GET',
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return { data, reqData }
}

const deleteWatchlistAction = serviceActionCreator(
  deleteWatchlistActions,
  deleteWatchlist
)

export default deleteWatchlistAction
export { deleteWatchlistServiceName }
