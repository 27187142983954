import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { PersistGate } from 'redux-persist/integration/react'

import AppStore, { PersistedAppStore, appContext } from '~/src/Configurations/AppStore'
import App from './App'

import '~/src/Lib/SecMaster'
import '~/public/serviceWorkerRegistration'
import TickerStore, { tickerContext } from './Configurations/TickerStore'

const container = document.getElementById('app')!
const root = createRoot(container)

root.render(
  <Provider store={TickerStore} context={tickerContext}>
    <Provider store={AppStore} context={appContext}>
      <PersistGate persistor={PersistedAppStore} onBeforeLift={() => undefined}>
        {persisted =>
          <App persisted={persisted} />
        }
      </PersistGate>
    </Provider>
  </Provider>
)
