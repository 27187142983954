import traceActionsCreator from '../traceActionsCreator'

export const createWatchlistServiceName = 'watchList/createWatchlist'
export const createWatchlistActions = traceActionsCreator(
  createWatchlistServiceName
)

export const deleteWatchlistServiceName = 'watchList/deleteWatchlist'
export const deleteWatchlistActions = traceActionsCreator(
  deleteWatchlistServiceName
)

export const fetchWatchlistsServiceName = 'watchList/fetchWatchlists'
export const fetchWatchlistsActions = traceActionsCreator(
  fetchWatchlistsServiceName
)

export const renameWatchlistServiceName = 'watchList/renameWatchlist'
export const renameWatchlistActions = traceActionsCreator(
  renameWatchlistServiceName
)

export const updateScriptsSequenceServiceName =
  'watchList/updateScriptsSequence'
export const updateScriptsSequenceActions = traceActionsCreator(
  updateScriptsSequenceServiceName
)

export const updateWatchlistsSequenceServiceName =
  'watchList/updateWatchlistsSequence'
export const updateWatchlistsSequenceActions = traceActionsCreator(
  updateWatchlistsSequenceServiceName
)

export const bulkUpdateWatchlistServiceName = 'watchList/bulkUpdateWatchlist'
export const bulkUpdateWatchlistActions = traceActionsCreator(
  bulkUpdateWatchlistServiceName
)
