import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import {
  getEmarginDetailsActions,
  getEmarginDetailsServiceName
} from '../Actions'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export type T_GET_E_MARGIN_PAYLOAD = {
  scriptId: string
}

async function getEmarginDetails(payload: T_GET_E_MARGIN_PAYLOAD) {
  const options = {
    url: 'funds/e-margin/get-margin-details',
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }
  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getEmarginDetailsAction = serviceActionCreator(
  getEmarginDetailsActions,
  getEmarginDetails
)

export default getEmarginDetailsAction
export { getEmarginDetailsServiceName }
