import React from 'react'
import { To } from 'react-router-dom'
import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'
import { DsBox, DsLink, DsTab, DsTabs } from '@am92/react-design-system'
import { headerTabs, PARAM_ROUTES, tabRoutes } from './HeaderTabs.constants'

export interface IHeaderTabProps extends IWithRouterProps {}

class HeaderTabs extends React.Component<IHeaderTabProps> {
  state = { selectedTab: 0 }

  componentDidMount(): void {
    this.handleLocationChange()
  }

  componentDidUpdate(prevProps: Readonly<IHeaderTabProps>): void {
    const { location } = this.props
    const { pathname } = location
    if (pathname !== prevProps.location.pathname) {
      this.handleLocationChange()
    }
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  handleTabChange = (newvalue: number) => {
    this.setState({ selectedTab: newvalue })
    this.navigateTo(tabRoutes[newvalue])
  }

  handleLocationChange = () => {
    const { location } = this.props
    const { pathname } = location
    const splitPath = pathname.split('/')
    const tabValue =
      (PARAM_ROUTES as any)[splitPath[1]] ||
      (PARAM_ROUTES as any)[splitPath[1]] === 0
        ? (PARAM_ROUTES as any)[splitPath[1]]
        : -1
    this.setState({ selectedTab: tabValue })
  }

  render() {
    const { selectedTab } = this.state
    return (
      <DsBox
        sx={{
          width: '100%',
          left: '17%',
          position: 'relative',
          top: 'var(--ds-spacing-frostbite)',
          display: 'flex'
        }}
      >
        {headerTabs.map(tab => (
          <DsBox
            onClick={() => this.handleTabChange(tab.id)}
            sx={{
              borderBottom:
                selectedTab === tab.id
                  ? '2px solid var(--ds-colour-actionSecondary)'
                  : 'unset',
              paddingX: 'var(--ds-spacing-mild)',
              paddingBottom: 'var(--ds-spacing-bitterCold)',
              cursor: 'pointer'
            }}
          >
            <DsLink
              key={tab.id}
              underline={'none'}
              sx={{
                color: `var(--ds-colour-${selectedTab === tab.id ? 'actionSecondary' : 'typoPrimary'})`
              }}
              variant='bodyRegularMedium'
            >
              {tab.label}
            </DsLink>
          </DsBox>
        ))}
      </DsBox>
    )
  }
}

export default withRouter(HeaderTabs)
