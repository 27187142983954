import traceActionsCreator from '../traceActionsCreator'

export const getMultiplePortfolioServiceName = 'portfolio/getMultiplePortfolio'
export const getMultiplePortfolioActions = traceActionsCreator(
  getMultiplePortfolioServiceName
)

export const getMutualFundPortfolioServiceName =
  'portfolio/getMutualFundPortfolio'
export const getMutualFundPortfolioActions = traceActionsCreator(
  getMutualFundPortfolioServiceName
)
