import React, { PureComponent } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'

// components
import ErrorDialog from '~/src/Components/ErrorDialog'

// constants
import { getErrorObj } from '~/src/Constants/ERROR_MAP'
import { AXIS_DIRECT_URL } from '~/src/Constants/APP_CONSTANTS'
import {
  handleClosePostLoginError,
  updateOrderPageModalIndicator
} from '~/src/Redux/Orders/reducer'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '../../Orderbook/Orderbook.Page'
import {
  getPostLoginErrorCode,
  getTemporaryOrderFormData
} from '~/src/Redux/Orders/selectors'

type ActionTypes = {
  updateOrderPageModalIndicator: (
    reqData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
  handleClosePostLoginError: (requestData: string) => Promise<any>
}

export interface IPostLoginErrorProps extends IWithRouterProps {
  errorCode: any
  actions: ActionTypes
  tempOrderFormData: any
}

class PostLoginError extends PureComponent<IPostLoginErrorProps> {
  handleDormant = () => {
    window.location.href = ''
  }

  // TODO: update the function defination
  handleSkip = async () => {
    const { actions, tempOrderFormData } = this.props
    // actions.updateOrderPageModalIndicator(tempOrderFormData)
    actions.handleClosePostLoginError('closePostLoginError')
  }

  // TODO: update the function defination
  handleRequestCall = async () => { }

  handleRedirection = () => {
    window.open(AXIS_DIRECT_URL, '_blank')
  }

  render() {
    const { errorCode } = this.props

    return (
      <>
        {errorCode === 1032 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleRedirection}
            secondaryButtonClick={this.handleSkip}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1035 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleRedirection}
            secondaryButtonClick={this.handleSkip}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1036 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            // primaryButtonClick={this.handleSkip}
            // secondaryButtonClick={this.handleSkip}
            // tertiaryButtonClick={this.handleRequestCall}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1037 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleRedirection}
            secondaryButtonClick={this.handleSkip}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1038 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleRedirection}
            secondaryButtonClick={this.handleSkip}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1039 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleRedirection}
            secondaryButtonClick={this.handleSkip}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1040 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleSkip}
            // secondaryButtonClick={this.handleSkip}
            // tertiaryButtonClick={this.handleRequestCall}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1041 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleRedirection}
            secondaryButtonClick={this.handleSkip}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1042 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleRedirection}
            secondaryButtonClick={this.handleSkip}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1043 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleSkip}
            // secondaryButtonClick={this.handleSkip}
            // tertiaryButtonClick={this.handleRequestCall}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1044 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleSkip}
            // secondaryButtonClick={this.handleSkip}
            // tertiaryButtonClick={this.handleRequestCall}
            onClose={this.handleSkip}
          />
        )}
        {errorCode === 1045 && (
          <ErrorDialog
            {...(getErrorObj(errorCode) as any)}
            primaryButtonClick={this.handleRedirection}
            secondaryButtonClick={this.handleSkip}
            onClose={this.handleSkip}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const errorCode = getPostLoginErrorCode(state)
  const tempOrderFormData = getTemporaryOrderFormData(state)
  return {
    errorCode,
    tempOrderFormData
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    handleClosePostLoginError: (requestData: string) =>
      dispatch(handleClosePostLoginError(requestData))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(PostLoginError)
