import { createAction } from '@reduxjs/toolkit'

export const getAvailableEquityLimitsServiceName =
  'inquiry/getAvailableEquityLimits'
export const getAvailableEquityLimitsActions = {
  loading: createAction<any, any>(
    `${getAvailableEquityLimitsServiceName}/LOADING`
  ),
  success: createAction<any, any>(
    `${getAvailableEquityLimitsServiceName}/SUCCESS`
  ),
  error: createAction<any, any>(`${getAvailableEquityLimitsServiceName}/ERROR`)
}

export const getAvailableCommodityLimitsServiceName =
  'inquiry/getAvailableCommodityLimits'
export const getAvailableCommodityLimitsActions = {
  loading: createAction<any, any>(
    `${getAvailableCommodityLimitsServiceName}/LOADING`
  ),
  success: createAction<any, any>(
    `${getAvailableCommodityLimitsServiceName}/SUCCESS`
  ),
  error: createAction<any, any>(
    `${getAvailableCommodityLimitsServiceName}/ERROR`
  )
}

export const getFundPayoutStatusServiceName = 'inquiry/getFundPayoutStatus'
export const getFundPayoutStatusActions = {
  loading: createAction<any, any>(`${getFundPayoutStatusServiceName}/LOADING`),
  success: createAction<any, any>(`${getFundPayoutStatusServiceName}/SUCCESS`),
  error: createAction<any, any>(`${getFundPayoutStatusServiceName}/ERROR`)
}

export const deletePayoutRequestServiceName = 'inquiry/deletePayoutRequest'
export const deletePayoutRequestActions: any = {
  loading: createAction<any, any>(`${deletePayoutRequestServiceName}/LOADING`),
  success: createAction<any, any>(`${deletePayoutRequestServiceName}/SUCCESS`),
  error: createAction<any, any>(`${deletePayoutRequestServiceName}/ERROR`)
}

export const getWithdrawEQLimitsServiceName = 'inquiry/getWithdrawEQLimits'
export const getWithdrawEQLimitsActions: any = {
  loading: createAction<any, any>(`${getWithdrawEQLimitsServiceName}/LOADING`),
  success: createAction<any, any>(`${getWithdrawEQLimitsServiceName}/SUCCESS`),
  error: createAction<any, any>(`${getWithdrawEQLimitsServiceName}/ERROR`)
}

export const getWithdrawCOMMLimitsServiceName = 'inquiry/getWithdrawCOMMLimits'
export const getWithdrawCOMMLimitsActions: any = {
  loading: createAction<any, any>(
    `${getWithdrawCOMMLimitsServiceName}/LOADING`
  ),
  success: createAction<any, any>(
    `${getWithdrawCOMMLimitsServiceName}/SUCCESS`
  ),
  error: createAction<any, any>(`${getWithdrawCOMMLimitsServiceName}/ERROR`)
}
