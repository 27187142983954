import traceActionsCreator from '../traceActionsCreator'

export const getResearchConfigServiceName = 'research/getConfig'
export const getResearchConfigActions = traceActionsCreator(
  getResearchConfigServiceName
)

export const getResearchDataServiceName = 'research/getResearchData'
export const getResearchDataActions = traceActionsCreator(
  getResearchDataServiceName
)

export const getDetailsResearchDataServiceName =
  'research/getDetailsResearchData'
export const getDetailsResearchDataActions = traceActionsCreator(
  getDetailsResearchDataServiceName
)

export const getRecommendationByScripIdServiceName =
  'research/getRecommendationByScripId'
export const getRecommendationByScripIdActions = traceActionsCreator(
  getRecommendationByScripIdServiceName
)
