import React from 'react'
import { connect } from 'react-redux'
import { DsIconButton, DsRemixIcon } from '@am92/react-design-system'

// selectors
import { getStockWatchlistMaster } from '../Redux/StockWatchlistMaster/Selectors'

// context
import { appContext } from '../Configurations/AppStore'

interface IBookmarkButtonProps {
  scripId: string
  stockWatchlistMasterData?: any
  handleOnClickBookmark: (scripId: string) => void
}

interface IBookmarkButtonState { }

class BookmarkButton extends React.Component<
  IBookmarkButtonProps,
  IBookmarkButtonState
> {
  render() {
    const {
      scripId,
      stockWatchlistMasterData,
      handleOnClickBookmark
    } = this.props
    const scriptInWatchlist = scripId && stockWatchlistMasterData[scripId] || []
    const totalElements = scriptInWatchlist.length
    return (
      <DsIconButton
        sx={{
          width: 'var(--ds-spacing-tropical)',
          height: 'var(--ds-spacing-tropical)',
          p: 4
        }}
        onClick={() => handleOnClickBookmark(scripId)}
        {...(totalElements && {
          color: 'iconActionSecondary'
        })}
      >
        <DsRemixIcon
          className={
            totalElements ? 'ri-bookmark-3-fill' : 'ri-bookmark-3-line'
          }
          color={totalElements ? 'actionSecondary' : 'disabled'}
        />
      </DsIconButton>
    )
  }
}

const mapStateToProps = (state: any) => {
  const stockWatchlistMasterData = getStockWatchlistMaster(state)

  return {
    stockWatchlistMasterData
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  context: appContext
})(BookmarkButton)
