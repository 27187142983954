import { createAction } from '@reduxjs/toolkit'

export const updateStockWatchlistMasterAction = createAction<any, string>(
  'stockWatchlistMaster/updateStockWatchlistMasterAction'
)

export const setNewStockWatchListArrayAction = createAction<any, string>(
  'stockWatchlistMaster/setNewStockWatchListArrayAction'
)

export const removeWatchlistIdFromStocksAction = createAction<any, string>(
  'stockWatchlistMaster/removeWatchlistIdFromStocksAction'
)
