import { createSelector } from '@reduxjs/toolkit'
export const SLICE_NAME = 'alerts'

const select = (state: any) => state[SLICE_NAME]

export const getAlertsData = createSelector(select, alerts => alerts.data)
export const getAlertsFormDetails = createSelector(
  select,
  alerts => alerts.toggleAlertsForm
)
