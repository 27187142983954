import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import { getNotificationsActions } from './Actions'
import { _groupBy, _isEqual, _orderBy } from '~/src/Utils/lodash'

const INITIAL_STATE = {
  notifications: {},
  // {
  //   subAccountId: 'AXS111',
  //   category: 'Funds',
  //   title: 'Funds added',
  //   description: 'Funds added successfully',
  //   action: '',
  //   createdAt: '2024-05-20T06:17:55Z',
  //   expiryDate: '2024-05-20T07:57:55Z',
  // "metaData": {
  //                  scriptId:"1223",
  //                   coCode:"234"
  //                  exchange:"NSE"
  //                  symbol:"IDEA"
  //            }
  // },
  // {
  //   subAccountId: 'AXS111',
  //   category: 'Orders',
  //   title: 'Order placed',
  //   description: 'Order placed successfully',
  //   action: '',
  //   createdAt: '2024-05-21T11:41:16Z',
  //   expiryDate: '2024-05-21T18:29:59Z'
  // }
  // 'All':{}
  newNotificationIndicator: false
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getNotificationsActions.success, (state, { payload }) => {
      if (payload == null) {
        state.notifications = {}
      } else {
        state.notifications = {
          All: _orderBy(payload, ['createdAt'], 'asc'),
          ..._groupBy(_orderBy(payload, ['createdAt'], 'asc'), 'category')
        }
      }
    })
  }
}

const slice = createSlice(sliceOptions)
export default slice.reducer
