import {
  DsBox,
  DsDivider,
  DsImage,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import React from 'react'
import NriHeader from './NriHeader'
import Nre, { Account } from './Nre'
import Nro from './Nro'
import withColorScheme from '~/src/Lib/withColorScheme'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { getAccounts, getRequiredCustomerDetailForNRI, getSelectedAccount } from '~/src/Redux/SSO/Selectors'

import { getNRICustomer } from '~/src/Utils/global'
import { NRI_MOBILE_IMAGE, NRI_WEB_IMAGE } from '~/src/Constants/ASSET_MAP'
import { updateCustomerDetailsAction } from '~/src/Redux/SSO/Actions'

interface NriContentProps {
  name: string
  subAccountId: string
  accounts: Account[]
  selectedAccount: Account
  actions: ActionTypes
  isDesktop: boolean
  onClick: () => any
}

type ActionTypes = {
  updateCustomerDetails: (primaryAccount: any) => any
}

interface NriContentState { }

type SeparatedAccounts = {
  NRE: Account[]
  NRO: Account[]
}

class NriContent extends React.Component<NriContentProps, NriContentState> {
  handleOnSelectAccount = async (settlementType: string) => {
    const { actions, accounts, subAccountId } = this.props
    const account =
      accounts.find(
        (account: { settlementType: string }) =>
          account.settlementType === settlementType
      ) || {}
    const isNRI = getNRICustomer(accounts)
    await actions.updateCustomerDetails({ ...account, isNRI })
    setTimeout(() => {
      this.handleReload()
    }, 500)
  }
  handleReload = () => {
    window.location.reload()
  }

  render() {
    const { name, accounts, selectedAccount, isDesktop, onClick } = this.props
    const separateNRIAccounts = () =>
      accounts?.reduce<SeparatedAccounts>(
        (acc, account) => {
          if (account?.settlementType?.startsWith('NRE')) {
            acc.NRE.push(account)
          } else if (account?.settlementType?.startsWith('NRO')) {
            acc.NRO.push(account)
          }
          return acc
        },
        { NRE: [], NRO: [] }
      )

    return (
      <DsBox
        sx={
          isDesktop
            ? {
              width: '100%',
              display: 'grid !important',
              gridTemplateColumns: '40% 50%',
              justifyContent: 'space-between',
              alignItems: 'center'
            }
            : {
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              justifyContent: 'center',
              alignItems: 'center'
            }
        }
      >
        {!isDesktop && (
          <DsBox
            sx={{
              width: '100%',
              display: 'flex',
              gap: 'var(--ds-spacing-bitterCold)',
              alignItems: 'center'
            }}
          >
            <DsRemixIcon
              onClick={onClick}
              className='ri-close-fill'
              fontSize='mild'
              sx={{
                cursor: 'pointer'
              }}
            />
            <DsTypography variant='headingBoldSmall'>
              Select Profile
            </DsTypography>
          </DsBox>
        )}
        <DsBox
          sx={{
            width: '-webkit-fill-available',
            backgroundColor: { xs: '#FFF2F8', md: 'unset' },
            position: 'relative'
          }}
        >
          <DsImage
            width={'-webkit-fill-available'}
            srcSet={!isDesktop ? NRI_MOBILE_IMAGE : NRI_WEB_IMAGE}
            style={{
              width: '-webkit-fill-available',
              display: 'flex'
            }}
          />
        </DsBox>

        <DsStack
          sx={{
            display: 'flex',
            width: '100%',
            gap: 'var(--ds-spacing-bitterCold)'
          }}
        >
          <NriHeader name={name} />
          <Nre
            selectedAccount={selectedAccount}
            handleOnSelectAccount={this.handleOnSelectAccount}
            accounts={separateNRIAccounts()?.NRE}
          />
          <DsDivider
            orientation='horizontal'
            sx={{
              height: '1px'
            }}
          />
          <Nro
            selectedAccount={selectedAccount}
            handleOnSelectAccount={this.handleOnSelectAccount}
            accounts={separateNRIAccounts()?.NRO}
          />
        </DsStack>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any) => {
  const customerDetail = getRequiredCustomerDetailForNRI(state)
  const { customerName: name, subAccountId } = customerDetail

  const accounts = getAccounts(state)
  const selectedAccount = getSelectedAccount(subAccountId)(state)

  return {
    name,
    subAccountId,
    accounts,
    selectedAccount
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateCustomerDetails: (primaryAccount: any) =>
      dispatch(updateCustomerDetailsAction(primaryAccount))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(withColorScheme(NriContent))
