import traceActionsCreator from '../traceActionsCreator'

export const getPledgeDataServiceName = 'pledge/getPledgeData'
export const getPledgeDataActions = traceActionsCreator(
  getPledgeDataServiceName
)
export const setPledgeMarginsServiceName = 'pledge/setPledgeMargins'
export const setPledgeMarginsActions = traceActionsCreator(
  setPledgeMarginsServiceName
)
