import { createSelector } from '@reduxjs/toolkit'
import { Indicators } from './Reducer'
export const SLICE_NAME = 'indicators'
const select = (state: any): Indicators => state[SLICE_NAME]

export const isOdinConnectionIstablished = createSelector(
  select,
  indicators => indicators.isOdinConnectionIstablishedIndicator
)

export const tabIndicator = createSelector(
  select,
  indicators => indicators.tabIndicator
)

export const isWatchlistFetched = createSelector(
  select,
  indicators => indicators.watchlistFetched
)

export const openModifySip = createSelector(
  select,
  indicators => indicators.openModifySipPopup
)

export const isSecurityMasterLoadedIndicator = createSelector(
  select,
  indicators => indicators.isSecurityMasterLoadedIndicator
)

export const isOdinWorkerCreated = createSelector(
  select,
  indicators => indicators.isOdinWorkerCreated
)

export const getPortfolioTabIndicator = createSelector(
  select,
  indicators => indicators.portfolioTabIndicator
)

export const getPortfolioIndicator = createSelector(
  select,
  indicators => indicators?.portfolioIndicator
)

export const isModifingSelector = createSelector(
  select,
  indicators => indicators?.isModifing
)

export const getSearchIndicator = createSelector(
  select,
  indicators => indicators.searchIndicator
)
export const getOrderPageSipModalIndicator = createSelector(
  select,
  orders => orders.orderPageSipModalIndicator
)

export const getShowGtdDetailsModalIndicator = createSelector(
  select,
  indicators => indicators.showGtdOrderDetailsModalIndicator
)

export const isEditingSipSelector = createSelector(
  select,
  indicators => indicators.isEditingSip
)

export const getOverallTodayPnlIndicator = createSelector(
  select,
  indicators => indicators.showOverAllTodayPnl
)

export const isReactivatingSelector = createSelector(
  select,
  indicators => indicators.isReactivating
)
