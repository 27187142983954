import { DsSkeleton, DsStack } from '@am92/react-design-system'
import React, { PureComponent, ReactNode } from 'react'

class PortfolioSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <>
        <DsStack sx={{ width: '100%' }}>
          <DsStack>
            <DsSkeleton variant='text' sx={{ height: '40px' }} />
            <DsStack sx={{ flexDirection: 'row', gap: '12px' }}>
              <DsSkeleton
                variant='text'
                sx={{ height: '150px', width: '150px' }}
              />
              <DsSkeleton
                variant='text'
                sx={{ height: '150px', width: '150px' }}
              />
              <DsSkeleton
                variant='text'
                sx={{ height: '150px', width: '150px' }}
              />
              <DsSkeleton
                variant='text'
                sx={{ height: '150px', width: '150px' }}
              />
            </DsStack>
          </DsStack>
          <DsStack
            spacing={'var(--ds-spacing-frostbite)'}
            sx={{ width: '100%' }}
          >
            <DsStack sx={{ flexDirection: 'row', gap: '12px' }}>
              <DsSkeleton
                variant='text'
                sx={{ height: '20px', width: '120px' }}
              />
              <DsSkeleton
                variant='text'
                sx={{ height: '20px', width: '120px' }}
              />
              <DsSkeleton
                variant='text'
                sx={{ height: '20px', width: '120px' }}
              />
            </DsStack>
            <DsSkeleton variant='text' sx={{ height: '100px' }} />
            <DsSkeleton variant='rounded' sx={{ height: '280px' }} />
          </DsStack>
        </DsStack>
      </>
    )
  }
}

export default PortfolioSkeleton
