import _ from 'lodash'
import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import { getExchangeMessagesActions, getMarketStatusActions } from './Actions'

const INITIAL_STATE = {
  exchangeMessages: [],
  marketStatus: {}
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getExchangeMessagesActions.success,
      (state, { payload }) => {
        state.exchangeMessages = payload
      }
    )
    builder.addCase(getMarketStatusActions.success, (state, { payload }) => {
      const filteredData = payload.filter(
        (item: any) =>
          item.exchangeSegment !== 'NSE_FO' && item.exchangeSegment !== 'BSE_FO'
      )
      const groupedResult = _.groupBy(filteredData, (marketStausData: any) => {
        const { exchangeSegment = '' } = marketStausData
        const exchange = exchangeSegment.split('_')[1]
        return exchange
      })
      state.marketStatus = groupedResult
    })
  }
}

const slice = createSlice(sliceOptions)
export default slice.reducer
