import React from 'react'
import {
  DsBox,
  DsList,
  DsListItemButton,
  DsTypography,
  DsRemixIcon,
  DsDivider,
  DsImage,
  DsInputAdornment,
  DsStack,
  DsTextField,
  DsIconButton,
  DsButton,
  withBreakpoints,
  DsClickAwayListener,
  enqueueNotistack
} from '@am92/react-design-system'
import { debounce } from 'lodash'

// hoc
import withErrorConnect from '~/src/Lib/withErrorConnect'

// constants
import { SEARCH_ZERO_SCREEN } from '~/src/Constants/ASSET_MAP'

// selectors
import { getRecentSearch, getSearch } from '~/src/Redux/Search/Selectors'

// works
import searchManager from '~/src/Workers/SearchMasterWorker/SearchManager'
import SearchTabs from './SearchTabs'
import { SEARCHTABS_LABEL } from './SearchTabs.constants'
import { shouldAddScriptToParticularWatchlist } from '~/src/Redux/WatchList/Selectors'
import { setAddScriptToParticularWatchlist } from '~/src/Redux/WatchList/Reducer'
import {
  getStockWatchlistMaster,
  getTempStockWatchlistMaster
} from '~/src/Redux/StockWatchlistMaster/Selectors'
import { T_StockWatchlistMasterData } from '~/src/Redux/StockWatchlistMaster/Reducer'
import { WATCHLIST_LABEL } from '~/src/Constants/LABEL'
import { To } from 'react-router-dom'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import {
  setSelectedExchange,
  updateOrderPageModalIndicator,
  updateSipSelectedScript
} from '~/src/Redux/Orders/reducer'
import {
  getSearchIndicator,
  isModifingSelector
} from '~/src/Redux/Indicators/Selectors'
import { setSearchIndicator } from '~/src/Redux/Indicators/Reducer'
import AddToWatchlist from '../../ManageWatchlist/AddToWatchlist'
import { T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD } from '~/src/Pages/Orderbook/Orderbook.Page'
import { isNonTradableStock } from '~/src/Lib/SecMaster/helpers'
import { triggerCleverTapEvent } from '~/src/Utils/global'
import createRecentSearchConfigAction from '~/src/Redux/Search/Services/createRecentSearch.Service'
import deleteRecentSearchConfigAction from '~/src/Redux/Search/Services/deleteAllSearch.Service'
import getRecentSearchConfigAction from '~/src/Redux/Search/Services/getRecentSearch.Service'
import { DEEP_LINK_KEYS } from '~/src/Constants/APP_CONSTANTS'
import { getDeepLinkAttributes } from '~/src/Redux/AppState/selectors'
import { TDeepLink, resetDeepLinkData } from '~/src/Redux/AppState/reducer'
import { getSelectedScripts } from '~/src/Redux/Orders/selectors'
import { toggleAlertsFormActions } from '~/src/Redux/Alerts/Actions'
import { type } from 'os'

type ActionTypes = {
  setSearchIndicator: (val: boolean) => any
  updateOrderPageModalIndicator: (
    reqData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
  ) => Promise<any>
  setAddScriptToParticularWatchlist: (reqData: boolean) => Promise<any>
  setSelectedExchange: (requestData: any) => Promise<any>
  getRecentSearch: () => Promise<any>
  createRecentSearch: (requestData: any) => Promise<any>
  deleteRecentSearch: () => Promise<any>
  resetDeepLinkData: () => Promise<any>
  toggleAlertsForm: (data: any) => any
  updateSelectedScripts: (data: any) => Promise<any>
}

interface SearchStocksProps extends IWithRouterProps {
  actions: ActionTypes
  searchResult: any
  recentSearchResult: any
  setOpen: any
  open: boolean
  breakpoints: any
  getStockWatchlistMasterData: T_StockWatchlistMasterData
  handleError: (res: any) => void
  addScriptToParticularWatchlist: boolean
  getTempStockWatchlistMasterData: T_StockWatchlistMasterData
  searchIndicator: boolean
  deepLinkAttributes: TDeepLink
  type?: string
  selectedSipScripts: any
  isModifingSip: boolean
  handlePopUpClose?: () => void
  isShortcutSearch: boolean
  shortcutCallback: any
}
interface ISearchStocksState {
  searchString: string
  isSearchFieldOutOfFocus: boolean
  selectedTabs: string
  openAddToWatchlistModal: boolean
  bookMarkedScriptId: number
  finalSearchResult: []
  stockAvailableInWatchListArray: string[]
  hover: string | number
  bookMarkIconClicked: boolean
  tempAddScriptToParticularWatchlist: boolean
  closeFromModal: boolean
}

class SearchStocks extends React.PureComponent<
  SearchStocksProps,
  ISearchStocksState
> {
  odinWorkerManager = null as any

  ref: React.RefObject<any>
  constructor(props: SearchStocksProps) {
    super(props)
    this.state = {
      hover: -1,
      searchString: '',
      isSearchFieldOutOfFocus: false,
      selectedTabs: 'ALL',
      openAddToWatchlistModal: false,
      bookMarkedScriptId: 0,
      finalSearchResult: [],
      stockAvailableInWatchListArray: [],
      bookMarkIconClicked: false,
      tempAddScriptToParticularWatchlist: false,
      closeFromModal: false
    }
    this.ref = React.createRef()
  }

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  async componentDidMount() {
    const { actions } = this.props
    await actions.getRecentSearch()
    this.handleDeepLink()
  }

  handleDeepLink = async () => {
    const { deepLinkAttributes, actions } = this.props
    if (deepLinkAttributes) {
      const { path, parameterList } = deepLinkAttributes
      //NOTE - we can add more casings here for other third parties
      if (path === DEEP_LINK_KEYS.SEARCH) {
        const parameter = parameterList[0]
        this.handleOnClickSearchBox()
        this.setState(
          { searchString: parameter.trimStart() },
          this.handleInitiateSearch
        )
        actions.resetDeepLinkData()
      }
    }
  }

  componentDidUpdate(
    prevProps: SearchStocksProps,
    prevState: ISearchStocksState
  ) {
    const { searchResult, open, searchIndicator, type = '' } = this.props
    const { selectedTabs } = this.state

    if (
      prevState.selectedTabs !== selectedTabs ||
      prevProps.searchResult !== searchResult
    ) {
      if (selectedTabs === 'ALL') {
        this.setState({
          finalSearchResult: searchResult
        })
        return
      }
      this.setState({
        finalSearchResult: searchResult.filter((item: { segment: string }) => {
          return item.segment === selectedTabs
        })
      })
    }
    if (prevProps.open !== open && open) {
      document.getElementById('textField')?.focus()
    }
    if (prevProps.searchIndicator !== searchIndicator && searchIndicator) {
      this.handleOnClickSearchBox()
    }
  }

  handleOnChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      { searchString: value.trimStart() },
      this.handleInitiateSearch
    )
  }

  handleInitiateSearch = debounce(() => {
    const { searchString } = this.state
    searchManager.search(searchString)
  }, 500)

  handleOnBlur = async () => {
    const { searchResult, setOpen, actions, recentSearchResult } = this.props
    const { searchString } = this.state
    actions.setSearchIndicator(false)
    if (searchResult.length === 0 && recentSearchResult.length === 0) {
      this.setState({ isSearchFieldOutOfFocus: true })
      // setOpen(false)
    }
    !searchString.length && actions.setAddScriptToParticularWatchlist(false)
  }

  handleOnFocus = () => {
    this.setState({ isSearchFieldOutOfFocus: false })
  }

  handleOnSelectStock = async (data: {
    scripId: string
    exchangeSymbol: string
    coname: string
  }) => {
    const {
      actions,
      type = '',
      selectedSipScripts,
      setOpen,
      isModifingSip,
      handlePopUpClose,
      isShortcutSearch,
      shortcutCallback
    } = this.props
    const { scripId: scriptId, exchangeSymbol, coname } = data
    const { exchange } = (window as any).secMaster.getByScriptId(scriptId) || {}
    const customAttributes = {
      'Event Status': 'Pass',
      'Screen Name': 'Search',
      Action: 'Script Clicked',
      Keyword: scriptId
    }
    triggerCleverTapEvent('Search_Done', customAttributes)
    actions.setSelectedExchange({
      selectedExchange: exchange
    })

    if (type === 'sip') {
      let formattedData = {}

      if (selectedSipScripts.length >= 10) {
        enqueueNotistack({
          message: 'You can add up to 10 Scrips',
          autoHideDuration: 2000
        })
        setOpen(false)
        this.setState({ searchString: '' })
        return
      }

      if (isModifingSip) {
        formattedData = {
          scriptId,
          exchangeSymbol,
          coname,
          exchange,
          action: 'Add'
        }
      } else {
        formattedData = {
          scriptId,
          exchangeSymbol,
          coname,
          exchange
        }
      }
      actions.updateSelectedScripts([...selectedSipScripts, formattedData])
      setOpen(false)
      this.setState({ searchString: '' })
      return
    }

    // event.stopPropagation()
    await actions.createRecentSearch({
      scriptId: scriptId,
      exchange,
      symbol: exchangeSymbol,
      coName: coname
    })
    await actions.getRecentSearch()
    if (type !== 'Alerts') {
      const customAttributes = {
        'Event Status': 'Pass',
        'Screen Name': 'Search',
        Action: 'Script Clicked',
        Keyword: scriptId
      }
      triggerCleverTapEvent('Search_Done', customAttributes)
      actions.setSelectedExchange({
        selectedExchange: exchange
      })
      // event.stopPropagation()
      await actions.createRecentSearch({
        scriptId: scriptId,
        exchange,
        symbol: exchangeSymbol,
        coName: coname
      })
      await actions.getRecentSearch()

      this.navigateTo(`/quotes/${scriptId}`)
    } else {
      await actions.toggleAlertsForm({
        toggle: true,
        scriptId: scriptId,
        isModify: false,
        data: null
      })
      handlePopUpClose && handlePopUpClose()
    }
    if (isShortcutSearch) shortcutCallback()
  }

  filterData = () => {
    const { selectedTabs } = this.state
    const { searchResult } = this.props
    if (selectedTabs === 'All') {
      return searchResult
    }
    const filteredData = searchResult.filter((item: any) => {
      if (selectedTabs === 'Stock') {
        return item.exchangeSeries == 'EQ' && item
      }
      if (selectedTabs === 'F&O') {
        return item.exchangeSeries !== 'EQ' && item
      }
    })
    return filteredData
  }

  handleSearchResultClickAway = () => {
    const { openAddToWatchlistModal, closeFromModal } = this.state
    const { setOpen, actions, open } = this.props
    if (!closeFromModal && !openAddToWatchlistModal) {
      setOpen(false)
      this.setState({ searchString: '' })
    }
    this.setState({ closeFromModal: false })
    if (!open) actions.setAddScriptToParticularWatchlist(false)
  }

  handleHover = (scriptId: string | number) => {
    this.setState({ hover: scriptId })
  }

  handleLeaveHover = () => {
    this.setState({ hover: -1 })
  }

  handleBuySell = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string,
    selectedScript: string
  ) => {
    const { actions } = this.props
    const { exchange } =
      (window as any).secMaster.getByScriptId(selectedScript) || {}
    e.stopPropagation()
    actions.updateOrderPageModalIndicator({
      orderPageModalIndicator: true,
      orderScriptId: selectedScript,
      orderBuyOrSell: value,
      selectedExchange: exchange,
      isModifyOrderRequest: false,
      isRepeatOrderRequest: false
    })
  }

  handleOnClickSearchBox = () => {
    const { setOpen, open, type = '' } = this.props
    if (type === 'sip') {
      this.setState({ selectedTabs: 'EQ' }, this.filterData)
    }
    if (open) {
      return
    }

    return setOpen(true)
  }

  handleClickOnBookMark = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any,
    stockAvailableInWatchListArray: string[],
    tempAddScriptToParticularWatchlist: boolean
  ) => {
    const customAttributes = {
      'Event Status': 'Pass',
      'Screen Name': 'Search',
      Action: 'Add to Watchlist'
    }
    triggerCleverTapEvent('Search_Done', customAttributes)
    event.stopPropagation()
    const { scriptId } = item
    this.setState({
      bookMarkIconClicked: true,
      stockAvailableInWatchListArray,
      bookMarkedScriptId: scriptId,
      tempAddScriptToParticularWatchlist
    })
  }

  handleOpenModal = () => {
    this.setState({ openAddToWatchlistModal: true })
  }

  handleCloseModal = () => {
    this.setState({
      bookMarkIconClicked: false,
      bookMarkedScriptId: 0,
      stockAvailableInWatchListArray: [],
      tempAddScriptToParticularWatchlist: false,
      openAddToWatchlistModal: false,
      closeFromModal: true
    })
  }

  handleResetClickState = () => {
    this.setState({
      bookMarkIconClicked: false
    })
  }

  handleMapping = (isRecent: boolean) => {
    const { searchString, selectedTabs, finalSearchResult, hover } = this.state
    const { recentSearchResult, shortcutCallback, isShortcutSearch } =
      this.props
    const searchTempArray = isRecent ? recentSearchResult : finalSearchResult
    const {
      searchResult,
      getStockWatchlistMasterData,
      getTempStockWatchlistMasterData,
      addScriptToParticularWatchlist,
      type
    } = this.props
    return (
      <DsBox sx={{ width: '100%', zIndex: 1000, height: 'max-content' }}>
        {!isRecent && !type && (
          <SearchTabs
            renderTabs={SEARCHTABS_LABEL}
            returnValue='id'
            enableArrow={true}
            containedTabs={false}
            onChange={(e: any) => {
              this.setState({ selectedTabs: e })
              this.filterData()
            }}
            searchResults={searchResult}
            selectedTabs={selectedTabs}
          />
        )}
        {searchTempArray.map((item: any) => {
          const {
            scriptId,
            instrumentType,
            exchangeSymbol,
            exchange,
            coname,
            symbol,
            coName,
            aslAllowed
          } = item

          const notTradable = isNonTradableStock(aslAllowed)

          let stockAvailableInWatchListArray =
            getStockWatchlistMasterData[scriptId] || []
          const tempAddScriptToParticularWatchlist =
            addScriptToParticularWatchlist

          if (addScriptToParticularWatchlist) {
            stockAvailableInWatchListArray =
              getTempStockWatchlistMasterData[scriptId] || []
          }

          const watchListArrayLength = stockAvailableInWatchListArray.length

          return (
            <>
              <DsListItemButton
                selected={item.label === searchString}
                key={scriptId}
                sx={{
                  p: 'var(--ds-spacing-bitterCold)'
                }}
                onClick={() => {
                  this.handleOnSelectStock({
                    scripId: scriptId.toString(),
                    exchangeSymbol,
                    coname
                  })
                }}
                onMouseEnter={() => {
                  this.handleHover(scriptId)
                }}
                onMouseLeave={() => {
                  this.handleLeaveHover()
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center'
                  }}
                >
                  <DsBox
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.25rem'
                    }}
                  >
                    {isRecent && (
                      <DsRemixIcon
                        className={'ri-time-line'}
                        sx={{
                          m: 'var(--ds-spacing-glacial)',
                          color: '#B4B4B4'
                        }}
                      />
                    )}
                    <DsStack sx={{ display: 'flex', gap: '0.5rem' }}>
                      <DsBox
                        sx={{
                          display: 'flex',
                          gap: '0.5rem',
                          alignItems: 'center'
                        }}
                      >
                        <DsTypography variant='bodyBoldSmall'>
                          {instrumentType === 'EQUITY'
                            ? isRecent
                              ? coname || coName
                              : exchangeSymbol || symbol
                            : isRecent
                              ? exchangeSymbol || symbol
                              : coname || coName}
                        </DsTypography>

                        <DsTypography
                          variant='supportRegularInfo'
                          sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                        >
                          {exchange}
                        </DsTypography>
                      </DsBox>

                      <DsTypography
                        variant='bodyRegularSmall'
                        sx={{ color: 'var(--ds-colour-typoSecondary)' }}
                      >
                        {instrumentType === 'EQUITY'
                          ? isRecent
                            ? exchangeSymbol || symbol
                            : coname || coName
                          : isRecent
                            ? coname || coName
                            : exchangeSymbol || symbol}
                      </DsTypography>
                    </DsStack>
                  </DsBox>
                  {type !== 'Alerts' && (
                    <DsIconButton
                      sx={{
                        width: 'var(--ds-spacing-tropical)',
                        height: 'var(--ds-spacing-tropical)',
                        p: 4
                      }}
                      onClick={e =>
                        this.handleClickOnBookMark(
                          e,
                          item,
                          stockAvailableInWatchListArray,
                          tempAddScriptToParticularWatchlist
                        )
                      }
                      {...(watchListArrayLength && {
                        color: 'iconActionSecondary'
                      })}
                    >
                      <DsRemixIcon
                        className={
                          watchListArrayLength
                            ? 'ri-bookmark-3-fill'
                            : 'ri-bookmark-3-line'
                        }
                        color={
                          watchListArrayLength ? 'actionSecondary' : 'disabled'
                        }
                      />
                    </DsIconButton>
                  )}
                </DsBox>
                {!type &&
                  item?.scriptId === hover &&
                  !notTradable &&
                  !isRecent && (
                    <DsBox
                      sx={{
                        position: 'absolute',
                        right: 60,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 'var(--ds-spacing-glacial)'
                      }}
                    >
                      <DsButton
                        // sx={{
                        //   backgroundColor: 'var(--ds-colour-iconPositive)',
                        //   '&:hover': {
                        //     backgroundColor:
                        //       'var(--ds-colour-iconPositive) !important'
                        //   }
                        // }}
                        color='success'
                        onClick={e => {
                          this.handleBuySell(e, 'BUY', item.scriptId)
                          if (isShortcutSearch) shortcutCallback()
                        }}
                      >
                        {WATCHLIST_LABEL.WATCHLIST.BUY}
                      </DsButton>
                      <DsButton
                        // sx={{
                        //   backgroundColor: 'var(--ds-colour-iconNegative)',
                        //   '&:hover': {
                        //     backgroundColor:
                        //       'var(--ds-colour-iconNegative) !important'
                        //   }
                        // }}
                        color='error'
                        onClick={e => {
                          this.handleBuySell(e, 'SELL', item.scriptId)
                          if (isShortcutSearch) shortcutCallback()
                        }}
                      >
                        {WATCHLIST_LABEL.WATCHLIST.SELL}
                      </DsButton>
                    </DsBox>
                  )}
              </DsListItemButton>
              <DsStack sx={{ alignItems: 'center' }}>
                <DsDivider sx={{ width: '94%' }} />
              </DsStack>
            </>
          )
        })}
      </DsBox>
    )
  }

  handleZeroScreen = (lineOne: string, lineTwo: string) => {
    return (
      <DsStack
        sx={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center ',
          width: '100%',
          height: '97%',
          gap: '1rem',
          position: 'absolute',
          zIndex: '1',
          backgroundColor: 'var(--ds-colour-surfacePrimary)'
        }}
      >
        <DsBox>
          <DsImage srcSet={SEARCH_ZERO_SCREEN} />
        </DsBox>
        <DsStack>
          <DsTypography variant='bodyRegularLarge'>{lineOne}</DsTypography>
          <DsTypography variant='bodyRegularLarge'>{lineTwo}</DsTypography>
        </DsStack>
      </DsStack>
    )
  }

  handleRenderCases = (searchString: string, finalSearchResult: []) => {
    const { selectedTabs } = this.state
    const { searchResult, recentSearchResult, actions, type = '' } = this.props

    return (
      <>
        <DsBox sx={{ width: '100%', zIndex: 1000, height: 'max-content' }}>
          {type !== 'sip' && searchString.length < 2 ? (
            recentSearchResult.length > 0 ? (
              <>
                <DsBox
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <DsTypography
                    variant='subheadingSemiboldDefault'
                    sx={{
                      padding: 'var(--ds-spacing-bitterCold)'
                    }}
                    color='var(--ds-colour-typoSecondary)'
                  >
                    Recent Searches
                  </DsTypography>
                  <DsButton
                    variant='text'
                    color='secondary'
                    sx={{
                      display: { md: 'inline-block' },
                      padding: 'var(--ds-spacing-zero)',
                      justifyContent: 'flex-end',
                      marginRight: '16px'
                    }}
                    onClick={() => actions.deleteRecentSearch()}
                  >
                    Clear All
                  </DsButton>
                </DsBox>
                {this.handleMapping(true)}
              </>
            ) : (
              this.handleZeroScreen(
                'Not sure where to begin ?',
                'Try searching for AXIS or INFY'
              )
            )
          ) : finalSearchResult.length === 0 ? (
            <DsBox sx={{ width: '100%', zIndex: 1000, height: 'max-content' }}>
              {type !== 'sip' && (
                <SearchTabs
                  renderTabs={SEARCHTABS_LABEL}
                  returnValue='id'
                  enableArrow={true}
                  containedTabs={false}
                  onChange={(e: any) => {
                    this.setState({ selectedTabs: e })
                    this.filterData()
                  }}
                  searchResults={searchResult}
                  selectedTabs={selectedTabs}
                />
              )}
              {this.handleZeroScreen(
                'No results found for your search.',
                'Please edit and try again.'
              )}
            </DsBox>
          ) : (
            this.handleMapping(false)
          )}
        </DsBox>
      </>
    )
  }

  render() {
    const {
      searchString,
      finalSearchResult,
      stockAvailableInWatchListArray,
      bookMarkedScriptId,
      bookMarkIconClicked,
      tempAddScriptToParticularWatchlist,
      openAddToWatchlistModal
    } = this.state
    const { open, setOpen, isShortcutSearch, type } = this.props
    const shouldKeepSearchResultsDropDownOpen =
      open ||
      document.getElementById('textField') === document.activeElement ||
      searchString

    return (
      <>
        <DsClickAwayListener onClickAway={this.handleSearchResultClickAway}>
          <DsBox
            id='searchWatchList'
            sx={{ width: '100%', position: 'relative', zIndex: 4 }}
          >
            <DsTextField
              id='textField'
              fullWidth
              sx={{
                '.MuiInputBase-root': {
                  borderRadius: 'var(--ds-radius-glacial) !important',
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
                  borderBottomLeftRadius: open
                    ? 'unset !important'
                    : 'var(--ds-radius-glacial) !important',
                  borderBottomRightRadius: open
                    ? 'unset !important'
                    : 'var(--ds-radius-glacial) !important'
                },
                '.Mui-focused': {
                  borderColor: 'var(--ds-colour-strokeDefault) !important',
                  borderBottomLeftRadius: 'unset !important',
                  borderBottomRightRadius: 'unset !important'
                }
              }}
              name='stockName'
              placeholder={'Search to buy/sell stocks'}
              value={searchString}
              startAdornment={
                <DsInputAdornment position='start'>
                  <DsRemixIcon
                    className='ri-search-line'
                    {...(open && {
                      color: type !== 'Alerts' ? 'secondary' : 'disabled'
                    })}
                  />
                </DsInputAdornment>
              }
              endAdornment={
                searchString.length ? (
                  <DsInputAdornment
                    sx={{ cursor: 'pointer' }}
                    onClick={() => this.handleSearchResultClickAway()}
                    position='end'
                  >
                    <DsRemixIcon className='ri-close-line' />
                  </DsInputAdornment>
                ) : null
              }
              onChange={this.handleOnChangeSearch}
              onClick={this.handleOnClickSearchBox}
              onBlur={this.handleOnBlur}
              onFocus={this.handleOnFocus}
              autoFocus={isShortcutSearch}
            />
            {/* open and close condition  */}
            {shouldKeepSearchResultsDropDownOpen && (
              <DsStack
                id='searchable'
                ref={this.ref}
                sx={{
                  '.MuiInputBase-root > .MuiInputBase-input:focus': {
                    outline: '0',
                    borderColor: 'var(--ds-colour-strokeDefault)'
                  }
                }}
              >
                <DsList
                  sx={{
                    height: type === 'sip' ? '83vh' : '468px',
                    overflow: searchString !== '' ? 'scroll' : 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '99.5%',
                    backgroundColor: 'var(--ds-colour-surfacePrimary)',
                    top: type === 'sip' ? '60px' : '46px',
                    left: '1px',
                    zIndex: '2',
                    boxShadow: ' 0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
                    '::-webkit-scrollbar': {
                      display: 'none'
                    },
                    '&.MuiList-root': { pt: '0px' }
                  }}
                >
                  {/* //zero screen conditions for search box */}
                  {this.handleRenderCases(searchString, finalSearchResult)}
                </DsList>
              </DsStack>
            )}
          </DsBox>
        </DsClickAwayListener>
        <AddToWatchlist
          openAddToWatchlistModal={openAddToWatchlistModal}
          bookMarkIconClicked={bookMarkIconClicked}
          stockAvailableInWatchListArray={stockAvailableInWatchListArray}
          bookMarkedScriptId={bookMarkedScriptId}
          tempAddScriptToParticularWatchlist={
            tempAddScriptToParticularWatchlist
          }
          handleCloseModal={this.handleCloseModal}
          handleOpenModal={this.handleOpenModal}
          handleResetClickState={this.handleResetClickState}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  const searchResult = getSearch(state)
  const searchIndicator = getSearchIndicator(state)
  const recentSearchResult = getRecentSearch(state)
  const getStockWatchlistMasterData = getStockWatchlistMaster(state)
  const addScriptToParticularWatchlist =
    shouldAddScriptToParticularWatchlist(state)
  const getTempStockWatchlistMasterData = getTempStockWatchlistMaster(state)
  const deepLinkAttributes = getDeepLinkAttributes(state)
  const selectedSipScripts = getSelectedScripts(state)
  const isModifingSip = isModifingSelector(state)

  return {
    searchResult,
    searchIndicator,
    recentSearchResult,
    getStockWatchlistMasterData,
    addScriptToParticularWatchlist,
    getTempStockWatchlistMasterData,
    deepLinkAttributes,
    selectedSipScripts,
    isModifingSip
  }
}

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  actions: {
    updateOrderPageModalIndicator: (
      requestData: T_UPDATE_ORDER_PAGE_MODAL_PAYLOAD
    ) => dispatch(updateOrderPageModalIndicator(requestData)),
    setAddScriptToParticularWatchlist: (requestData: boolean) =>
      dispatch(setAddScriptToParticularWatchlist(requestData)),
    setSelectedExchange: (requestData: any) =>
      dispatch(setSelectedExchange(requestData)),
    setSearchIndicator: (val: boolean) => dispatch(setSearchIndicator(val)),

    getRecentSearch: () => dispatch(getRecentSearchConfigAction()),
    deleteAllRecent: () => dispatch(deleteRecentSearchConfigAction()),
    createRecentSearch: (requestData: any) =>
      dispatch(createRecentSearchConfigAction(requestData)),
    deleteRecentSearch: () => dispatch(deleteRecentSearchConfigAction()),
    resetDeepLinkData: () => dispatch(resetDeepLinkData(null)),
    updateSelectedScripts: (val: any) => dispatch(updateSipSelectedScript(val)),
    toggleAlertsForm: (data: any) => dispatch(toggleAlertsFormActions(data))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(SearchStocks)
)
