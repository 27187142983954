import {
  DsStack,
  DsInputLabel,
  DsTypography,
  DsDatePicker
} from '@am92/react-design-system'
import { addDays, addYears, format } from 'date-fns'
import React, { Component } from 'react'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { isModifyOrderRequest } from '~/src/Redux/Orders/selectors'

interface IChooseValidity {
  handleChange: (e: React.ChangeEvent<any>) => void
  goodTillDate: string
}

class ChooseValidity extends Component<IChooseValidity, any> {
  constructor(props: IChooseValidity) {
    super(props)
  }

  componentDidUpdate(prevProps: any): void {}

  render() {
    const { handleChange, goodTillDate } = this.props
    const minDate = addDays(new Date(), 1)
    const maxDate = addDays(minDate, 90)

    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            '>div:nth-child(2)': {
              width: '48%'
            }
          }}
        >
          <DsInputLabel
            label={
              <DsTypography variant='bodyRegularMedium'>
                Choose Validity
              </DsTypography>
            }
            sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
          />
          <DsDatePicker
            name='goodTillDate'
            value={new Date(goodTillDate)}
            minDate={minDate}
            maxDate={maxDate}
            disableHighlightToday={true}
            onChange={(name, value) => {
              return handleChange({ target: { name, value } } as any)
            }}
            sx={{
              '.MuiInputBase-readOnly': {
                pointerEvents: 'unset !important',
                background: 'unset !important'
              },
              '.MuiIconButton-root': {
                color: 'var(--ds-colour-iconDefault) !important'
              }
            }}
          />
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseValidity)
