import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { DsTypography } from '../DsTypography';
export class DsInputLabel extends PureComponent {
    render() {
        const { label, labelSupportText, success, error, ...inputLabelProps } = this.props;
        if (!label && !labelSupportText) {
            return null;
        }
        return (_jsxs(InputLabel, { error: error, ...inputLabelProps, children: [_jsx(DsTypography, { component: "span", variant: "bodyRegularMedium", sx: { float: 'left' }, children: label }), _jsx(DsTypography, { component: "span", variant: "bodyRegularSmall", color: "text.secondary", sx: { float: 'right', width: '100%' }, children: labelSupportText })] }));
    }
}
