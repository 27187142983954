import { createSelector } from '@reduxjs/toolkit'
export const SLICE_NAME = 'exchangeNotification'

const select = (state: any) => state[SLICE_NAME]

export const getExchangeMessagesSelector = createSelector(
  select,
  exchangeNotification => exchangeNotification.exchangeMessages
)

export const getMarketStatusSelector = createSelector(
  select,
  exchangeNotification => exchangeNotification.marketStatus
)
