import { combineReducers, ReducersMapObject } from 'redux'

import ThemeReducer from './Theme/Reducer'
import { SLICE_NAME as ThemeSliceName } from './Theme/Selectors'

import AuthReducer from './Auth/Reducer'
import { SLICE_NAME as AuthSliceName } from './Auth/Selectors'

import IndicatorsReducer from './Indicators/Reducer'
import { SLICE_NAME as IndicatorsSliceName } from './Indicators/Selectors'

import WatchlistReducer from './WatchList/Reducer'
import { SLICE_NAME as WatchlistSliceName } from './WatchList/Selectors'

import DashboardReducer from './Dashboard/Reducer'
import { SLICE_NAME as DashboardSliceName } from './Dashboard/Selectors'

import ResearchAndRecommendationReducer from './ResearchAndRecommendation/Reducers'
import { SLICE_NAME as ResearchAndRecommendationSliceName } from './ResearchAndRecommendation/Selectors'

import AlertsReducer from './Alerts/Reducer'
import { SLICE_NAME as AlertsSliceName } from './Alerts/Selectors'

import StockMasterReducer from './StockMaster/Reducer'
import { SLICE_NAME as StockMasterSliceName } from './StockMaster/Selectors'

import StockWatchlistMasterReducer from './StockWatchlistMaster/Reducer'
import { SLICE_NAME as StockWatchlistMasterSliceName } from './StockWatchlistMaster/Selectors'

import SelectedStockReducer from './Stocks/Reducer'
import { SLICE_NAME as SelectedStockSliceName } from './Stocks/Selectors'

import ServiceTrackerReducer from './ServiceTracker/Reducer'
import { SLICE_NAME as ServiceTrackerSliceName } from './ServiceTracker/Selectors'

import ErrorReducer from './Error/Reducer'
import { SLICE_NAME as ErrorSliceName } from './Error/Selectors'

import SSOReducer, { ssoPersistConfig } from './SSO/Reducer'
import { SLICE_NAME as SSOSliceName } from './SSO/Selectors'

import FundsReducer from './Inquiry/Reducer'
import { SLICE_NAME as FundsSliceName } from './Inquiry/Selectors'

import OrdersReducer, { ordersPersistConfig } from './Orders/reducer'
import { SLICE_NAME as OrdersSliceName } from './Orders/selectors'

import PositionsReducer from './Positions/reducer'
import { SLICE_NAME as PositionsSliceName } from './Positions/selectors'

import PledgeDataReducer from './Pledge/reducer'
import { SLICE_NAME as PledgeDataSliceName } from './Pledge/selectors'

import NewsReducer from './News/reducer'
import { SLICE_NAME as NewsSliceName } from './News/selectors'

import EventsReducer from './Events/reducer'
import { SLICE_NAME as EventsSliceName } from './Events/selectors'

import SearchReducer from './Search/Reducer'
import { SLICE_NAME as SearchSliceName } from './Search/Selectors'

import CustomerReducer from './Customer/Reducer'
import { SLICE_NAME as CustomerSliceName } from './Customer/Selectors'

import ScreenersReducer from './Screeners/Reducers'
import { SLICE_NAME as ScreenersSliceName } from './Screeners/Selectors'

import HistoricalDataReducer from './/HistoricalData/Reducer'
import { SLICE_NAME as HistoricalDataName } from './HistoricalData/Selectors'

import ExchangeNotificationReducer from './ExchangeNotification/Reducer'
import { SLICE_NAME as ExchangeNotificationSliceName } from './ExchangeNotification/Selectors'

import DerivativesReducer from './Derivatives/Reducer'
import { SLICE_NAME as DerivativesSliceName } from './Derivatives/Selectors'

import NotificationsReducer from './Notifications/Reducer'
import { SLICE_NAME as NotificationsSliceName } from './Notifications/Selectors'

import ReverseFeedReducer from './ReverseFeed/Reducer'
import { SLICE_NAME as ReverseFeedSliceName } from './ReverseFeed/Selectors'

import PortfolioReducer, { portfolioPersistConfig } from './Portfolio/reducer'
import { SLICE_NAME as PortfolioSliceName } from './Portfolio/selectors'

import AppStateReducer from './AppState/reducer'
import { SLICE_NAME as appState } from './AppState/selectors'

import { persistReducer } from 'redux-persist'

const reducers: ReducersMapObject = {
  [ThemeSliceName]: ThemeReducer,
  [AuthSliceName]: AuthReducer,
  [IndicatorsSliceName]: IndicatorsReducer,
  [WatchlistSliceName]: WatchlistReducer,
  [DashboardSliceName]: DashboardReducer,
  [StockMasterSliceName]: StockMasterReducer,
  [StockWatchlistMasterSliceName]: StockWatchlistMasterReducer,
  [SelectedStockSliceName]: SelectedStockReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer,
  [SSOSliceName]: persistReducer(ssoPersistConfig, SSOReducer),
  [ErrorSliceName]: ErrorReducer,
  [FundsSliceName]: FundsReducer,
  [OrdersSliceName]: persistReducer(ordersPersistConfig, OrdersReducer),
  [PositionsSliceName]: PositionsReducer,
  [PledgeDataSliceName]: PledgeDataReducer,
  [SearchSliceName]: SearchReducer,
  [CustomerSliceName]: CustomerReducer,
  [NewsSliceName]: NewsReducer,
  [EventsSliceName]: EventsReducer,
  [ScreenersSliceName]: ScreenersReducer,
  [HistoricalDataName]: HistoricalDataReducer,
  [ExchangeNotificationSliceName]: ExchangeNotificationReducer,
  [DerivativesSliceName]: DerivativesReducer,
  [ResearchAndRecommendationSliceName]: ResearchAndRecommendationReducer,
  [NotificationsSliceName]: NotificationsReducer,
  [AlertsSliceName]: AlertsReducer,
  [ReverseFeedSliceName]: ReverseFeedReducer,
  [PortfolioSliceName]: persistReducer(
    portfolioPersistConfig,
    PortfolioReducer
  ),
  [appState]: AppStateReducer
}

const persistedReducers = [ThemeSliceName]

export default combineReducers(reducers)
export { persistedReducers }
