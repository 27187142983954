import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsFormControl,
  DsStack,
  DsInputLabel,
  DsInputBase,
  DsRemixIcon,
  DsFormHelperText,
  DsTypography
} from '@am92/react-design-system'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { changeOrderFormData } from '~/src/Redux/Orders/reducer'
import NumberFormatter from '../../NumberFormatter'
import { getOrderPageModalIndicator } from '~/src/Redux/Orders/selectors'
import { FormikErrors, FormikProps } from 'formik'
import { FormValues } from './OrderFormSection'

type ActionTypes = {
  changeOrderFormData: (payload: { key: string; data: any }) => Promise<any>
}

export interface IQuantitySectionProps extends IWithRouterProps {
  actions: ActionTypes
  quantityError: boolean
  quantityHelperText: string | undefined
  handleChange: (e: React.ChangeEvent<any>) => void
  quantity: number
  breakpoints: any
  stockDetails: T_SCRIPTDATA
  isModifyOrder: boolean
  isRepeatOrder: boolean
  orderPageModalIndicator: boolean
  formikBag: FormikProps<FormValues>
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValues>>
}

class QuantitySection extends Component<IQuantitySectionProps> {
  constructor(props: IQuantitySectionProps) {
    super(props)
  }
  state: Readonly<any> = {
    qty: null
  }
  componentDidMount(): void {
    const {
      quantity,
      stockDetails,
      isModifyOrder,
      isRepeatOrder,
      setFieldValue
    } = this.props
    const { segment, lotsize } = stockDetails || {}
    this.setState({ qty: null })
    isModifyOrder || isRepeatOrder
      ? segment === 'EQ'
        ? this.setState({ qty: quantity })
        : this.setState({ qty: Number(quantity) / Number(lotsize) })
      : this.setState({ qty: quantity })
    if (segment !== 'EQ')
      setFieldValue('quantity', Number(quantity) / Number(lotsize))
  }

  componentDidUpdate(
    prevProps: Readonly<IQuantitySectionProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    const { orderPageModalIndicator } = this.props
    if (orderPageModalIndicator !== prevProps.orderPageModalIndicator) {
      this.setState({ qty: 1 })
    }
  }

  renderLabel = () => {
    const { stockDetails } = this.props
    const { segment, lotsize } = stockDetails || {}
    const { qty } = this.state
    return (
      <DsInputLabel
        label={
          <DsTypography variant='bodyRegularMedium'>
            {segment === 'EQ' ? (lotsize > 1 ? 'Lots' : 'Quantity') : 'Lots'}
          </DsTypography>
        }
        sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
        {...((segment === 'EQ' && lotsize > 1) || segment !== 'EQ'
          ? {
              labelSupportText: (
                <DsTypography variant='bodyRegularMedium'>
                  Size: {lotsize} Qty: {lotsize * qty}
                </DsTypography>
              )
            }
          : '')}
      />
    )
  }

  render() {
    const { quantityError, quantityHelperText, handleChange } = this.props
    const { qty } = this.state
    return (
      <DsFormControl
        sx={{
          paddingLeft: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-mild)'
          },
          paddingRight: {
            xs: 'var(--ds-spacing-bitterCold)',
            md: 'var(--ds-spacing-mild)'
          }
        }}
        error={quantityError}
      >
        <DsStack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {this.renderLabel()}
          <DsInputBase
            inputComponent={NumberFormatter}
            name='quantity'
            placeholder='Enter Quantity'
            onChange={e => {
              this.setState({ qty: e.target.value })
              handleChange(e)
            }}
            value={qty}
            inputProps={{
              inputMode: 'numeric'
            }}
          />
        </DsStack>
        <DsStack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 'var(--ds-spacing-quickFreeze)',
            marginLeft: 'var(--ds-spacing-quickFreeze)',
            marginTop: 'var(--ds-spacing-glacial)'
          }}
        >
          {(quantityError && (
            <DsRemixIcon
              className='ri-error-warning-fill'
              fontSize='small'
              sx={{ color: 'var(--ds-colour-supportNegative)' }}
            />
          )) ||
            null}
          <DsFormHelperText
            error={quantityError}
            sx={{
              marginTop: 'var(--ds-spacing-zero)',
              padding: 'var(--ds-spacing-zero)',
              minHeight: 'unset'
            }}
          >
            {quantityHelperText}
          </DsFormHelperText>
        </DsStack>
      </DsFormControl>
    )
  }
}

const mapStateToProps = (state: any) => {
  const orderPageModalIndicator = getOrderPageModalIndicator(state)
  return { orderPageModalIndicator }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    changeOrderFormData: (payload: { key: string; data: any }) =>
      dispatch(changeOrderFormData(payload))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(QuantitySection)
