import { DsBox, DsSkeleton, DsStack, DsTypography } from "@am92/react-design-system";
import React, { ReactNode } from "react";

class BankDetailsSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <DsBox
        sx={{
          backgroundColor: 'var(--ds-colour-surfaceSecondary)',
          mb: "var(--ds-spacing-bitterCold)"
        }}
      >
        <DsTypography
          sx={{ display: { xs: 'none', md: 'flex' } }}
          variant="headingBoldLarge"
        >
          Bank Details & DP Details
        </DsTypography>
        <DsStack spacing={"var(--ds-spacing-warm)"}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 'var(--ds-spacing-zero)', md: 'var(--ds-spacing-mild)' },
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            margin: { xs: 'var(--ds-spacing-zero)', md: 'var(--ds-spacing-bitterCold) var(--ds-spacing-zero)' },
            padding: { md: 'var(--ds-spacing-mild)' }
          }}>
          <DsStack spacing={"var(--ds-spacing-cool)"} sx={{ width: "100%" }}>
            <DsSkeleton variant="rounded" sx={{ height: "30px" }} />
          </DsStack>
          <DsStack spacing={"var(--ds-spacing-cool)"} sx={{ width: "60%" }}>
            <DsSkeleton variant="rounded" sx={{ height: "80px" }} />
          </DsStack>
        </DsStack>
      </DsBox>
    )
  }
}

export default BankDetailsSkeleton