const dsRadius = {
    zero: '0px',
    deepFreeze: '2px',
    quickFreeze: '4px',
    glacial: '8px',
    frostbite: '12px',
    bitterCold: '16px',
    cool: '20px',
    mild: '24px',
    pleasant: '28px'
};
export default dsRadius;
