import { AS_DEFAULT_WSS_URL } from '~/src/Configurations/env'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'

export const APP_TO_WORKER_ACTIONS = {
  WEB_SOCKET_INITIALIZE: 'WEB_SOCKET_INITIALIZE',
  WEB_SOCKET_SUBSCRIBE: 'WEB_SOCKET_SUBSCRIBE',
  WEB_SOCKET_UNSUBSCRIBE: 'WEB_SOCKET_UNSUBSCRIBE'
}

export const WORKER_TO_APP_ACTIONS = {
  WEB_SOCKET_ONOPEN: 'WEB_SOCKET_ONOPEN',
  ODIN_DATA_RECEIVED: 'ODIN_DATA_RECEIVED',
  GET_DATA_BY_SCRIPTID: 'GET_DATA_BY_SCRIPTID'
}

export type T_ACTIONS_STATUS_TYPES = 'SUCCESS' | 'FAILURE'

export interface T_STOCKDATA extends T_SCRIPTDATA {
  scriptId: string
  ODIN_LLFC_SEGMENTID: number
}

export type T_ACTION_STATUS = {
  SUCCESS: string
  FAILURE: string
}

export const ACTION_STATUS: T_ACTION_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

export type T_ONMESSAGE_CTX = {
  func: (event: MessageEvent) => void
  ctx: any
}

export type T_ONERROR_CTX = {
  func: (event: MessageEvent) => void
  ctx: any
}

export type T_WORKER_TO_APP_ACTIONS_TYPES =
  | 'WEB_SOCKET_ONOPEN'
  | 'ODIN_DATA_RECEIVED'
  | 'GET_DATA_BY_SCRIPTID'

export type T_APP_TO_WORKER_ACTIONS_TYPES =
  | 'WEB_SOCKET_INITIALIZE'
  | 'WEB_SOCKET_SUBSCRIBE'

export type T_ACTION_SWITCHER_RESULT_TYPES = {
  action: T_APP_TO_WORKER_ACTIONS_TYPES | string
  data: any
  status: T_ACTIONS_STATUS_TYPES | string
  error?: any
}

export type T_SOCKET_DATA_CALLBACK = (
  data: any,
  action: T_WORKER_TO_APP_ACTIONS_TYPES,
  status: T_ACTIONS_STATUS_TYPES
) => void

export type T_SUBSCRIBE = {
  ODIN_TOKEN_ID: number
  SEGMENT: number
  OPRATION: number
}

export type T_UNSUBSCRIBE = {
  ODIN_TOKEN_ID: number
  SEGMENT: number
  OPRATION: number
}

export const DEFAULT_WSS_URL = AS_DEFAULT_WSS_URL

export type T_LOGIN_DATA = {
  USER_ID: string
  TOKEN: string
}

export const BINARY = 'arraybuffer'
