import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'

import {
  getAvailableEquityLimitsActions,
  getAvailableEquityLimitsServiceName
} from '../Actions'

import { getRequiredDataForHeaders } from '~/src/Utils/global'

async function getAvailableEquityLimits(payload: any) {
  const options = {
    url: '/funds/inquiry/get-limits',
    method: 'POST',
    data: payload,
    headers: getRequiredDataForHeaders()
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getAvailableEquityLimitsAction = serviceActionCreator(
  getAvailableEquityLimitsActions,
  getAvailableEquityLimits
)

export default getAvailableEquityLimitsAction
export { getAvailableEquityLimitsServiceName }
