import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsPopup,
  DsStack,
  DsTypography,
  DsBox,
  DsDrawer,
  DsRemixIcon,
  DsBottomSheet
} from '@am92/react-design-system'
import { getHoldingAndAveragePrice } from '~/src/Redux/Dashboard/Selectors'
import HoldingsPopUpContent from './HoldingsPopUpContent'
import { getOrderScriptId } from '~/src/Redux/Orders/selectors'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'

type ActionTypes = {}

export interface IHoldingsPopUpProps extends IWithRouterProps {
  actions: ActionTypes
  openHoldings: boolean
  handleShowHoldings: () => void
  scriptId: string
  holdings: string | number
  dmatfreeQty: string | number
  collateralQty: string | number
  emarginNetQuantity: string | number
  avgPrice: string | number
  investedValue: string | number
  stockDetails: any
}

class HoldingsPopUp extends Component<IHoldingsPopUpProps> {
  render() {
    const {
      openHoldings,
      handleShowHoldings,
      holdings,
      dmatfreeQty = 'NA',
      collateralQty = 'NA',
      emarginNetQuantity = 'NA',
      avgPrice,
      investedValue,
      scriptId
    } = this.props

    return (
      <>
        <DsDrawer
          PaperProps={{
            sx: {
              top: '350px',
              zIndex: '10',
              boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)',
              width: '460px',
              left: '40%',
              borderTop: 'unset',
              borderRadius:
                'var(--ds-spacing-bitterCold) var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-zero)'
            }
          }}
          anchor='bottom'
          open={openHoldings}
          variant='persistent'
          hideBackdrop
          ModalProps={{
            keepMounted: false
          }}
          sx={{
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
        >
          <DsStack
            sx={{
              padding: 'var(--ds-spacing-mild)'
            }}
          >
            <DsStack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 'var(--ds-spacing-mild)'
              }}
            >
              <DsTypography variant='headingBoldMedium'>
                Holdings Details
              </DsTypography>
              <DsRemixIcon
                onClick={handleShowHoldings}
                className='ri-close-fill'
                fontSize='mild'
                sx={{
                  cursor: 'pointer'
                }}
              />
            </DsStack>
            <HoldingsPopUpContent
              holdings={holdings}
              dmatfreeQty={dmatfreeQty}
              collateralQty={collateralQty}
              emarginNetQuantity={emarginNetQuantity}
              avgPrice={avgPrice}
              investedValue={investedValue}
              scriptId={scriptId}
            />
          </DsStack>
        </DsDrawer>

        <DsBottomSheet
          open={openHoldings}
          sx={{
            display: {
              xs: 'block',
              md: 'none'
            }
          }}
          onClose={handleShowHoldings}
          title='Holdings Details'
        >
          <HoldingsPopUpContent
            holdings={holdings}
            dmatfreeQty={dmatfreeQty}
            collateralQty={collateralQty}
            emarginNetQuantity={emarginNetQuantity}
            avgPrice={avgPrice}
            investedValue={investedValue}
            scriptId={scriptId}
          />
        </DsBottomSheet>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const orderScriptId = getOrderScriptId(state)
  const stockDetails: any = getStockMasterData(orderScriptId)(state)
  const {
    holdings,
    dmatfreeQty,
    emarginNetQuantity,
    avgPrice,
    investedValue,
    collateralQty
  } = getHoldingAndAveragePrice(scriptId, stockDetails)(state) || {}
  return {
    holdings,
    dmatfreeQty,
    emarginNetQuantity,
    avgPrice,
    investedValue,
    collateralQty,
    stockDetails
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(HoldingsPopUp)
