import {
  DsDialog,
  DsStack,
  DsBox,
  DsImage,
  DsTypography,
  DsButton,
  withBreakpoints,
  DsList,
  DsListItem,
  DsListItemText,
  DsLink,
  DsRemixIcon
} from '@am92/react-design-system'
import React from 'react'
import { IWithRouterProps } from '../Lib/withRouter'
import { RISK_DISCLOSURE } from '../Constants/ASSET_MAP'
import { SEBI_DISCLOSURE } from '../Configurations/env'
import { getDeviceActiveScreen } from '../Utils/deviceDetails'

export interface IRiskDisclosureProps extends IWithRouterProps {
  breakpoints: any
}

export interface IRiskDisclosureState {
  open: boolean
}

class RiskDisclosure extends React.PureComponent<
  IRiskDisclosureProps,
  IRiskDisclosureState
> {
  state = {
    open: true
  }

  handleOnClose = () => {
    const { open } = this.state
    this.setState({ open: !open })
    localStorage.setItem('haveSeenRiskDisclosure', 'true')
  }

  handleOnClick = () => {
    window.open(SEBI_DISCLOSURE)
  }

  render() {
    //mobile view breakpoints
    const { breakpoints = {} } = this.props
    const { isDesktop } = getDeviceActiveScreen(breakpoints)
    const { open } = this.state

    return (
      <>
        <DsDialog
          sx={{
            justifyContent: 'space-between',
            '.MuiPaper-root': {
              borderRadius: {
                xs: 'var(--ds-spacing-zero)',
                md: 'var(--ds-spacing-bitterCold)'
              },
              '& .MuiStack-root': {}
            }
          }}
          showClose={false}
          fullScreen={!isDesktop}
          open={open}
        >
          <DsStack
            spacing='var(--ds-spacing-mild)'
            sx={{
              textAlign: 'center',
              height: '100%'
            }}
            justifyContent={'space-between'}
          >
            <DsStack>
              <DsBox sx={{ display: { xs: 'flex', md: 'none' } }}>
                <DsImage srcSet={RISK_DISCLOSURE} />
              </DsBox>

              <DsStack
                sx={{
                  background: {
                    xs: 'var(--ds-colour-neutral1)',
                    md: 'none',
                    padding: 'var(--ds-spacing-frostbite)'
                  },
                  borderRadius: 'var(--ds-spacing-glacial)'
                }}
              >
                <DsBox
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: { xs: 'flex-start', md: 'center' },
                    gap: 'var(--ds-spacing-glacial)'
                  }}
                >
                  <DsBox
                    sx={{
                      display: { xs: 'flex', md: 'none' },
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: 'white',
                      borderRadius: 'var(--ds-spacing-quickFreeze)',
                      width: 'var(--ds-spacing-mild)',
                      height: 'var(--ds-spacing-mild)'
                    }}
                  >
                    <DsRemixIcon
                      className='ri-shield-check-line'
                      fontSize='frigid'
                      color='error'
                    />
                  </DsBox>

                  <DsTypography variant='headingBoldLarge' component={'div'}>
                    Risk Disclosure on Derivatives
                  </DsTypography>
                </DsBox>

                <DsStack spacing='var(--ds-spacing-cool)'>
                  <DsList
                    sx={{
                      listStyleType: 'disc',
                      paddingInlineStart: 'var(--ds-spacing-bitterCold)'
                    }}
                  >
                    <DsListItem sx={{ display: 'list-item' }}>
                      <DsListItemText secondary='9 out of 10 individual traders incurred net-losses.' />
                    </DsListItem>
                    <DsListItem sx={{ display: 'list-item' }}>
                      <DsListItemText secondary='On average, loss makers registered net trading loss close to ₹ 50,000.' />
                    </DsListItem>
                    <DsListItem sx={{ display: 'list-item' }}>
                      <DsListItemText secondary='Loss makers expended an additional 28% of net trading losses as transaction costs.' />
                    </DsListItem>
                    <DsListItem sx={{ display: 'list-item' }}>
                      <DsListItemText secondary='Those making net trading profits incurred between 15% to 50% of such profits as transaction cost.' />
                    </DsListItem>
                  </DsList>
                  <DsLink underline='always' onClick={this.handleOnClick}>
                    Read Full Sebi Disclosure
                  </DsLink>
                </DsStack>
              </DsStack>
            </DsStack>

            <DsButton
              color='primary'
              size='large'
              sx={{ mb: 'var(--ds-spacing-frostbite)' }}
              onClick={this.handleOnClose}
            >
              Okay, Understood
            </DsButton>
          </DsStack>
        </DsDialog>
      </>
    )
  }
}

export default withBreakpoints(RiskDisclosure)
