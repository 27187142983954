import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreator'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'
import {
  createModifyAlertsFormActions,
  createModifyAlertsFormServiceName
} from '../Actions'
import { NOTIFICATIONS_URL } from '~/src/Configurations/env'

export type reqBody = {
  alertId?: string
  scriptId: string
  value: number
  condition: string
  description?: string
}

async function alertsForm(req: reqBody) {
  const options = {
    url: `/alerts`,
    method: 'POST',
    data: req,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const alertsFormService = serviceActionCreator(
  createModifyAlertsFormActions,
  alertsForm
)

export default alertsFormService
export { createModifyAlertsFormServiceName }
