import React from 'react'

// DS Components
import { DsBox, DsRemixIcon, DsTypography } from '@am92/react-design-system'
import { IrenderTabObj } from '../Sidebar/Sidebar'
import { triggerCleverTapEvent } from '~/src/Utils/global'

// Lib
export interface IContainerTabProps {
  renderTabs: IrenderTabObj[]
  returnValue: string
  onChange: (returnValue: string) => void
  enableArrow?: boolean
  containedTabs: boolean
  roundTabs?: boolean
  value?: number
  watchlistCreated?: boolean
  isLowerCasingNeeded?: boolean
  type?: string
}

class ContainerTab extends React.Component<IContainerTabProps> {
  ref: React.RefObject<any>
  scrollPosition: number

  constructor(props: any) {
    super(props)
    this.ref = React.createRef()
    this.scrollPosition = 0
  }

  state = {
    leftScroll: false,
    rightScroll: false,
    scrollPosition: 0
  }

  componentDidMount(): void {
    const { value } = this.props
    this.handleScrollToCenter(value, 50)
  }

  componentDidUpdate(prevProps: Readonly<IContainerTabProps>): void {
    const { value } = this.props
    if (prevProps.value !== value) {
      this.handleScrollToCenter(value as number, -10)
    }
    if (
      prevProps.watchlistCreated !== this.props.watchlistCreated &&
      this.props.watchlistCreated === true
    ) {
      this.handleScrollToExtremeRight()
    }
  }

  handleScroll = () => {
    const section = this.ref.current

    if (section) {
      const newScrollPosition = section.scrollLeft + section.clientWidth + 10
      this.setState({ scrollPosition: newScrollPosition })
      this.scrollPosition = newScrollPosition
    }
    if (this.scrollPosition > section?.scrollWidth) {
      this.setState({ rightScroll: false })
    }
    if (this.scrollPosition < section?.scrollWidth) {
      this.setState({ rightScroll: true })
    }
    if (this.scrollPosition - section.clientWidth - 10 < 15) {
      this.setState({ leftScroll: false })
    }
    if (this.scrollPosition - section.clientWidth - 10 > 15) {
      this.setState({ leftScroll: true })
    }
  }

  handleScrollLeft = () => {
    if (this.ref.current !== null) {
      this.ref.current.scrollLeft -= 100
    }
  }

  handleScrollRight = () => {
    if (this.ref.current !== null) {
      this.ref.current.scrollLeft += 100
    }
  }

  handleScrollToExtremeRight = () => {
    const section = this.ref.current
    if (this.ref.current !== null) {
      this.ref.current.scrollLeft +=
        section.scrollLeft + section.clientWidth + 150
    }
  }

  handleScrollToCenter = (index: any, value: any) => {
    if (this.ref.current) {
      const tabElement = this.ref.current.querySelector(
        `[data-tab-id="${index}"]`
      )
      if (tabElement) {
        const tabRect = tabElement.getBoundingClientRect()
        const containerRect = this.ref.current.getBoundingClientRect()
        const scrollPosition = tabRect.left - containerRect.width / 2
        this.ref.current.scrollLeft += scrollPosition + value
      }
    }
  }

  handleSelec = (tabIndex: number) => {
    const { onChange, returnValue, renderTabs } = this.props
    // this.setState({ selectedTab: tabIndex })
    onChange(renderTabs[tabIndex][returnValue])
    // this.handleScrollToCenter(tabIndex, -10)
  }

  handleTabChange = (newvalue: number, index?: any) => {
    if (newvalue === 13 && index === 13) {
      const customAttributes = {
        'Event Status': 'Pass',
        Action: 'Manage'
      }
      triggerCleverTapEvent('Watchlist', customAttributes)
    }
    const { onChange, returnValue, renderTabs } = this.props
    // this.setState({ selectedTab: newvalue })
    onChange(renderTabs[newvalue] ? renderTabs[newvalue][returnValue] : '13')
    this.handleScrollToCenter(index.id, 50)
  }

  render() {
    const {
      renderTabs,
      enableArrow,
      containedTabs = false,
      roundTabs = false,
      isLowerCasingNeeded = false,
      type,
      value
    } = this.props
    const { leftScroll, rightScroll } = this.state
    const selectedTab = value
    return (
      <>
        {containedTabs ? (
          <DsBox
            sx={{
              display: 'grid',
              gridTemplateColumns: enableArrow
                ? leftScroll && !rightScroll
                  ? 'var(--ds-spacing-tropical) auto'
                  : !leftScroll && rightScroll
                    ? 'auto var(--ds-spacing-tepid)'
                    : !leftScroll && !rightScroll
                      ? 'auto'
                      : 'var(--ds-spacing-tropical) auto var(--ds-spacing-tepid)'
                : '',
              py:
                type === 'HOLDINGS'
                  ? 'var(--ds-spacing-zero)'
                  : !leftScroll && !rightScroll
                    ? 'var(--ds-spacing-frostbite)'
                    : 'var(--ds-spacing-zero)'
            }}
          >
            {enableArrow && leftScroll ? (
              <DsBox
                sx={
                  roundTabs
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }
                    : {
                        display: 'flex',
                        alignItems: 'center',
                        width: 'var(--ds-spacing-tepid)',
                        height:
                          'calc(var(--ds-spacing-molten) - var(--ds-spacing-quickFreeze))',
                        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
                        borderTopLeftRadius: 'var(--ds-spacing-glacial)',
                        p: 'var(--ds-spacing-glacial) var(--ds-spacing-quickFreeze)',
                        cursor: 'pointer'
                      }
                }
                onClick={this.handleScrollLeft}
              >
                <DsRemixIcon
                  className='ri-arrow-drop-left-line'
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                />
              </DsBox>
            ) : null}

            <DsBox
              id='scrollContainer'
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: `var(--ds-spacing-${
                  roundTabs ? 'bitterCold' : 'glacial'
                })`,
                overflowX: roundTabs ? 'inherit' : 'auto',
                whiteSpace: 'pre',
                scrollBehavior: 'smooth',
                '&::-webkit-scrollbar': { display: 'none' },
                ml:
                  type === 'NON_WATCHLIST' || type === 'HOLDINGS'
                    ? 'var(--ds-spacing-zero)'
                    : !leftScroll
                      ? 'var(--ds-spacing-mild)'
                      : 'var(--ds-spacing-zero)',
                mr:
                  type === 'NON_WATCHLIST' || type === 'HOLDINGS'
                    ? 'var(--ds-spacing-zero)'
                    : !rightScroll
                      ? 'var(--ds-spacing-mild)'
                      : 'var(--ds-spacing-zero)',
                mb:
                  type === 'HOLDINGS'
                    ? 'var(--ds-spacing-zero)'
                    : type === 'NON_WATCHLIST'
                      ? 'var(--ds-spacing-bitterCold)'
                      : 'var(--ds-spacing-zero)',
                width:
                  type === 'HOLDINGS'
                    ? '100%'
                    : type === 'NON_WATCHLIST'
                      ? '100%'
                      : 'auto'
              }}
              ref={this.ref}
              onScroll={() => this.handleScroll()}
            >
              {renderTabs.map((item: any, index: any) => (
                <DsBox
                  key={index}
                  data-tab-id={index}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    px: `calc(var(--ds-spacing-${
                      roundTabs ? 'frostbite' : 'glacial'
                    }) - 1px)`,
                    py: `calc(var(--ds-spacing-${
                      roundTabs ? 'glacial' : 'frostbite'
                    }) -  ${roundTabs ? '3px' : '1px'})`,
                    backgroundColor: {
                      xs:
                        selectedTab === index
                          ? `var(--ds-colour-${
                              roundTabs
                                ? 'actionSecondary'
                                : 'stateSelectedSecondaryHover'
                            })`
                          : `var(--ds-colour-${
                              roundTabs ? 'surfacePrimary' : 'surfaceSecondary'
                            })`
                    },
                    border: {
                      xs:
                        selectedTab === index
                          ? `1px solid var(--ds-colour-${
                              roundTabs
                                ? 'strokeSelected'
                                : 'strokeSecondarySelected'
                            })`
                          : '1px solid var(--ds-colour-strokeDefault)'
                    },
                    borderRadius: roundTabs
                      ? 'calc(var(--ds-spacing-bitterCold) + 1px)'
                      : 'var(--ds-spacing-quickFreeze)',
                    cursor: 'pointer'
                  }}
                  onClick={() => this.handleSelec(index)}
                  id={item.label}
                >
                  <DsTypography
                    variant={
                      roundTabs ? 'bodyRegularMedium' : 'supportRegularMetadata'
                    }
                    color={
                      selectedTab === index
                        ? `var(--ds-colour-${
                            roundTabs ? 'typoOnSurface' : 'iconActionTertiary'
                          })`
                        : `var(--ds-colour-${
                            roundTabs ? 'iconDefault' : 'typoSecondary'
                          })`
                    }
                    sx={{
                      textTransform:
                        roundTabs || isLowerCasingNeeded ? 'none' : 'uppercase'
                    }}
                  >
                    {item.label}
                  </DsTypography>
                </DsBox>
              ))}
            </DsBox>

            {enableArrow && rightScroll ? (
              <DsBox
                sx={
                  roundTabs
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }
                    : {
                        display: 'flex',
                        alignItems: 'center',
                        width: 'var(--ds-spacing-tepid)',
                        height:
                          'calc(var(--ds-spacing-molten) - var(--ds-spacing-quickFreeze))',
                        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
                        borderTopRightRadius: 'var(--ds-spacing-glacial)',
                        p: 'var(--ds-spacing-glacial) var(--ds-spacing-quickFreeze)',
                        cursor: 'pointer'
                      }
                }
                onClick={this.handleScrollRight}
              >
                <DsRemixIcon
                  className='ri-arrow-drop-right-line'
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                />
              </DsBox>
            ) : null}
          </DsBox>
        ) : (
          <DsBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflowX: { xs: 'auto', md: 'visible' },
              whiteSpace: 'pre',
              scrollBehavior: 'smooth',
              '&::-webkit-scrollbar': { display: 'none' }
            }}
            ref={this.ref}
            onScroll={() => this.handleScroll()}
          >
            {renderTabs.map((item: IrenderTabObj, index: number) => {
              return (
                <DsBox
                  key={item.id}
                  data-tab-id={item.id}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    p: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)',
                    borderBottom: {
                      xs:
                        selectedTab === index
                          ? '2px solid var(--ds-colour-actionSecondary)'
                          : 'none'
                    },
                    cursor: 'pointer'
                  }}
                  onClick={() => this.handleTabChange(index, item)}
                  id={item.label}
                  className={selectedTab === index ? 'selected' : ''}
                >
                  <DsTypography
                    variant='bodyRegularMedium'
                    color={`var(--ds-colour-${
                      selectedTab === index ? 'actionSecondary' : 'typoTertiary'
                    })`}
                    sx={{
                      textTransform: isLowerCasingNeeded ? 'none' : 'uppercase'
                    }}
                  >
                    {item.label}
                  </DsTypography>
                </DsBox>
              )
            })}

            {renderTabs.length ? (
              <DsBox
                key={13}
                data-tab-id={13}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  p: 'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold)',
                  borderBottom: {
                    xs:
                      selectedTab === 13
                        ? '2px solid var(--ds-colour-actionSecondary)'
                        : 'none'
                  },
                  cursor: 'pointer'
                }}
                onClick={() => this.handleTabChange(13, 13)}
                id={'Manage'}
                className={selectedTab === 13 ? 'selected' : ''}
              >
                <DsTypography
                  variant='bodyRegularMedium'
                  color={`var(--ds-colour-${
                    selectedTab === 13 ? 'actionSecondary' : 'typoTertiary'
                  })`}
                  sx={{
                    textTransform: isLowerCasingNeeded ? 'none' : 'uppercase'
                  }}
                >
                  {'Manage'}
                </DsTypography>
              </DsBox>
            ) : (
              ''
            )}
          </DsBox>
        )}
      </>
    )
  }
}

export default ContainerTab
