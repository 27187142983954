import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import {
  clearDashboardNewsActions,
  clearQuotesNewsActions,
  getDashboardNewsActions,
  getQuotesNewsActions
} from './actions'
import { SLICE_NAME } from './selectors'

const INITIAL_STATE = {
  dashboardNews: {},
  quotesNews: {}
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getDashboardNewsActions.success, (state, { payload }) => {
      const { dashboardNews = {} } = state
      const { stockNews: existingStockNews = [] } = dashboardNews
      const { stockNews = [], isNextPage } = payload
      const newData =
        isNextPage && stockNews && stockNews.length > 0 ? stockNews : []

      state.dashboardNews =
        existingStockNews && existingStockNews.length === 0
          ? payload
          : {
              ...payload,
              stockNews: [...existingStockNews, ...newData]
            }
    })
    builder.addCase(clearDashboardNewsActions, state => {
      state.dashboardNews = {}
    })

    builder.addCase(getQuotesNewsActions.success, (state, { payload }) => {
      const { quotesNews = {} } = state
      const { stockNews: existingStockNews = [] } = quotesNews
      const { stockNews = [], isNextPage } = payload
      const newData =
        isNextPage && stockNews && stockNews.length > 0 ? stockNews : []

      state.quotesNews =
        existingStockNews && existingStockNews.length === 0
          ? payload
          : existingStockNews
          ? {
              ...payload,
              stockNews: [...existingStockNews, ...newData]
            }
          : []
    })

    builder.addCase(clearQuotesNewsActions, state => {
      state.quotesNews = {}
    })
  }
}

const slice = createSlice(sliceOptions)
export default slice.reducer
