import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { DsIconButton } from '../DsIconButton';
import { DsRemixIcon } from '../DsRemixIcon';
import { DsStack } from '../DsStack';
import { DsTypography } from '../DsTypography';
export class DefaultToolbar extends Component {
    render() {
        const { value, ownerState } = this.props;
        const { onCancel } = ownerState;
        const formatOptions = {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        };
        const currentDateAndDay = value && value.toLocaleDateString('en-GB', formatOptions);
        return (_jsx(DsStack, { sx: {
                backgroundColor: 'var(--ds-colour-surfaceSecondary)',
                padding: 'var(--ds-spacing-cool) var(--ds-spacing-bitterCold)',
                gridArea: '1 / 2 / auto / 4',
                borderRadius: '16px 16px 0px 0px',
                borderBottom: '1px solid var(--ds-colour-strokeDefault)'
            }, children: _jsxs(DsTypography, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }, variant: "headingBoldExtraSmall", children: [value ? `Selected date: ${currentDateAndDay}` : 'Select a date', _jsx(DsIconButton, { onClick: onCancel, children: _jsx(DsRemixIcon, { className: "ri-close-line" }) })] }) }));
    }
}
