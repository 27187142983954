import React from 'react'
import {
  DsBox,
  DsButton,
  DsButtonBase,
  DsDialog,
  DsImage,
  DsLink,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'
import { PureComponent } from 'react'
import { getErrorCodeSelector } from '~/src/Redux/Error/Selectors'
import withRouter from '~/src/Lib/withRouter'
import { connect } from 'react-redux'
import { CUSTOMERSUPPORT } from '~/src/Constants/ASSET_MAP'
import { appContext } from '~/src/Configurations/AppStore'

type ActionTypes = {}
export interface ICustomerSupportServiceNotAvailableErrorProps {
  errorCode: any
  isDesktop: any
  navigateTo: any
  setShowServiceModal: any
  actions: ActionTypes
}
class CustomerSupportServiceNotAvailableError extends PureComponent<ICustomerSupportServiceNotAvailableErrorProps> {
  onClose = () => {
    const { navigateTo } = this.props
    navigateTo(-2)
  }
  render() {
    const { isDesktop, setShowServiceModal } = this.props

    return (
      <DsDialog
        sx={{
          '.MuiPaper-root': {
            display: 'flex',
            height: { md: 'auto', xs: '100%' },
            borderRadius: {
              xs: 'var(--ds-radius-zero)',
              md: 'var(--ds-radius-bitterCold)',
            },
            '& .MuiStack-root': {
              // justifyContent: 'center',
              '& .MuiBox-root': {}
            },
            '& .MuiBox-root': {
              display: 'flex'
            }
          }
        }}
        showClose={false}
        // onClose={() => setShowServiceModal(false)}
        open={true}
        fullScreen={!isDesktop}
      >
        <>

          <DsStack
            // spacing="var(--ds-spacing-bitterCold)"
            sx={{
              justifyContent: 'space-between',
              gap: "var(--ds-spacing-bitterCold)",
              alignItems: 'center',
              textAlign: 'center',
              height: '100%',
              flexDirection: "column",
              position: ' relative'
            }}
          >
            {!isDesktop && (
              <DsRemixIcon
                className="ri-close-line"
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  left: '0',
                  top: 'var(--ds-spacing-frostbite)'
                }}
                onClick={() => setShowServiceModal(false)}
              />
            )}
            <DsStack
              sx={{
                alignItems: 'center',
                flexDirection: "column",
                gap: "var(--ds-spacing-warm)",
                textAlign: 'center',
                mt: { xs: "var(--ds-spacing-plasma)", md: "var(--ds-spacing-zero)" }
              }}
            >
              <DsBox>
                <DsImage srcSet={CUSTOMERSUPPORT} />
              </DsBox>
              <DsTypography variant="headingBoldLarge">
                Service not available
              </DsTypography>
              <DsTypography variant="bodyRegularLarge">
                This feature is not yet available for your account. Please contact our customer care for more details.
              </DsTypography>

              <DsBox
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexGrow: '0 !important',
                  width: '94%'
                }}
              >
                <DsStack
                  sx={{
                    alignItems: 'flex-start',
                    width: '50%'
                  }}
                >
                  <DsTypography variant="supportRegularMetadata">
                    Contact number
                  </DsTypography>
                  <DsTypography
                    sx={{
                      color: 'var(--secondary-pink-pink-100, #ED1164)',
                      textAlign: 'start'
                    }}
                  >
                    <DsLink href="tel:022-40508080" underline="none">022-40508080</DsLink> | <DsLink href="tel:022-61480808" underline="none">022-61480808</DsLink>
                  </DsTypography>
                </DsStack>
                <DsStack
                  sx={{
                    alignItems: 'flex-start',
                    width: '50%'
                  }}
                >
                  <DsTypography variant="supportRegularMetadata">
                    Email Address
                  </DsTypography>
                  <DsLink
                    href="mailto:helpdesk@axisdirect.in"
                    underline="none"
                  >
                    <DsTypography
                      sx={{ color: 'var(--secondary-pink-pink-100, #ED1164)' }}
                    >
                      helpdesk@axisdirect.in
                    </DsTypography>
                  </DsLink>
                </DsStack>
              </DsBox>
            </DsStack>

            <DsButtonBase
              sx={{
                display: 'flex',
                gap: '8px',
                width: '100%'
              }}
            >
              <DsButton
                onClick={() => setShowServiceModal(false)}
                size="medium"
                fullWidth={true}
              >
                Go Back
              </DsButton>
              {/* <DsButton fullWidth={true} size="medium" color="primary">
                Request a call
              </DsButton> */}
            </DsButtonBase>
          </DsStack >
        </>
      </DsDialog>

    )
  }
}
const mapStateToProps = (state: any) => {
  const errorCode = getErrorCodeSelector(state)
  return {
    errorCode
  }
}
const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  actions: {}
})
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps, null, { context: appContext }
  )(CustomerSupportServiceNotAvailableError)
)
