import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import { DsButton } from '../DsButton';
import { DsButtonGroup } from '../DsButtonGroup';
export class DefaultActionBar extends PureComponent {
    render() {
        const { onAccept, onClear, ownerState, actions } = this.props;
        const { value } = ownerState || {};
        if (!actions?.length) {
            return;
        }
        const isClearVisible = actions.includes('clear');
        const isConfirmVisible = actions.includes('accept');
        return (_jsxs(DsButtonGroup, { sx: {
                gridArea: '3 / 1 / auto / 4',
                backgroundColor: 'var(--ds-colour-surfaceSecondary)',
                px: 'var(--ds-spacing-mild)',
                borderTop: '1px solid var(--ds-colour-strokeDefault)',
                borderRadius: '0px 0px 16px 16px '
            }, justifyContent: "space-between", size: "medium", children: [isClearVisible && (_jsx(DsButton, { sx: {
                        py: 'var(--ds-spacing-glacial)',
                        px: 'var(--ds-spacing-pleasant)'
                    }, variant: "text", size: "medium", color: "secondary", onClick: onClear, disabled: !value, children: "Clear" })), isConfirmVisible && (_jsx(DsButton, { sx: {
                        py: 'var(--ds-spacing-glacial)',
                        px: 'var(--ds-spacing-pleasant)'
                    }, variant: "text", size: "medium", color: "secondary", onClick: onAccept, disabled: !value, children: "Confrim" }))] }));
    }
}
