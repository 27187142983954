import { createSelector } from '@reduxjs/toolkit'
export const SLICE_NAME = 'notifications'

const select = (state: any) => state[SLICE_NAME]

export const getNotificationsSelector = createSelector(
  select,
  notifications => notifications.notifications
)
export const newNotificationIndicatorSelector = createSelector(
  select,
  notifications => notifications.newNotificationIndicator
)
