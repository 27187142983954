import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import { updateOdinConnectionIndicatorAction } from './Actions'

export interface Indicators {
  orderPageSipModalIndicator: boolean
  openModifySipPopup: boolean
  sidebarIndicator: boolean
  tabIndicator: number
  showAddWithdrawIndicator: boolean
  isOdinDataReceivedIndicator: boolean
  isOdinConnectionIstablishedIndicator: boolean
  watchlistFetched: boolean
  forceRunIndexDb: number
  isSecurityMasterLoadedIndicator: boolean
  isOdinWorkerCreated: boolean
  isScreenerDataReceivedIndicator: boolean
  portfolioTabIndicator: number
  portfolioIndicator: number
  searchIndicator: boolean
  deviceName: any
  osType: any
  isModifing: boolean
  showGtdOrderDetailsModalIndicator: boolean
  showOverAllTodayPnl: boolean
  isEditingSip: boolean
  isReactivating: boolean
}
const INITIAL_STATE: Indicators = {
  orderPageSipModalIndicator: false,
  sidebarIndicator: false,
  openModifySipPopup: false,
  tabIndicator: 0,
  showAddWithdrawIndicator: false,
  isOdinDataReceivedIndicator: false,
  isOdinConnectionIstablishedIndicator: false,
  watchlistFetched: false,
  forceRunIndexDb: 0,
  isSecurityMasterLoadedIndicator: false,
  isOdinWorkerCreated: false,
  isScreenerDataReceivedIndicator: false,
  portfolioTabIndicator: 0,
  portfolioIndicator: 0,
  searchIndicator: false,
  deviceName: null,
  osType: null,
  isModifing: false,
  showGtdOrderDetailsModalIndicator: false,
  showOverAllTodayPnl: false,
  isEditingSip: false,
  isReactivating: false
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    updateOrderSipPageModalIndicator: (state, action) => {
      state.orderPageSipModalIndicator = action?.payload
    },

    updateModifySipPopup: (state, action) => {
      state.openModifySipPopup = action?.payload
    },

    updateIsEditingSip: (state, action) => {
      state.isEditingSip = action?.payload
    },
    updateIsReactivating: (state, action) => {
      state.isReactivating = action?.payload
    },

    updateModifySipOrderIndicator: (state, action) => {
      state.isModifing = action?.payload
    },

    sidebar: (state, action) => {
      state.sidebarIndicator = action?.payload.sidebarIndicator
    },
    showAddWithdraw: (state, action) => {
      state.showAddWithdrawIndicator = action?.payload.showAddWithdrawIndicator
    },
    odinDataReceive: (state, action) => {
      state.isOdinDataReceivedIndicator =
        action?.payload.isOdinDataReceivedIndicator
    },
    watchlistFetched: (state, action) => {
      state.watchlistFetched = action?.payload
    },
    screenerDataReceive: (state, action) => {
      state.isScreenerDataReceivedIndicator =
        action?.payload.isScreenerDataReceivedIndicator
    },
    forceIndexDbLoad: (state, action) => {
      state.forceRunIndexDb =
        state.forceRunIndexDb + action?.payload.forceRunIndexDb
    },
    securityMasterLoaded: (state, action) => {
      state.isSecurityMasterLoadedIndicator =
        action?.payload.isSecurityMasterLoadedIndicator
    },
    createOdinWorker: (state, action) => {
      state.isOdinWorkerCreated = action?.payload
    },
    setTabIndicator: (state, action) => {
      state.tabIndicator = action?.payload
    },
    setPortfolioTabIndicator: (state, action) => {
      state.portfolioTabIndicator = action?.payload
    },
    setPortfolioIndicator: (state, action) => {
      state.portfolioIndicator = action?.payload
    },
    setSearchIndicator: (state, action) => {
      state.searchIndicator = action?.payload
    },
    setDeviceDetails: (state, action) => {
      const { osType, deviceName } = action.payload
      state.deviceName = deviceName
      state.osType = osType
    },
    toggleGtdOrderDetailModal: (state, action) => {
      state.showGtdOrderDetailsModalIndicator =
        !state.showGtdOrderDetailsModalIndicator
    },
    toggleOverallTodayPnl: (state, action) => {
      state.showOverAllTodayPnl = !state.showOverAllTodayPnl
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      updateOdinConnectionIndicatorAction,
      (state, { payload }) => {
        state.isOdinConnectionIstablishedIndicator = payload
      }
    )
  }
}

const indicatorSlice = createSlice(sliceOptions)

export const {
  updateOrderSipPageModalIndicator,
  updateModifySipPopup,
  sidebar,
  showAddWithdraw,
  odinDataReceive,
  odinConnection,
  forceIndexDbLoad,
  securityMasterLoaded,
  screenerDataReceive,
  setTabIndicator,
  watchlistFetched,
  createOdinWorker,
  setPortfolioTabIndicator,
  setPortfolioIndicator,
  setSearchIndicator,
  setDeviceDetails,
  updateModifySipOrderIndicator,
  toggleGtdOrderDetailModal,
  toggleOverallTodayPnl,
  updateIsEditingSip,
  updateIsReactivating
} = indicatorSlice.actions

export default indicatorSlice.reducer
