/**
 * Constant which has all keys that can be set in the WebHttpContext Map.
 */
export const WEB_HTTP_CONTEXT = {
    SESSION_ID: 'SESSION_ID',
    API_KEY: 'API_KEY',
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    PUBLIC_KEY: 'PUBLIC_KEY',
    CLIENT_ID: 'CLIENT_ID',
    AUTHENTICATION_TOKEN_KEY: 'AUTHENTICATION_TOKEN_KEY'
};
