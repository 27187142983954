import { jsx as _jsx } from "react/jsx-runtime";
import { PureComponent } from 'react';
import Switch from '@mui/material/Switch';
export class DsToggle extends PureComponent {
    handleChange = (event) => {
        const { onChange } = this.props;
        const { name, checked } = event.target;
        if (typeof onChange === 'function') {
            onChange(name, checked);
        }
    };
    render() {
        const { value, onChange, ...restProps } = this.props;
        return (_jsx(Switch, { ...restProps, color: "secondary", checked: value, onChange: this.handleChange }));
    }
}
