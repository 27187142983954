import {
  I_EQUITY_SCRIPT_STRUCTURE,
  T_FLATTEN_EQUITY_ARRAY,
  T_FLATTEN_UNDERLYING_ARRAY,
  T_FLATTEN_DERIVATIVES_INNER_ARRAY,
  I_DERIVATIVES_SCRIPT_STRUCTURE,
  I_SEARCH_EQUITY_SCRIPT_STRUCTURE,
  I_SEARCH_DERIVATIVES_SCRIPT_STRUCTURE,
  I_UNDERLYING_SCRIPT_STRUCTURE
} from '~/src/Constants/SEC_MASTER'

const SEG_FUNC_MAP = {
  NSE_EQ_EQUITY: mapEquitySegment,
  BSE_EQ_EQUITY: mapEquitySegment,
  NSE_FO_FUTSTK: mapDerivativeSegment,
  NSE_FO_FUTIDX: mapDerivativeSegment,
  NSE_FO_OPTIDX: mapDerivativeSegment,
  NSE_FO_OPTSTK: mapDerivativeSegment,
  NSE_CURR_FUTCUR: mapDerivativeSegment,
  NSE_CURR_OPTCUR: mapDerivativeSegment,
  MCX_COMM_OPTFUT: mapDerivativeSegment,
  MCX_COMM_FUTCOM: mapDerivativeSegment,
  NCDEX_COMM_OPTFUT: mapDerivativeSegment,
  NCDEX_COMM_FUTCOM: mapDerivativeSegment,
  NSE_CURR_UNDERLYING: mapUnderlyingSegment,
  MCX_COMM_UNDERLYING: mapUnderlyingSegment,
  NCDEX_COMM_UNDERLYING: mapUnderlyingSegment,
  NSE_EQ_UNDERLYING: mapUnderlyingSegment,
  BSE_EQ_UNDERLYING: mapUnderlyingSegment
}

export default SEG_FUNC_MAP

interface IMapSegmentOptions {
  getSearchableStructure: boolean
}

const DEFAULT_VALUES_MAP_SEGMENT_OPTIONS = {
  getSearchableStructure: false
}

export function mapEquitySegment(
  data: T_FLATTEN_EQUITY_ARRAY,
  options: IMapSegmentOptions = DEFAULT_VALUES_MAP_SEGMENT_OPTIONS
) {
  const [
    scriptId,
    odinTokenId,
    exchangeSecurityId,
    aslAllowed,
    exchangeSymbol,
    exchangeSeries,
    isinCode,
    coname,
    lotsize,
    ticksize,
    nriAllowed,
    closeprice,
    assetClass,
    searchable,
    searchPriority,
    yesterdayOpenInt,
    maxSingleOrderQty,
    _,
    asm_flag,
    ODIN_LLFC_SEGMENTID,
    CMOTS_COCODE
  ] = data

  if (options.getSearchableStructure) {
    const script: I_SEARCH_EQUITY_SCRIPT_STRUCTURE = {
      scriptId,
      odinTokenId,
      aslAllowed,
      coname,
      nriAllowed,
      searchPriority,
      exchangeSymbol,
      assetClass
    }
    return script
  }

  const script: I_EQUITY_SCRIPT_STRUCTURE = {
    scriptId,
    odinTokenId,
    exchangeSecurityId,
    aslAllowed,
    exchangeSymbol,
    exchangeSeries,
    isinCode,
    coname,
    lotsize,
    ticksize,
    nriAllowed,
    closeprice,
    assetClass,
    searchable,
    searchPriority,
    yesterdayOpenInt,
    maxSingleOrderQty,
    asm_flag,
    ODIN_LLFC_SEGMENTID,
    CMOTS_COCODE
  }

  return script
}

export function mapDerivativeSegment(
  data: T_FLATTEN_DERIVATIVES_INNER_ARRAY,
  options: IMapSegmentOptions = DEFAULT_VALUES_MAP_SEGMENT_OPTIONS
) {
  const [
    scriptId,
    odinTokenId,
    exchangeSecurityId,
    aslAllowed,
    coname,
    expiryDate,
    strikePrice,
    optionType,
    lotsize,
    ticksize,
    nriAllowed,
    closeprice,
    searchable,
    searchPriority,
    yesterdayOpenInt,
    maxSingleOrderQty,
    _,
    asm_flag,
    ODIN_LLFC_SEGMENTID,
    CMOTS_COCODE
  ] = data

  if (options.getSearchableStructure) {
    const script: I_SEARCH_DERIVATIVES_SCRIPT_STRUCTURE = {
      scriptId,
      odinTokenId,
      aslAllowed,
      coname,
      nriAllowed,
      searchPriority
    }

    return script
  }

  const script: I_DERIVATIVES_SCRIPT_STRUCTURE = {
    scriptId,
    odinTokenId,
    exchangeSecurityId,
    aslAllowed,
    expiryDate,
    strikePrice,
    optionType,
    coname,
    lotsize,
    ticksize,
    nriAllowed,
    closeprice,
    searchable,
    searchPriority,
    yesterdayOpenInt,
    maxSingleOrderQty,
    asm_flag,
    ODIN_LLFC_SEGMENTID,
    CMOTS_COCODE
  }

  return script
}

export function mapUnderlyingSegment(
  data: T_FLATTEN_UNDERLYING_ARRAY,
  options: IMapSegmentOptions = DEFAULT_VALUES_MAP_SEGMENT_OPTIONS
) {
  const [
    scriptId,
    odinTokenId,
    exchangeSecurityId,
    aslAllowed,
    exchangeSymbol,
    coname,
    lotsize,
    ticksize,
    nriAllowed,
    closeprice,
    assetClass,
    searchable,
    searchPriority,
    yesterdayOpenInt,
    _,
    asm_flag,
    ODIN_LLFC_SEGMENTID,
    CMOTS_COCODE
  ] = data

  const script: I_UNDERLYING_SCRIPT_STRUCTURE = {
    scriptId,
    odinTokenId,
    exchangeSecurityId,
    aslAllowed,
    exchangeSymbol,
    coname,
    lotsize,
    ticksize,
    nriAllowed,
    closeprice,
    assetClass,
    searchable,
    searchPriority,
    yesterdayOpenInt,
    asm_flag,
    ODIN_LLFC_SEGMENTID,
    CMOTS_COCODE
  }

  return script
}

export function mapSymbolData(data: any) {
  const [exchangeSymbol, ltp, assetClass] = data
  return { exchangeSymbol, ltp, assetClass }
}

export function isNonTradableStock(aslAllowed: string) {
  return aslAllowed === 'S'
}
