
import { asHttp } from "~/src/Configurations/WebHttp";

import { getRecentSearchActions } from "../Actions";

import { getSubAccountIdFromRedux } from "~/src/Utils/global";
import serviceActionCreator from "~/src/Redux/serviceActionCreatorWithTokenRotation";


async function getRecentSearchService() {
  const options = {
    url: `search/get-all`,
    method: "GET",
    headers: {
      "X-SubAccountID": getSubAccountIdFromRedux(),
    }
  };

  const response = await asHttp.request(options);
  const { data: body } = response;
  const { data } = body;
  return data;
}

const getRecentSearchConfigAction = serviceActionCreator(
  getRecentSearchActions,
  getRecentSearchService
);

export default getRecentSearchConfigAction;

