export const WORKER_CONSTANTS_OPTIONS = {
  init: 'INITIALIZE',
  start: 'START',
  search: 'SEARCH',
  terminmate: 'TERMINATE'
}

export interface WORKER_CONSTANTS_OPTIONS {
  init: string
  start: string
  search: string
  terminmate: string
}
