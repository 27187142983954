const breakpoints = {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
        xs: 0,
        sm: 414,
        md: 744,
        lg: 1280,
        xl: 1440
    }
};
export default breakpoints;
