import { asHttp } from '~/src/Configurations/WebHttp'

import { createSipOrderActions } from '../actions'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export type T_GET_CREATE_SIP_PAYLOAD = {
  segment: string
  basketName: string
  sipFrequency: string
  sipPeriod: string
  sipType: string
  startDate: string
  securities: any
}

async function createSipOrder(payload: T_GET_CREATE_SIP_PAYLOAD) {
  const options = {
    url: '/trading/sip/create',
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const createSipOrderAction = serviceActionCreator(
  createSipOrderActions,
  createSipOrder
)

export default createSipOrderAction
