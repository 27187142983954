import { DsSkeleton, DsStack } from "@am92/react-design-system";
import React, { PureComponent, ReactNode } from "react";

class DashboardSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <>
        <DsStack spacing={"var(--ds-spacing-warm)"}>
          <DsStack spacing={"var(--ds-spacing-cool)"}>
            <DsSkeleton variant="text" sx={{ height: "40px" }} />
            <DsStack sx={{ flexDirection: "row", gap: "40px", width: "100%", justifyContent: "space-between" }}>
              <DsStack sx={{ width: "100%" }} spacing={"var(--ds-spacing-frostbite)"}>
                <DsSkeleton variant="text" sx={{ width: "40%" }} />
                <DsStack sx={{ flexDirection: "row", gap: "12px", width: "100%" }}>
                  <DsSkeleton variant="rounded" sx={{ flexBasis: "50%", height: "80px" }} />
                  <DsSkeleton variant="rounded" sx={{ flexBasis: "50%", height: "80px" }} />
                </DsStack>
              </DsStack>
              <DsStack sx={{ width: "100%" }} spacing={"var(--ds-spacing-frostbite)"}>
                <DsSkeleton variant="text" sx={{ width: "40%" }} />
                <DsStack sx={{ flexDirection: "row", gap: "12px", width: "100%" }}>
                  <DsSkeleton variant="rounded" sx={{ flexBasis: "50%", height: "80px" }} />
                  <DsSkeleton variant="rounded" sx={{ flexBasis: "50%", height: "80px" }} />
                </DsStack>
              </DsStack>
            </DsStack>
          </DsStack>
          {/* PORTFOLIO */}
          <DsStack sx={{ gap: "12px", width: "100%", justifyContent: "space-between" }}>
            <DsSkeleton variant="text" sx={{ width: "120px" }} />
            <DsStack sx={{ flexDirection: "row", gap: "40px", width: "100%" }}>
              <DsSkeleton variant="rounded" sx={{ height: "180px", width: "100%" }} />
              <DsSkeleton variant="rounded" sx={{ height: "180px", width: "100%" }} />
            </DsStack>
          </DsStack>
          {/* SCREENERS */}
          <DsStack sx={{ gap: "12px", width: "100%", justifyContent: "space-between" }}>
            <DsSkeleton variant="text" sx={{ width: "120px" }} />
            <DsStack sx={{ flexDirection: "row", gap: "40px", width: "100%" }}>
              <DsSkeleton variant="rounded" sx={{ height: "180px", width: "100%" }} />
              <DsSkeleton variant="rounded" sx={{ height: "180px", width: "100%" }} />
            </DsStack>
          </DsStack>
        </DsStack>
      </>
    )
  }
}

export default DashboardSkeleton