import React from 'react'
import { RouteObject, createBrowserRouter } from 'react-router-dom'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import AppStore from './AppStore'
import AuthenticationLayout from '~/src/Layouts/Authentication.Layout'
import { ErrorBoundary } from '../Components/ErrorBoundary/ErrorBoundary'

import DashboardSkeleton from '~/src/Pages/Dashboard/Components/Dashboard.Skeleton'
import QuotesSkeleton from '~/src/Pages/Quotes/Components/Quotes.Skeleton'
import OrderbookSkeleton from '~/src/Pages/Orderbook/Components/Orderbook.Skeleton'
import PortfolioSkeleton from '../Pages/Portfolio/Components/Portfolio.Skeleton'
import FundsSkeleton from '../Pages/FundsDashboard/Components/Funds.Skeleton'
import ResearchSkeleton from '../Pages/Research/Components/ResearchSkeleton'
import ProfileSkeleton from '../Pages/Profile/Components/ProfileSkeleton'
import PledgeSkeleton from '../Pages/PledgeUnpledge/Components/PledgeSkeleton'
import BankDetailsSkeleton from '../Pages/BankDetails/Components/BankDetailsSkeleton'
import PartnersSkeleton from '../Pages/Partners/Components/PartnersSkeleton'
import ReportsSkeleton from '../Pages/Reports/Components/ReportsSkeleton'
import ExchangeMessageSkeleton from '../Pages/ExchangeMessages/Components/ExchangeMessageSkeleton'
import MarketStatusSkeleton from '../Pages/MarketStatus/Components/MarketStatusSkeleton'
import NotiStackWrappedMainLayout from '../NotiStackProvider'

const ChartsFull = React.lazy(
  () => import('~/src/Components/ChartIq/ChartsFull')
)

const SipTerms = React.lazy(
  () => import('~/src/Components/SipModal/Components/SipTerms')
)
const BnplTerms = React.lazy(() => import('~/src/Components/Bnpl/BnplTerms'))

const BankDetailsPage = React.lazy(
  () => import('~/src/Pages/BankDetails/BankDetails.Page')
)

const DashboardNewsPage = React.lazy(
  () => import('~/src/Pages/DashboardNews/DashboardNews.Page')
)
const PledgeLayout = React.lazy(
  () => import('~/src/Pages/PledgeUnpledge/PledgeLayout')
)

const OrderbookPage = React.lazy(
  () => import('~/src/Pages/Orderbook/Orderbook.Page')
)

const QuotesPage = React.lazy(() => import('~/src/Pages/Quotes/Quotes.Page'))

const DashboardPage = React.lazy(
  () => import('~/src/Pages/Dashboard/Dashboard.Page')
)

const PledgeHistory = React.lazy(
  () => import('~/src/Pages/PledgeHistory/PledgeHistory.Page')
)

const ProfileMenuPage = React.lazy(
  () => import('~/src/Pages/ProfileMenu/ProfileMenu.Page')
)

const Notifications = React.lazy(
  () => import('~/src/Pages/Notifications/Notifications.Page')
)

const ProfilePage = React.lazy(
  () => import('~/src/Pages/Profile/Components/Profile')
)

const ReportsPage = React.lazy(() => import('~/src/Pages/Reports/Reports.Page'))

const ExchangeMessages = React.lazy(
  () => import('~/src/Pages/ExchangeMessages/ExchangeMessages.Page')
)

const MarketStatus = React.lazy(
  () => import('~/src/Pages/MarketStatus/MarketStatus.Page')
)

const NotFoundPage = React.lazy(
  () => import('~/src/Pages/NotFound/NotFound.Page')
)

const FundsDashboard = React.lazy(
  () => import('~/src/Pages/FundsDashboard/FundsDashboard.Page')
)

const AuthenticateSSO = React.lazy(
  () => import('~/src/Pages/AuthenticateSSO/AuthenticateSSO.Page')
)

const ValidateSSO = React.lazy(
  () => import('~/src/Pages/ValidateSSO/ValidateSSO.Page')
)

const SomethingWentWrong = React.lazy(
  () => import('~/src/Pages/SomethingWentWrong/SomethingWentWrong.Page')
)

const MobileWatchlist = React.lazy(
  () => import('~/src/Components/Sidebar/Sidebar')
)

const Login = React.lazy(() => import('~/src/Pages/Login/Login.Page'))

const MobileEditScripts = React.lazy(
  () => import('~/src/Components/Sidebar/Components/EditScriptsMobile')
)

const MobileSearchStocks = React.lazy(
  () => import('~/src/Components/Sidebar/Components/MobileSearch')
)
const Alerts = React.lazy(() => import('~/src/Pages/Alerts/Alerts.Page'))

const CustomerSupportSomethingWentWrongErrorContainer = React.lazy(
  () =>
    import(
      '~/src/Components/CustomerSupportSomethingWentWrongError/CustomerSupportSomethingWentWrongError.Container'
    )
)

const LimitStatement = React.lazy(
  () => import('~/src/Pages/LimitStatement/LimitStatement.Page')
)

const PledgeUnpledgePage = React.lazy(
  () => import('~/src/Pages/PledgeUnpledge/PledgeUnpledge.Page')
)
const StockPortfolioPage = React.lazy(
  () => import('~/src/Pages/Portfolio/StockPortfolio.Page')
)

const MultiplePortfolioDashboardPage = React.lazy(
  () => import('~/src/Pages/Portfolio/MultiplePortfolioDashboard.Page')
)

const MutualFundPortfolioPage = React.lazy(
  () => import('~/src/Pages/Portfolio/MutualFundPortfolio.Page')
)
const RecommendationOverview = React.lazy(
  () => import('~/src/Pages/RecommendationOverview/RecommendationOverview.Page')
)

const PositionsSqOffPage = React.lazy(
  () =>
    import(
      '~/src/Pages/Portfolio/Components/Positions/PositionsBulkSquare.Page'
    )
)

const ResearchPage = React.lazy(
  () => import('~/src/Pages/Research/Research.Page')
)

const RecommendationSearch = React.lazy(
  () => import('~/src/Pages/Research/Recommendations/RecommendationSearch.Page')
)

const ScreenersPage = React.lazy(
  () => import('~/src/Pages/Screeners/Screeners.Page')
)

const SessionExpiredPage = React.lazy(
  () => import('~/src/Pages/SessionExpired/SessionExpired.Page')
)

const ErrorBoundaryClass = React.lazy(
  () => import('~/src/Components/ErrorBoundary/ErrorBoundaryClass')
)

const PartnersInfo = React.lazy(
  () => import('~/src/Pages/Partners/Partners.Pages')
)

const Deeplink = React.lazy(() => import('~/src/Components/Deeplink/Deeplink'))

const ResearchCategory = React.lazy(
  () => import('~/src/Pages/Research/Recommendations/ResearchCategory.Page')
)

const isUserAuthenticated = () => {
  const { isAuthenticated } = AppStore.getState().sso
  if (!isAuthenticated) {
    throw new Response('Not Found', { status: 401 })
    // throw { status: 401 }
  }
  return {}
}

const routeObj: RouteObject[] = [
  {
    element: <NotiStackWrappedMainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.DASHBOARD.pathname,
        element: (
          <>
            <React.Suspense fallback={<DashboardSkeleton />}>
              <DashboardPage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.QUOTES.pathname,
        element: (
          <>
            <React.Suspense fallback={<QuotesSkeleton />}>
              <QuotesPage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.RECOMMENDATION_OVERVIEW.pathname,
        element: <RecommendationOverview />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.PLEDGE.pathname,
        element: (
          <>
            <React.Suspense fallback={<PledgeSkeleton />}>
              <PledgeUnpledgePage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.PLEDGEHISTORY.pathname,
        element: <PledgeHistory />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.PROFILEMENU.pathname,
        element: <ProfileMenuPage />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.PROFILE.pathname,
        element: (
          <>
            <React.Suspense fallback={<ProfileSkeleton />}>
              <ProfilePage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.NOTIFICATION.pathname,
        element: <Notifications />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.REPORTS.pathname,
        element: (
          <>
            <React.Suspense fallback={<ReportsSkeleton />}>
              <ReportsPage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.BANKDETAILS.pathname,
        element: (
          <>
            <React.Suspense fallback={<BankDetailsSkeleton />}>
              <BankDetailsPage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.EXCHANGEMESSAGES.pathname,
        element: (
          <>
            <React.Suspense fallback={<ExchangeMessageSkeleton />}>
              <ExchangeMessages />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.MARKETSTATUS.pathname,
        element: (
          <>
            <React.Suspense fallback={<MarketStatusSkeleton />}>
              <MarketStatus />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.FUNDSDASHBOARD.pathname,
        element: (
          <>
            <React.Suspense fallback={<FundsSkeleton />}>
              <FundsDashboard />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.ORDERBOOK.pathname,
        element: (
          <>
            <React.Suspense fallback={<OrderbookSkeleton />}>
              <OrderbookPage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.MOBILE_WATCHLIST.pathname,
        element: <MobileWatchlist />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.MOBILE_EDIT_WATCHLIST.pathname,
        element: <MobileEditScripts />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.MOBILE_SEARCH.pathname,
        element: <MobileSearchStocks />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.CUSTOMER_SUPPORT.pathname,
        element: <CustomerSupportSomethingWentWrongErrorContainer />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.MULTIPLE_PORTFOLIO.pathname,
        element: (
          <>
            <React.Suspense fallback={<PortfolioSkeleton />}>
              <MultiplePortfolioDashboardPage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.STOCK_PORTFOLIO.pathname,
        element: (
          <>
            <React.Suspense fallback={<PortfolioSkeleton />}>
              <StockPortfolioPage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.MUTUAL_FUND_PORTFOLIO.pathname,
        element: (
          <>
            <React.Suspense fallback={<PortfolioSkeleton />}>
              <MutualFundPortfolioPage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.POSITION_SQ_OFF.pathname,
        element: <PositionsSqOffPage />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.LIMIT_STATEMENT.pathname,
        element: <LimitStatement />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.ALERTS.pathname,
        element: <Alerts />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.RESEARCH.pathname,
        element: (
          <>
            <React.Suspense fallback={<ResearchSkeleton />}>
              <ResearchPage />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.RESEARCH_CATEGORY.pathname,
        element: <ResearchCategory />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.SCREENERS.pathname,
        element: <ScreenersPage />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.DASHBOARD_NEWS.pathname,
        element: <DashboardNewsPage />,
        loader: isUserAuthenticated
      },
      {
        path: APP_ROUTES.PARTNERS_INFO.pathname,
        element: (
          <>
            <React.Suspense fallback={<PartnersSkeleton />}>
              <PartnersInfo />
            </React.Suspense>
          </>
        ),
        loader: isUserAuthenticated
      }
    ]
  } as RouteObject,
  {
    element: <AuthenticationLayout />,
    errorElement: <ErrorBoundaryClass />,
    children: [
      {
        path: APP_ROUTES.AUTHENTICATE_SSO.pathname,
        element: <AuthenticateSSO />
      },
      {
        path: APP_ROUTES.VALIDATE_SSO.pathname,
        element: <ValidateSSO />
      },
      {
        path: APP_ROUTES.SOMETHING_WENT_WRONG.pathname,
        element: <SomethingWentWrong />
      },
      {
        path: APP_ROUTES.SESSION_EXPIRED.pathname,
        element: <SessionExpiredPage />
      }
    ]
  } as RouteObject,
  {
    path: APP_ROUTES.LOGIN.pathname,
    element: <Login />
  } as RouteObject,
  {
    path: APP_ROUTES.CHART.pathname,
    element: <ChartsFull />
  } as RouteObject,
  {
    path: APP_ROUTES.SIP_TERMS.pathname,
    element: <SipTerms />
  } as RouteObject,
  {
    path: APP_ROUTES.BNPL_TERMS.pathname,
    element: <BnplTerms />
  } as RouteObject,
  {
    path: APP_ROUTES.RECOMMENDATION_SEARCH.pathname,
    element: <RecommendationSearch />
  } as RouteObject,
  {
    path: APP_ROUTES.ANY.pathname,
    element: <Deeplink />,
    errorElement: <ErrorBoundary />,
    loader: isUserAuthenticated
  } as RouteObject
]

const getAppRouter = () => createBrowserRouter(routeObj)

export default getAppRouter
