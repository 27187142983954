import { asHttp } from "~/src/Configurations/WebHttp";

import { createRecentSearchActions } from "../Actions";

import { getSubAccountIdFromRedux } from "~/src/Utils/global";
import serviceActionCreator from "~/src/Redux/serviceActionCreatorWithTokenRotation";


export interface ICreateRecentSearchPayload {
  scriptId: string

}

async function createRecentSearchConfigService(payload: any) {
  const options = {
    url: `search/create`,
    method: "POST",
    data: payload,
    headers: {
      "x-SubAccountId": getSubAccountIdFromRedux(),
    }
  };

  const response = await asHttp.request(options);
  const { data: body } = response;
  const { data } = body;

  return data;
}

const createRecentSearchConfigAction = serviceActionCreator(
  createRecentSearchActions,
  createRecentSearchConfigService
);

export default createRecentSearchConfigAction;
