import React, { Component } from 'react'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import StockTicker from '~/src/Components/Sidebar/Components/StockTicker'

interface IQuotesStockData {
  scriptId: string
  stockDetails: T_SCRIPTDATA
  showNetChange?: any
  type?: any
  withCurrencySymbol?: boolean
  ltpTypoVariant?: string
}

class QuotesStockData extends Component<IQuotesStockData> {
  state = {}

  render() {
    const { stockDetails = {}, scriptId, showNetChange, type, withCurrencySymbol, ltpTypoVariant = "headingBoldExtraSmall", path = '' }: any = this.props
    const { closeprice, segment } = stockDetails || {}

    return (
      <StockTicker
        scriptId={scriptId}
        closeprice={closeprice}
        netChangeTypoVariant='bodyRegularSmall'
        segment={segment}
        path={path}
        ltpTypoVariant={ltpTypoVariant}
        showNetChange={showNetChange}
        type={type}
        withCurrencySymbol={withCurrencySymbol}

      />
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const stockDetails = getStockMasterData(scriptId)(state)
  return {
    stockDetails
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(QuotesStockData)
