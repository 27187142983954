import { asHttp } from '~/src/Configurations/WebHttp'

import { placeOrderActions } from '../actions'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export type T_PLACE_ORDER_PAYLOAD = {
  orderRefId: string
  segment: string
  exchange: string
  scriptId: string
  transactionType: string
  productType: string
  orderType: string
  totalQty: number
  disclosedQty: number
  orderPrice: number
  triggerPrice: number
  validityType: string
  validityDays: number
  isAmo: boolean
}

async function placeOrder(payload: T_PLACE_ORDER_PAYLOAD) {
  const options = {
    url: `/trading/orders/place-order`,
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const placeOrderAction = serviceActionCreator(placeOrderActions, placeOrder)

export default placeOrderAction
