import {
  DsTypography,
  DsStack,
  DsSkeleton,
  DsRemixIcon
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { tickerContext } from '~/src/Configurations/TickerStore'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'
import { calculateNetChange, getCurrencyFormat } from '~/src/Utils/global'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'

interface IStockTickerOnHeader {
  scriptId: string
  closeprice: number
  netChangeTypoVariant?: string
  showNetChange?: boolean
  showLTP?: boolean
  type?: string
  segment: string
  stockDetails: T_SCRIPTDATA
  stockPriceDetails: ITicker
}

class StockTickerOnHeader extends Component<IStockTickerOnHeader, any> {
  odinWorkerManager = null as any
  constructor(props: IStockTickerOnHeader) {
    super(props)
    const { stockPriceDetails, closeprice, segment } = props
    const {
      NET_CHANGE = '',
      PERCENT_CHANGE = '',
      LTP = 0
    } = (stockPriceDetails as ITicker) || {}

    this.state = {
      NET_CHANGE: NET_CHANGE
        ? NET_CHANGE === '+0.00'
          ? calculateNetChange(LTP, closeprice, segment)[0]
          : Number(NET_CHANGE).toFixed(segment === 'CURR' ? 4 : 2)
        : '',
      PERCENT_CHANGE: PERCENT_CHANGE
        ? NET_CHANGE === '+0.00'
          ? calculateNetChange(LTP, closeprice, segment)[1]
          : PERCENT_CHANGE
        : ''
    }
  }

  componentDidUpdate(prevProps: Readonly<IStockTickerOnHeader>): void {
    const { stockPriceDetails, closeprice, scriptId, segment } = this.props
    if (prevProps.scriptId !== scriptId) {
      this.odinWorkerManager.unSubscribeStocks([
        { scriptId: prevProps.scriptId }
      ])
      this.odinWorkerManager.subscribeStocks([{ scriptId: scriptId }])
    }
    if (
      prevProps.stockPriceDetails !== stockPriceDetails &&
      stockPriceDetails
    ) {
      if (stockPriceDetails.NET_CHANGE === '+0.00') {
        const [netChange, percentChange] = calculateNetChange(
          stockPriceDetails.LTP,
          closeprice,
          segment
        )
        this.setState({
          NET_CHANGE: netChange,
          PERCENT_CHANGE: percentChange
        })
        return
      }
      this.setState({
        NET_CHANGE: stockPriceDetails.NET_CHANGE,
        PERCENT_CHANGE: stockPriceDetails.PERCENT_CHANGE
      })
    }
  }

  renderLTP = () => {
    const {
      stockPriceDetails = {},
      closeprice,
      type = '',
      segment
    }: any = this.props
    const { LTP } = stockPriceDetails || {}
    const optionLtp = type === 'option'
    const options = { minimumFractionDigits: segment === 'CURR' ? 4 : 2 }
    return (
      <DsTypography
        variant='subheadingSemiboldDefault'
        sx={{
          color: 'var(--ds-colour-surfaceBackground)'
        }}
      >
        {LTP === null || LTP === undefined ? (
          closeprice ? (
            `${getCurrencyFormat(closeprice, options, true)}`
          ) : (
            <DsSkeleton
              variant='text'
              width={60}
              sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
            />
          )
        ) : optionLtp ? (
          getCurrencyFormat(LTP, options, true)
        ) : (
          `${getCurrencyFormat(LTP, options, true)}`
        )}
      </DsTypography>
    )
  }

  renderNetChange = () => {
    const { NET_CHANGE, PERCENT_CHANGE } = this.state
    const { type = '' }: any = this.props
    if (type === 'option') {
      return (
        <DsTypography
          align='center'
          variant='subheadingSemiboldDefault'
          color={'var(--ds-colour-surfaceBackground)'}
          sx={{ textAlign: 'center' }}
        >
          {PERCENT_CHANGE}
        </DsTypography>
      )
    }

    if (type === 'changePer') {
      return (
        <>
          <DsTypography
            align='center'
            color='var(--ds-colour-surfaceBackground)'
            variant='subheadingSemiboldDefault'
          >
            {`(${PERCENT_CHANGE?.slice(1)}%)`}
          </DsTypography>
          <DsRemixIcon
            className={`ri-arrow-${
              Number(NET_CHANGE) > 0 ? 'up' : 'down'
            }-fill`}
            fontSize='bitterCold'
            sx={{
              display: 'flex',
              marginLeft: 'var(--ds-spacing-deepFreeze)',
              color: 'var(--ds-colour-surfaceBackground)'
            }}
          />
        </>
      )
    }

    return (
      <>
        <DsTypography
          align='center'
          color='var(--ds-colour-surfaceBackground)'
          variant='subheadingSemiboldDefault'
        >
          {`${NET_CHANGE} (${PERCENT_CHANGE?.slice(1)}%)`}
        </DsTypography>
        <DsRemixIcon
          className={`ri-arrow-${Number(NET_CHANGE) > 0 ? 'up' : 'down'}-fill`}
          fontSize='bitterCold'
          sx={{
            display: 'flex',
            marginLeft: 'var(--ds-spacing-deepFreeze)',
            color: 'var(--ds-colour-surfaceBackground)'
          }}
        />
      </>
    )
  }

  render() {
    const { NET_CHANGE } = this.state
    const { showNetChange = true, showLTP = true }: any = this.props

    return (
      <>
        {showLTP ? this.renderLTP() : null}

        {showNetChange ? (
          <DsStack
            sx={{
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            {NET_CHANGE ? (
              this.renderNetChange()
            ) : (
              <DsSkeleton
                variant='text'
                width={80}
                sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
              />
            )}
          </DsStack>
        ) : null}
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const stockPriceDetails = getTickerData(scriptId)(state)
  return {
    stockPriceDetails
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(StockTickerOnHeader)
