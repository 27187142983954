import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './selectors'
import {
  getMultiplePortfolioActions,
  getMutualFundPortfolioActions
} from './actions'
import localforage from 'localforage'
import { DEFAULT_VALUES } from '../Dashboard/Reducer'
import { MUTUAL_FUND_FILTER_BY_MAPPER } from '~/src/Pages/Portfolio/Components/MutualFund/MutualFund.constants'
import { sortBy } from 'lodash'

export type TMultiplePortfolioSummary = {
  totalInvestedAmount: number
  totalMarketValue: number
  totalUnrealizedGain: number
  totalUnrealizedGainPerc: number
  totalDayGain: number
  totalDayGainPerc: number
}

export type TSinglePortfolioData = {
  clientCode: string
  subAccountName: string
  holdingType: string
  investment: number
  marketValue: number
  unrealizedGain: number
  unrealizedGainPerc: number
  dayGain: number
  dayGainPerc: number
  prevCloseValue: number
  asOnDate: string
}

export interface IMutualFundPortfolioSummary
  extends TMultiplePortfolioSummary {}

export interface ISingleMutualFundPortfolioData
  extends Omit<TSinglePortfolioData, 'clientCode' | 'subAccountName'> {
  mutualFundName: string
  holdingQuantity: string
  averagePrice: string
  livePrice: string
}

export interface IMultiplePortfolioData {
  portfolioSummary: TMultiplePortfolioSummary | null
  portfolio: TSinglePortfolioData[] | null
}

export interface IMutualFundPortfolioData {
  portfolioSummary: IMutualFundPortfolioSummary | null
  portfolio: ISingleMutualFundPortfolioData[] | null
}

export type TMultiplePortFolioSection = {
  title: string
  filter: string
  filterType: boolean
  gridTemplateColumns: string
}

export type TMutualFundSortAndFilter = {
  mutualFundFilter: ISingleMutualFundPortfolioData[] | []
  sortBy: string
}

export interface IPortfolioInitialState {
  multiplePortfolioData: IMultiplePortfolioData
  mutualFundPortfolioData: IMutualFundPortfolioData
  multiplePortFolioSections: TMultiplePortFolioSection[] | null
  selectedPortfolio: TSinglePortfolioData | null
  showMutualFundDisclaimer: boolean
  showMutualFundSortAndFilter: boolean
  mutualFundSortAndFilter: TMutualFundSortAndFilter
  selectedSortAndFilterOption: string
  mutualFundSortedAndFilteredData: ISingleMutualFundPortfolioData[] | null
  isDefaultFiltersEnabled: boolean
}

export const multiPortfolioMapper: {
  [key: string]: string
} = {
  'Direct Transactions-Equity': 'stocks',
  'SmartEdge-Equity': 'smartedgeplus',
  'Managed Account-Beta-Equity': 'cat3',
  'Direct Transactions-MutualFund': 'MutualFund'
}

const INITIAL_STATE: IPortfolioInitialState = {
  // multiplePortfolioData: {
  //   portfolioSummary: {
  //     totalInvestedAmount: 38947286.480000004,
  //     totalMarketValue: 43084056.75,
  //     totalUnrealizedGain: -4136770.2699999996,
  //     totalUnrealizedGainPerc: 10.621459526132304,
  //     totalDayGain: 7366.51,
  //     totalDayGainPerc: 0.017097995304260663
  //   },
  //   portfolio: [
  //     {
  //       clientCode: '111456',
  //       subAccountName: 'Direct Transactions',
  //       holdingType: 'MutualFund',
  //       investment: 318817.07,
  //       marketValue: 1060139.75,
  //       unrealizedGain: 741322.68,
  //       unrealizedGainPerc: 232.52,
  //       dayGain: 6176.51,
  //       dayGainPerc: 0.59,
  //       prevCloseValue: 1053963.24,
  //       asOnDate: '2024-07-21T18:30:00Z'
  //     },
  //     {
  //       clientCode: '165249',
  //       subAccountName: 'Direct Transactions',
  //       holdingType: 'Equity',
  //       investment: 2681816.01,
  //       marketValue: 10933040.08,
  //       unrealizedGain: 8251224.07,
  //       unrealizedGainPerc: 307.67,
  //       dayGain: 0,
  //       dayGainPerc: 0,
  //       prevCloseValue: 0,
  //       asOnDate: '2024-07-03T18:30:00Z'
  //     },
  //     {
  //       clientCode: '165249',
  //       subAccountName: 'SmartEdge',
  //       holdingType: 'Equity',
  //       investment: 2681816.01,
  //       marketValue: 10933040.08,
  //       unrealizedGain: 8251224.07,
  //       unrealizedGainPerc: 307.67,
  //       dayGain: 0,
  //       dayGainPerc: 0,
  //       prevCloseValue: 0,
  //       asOnDate: '2024-07-03T18:30:00Z'
  //     },
  //     {
  //       clientCode: '2596689',
  //       subAccountName: 'Managed Account-Beta',
  //       holdingType: 'Equity',
  //       investment: 33264837.39,
  //       marketValue: 20157836.84,
  //       unrealizedGain: -13107000.55,
  //       unrealizedGainPerc: -39.4,
  //       dayGain: 1190,
  //       dayGainPerc: 0.01,
  //       prevCloseValue: 33290,
  //       asOnDate: '2024-07-03T18:30:00Z'
  //     }
  //   ]
  // },
  // mutualFundPortfolioData: {
  //   portfolioSummary: {
  //     totalInvestedAmount: 318817.05999999994,
  //     totalMarketValue: 1056677.64,
  //     totalUnrealizedGain: 737860.57,
  //     totalUnrealizedGainPerc: 231.4369783097555,
  //     totalDayGain: -3547.3100000000004,
  //     totalDayGainPerc: -0.3357040847386532
  //   },
  //   portfolio: [
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'Aditya Birla SL Frontline Equity Fund (G)',
  //       holdingQuantity: '38.4640',
  //       averagePrice: '155.9900',
  //       livePrice: '510.35',
  //       investment: 6000,
  //       marketValue: 19630.1,
  //       unrealizedGain: 13630.1,
  //       unrealizedGainPerc: 227.17,
  //       dayGain: -30.39,
  //       dayGainPerc: -0.15,
  //       prevCloseValue: 511.14,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'AXIS Bluechip Fund (G)',
  //       holdingQuantity: '408.0740',
  //       averagePrice: '17.1538',
  //       livePrice: '58.93',
  //       investment: 7000,
  //       marketValue: 24047.8,
  //       unrealizedGain: 17047.8,
  //       unrealizedGainPerc: 243.54,
  //       dayGain: -77.53,
  //       dayGainPerc: -0.32,
  //       prevCloseValue: 59.12,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'AXIS ELSS Tax Saver Fund (IDCW)',
  //       holdingQuantity: '45.3370',
  //       averagePrice: '22.0569',
  //       livePrice: '26.8448',
  //       investment: 999.99,
  //       marketValue: 1217.06,
  //       unrealizedGain: 217.07,
  //       unrealizedGainPerc: 21.71,
  //       dayGain: -3.9,
  //       dayGainPerc: -0.32,
  //       prevCloseValue: 26.9309,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'AXIS ELSS Tax Saver Fund (IDCW)',
  //       holdingQuantity: '211.7860',
  //       averagePrice: '23.6087',
  //       livePrice: '26.8448',
  //       investment: 4999.99,
  //       marketValue: 5685.35,
  //       unrealizedGain: 685.36,
  //       unrealizedGainPerc: 13.71,
  //       dayGain: -18.23,
  //       dayGainPerc: -0.32,
  //       prevCloseValue: 26.9309,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'AXIS Equity Saver Fund (G)',
  //       holdingQuantity: '999.9900',
  //       averagePrice: '9.8400',
  //       livePrice: '21.09',
  //       investment: 9839.9,
  //       marketValue: 21089.79,
  //       unrealizedGain: 11249.89,
  //       unrealizedGainPerc: 114.33,
  //       dayGain: -50,
  //       dayGainPerc: -0.24,
  //       prevCloseValue: 21.14,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'AXIS Equity Saver Fund (G)',
  //       holdingQuantity: '2000.0000',
  //       averagePrice: '10.0000',
  //       livePrice: '21.09',
  //       investment: 20000,
  //       marketValue: 42180,
  //       unrealizedGain: 22180,
  //       unrealizedGainPerc: 110.9,
  //       dayGain: -100,
  //       dayGainPerc: -0.24,
  //       prevCloseValue: 21.14,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'CPSE ETF',
  //       holdingQuantity: '1146.0000',
  //       averagePrice: '17.4520',
  //       livePrice: '98.5905',
  //       investment: 20000,
  //       marketValue: 112984.71,
  //       unrealizedGain: 92984.71,
  //       unrealizedGainPerc: 464.92,
  //       dayGain: -1255.9,
  //       dayGainPerc: -1.1,
  //       prevCloseValue: 99.6864,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'CPSE ETF',
  //       holdingQuantity: '594.0000',
  //       averagePrice: '25.2141',
  //       livePrice: '98.5905',
  //       investment: 14977.18,
  //       marketValue: 58562.76,
  //       unrealizedGain: 43585.58,
  //       unrealizedGainPerc: 291.01,
  //       dayGain: -650.96,
  //       dayGainPerc: -1.1,
  //       prevCloseValue: 99.6864,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'DSP 3 Years Close Ended Equity Fund (G)',
  //       holdingQuantity: '2000.0000',
  //       averagePrice: '10.0000',
  //       livePrice: '14.685',
  //       investment: 20000,
  //       marketValue: 29370,
  //       unrealizedGain: 9370,
  //       unrealizedGainPerc: 46.85,
  //       dayGain: -88,
  //       dayGainPerc: -0.3,
  //       prevCloseValue: 14.729,
  //       asOnDate: '2020-04-12T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'DSP Global Allocation Fund of Fund (IDCW)',
  //       holdingQuantity: '1000.0000',
  //       averagePrice: '10.0000',
  //       livePrice: '16.2637',
  //       investment: 10000,
  //       marketValue: 16263.7,
  //       unrealizedGain: 6263.7,
  //       unrealizedGainPerc: 62.64,
  //       dayGain: 37.7,
  //       dayGainPerc: 0.23,
  //       prevCloseValue: 16.226,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'DSP Midcap Fund (IDCW)',
  //       holdingQuantity: '483.8080',
  //       averagePrice: '18.6024',
  //       livePrice: '32.476',
  //       investment: 9000,
  //       marketValue: 15712.15,
  //       unrealizedGain: 6712.15,
  //       unrealizedGainPerc: 74.58,
  //       dayGain: -32.42,
  //       dayGainPerc: -0.21,
  //       prevCloseValue: 32.543,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'DSP Midcap Fund (IDCW)',
  //       holdingQuantity: '291.1170',
  //       averagePrice: '20.6102',
  //       livePrice: '32.476',
  //       investment: 5999.98,
  //       marketValue: 9454.32,
  //       unrealizedGain: 3454.33,
  //       unrealizedGainPerc: 57.57,
  //       dayGain: -19.5,
  //       dayGainPerc: -0.21,
  //       prevCloseValue: 32.543,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'Franklin India ELSS Tax Saver Fund - Regular (IDCW)',
  //       holdingQuantity: '126.0970',
  //       averagePrice: '39.6520',
  //       livePrice: '70.1849',
  //       investment: 5000,
  //       marketValue: 8850.11,
  //       unrealizedGain: 3850.11,
  //       unrealizedGainPerc: 77,
  //       dayGain: -19.99,
  //       dayGainPerc: -0.23,
  //       prevCloseValue: 70.3434,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'Franklin India Focused Equity Fund (G)',
  //       holdingQuantity: '342.0110',
  //       averagePrice: '29.2388',
  //       livePrice: '106.3614',
  //       investment: 9999.99,
  //       marketValue: 36376.77,
  //       unrealizedGain: 26376.78,
  //       unrealizedGainPerc: 263.77,
  //       dayGain: -145.46,
  //       dayGainPerc: -0.4,
  //       prevCloseValue: 106.7867,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'Franklin India Multi-Asset Solution Fund of Funds (G)',
  //       holdingQuantity: '1000.0000',
  //       averagePrice: '10.0000',
  //       livePrice: '18.5835',
  //       investment: 10000,
  //       marketValue: 18583.5,
  //       unrealizedGain: 8583.5,
  //       unrealizedGainPerc: 85.83,
  //       dayGain: -139.3,
  //       dayGainPerc: -0.74,
  //       prevCloseValue: 18.7228,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'Franklin India Smaller Companies Fund (G)',
  //       holdingQuantity: '162.6550',
  //       averagePrice: '36.8879',
  //       livePrice: '179.665',
  //       investment: 6000.01,
  //       marketValue: 29223.41,
  //       unrealizedGain: 23223.41,
  //       unrealizedGainPerc: 387.06,
  //       dayGain: -83.95,
  //       dayGainPerc: -0.29,
  //       prevCloseValue: 180.1811,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'HDFC ELSS Tax Saver Fund (IDCW)',
  //       holdingQuantity: '294.5680',
  //       averagePrice: '50.9220',
  //       livePrice: '78.868',
  //       investment: 14999.99,
  //       marketValue: 23231.99,
  //       unrealizedGain: 8232,
  //       unrealizedGainPerc: 54.88,
  //       dayGain: -61.86,
  //       dayGainPerc: -0.27,
  //       prevCloseValue: 79.078,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'HDFC Mid-Cap Opportunities Fund (IDCW)',
  //       holdingQuantity: '232.1740',
  //       averagePrice: '25.8428',
  //       livePrice: '55.976',
  //       investment: 6000.02,
  //       marketValue: 12996.17,
  //       unrealizedGain: 6996.15,
  //       unrealizedGainPerc: 116.6,
  //       dayGain: -37.15,
  //       dayGainPerc: -0.29,
  //       prevCloseValue: 56.136,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'HSBC Business Cycles Fund (G)',
  //       holdingQuantity: '477.1450',
  //       averagePrice: '10.4790',
  //       livePrice: '41.1178',
  //       investment: 5000,
  //       marketValue: 19619.15,
  //       unrealizedGain: 14619.15,
  //       unrealizedGainPerc: 292.38,
  //       dayGain: -36.6,
  //       dayGainPerc: -0.19,
  //       prevCloseValue: 41.1945,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'HSBC Business Cycles Fund (G)',
  //       holdingQuantity: '1002.4060',
  //       averagePrice: '9.9760',
  //       livePrice: '41.1178',
  //       investment: 10000,
  //       marketValue: 41216.73,
  //       unrealizedGain: 31216.73,
  //       unrealizedGainPerc: 312.17,
  //       dayGain: -76.88,
  //       dayGainPerc: -0.19,
  //       prevCloseValue: 41.1945,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'HSBC Business Cycles Fund (G)',
  //       holdingQuantity: '1002.4060',
  //       averagePrice: '9.9760',
  //       livePrice: '41.1178',
  //       investment: 10000,
  //       marketValue: 41216.73,
  //       unrealizedGain: 31216.73,
  //       unrealizedGainPerc: 312.17,
  //       dayGain: -76.88,
  //       dayGainPerc: -0.19,
  //       prevCloseValue: 41.1945,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'HSBC Business Cycles Fund (G)',
  //       holdingQuantity: '989.8050',
  //       averagePrice: '10.1030',
  //       livePrice: '41.1178',
  //       investment: 10000,
  //       marketValue: 40698.6,
  //       unrealizedGain: 30698.6,
  //       unrealizedGainPerc: 306.99,
  //       dayGain: -75.92,
  //       dayGainPerc: -0.19,
  //       prevCloseValue: 41.1945,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'HSBC Small Cap Fund (G)',
  //       holdingQuantity: '1000.0000',
  //       averagePrice: '10.0000',
  //       livePrice: '83.941',
  //       investment: 10000,
  //       marketValue: 83941,
  //       unrealizedGain: 73941,
  //       unrealizedGainPerc: 739.41,
  //       dayGain: -72.1,
  //       dayGainPerc: -0.09,
  //       prevCloseValue: 84.0131,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'HSBC Small Cap Fund (G)',
  //       holdingQuantity: '2000.0000',
  //       averagePrice: '10.0000',
  //       livePrice: '83.941',
  //       investment: 20000,
  //       marketValue: 167882,
  //       unrealizedGain: 147882,
  //       unrealizedGainPerc: 739.41,
  //       dayGain: -144.2,
  //       dayGainPerc: -0.09,
  //       prevCloseValue: 84.0131,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'ICICI Pru Bluechip Fund (G)',
  //       holdingQuantity: '192.9760',
  //       averagePrice: '25.9100',
  //       livePrice: '105.45',
  //       investment: 5000.01,
  //       marketValue: 20349.32,
  //       unrealizedGain: 15349.31,
  //       unrealizedGainPerc: 306.99,
  //       dayGain: -67.54,
  //       dayGainPerc: -0.33,
  //       prevCloseValue: 105.8,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'ICICI Pru Capital Protection Orient. VIII-A Reg(D)',
  //       holdingQuantity: '1000.0000',
  //       averagePrice: '10.0000',
  //       livePrice: '12.3401',
  //       investment: 10000,
  //       marketValue: 12340.1,
  //       unrealizedGain: 2340.1,
  //       unrealizedGainPerc: 23.4,
  //       dayGain: 1.4,
  //       dayGainPerc: 0.01,
  //       prevCloseValue: 12.3387,
  //       asOnDate: '2018-10-15T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'ICICI Pru Capital Protection Orient. VIII-A Reg(G)',
  //       holdingQuantity: '1000.0000',
  //       averagePrice: '10.0000',
  //       livePrice: '12.3401',
  //       investment: 10000,
  //       marketValue: 12340.1,
  //       unrealizedGain: 2340.1,
  //       unrealizedGainPerc: 23.4,
  //       dayGain: 1.4,
  //       dayGainPerc: 0.01,
  //       prevCloseValue: 12.3387,
  //       asOnDate: '2018-10-15T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'ICICI Pru Dividend Yield Equity Fund-Regular(IDCW)',
  //       holdingQuantity: '2720.5570',
  //       averagePrice: '10.2920',
  //       livePrice: '22.56',
  //       investment: 27999.99,
  //       marketValue: 61375.77,
  //       unrealizedGain: 33375.77,
  //       unrealizedGainPerc: 119.2,
  //       dayGain: -108.82,
  //       dayGainPerc: -0.18,
  //       prevCloseValue: 22.6,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'ICICI Pru US Bluechip Equity Fund (G)',
  //       holdingQuantity: '303.9510',
  //       averagePrice: '16.4500',
  //       livePrice: '58.89',
  //       investment: 4999.99,
  //       marketValue: 17899.67,
  //       unrealizedGain: 12899.68,
  //       unrealizedGainPerc: 257.99,
  //       dayGain: -82.07,
  //       dayGainPerc: -0.46,
  //       prevCloseValue: 59.16,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'ICICI Pru Value Discovery Fund (G)',
  //       holdingQuantity: '50.7050',
  //       averagePrice: '98.6100',
  //       livePrice: '444.32',
  //       investment: 5000.02,
  //       marketValue: 22529.25,
  //       unrealizedGain: 17529.23,
  //       unrealizedGainPerc: 350.58,
  //       dayGain: -3.55,
  //       dayGainPerc: -0.02,
  //       prevCloseValue: 444.39,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     },
  //     {
  //       holdingType: 'MutualFund',
  //       mutualFundName: 'SBI BlueChip Fund (IDCW)',
  //       holdingQuantity: '587.1230',
  //       averagePrice: '17.0322',
  //       livePrice: '50.7722',
  //       investment: 10000,
  //       marketValue: 29809.53,
  //       unrealizedGain: 19809.53,
  //       unrealizedGainPerc: 198.1,
  //       dayGain: -28.71,
  //       dayGainPerc: -0.1,
  //       prevCloseValue: 50.8211,
  //       asOnDate: '2024-07-22T18:30:00Z'
  //     }
  //   ]
  // },
  // multiplePortFolioSections: [
  //   {
  //     title: 'Stocks Portfolios',
  //     filter: 'MutualFund',
  //     filterType: false,
  //     gridTemplateColumns: 'auto auto'
  //   },
  //   {
  //     title: 'Mutual Funds',
  //     filter: 'MutualFund',
  //     filterType: true,
  //     gridTemplateColumns: '50%'
  //   }
  // ],
  multiplePortfolioData: {
    portfolioSummary: null,
    portfolio: null
  },
  mutualFundPortfolioData: {
    portfolioSummary: null,
    portfolio: null
  },
  multiplePortFolioSections: null,
  selectedPortfolio: null,
  showMutualFundDisclaimer: false,
  showMutualFundSortAndFilter: false,
  mutualFundSortAndFilter: {
    mutualFundFilter: [],
    sortBy: 'INVESTED_HIGH_TO_LOW'
  },
  selectedSortAndFilterOption: MUTUAL_FUND_FILTER_BY_MAPPER.SORT_BY.label,
  mutualFundSortedAndFilteredData: null,
  isDefaultFiltersEnabled: true
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedPortfolio: (state, action) => {
      state.selectedPortfolio = action?.payload
    },
    toggleMutualFundDisclaimer: (state, action) => {
      state.showMutualFundDisclaimer = !state.showMutualFundDisclaimer
    },
    toggleMutualFundSortAndFilter: (state, action) => {
      state.showMutualFundSortAndFilter = !state.showMutualFundSortAndFilter
    },
    mutualFundSortAndFilter: (state, action) => {
      state.mutualFundSortAndFilter = action?.payload
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    },
    changeSelectedSortAndFilterOption: (state, action) => {
      state.selectedSortAndFilterOption = action?.payload
    },
    resetOriginalMutualFundData: state => {
      if (!state.isDefaultFiltersEnabled) {
        state.mutualFundSortedAndFilteredData =
          state.mutualFundPortfolioData.portfolio.sort(
            (
              ITEM_A: ISingleMutualFundPortfolioData,
              ITEM_B: ISingleMutualFundPortfolioData
            ) => {
              const { investment: investedValueA } = ITEM_A
              const { investment: investedValueB } = ITEM_B
              return investedValueB - investedValueA
            }
          )
        state.mutualFundSortAndFilter = INITIAL_STATE.mutualFundSortAndFilter
        state.isDefaultFiltersEnabled = true
      }
      state.selectedSortAndFilterOption =
        INITIAL_STATE.selectedSortAndFilterOption
    },
    setSortedAndFilteredMutualFundData: (state, action) => {
      state.mutualFundSortedAndFilteredData = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getMultiplePortfolioActions.success,
      (state, { payload }) => {
        const { porfolio: portfolio, portfolioSummary } = payload
        const multiplePortFolioSections = []
        let stockPortFolioCount = 0
        let mutualFundPortFolioCount = 0
        for (let i = 0; i < portfolio?.length; i++) {
          const { holdingType } = portfolio[i]
          if (holdingType === 'Equity') {
            stockPortFolioCount++
            continue
          }
          mutualFundPortFolioCount++
        }

        // create portfolio sections
        stockPortFolioCount &&
          multiplePortFolioSections.push({
            title: 'Stocks Portfolios',
            filter: 'MutualFund',
            filterType: false,
            gridTemplateColumns: 'auto auto'
          })

        mutualFundPortFolioCount &&
          multiplePortFolioSections.push({
            title: 'Mutual Funds',
            filter: 'MutualFund',
            filterType: true,
            gridTemplateColumns: '50%'
          })

        state.multiplePortFolioSections = multiplePortFolioSections
        state.multiplePortfolioData = {
          portfolioSummary,
          portfolio
        }
      }
    )
    builder.addCase(
      getMutualFundPortfolioActions.success,
      (state, { payload }) => {
        const { portfolio } = payload
        state.mutualFundPortfolioData = payload
        const mutualFundSortedAndFilteredData = portfolio.sort(
          (
            ITEM_A: ISingleMutualFundPortfolioData,
            ITEM_B: ISingleMutualFundPortfolioData
          ) => {
            const { investment: investedValueA } = ITEM_A
            const { investment: investedValueB } = ITEM_B
            return investedValueB - investedValueA
          }
        )
        state.mutualFundSortedAndFilteredData = mutualFundSortedAndFilteredData

        // initially setting the original data in this temperory source used for sorting and filtering
      }
    )
  }
}

function checkIfDefaultFiltersAreSet(state: IPortfolioInitialState) {
  const { mutualFundSortAndFilter } = state
  const { mutualFundFilter, sortBy } = mutualFundSortAndFilter
  const noFilters = mutualFundFilter.length === 0 ? true : false
  if (sortBy === DEFAULT_VALUES.selectedSortBy && noFilters) {
    return true
  }
  return false
}

const slice = createSlice(sliceOptions)

export const {
  setSelectedPortfolio,
  toggleMutualFundDisclaimer,
  toggleMutualFundSortAndFilter,
  mutualFundSortAndFilter,
  changeSelectedSortAndFilterOption,
  resetOriginalMutualFundData,
  setSortedAndFilteredMutualFundData
} = slice.actions

export const portfolioPersistConfig = {
  key: SLICE_NAME,
  version: 1,
  storage: localforage,
  whitelist: ['selectedPortfolio']
}

export default slice.reducer
