import { createSelector } from '@reduxjs/toolkit'
import TickerStore from '~/src/Configurations/TickerStore'
import { getFilterData } from '~/src/Utils/global'
export const SLICE_NAME = 'positions'

const select = (state: any) => state[SLICE_NAME]

export const getPositionsData = createSelector(
  select,
  positions => positions?.positionsData
)

export const getOpenPositionsData = createSelector(
  select,
  positions => positions?.positionsDataOpen
)

export const isDefaultOrdersFiltersEnabled = createSelector(
  select,
  positions => positions.isDefaultFiltersEnabled
)

export const getPositionsFilter = createSelector(
  select,
  positions => positions?.positionsFilter
)

export const getPositionFilterLabel = createSelector(
  select,
  positions => positions.filterLabels
)

export const getPositionsTableDetails = (
  positionsData: any,
  positionsFilter: any
) =>
  createSelector(select, stockMaster => {
    const { productsFilter, stocksFilter, assetsFilter } = positionsFilter
    let positionFiltered = positionsData
    positionFiltered = getFilterData(positionsData, {
      product: productsFilter,
      instrument: assetsFilter,
      symbol: stocksFilter
    })

    const currentData: any = []
    positionFiltered?.forEach((object: any) => {
      const {
        netQty = 0,
        avgBuyPrice = 0,
        avgSellPrice = 0,
        netPrice = 0,
        sellQty = 0,
        buyQty = 0,
        positionSide,
        symbol,
        product,
        scriptId,
        instrument = 'equity'
      } = object
      const { coname = symbol } =
        (window as any).secMaster.getByScriptId(scriptId) || {}
      const scripName = instrument.toUpperCase() === 'EQUITY' ? symbol : coname
      const buyOrSell = positionSide === 'buy' ? 'BUY' : 'SELL'
      let quantity = Math.abs(netQty)
      let price = positionSide === 'buy' ? avgBuyPrice : avgSellPrice
      if (price <= 0) price = netPrice
      const tickerStore = TickerStore.getState()
      const { ticker = {} } = tickerStore || {}
      let { LTP, CLOSE_PRICE } = ticker?.[scriptId] || {}
      const currentVal = Number(LTP) * Number(quantity) || 0
      const investedValue = Number(price) * Number(quantity) || 0
      const pnl = Number(currentVal) - Number(investedValue) || 0
      const pnlPer = (pnl / price) * 100 || 0
      currentData.push([
        [scripName],
        [buyOrSell, `${product}`],
        LTP,
        quantity,
        price,
        pnl,
        pnlPer,
        object
      ])
    })
    return currentData
  })
