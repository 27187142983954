import {
  DsTypography,
  DsStack,
  DsSkeleton,
  DsRemixIcon,
  DsBox
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { tickerContext } from '~/src/Configurations/TickerStore'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'
import { calculateNetChange, getCurrencyFormat } from '~/src/Utils/global'
import { odinWorkerManager } from '~/src/Workers/Odin/OdinWorkerManager'

interface IStockTicker {
  scriptId: string
  closeprice: number
  stockPriceDetails: ITicker
  component?: string
  ltpTypoVariant?: string
  netChangeTypoVariant?: string
  showNetChange?: boolean
  showLTP?: boolean
  type?: string
  path?: string
  segment: string
}

class StockTicker extends Component<IStockTicker, any> {
  odinWorkerManager = null as any
  constructor(props: IStockTicker) {
    super(props)
    const { stockPriceDetails, closeprice, segment } = props
    const {
      NET_CHANGE = '',
      PERCENT_CHANGE = '',
      LTP = 0
    } = (stockPriceDetails as ITicker) || {}

    this.state = {
      NET_CHANGE: NET_CHANGE
        ? NET_CHANGE === '+0.00'
          ? calculateNetChange(LTP, closeprice, segment)[0]
          : Number(NET_CHANGE).toFixed(segment === 'CURR' ? 4 : 2)
        : '',
      PERCENT_CHANGE: PERCENT_CHANGE
        ? NET_CHANGE === '+0.00'
          ? calculateNetChange(LTP, closeprice, segment)[1]
          : PERCENT_CHANGE
        : ''
    }
  }

  componentDidMount(): void {
    const { scriptId } = this.props
    this.odinWorkerManager = odinWorkerManager
    this.odinWorkerManager.subscribeStocks([{ scriptId: scriptId }])
  }

  componentDidUpdate(prevProps: Readonly<IStockTicker>): void {
    const { stockPriceDetails, closeprice, scriptId, segment } = this.props
    if (prevProps.scriptId !== scriptId) {
      this.odinWorkerManager.unSubscribeStocks([
        { scriptId: prevProps.scriptId }
      ])
      this.odinWorkerManager.subscribeStocks([{ scriptId: scriptId }])
    }
    if (
      prevProps.stockPriceDetails !== stockPriceDetails &&
      stockPriceDetails
    ) {
      if (stockPriceDetails.NET_CHANGE === '+0.00') {
        const [netChange, percentChange] = calculateNetChange(
          stockPriceDetails.LTP,
          closeprice,
          segment
        )
        this.setState({
          NET_CHANGE: netChange,
          PERCENT_CHANGE: percentChange
        })
        return
      }
      this.setState({
        NET_CHANGE: stockPriceDetails.NET_CHANGE,
        PERCENT_CHANGE: stockPriceDetails.PERCENT_CHANGE
      })
    }
  }

  renderLTP = () => {
    const {
      stockPriceDetails = {},
      closeprice,
      ltpTypoVariant,
      type = '',
      segment,
      scriptId
    }: any = this.props
    const { LTP } = stockPriceDetails || {}
    const optionLtp = type === 'option'
    const options = { minimumFractionDigits: segment === 'CURR' ? 4 : 2 }
    return (
      <DsTypography
        variant={ltpTypoVariant ? ltpTypoVariant : 'bodyRegularSmall'}
      >
        {LTP === null || LTP === undefined || Number(LTP) === 0 ? (
          closeprice ? (
            `${getCurrencyFormat(closeprice, options)}`
          ) : (
            <DsSkeleton
              variant='text'
              width={60}
              sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
            />
          )
        ) : optionLtp ? (
          getCurrencyFormat(LTP, options)
        ) : (
          `${getCurrencyFormat(LTP, options)}`
        )}
      </DsTypography>
    )
  }

  renderNetChange = () => {
    const { NET_CHANGE, PERCENT_CHANGE } = this.state
    const {
      component = '',
      netChangeTypoVariant,
      type = '',
      segment
    }: any = this.props
    const options = { minimumFractionDigits: segment === 'CURR' ? 4 : 2 }
    const percentChangeWithSign =
      `${Number(NET_CHANGE) > 0 ? '+' : '-'}${PERCENT_CHANGE?.slice(1)}`.slice(
        1
      )
    const percentChangeWithSignOpt = `${Number(NET_CHANGE) > 0 ? '+' : '-'}${PERCENT_CHANGE?.slice(1)}`

    if (type === 'option') {
      return (
        <DsTypography
          align='center'
          variant='bodyRegularMedium'
          color={'var(--ds-colour-secondary)'}
          sx={{ textAlign: 'center' }}
        >
          {percentChangeWithSignOpt}
        </DsTypography>
      )
    }

    if (type === 'changePer') {
      return (
        <>
          <DsTypography
            align='center'
            color={
              Number(NET_CHANGE) > 0
                ? 'var(--ds-colour-iconPositive)'
                : 'var(--ds-colour-iconNegative)'
            }
            variant={
              netChangeTypoVariant ? netChangeTypoVariant : 'supportRegularInfo'
            }
          >
            {`${PERCENT_CHANGE?.slice(1)}%`}
          </DsTypography>
          <DsRemixIcon
            className={`ri-arrow-${
              Number(NET_CHANGE) > 0 ? 'up' : 'down'
            }-fill`}
            fontSize='bitterCold'
            sx={{
              display: 'flex',
              marginLeft: 'var(--ds-spacing-deepFreeze)'
            }}
            color={Number(NET_CHANGE) > 0 ? 'success' : 'error'}
          />
        </>
      )
    }

    if (type === 'change') {
      return (
        <DsTypography
          align='center'
          color={
            Number(NET_CHANGE) > 0
              ? 'var(--ds-colour-iconPositive)'
              : 'var(--ds-colour-iconNegative)'
          }
          variant={
            netChangeTypoVariant ? netChangeTypoVariant : 'supportRegularInfo'
          }
        >
          {getCurrencyFormat(NET_CHANGE, options)}
        </DsTypography>
      )
    }

    return (
      <DsBox sx={{ display: 'flex' }}>
        <DsTypography
          // align='center'
          color={
            Number(NET_CHANGE) > 0
              ? 'var(--ds-colour-iconPositive)'
              : 'var(--ds-colour-iconNegative)'
          }
          variant={
            netChangeTypoVariant ? netChangeTypoVariant : 'supportRegularInfo'
          }
        >
          {component !== 'orders'
            ? `${NET_CHANGE} (${PERCENT_CHANGE?.slice(1)}%)`
            : `${PERCENT_CHANGE?.slice(1)}%`}
        </DsTypography>
        <DsRemixIcon
          className={`ri-arrow-${Number(NET_CHANGE) > 0 ? 'up' : 'down'}-fill`}
          fontSize='bitterCold'
          sx={{
            display: 'flex',
            marginLeft: 'var(--ds-spacing-deepFreeze)'
          }}
          color={Number(NET_CHANGE) > 0 ? 'success' : 'error'}
        />
      </DsBox>
    )
  }

  render() {
    const { NET_CHANGE } = this.state
    const {
      showNetChange = true,
      showLTP = true,
      path = '',
      type = ''
    }: any = this.props
    return (
      <DsBox
        sx={
          path === 'research' || type == 'quotes' || type == 'optionChain'
            ? { display: 'flex', gap: '8px' }
            : type == 'watchlist'
              ? {
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'column'
                }
              : {}
        }
      >
        {showLTP ? this.renderLTP() : null}

        {showNetChange ? (
          <DsStack
            sx={{
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            {NET_CHANGE ? (
              this.renderNetChange()
            ) : (
              <DsSkeleton
                variant='text'
                width={80}
                sx={{ fontSize: 'var(--ds-spacing-frostbite)' }}
              />
            )}
          </DsStack>
        ) : null}
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const stockPriceDetails = getTickerData(scriptId)(state)
  return {
    stockPriceDetails
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(StockTicker)
