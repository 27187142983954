import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { getOpenPositionsActions, getPositionsActions } from './actions'
import { SLICE_NAME } from './selectors'
export const DEFAULT_VALUES = {
  selectedSortBy: 'PNL_HIGH_TO_LOW'
}
const INITIAL_STATE = {
  positionsData: [],
  positionsDataOpen: [],
  positionsFilter: {
    stocksFilter: [],
    assetsFilter: [],
    productsFilter: [],
    sortBy: 'PNL_HIGH_TO_LOW'
  },
  isDefaultFiltersEnabled: true,
  filterLabels: {
    productFilterLabel: [],
    assetFilterLabel: [],
    stockFilterLabel: []
  }
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    positionsFilter: (state, action) => {
      state.positionsFilter = action?.payload
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    },
    setPositions: (state, action) => {
      state.positionsData = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getOpenPositionsActions.success, (state, { payload }) => {
      state.positionsDataOpen = payload
      if (!state.positionsData.length) state.positionsData = payload
    })
    builder.addCase(getPositionsActions.success, (state, { payload }) => {
      state.positionsData = payload
      const uniqueProductValues = new Set(
        payload?.map((obj: any) => obj.product)
      )
      const uniqueAssetValues = new Set(
        payload?.map((obj: any) => obj.instrument)
      )
      const uniqueStockValues = new Set(payload?.map((obj: any) => obj.symbol))
      state.filterLabels = {
        productFilterLabel: Array.from(uniqueProductValues),
        assetFilterLabel: Array.from(uniqueAssetValues),
        stockFilterLabel: Array.from(uniqueStockValues)
      }
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    })
  }
}

function checkIfDefaultFiltersAreSet(state: any) {
  const { positionsFilter } = state
  const { stocksFilter, assetsFilter, productsFilter } = positionsFilter
  const noFilters =
    stocksFilter.length + assetsFilter.length + productsFilter.length === 0
      ? true
      : false
  if (positionsFilter.sortBy === DEFAULT_VALUES.selectedSortBy && noFilters) {
    return true
  }
  return false
}

const slice = createSlice(sliceOptions)

export const { positionsFilter, setPositions } = slice.actions

export default slice.reducer
