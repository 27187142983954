import { asHttp } from "~/src/Configurations/WebHttp";

import { deleteRecentSearchActions } from "../Actions";

import { getSubAccountIdFromRedux } from "~/src/Utils/global";
import serviceActionCreator from "~/src/Redux/serviceActionCreatorWithTokenRotation";


async function deleteRecentSearchService() {
  const options = {
    url: `search/delete-all`,
    method: "GET",
    headers: {
      "X-SubAccountID": getSubAccountIdFromRedux(),
    }
  };

  const response = await asHttp.request(options);
  const { data: body } = response;
  const { data } = body;

  return data;
}

const deleteRecentSearchConfigAction = serviceActionCreator(
  deleteRecentSearchActions,
  deleteRecentSearchService
);

export default deleteRecentSearchConfigAction;
