import {
  DsBox,
  DsStack,
  DsRemixIcon,
  DsTypography,
  DsImage
} from '@am92/react-design-system'
import React, { Component } from 'react'
import { SIP_BOTTOM } from '~/src/Constants/ASSET_MAP'
import SipFormSection from './SipFormSection'
import SipHeader from './SipHeader'
import SearchStocks from '../../Sidebar/Components/Search'

export interface ISipCreationPageProps {
  showSelecetStock: any
  setShowSelecetStock: any
  orderPageSipModalIndicator: any
  isDesktop: boolean
  sipName: string
  isEditingSip: boolean
  showSearch: boolean
  setShowSearch: (item: boolean) => void
}

export default class SipCreationPage extends Component<ISipCreationPageProps> {
  render() {
    const {
      showSelecetStock,
      setShowSelecetStock,
      orderPageSipModalIndicator,
      isDesktop,
      sipName,
      showSearch,
      setShowSearch
    } = this.props

    return (
      <>
        <DsBox sx={{ position: 'relative', background: '#F9F9F9' }}>
          {showSearch && !isDesktop && (
            <>
              <DsStack
                sx={{
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                  padding:
                    'var(--ds-spacing-frostbite) var(--ds-spacing-bitterCold) var(--ds-spacing-mild) var(--ds-spacing-bitterCold)'
                }}
              >
                <DsRemixIcon
                  onClick={() => setShowSearch(false)}
                  className='ri-arrow-left-line'
                  fontSize='mild'
                  sx={{
                    cursor: 'pointer'
                  }}
                />
                <DsTypography variant='headingBoldSmall'>
                  Add Scrips to {sipName}
                </DsTypography>
              </DsStack>

              {showSearch && (
                <SearchStocks
                  type={'sip'}
                  setOpen={setShowSearch}
                  open={showSearch}
                />
              )}
            </>
          )}
        </DsBox>

        <SipHeader />

        <DsBox
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: { md: '25rem', xs: '100vw' },
            animation: 'reverse',
            overflow: 'hidden',
            overscrollBehavior: 'contain'
          }}
        >
          {orderPageSipModalIndicator && (
            <DsBox sx={{ padding: '0 var(--ds-spacing-bitterCold)' }}>
              <SipFormSection
                showSelecetStock={showSelecetStock}
                setShowSelecetStock={setShowSelecetStock}
                setShowSearch={setShowSearch}
              />
            </DsBox>
          )}

          <DsBox
            sx={{
              position: 'absolute',
              bottom: '0',
              width: '100%',
              zIndex: '-1'
            }}
          >
            <DsImage
              srcSet={SIP_BOTTOM}
              width={'100%'}
              style={{ width: '100%' }}
            />
          </DsBox>
        </DsBox>
      </>
    )
  }
}
