const dsRules = {
    headerMobileHeight: '64px',
    headerDesktopHeight: '84px',
    appBarMobileMinHeight: '56px',
    stepperConnectorMinHeight: '28px',
    searchbarMinWidth: '288px',
    searchbarHeight: '40px',
    drawerWidth: '240px',
    drawerMiniWidth: '56px',
    dialogMdMaxWidth: '600px',
    bottomSheetWorkingAreaHeight: '68vh',
    avatarSSize: '24px',
    avatarMSize: '32px',
    avatarLSize: '40px',
    avatarXLSize: '48px',
    avatarXXLSize: '64px',
    avatar3XLSize: '80px',
    formHelperTextMinHeight: '28px'
};
export default dsRules;
