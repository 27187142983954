import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import { DsFormControl } from '../DsFormControl';
import { DsInputLabel } from '../DsInputLabel';
import { DsInputBase } from '../DsInputBase';
import { DsHelperText } from '../DsHelperText';
export class DsTextField extends PureComponent {
    render() {
        const { id, name, label, labelSupportText, color, helperText, success, error, sx, fullWidth, ref, inputRef, disabled, required, FormControlProps, InputLabelProps, HelperTextProps, ...inputBaseProps } = this.props;
        const customColor = success ? 'success' : color;
        return (_jsxs(DsFormControl, { fullWidth: fullWidth, color: customColor, error: error, sx: sx, disabled: disabled, required: required, ...FormControlProps, children: [_jsx(DsInputLabel, { label: label, labelSupportText: labelSupportText, error: error, success: success, htmlFor: id || name, disabled: disabled, required: required, ...InputLabelProps }), _jsx(DsInputBase, { fullWidth: fullWidth, color: customColor, error: error, id: id || name, name: name, inputRef: ref || inputRef, disabled: disabled, required: required, ...inputBaseProps }), _jsx(DsHelperText, { helperText: helperText, color: color, success: success, error: error, ...HelperTextProps })] }));
    }
}
