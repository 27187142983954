import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const getCustomerProfileDetailsServiceName: any =
  'auth/getCustomerProfileDetails'
export const getCustomerProfileDetailsActions: any = {
  loading: createAction<any, any>(
    `${getCustomerProfileDetailsServiceName}/LOADING`
  ),
  success: createAction<any, any>(
    `${getCustomerProfileDetailsServiceName}/SUCCESS`
  ),
  error: createAction<any, any>(`${getCustomerProfileDetailsServiceName}/ERROR`)
}

export const getBankBalanceServiceName = 'inquiry/getBankBalance'
export const getBankBalanceActions: any = {
  loading: createAction<any, any>(`${getBankBalanceServiceName}/LOADING`),
  success: createAction<any, any>(`${getBankBalanceServiceName}/SUCCESS`),
  error: createAction<any, any>(`${getBankBalanceServiceName}/ERROR`)
}

export const getPartnersListServiceName = 'inquiry/getPartnersList'
export const getPartnersListActions: any = {
  loading: createAction<any, any>(`${getPartnersListServiceName}/LOADING`),
  success: createAction<any, any>(`${getPartnersListServiceName}/SUCCESS`),
  error: createAction<any, any>(`${getPartnersListServiceName}/ERROR`)
}

export const getResearchPartnersListServiceName =
  'inquiry/getResearchPartnersList'
export const getResearchPartnersListActions: any = {
  loading: createAction<any, any>(
    `${getResearchPartnersListServiceName}/LOADING`
  ),
  success: createAction<any, any>(
    `${getResearchPartnersListServiceName}/SUCCESS`
  ),
  error: createAction<any, any>(`${getResearchPartnersListServiceName}/ERROR`)
}

export const getReportsServiceName = 'inquiry/getReportsList'
export const getReportsActions: any = {
  loading: createAction<any, any>(`${getReportsServiceName}/LOADING`),
  success: createAction<any, any>(`${getReportsServiceName}/SUCCESS`),
  error: createAction<any, any>(`${getReportsServiceName}/ERROR`)
}

export const setEmarginServiceName = 'customer/setEmargin'
export const setEmarginActions = traceActionsCreator(setEmarginServiceName)

export const getEmarginDetailsServiceName = 'customer/getEmarginDetails'
export const getEmarginDetailsActions = traceActionsCreator(
  getEmarginDetailsServiceName
)
