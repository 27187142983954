import { createSelector } from '@reduxjs/toolkit'
import { _groupBy } from '~/src/Utils/lodash'

export const SLICE_NAME = 'derivatives'

const select = (state: any) => state[SLICE_NAME]
export const getAllDerivativeSelector = createSelector(
  select,
  derivatives => derivatives?.derivativeIndex
)

export const getFuturesByScriptIdSelector = (scriptId: number) =>
  createSelector(getAllDerivativeSelector, derivatives => {
    const mappedId = (window as any).secMaster._getScripIdForBSEDerivative(
      scriptId
    )

    return derivatives?.[mappedId]?.['FUTURES'] || []
  })

export const getOptionsByScriptIdSelector = (scriptId: number) =>
  createSelector(getAllDerivativeSelector, derivatives => {
    const mappedId = (window as any).secMaster._getScripIdForBSEDerivative(
      scriptId
    )
    return derivatives?.[mappedId]?.['OPTIONS'] || []
  })

export const getSelectedDateSelector = createSelector(
  select,
  derivative => derivative?.selectedDate
)

export const getGroupedOptionsSelector = createSelector(
  select,
  derivative => derivative?.groupedOptionData
)

export const getGroupedOptionsByDateSelector = (selectedDate: string) =>
  createSelector(
    select,
    derivatives => derivatives?.groupedOptionData?.[selectedDate]
  )

export const getMidIndexValueWithCallPutOptionsSelector = (LTP: any) =>
  createSelector(select, derivatives => getMidIndexValue(LTP, derivatives))

function getMidIndexValue(currentLTP: number, derivatives: any) {
  const { selectedDate = '', groupedOptionData = {} } = derivatives
  const optionsBySelectedDate = groupedOptionData[selectedDate]
  const { CE = [], PE = [] } = optionsBySelectedDate || {}
  let midIndex = 0
  if (CE.length) {
    CE.forEach((item: any, index: number) => {
      const { strikePrice } = item
      if (Math.abs(currentLTP) - Number(strikePrice) >= 0) {
        midIndex = index
      }
    })
  }
  return { midIndex, callOptions: CE, putOptions: PE, selectedDate }
}
