import { createSelector } from '@reduxjs/toolkit'
import { compareAsc } from 'date-fns'
import TickerStore from '~/src/Configurations/TickerStore'
import { THoldingsDocument } from '~/src/Pages/Dashboard/Components/HoldingSummaryCard/HoldingSummaryCard'
import { getFilterData, getCurrencyFormat } from '~/src/Utils/global'
export const SLICE_NAME = 'stockMaster'
const select = (state: any) => state[SLICE_NAME]

export const getStockMasterData = (scriptId: any) =>
  createSelector(select, stockMaster => stockMaster?.[scriptId])

export const getFuturesMasterData = (scriptIdArray: any) =>
  createSelector(select, stockMaster =>
    _getFuturesStocksData(scriptIdArray, stockMaster)
  )
const _getFuturesStocksData = (scriptIdArray: any, stockMaster: any) => {
  let stocksDataArray: any = []
  scriptIdArray.filter((scriptId: any) => {
    if (stockMaster[scriptId]) {
      stocksDataArray.push({ ...stockMaster[scriptId], scriptId })
    }
  })
  stocksDataArray.sort(
    (
      a: { expiryDate: string | number | Date },
      b: { expiryDate: string | number | Date }
    ) => compareAsc(new Date(a.expiryDate), new Date(b.expiryDate))
  )
  return stocksDataArray
}

export const getOptionsMasterData = (scriptIdArray: any) =>
  createSelector(select, stockMaster =>
    _getOptionsStocksData(scriptIdArray, stockMaster)
  )
const _getOptionsStocksData = (scriptIdArray: any, stockMaster: any) => {
  const stocksDataArray = scriptIdArray?.map(
    (item: any) => stockMaster?.[item.scriptId]
  )
  return stocksDataArray
}

export const getFullStockMasterData = createSelector(
  select,
  stockMaster => stockMaster
)

export const getStockMasterDetailsForSelectedScrips = (scripsInHolding: any) =>
  createSelector(select, stockMaster => {
    return scripsInHolding?.map((scripInHolding: any) => {
      const { scriptId = '' } = scripInHolding
      const stockDetails = stockMaster?.[scriptId]
      const { closeprice = 0, assetClass } = stockDetails || {}
      return { ...scripInHolding, closeprice, assetClass }
    })
  })

export const getHoldingTableDetails = (
  holdingsWithStockMasterDetails: any,
  holdingsFilter: any,
  selectedTab: 'All' | 'Stocks' | 'ETF',
  holdingDetails: any
) =>
  createSelector(select, stockMaster => {
    const currentData: any = []
    const { stocksFilter } = holdingsFilter
    let filteredHoldings = holdingsWithStockMasterDetails
    filteredHoldings = getFilterData(holdingsWithStockMasterDetails, {
      assetClass: selectedTab,
      symbol: stocksFilter
    })
    if (filteredHoldings && filteredHoldings.length > 0) {
      filteredHoldings?.forEach((individualHolding: THoldingsDocument) => {
        const { scriptId, holdingQty, avgPrice, investedValue } =
          individualHolding
        const tickerStore = TickerStore.getState()
        const { ticker = {} } = tickerStore || {}
        let { LTP } = ticker?.[scriptId] || {}
        const { closeprice: CLOSE_PRICE } =
          (window as any).secMaster.getByScriptId(scriptId) || {}
        const currentVal =
          LTP && holdingQty
            ? LTP * Number(holdingQty)
            : CLOSE_PRICE && holdingQty && Number(holdingQty) * CLOSE_PRICE
        const pnl = Number(currentVal) - Number(investedValue)
        const todaysPL = currentVal - Number(holdingQty) * Number(CLOSE_PRICE)
        const foundObj = holdingDetails.find(
          (obj2: any) => obj2.isinCode === individualHolding.isinCode
        )
        currentData.push([
          [individualHolding.symbol || ''],
          LTP,
          String(holdingQty),
          avgPrice,
          getCurrencyFormat(String(investedValue)),
          currentVal,
          pnl,
          { ...individualHolding, CLOSE_PRICE, ...foundObj },
          todaysPL
        ])
      })
    }
    return currentData
  })
