import React, { Component } from 'react'
import Notifications from './Notifications'
import { IwithBreakpoints, withBreakpoints } from '@am92/react-design-system'
import withErrorConnect from '~/src/Lib/withErrorConnect'

interface INotificationPopoverProps extends IwithBreakpoints {
  toggle: boolean
  setShowNotification: (data: any) => void
}
export class NotificationPopover extends Component<INotificationPopoverProps> {
  render() {
    const { toggle, setShowNotification } = this.props

    return (
      <>
        {
          toggle &&
          <Notifications toggle={toggle} setShowNotification={setShowNotification} />
        }
      </>)

  }
}

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withBreakpoints(withErrorConnect(mapStateToProps, mapDispatchToProps)(NotificationPopover))
