import { AXIS_DIRECT_URL, OPEN_ACCOUNT } from './APP_CONSTANTS'
import APP_ROUTES from './APP_ROUTES'
import { To } from 'react-router-dom'

type ErrorCodes =
  | 100
  | 1001
  | 1002
  | 1003
  | 1004
  | 1005
  | 1006
  | 1007
  | 1008
  | 1009
  | 1010
  | 1011
  | 1012
  | 1013
  | 1014
  | 1015
  | 1016
  | 1017
  | 1018
  | 1019
  | 1020
  | 1021
  | 1022
  | 1023
  | 1024
  | 1025
  | 1026
  | 1027
  | 1028
  | 1029
  | 1030
  | 1031
  | 1032
  | 1033
  | 1034
  | 1035
  | 1036
  | 1037
  | 1038
  | 1039
  | 1040
  | 1041
  | 1042
  | 1043
  | 1044
  | 1045
  | 1046
  | 1047
  | 1048
  | 1049
  | 1050
  | 1051
  | 1052
  | 1053
  | 1054
  | 1055
  | 1056
  | 1057
  | 1058
  | 1059
  | 1060
  | 1061
  | 1062
  | 1063
  | 1064
  | 1065
  | 1066
  | 1067
  | 1068
  | 1069
  | 1070
  | 1071
  | 1072
  | 1073
  | 1074
  | 1075
  | 1076
  | 1077
  | 1078
  | 1079
  | 1080
  | 1081
  | 1082
  | 1083
  | 1084
  | 1085
  | 1086
  | 1087
  | 1088
  | 1089
  | 1090
  | 1091
  | 1092
  | 1093
  | 1094
  | 1095
  | 1096
  | 9999
  | 9998
  | 1921
  | 1303
  | 1951

export interface Error {
  type?:
    | 'DIALOG'
    | 'FIELD_ERROR'
    | 'NOTISTACK'
    | 'NOTISTACK_NAVIGATION'
    | 'NAVIGATION'
  errorCode: number
  fieldErrors?: any
  notification?: {
    message: string
  }
  navigation?: {
    pathname: number | To
  }

  dialog?: {
    title: string
    descriptions: string[]
    reachOut?: {
      contactNumbers: string[]
      emails: string[]
    }
    primaryButtonLabel: string
    primaryButtonIcon?: string

    secondaryButtonLabel?: string
    secondaryButtonIcon?: string

    tertiaryButtonLabel?: string

    primaryButtonClickActions: {
      type:
        | 'EXTERNAL_LINK'
        | 'LINK'
        | 'CLOSE'
        | 'RESET_FORM'
        | 'RESET_REDUX_VALUES'
      data?: { url?: To; pathname?: number | To; redux_fields?: string[] }
    }[]
    secondaryButtonClickActions?: {
      type: string
      data?: { url?: string; pathname?: number | To; redux_fields?: string[] }
    }[]
  }
  bottomStagger?: {
    buttonGroupSeperator?: 'LOGIN WITH'
    title: string
    descriptions: string[]
    reachOut?: {
      contactNumbers: string[]
      emails: string[]
    }
    primaryButtonLabel: string
    secondaryButtonLabel?: string
    primaryButtonClickActions: {
      type:
        | 'EXTERNAL_LINK'
        | 'LINK'
        | 'CLOSE'
        | 'RESET_FORM'
        | 'RESET_REDUX_VALUES'
      data?: {
        url?: To
        pathname?: number | To
        redux_fields?: string[]
      }
    }[]
    secondaryButtonClickActions?: {
      type: string
      data?: { url?: string; pathname?: number | To; redux_fields?: string[] }
    }[]
  }
}
const REACH_OUT = {
  contactNumbers: ['022-40508080', '022-61480808'],
  emails: ['helpdesk@axisdirect.in']
}
const ERROR_MAP: { [key: number]: Error } = {
  1001: {
    type: 'NOTISTACK',
    errorCode: 1001,
    notification: {
      message: 'Something went wrong! Please contact customer support.'
    }
  },

  1032: {
    type: 'DIALOG',
    errorCode: 1032,
    dialog: {
      title: 'Incomplete Nomination',
      descriptions: [
        'You have not added a nominee to your account. For your safety and security please add a nominee to your account.'
      ],
      primaryButtonLabel: 'Add nominee',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1034: {
    type: 'DIALOG',
    errorCode: 1034,
    dialog: {
      title: 'Account closed',
      descriptions: [
        'Your account has been closed. To trade with us please open a new account'
      ],
      primaryButtonLabel: 'Open account',
      secondaryButtonLabel: 'Cancel',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: OPEN_ACCOUNT }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1035: {
    type: 'DIALOG',
    errorCode: 1035,
    dialog: {
      title: 'Where have you been?',
      descriptions: [
        'Your account has been dormant for 12 months. Please update and verify your details before you start trading again.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1036: {
    type: 'DIALOG',
    errorCode: 1036,
    dialog: {
      title: 'Account deactivated',
      descriptions: [
        'Your account has been deactivated for safety purpose. A customer executive will get in touch with you to activate your account.',
        'Thank you for your patience.'
      ],
      reachOut: {
        ...REACH_OUT
      },
      primaryButtonLabel: 'Go Back',
      primaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1037: {
    type: 'DIALOG',
    errorCode: 1037,
    dialog: {
      title: 'Bank Deactivated',
      descriptions: [
        'Your bank has been deactivated due to incomplete details. Please update your details for a better trading experience.'
      ],
      primaryButtonLabel: 'Update Details',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1038: {
    type: 'DIALOG',
    errorCode: 1038,
    dialog: {
      title: 'Incomplete UCC',
      descriptions: [
        'You have not added your UCC (Unique Client Code) details. Please update your details for a better trading experience.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1039: {
    type: 'DIALOG',
    errorCode: 1039,
    dialog: {
      title: 'Incomplete KYC',
      descriptions: [
        'You need to register your KYC details to continue your trading journey with us.'
      ],
      primaryButtonLabel: 'Register KYC',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1040: {
    type: 'DIALOG',
    errorCode: 1040,
    dialog: {
      title: 'Account deactivated',
      descriptions: [
        'Your account has been deactivated for safety purpose. A customer executive will get in touch with you to activate your account.',
        'Thank you for your patience.'
      ],
      reachOut: {
        ...REACH_OUT
      },
      primaryButtonLabel: 'Go Back',
      primaryButtonClickActions: [{ type: 'CLOSE' }]

      // primaryButtonLabel: 'Support',
      // primaryButtonIcon: 'ri-external-link-fill',
      // secondaryButtonLabel: 'Skip',
      // tertiaryButtonLabel: 'Request A Call',
      // primaryButtonClickActions: [{ type: 'CLOSE' }],
      // secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1041: {
    type: 'DIALOG',
    errorCode: 1041,
    dialog: {
      title: 'Incomplete UCC',
      descriptions: [
        'You have not added your UCC (Unique Client Code) details. Please update your details for a better trading experience.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1042: {
    type: 'DIALOG',
    errorCode: 1042,
    dialog: {
      title: 'Incomplete UCC',
      descriptions: [
        'You have not added your UCC (Unique Client Code) details. Please update your details for a better trading experience.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1043: {
    type: 'DIALOG',
    errorCode: 1043,
    dialog: {
      title: 'Account deactivated',
      descriptions: [
        'Your account has been deactivated for safety purpose. A customer executive will get in touch with you to activate your account.',
        'Thank you for your patience.'
      ],
      reachOut: {
        ...REACH_OUT
      },
      primaryButtonLabel: 'Go Back',
      primaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1044: {
    type: 'DIALOG',
    errorCode: 1044,
    dialog: {
      title: 'Account deactivated',
      descriptions: [
        'Your account has been deactivated for safety purpose. A customer executive will get in touch with you to activate your account.',
        'Thank you for your patience.'
      ],
      reachOut: {
        ...REACH_OUT
      },
      primaryButtonLabel: 'Go Back',
      primaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1045: {
    type: 'DIALOG',
    errorCode: 1045,
    dialog: {
      title: 'Where have you been?',
      descriptions: [
        'Your account has been dormant for 24 months. Please update and verify your details before you start trading again.'
      ],
      primaryButtonLabel: 'Update Now',
      secondaryButtonLabel: 'Skip',
      primaryButtonClickActions: [
        {
          type: 'EXTERNAL_LINK',
          data: { url: AXIS_DIRECT_URL }
        },
        { type: 'CLOSE' }
      ],
      secondaryButtonClickActions: [{ type: 'CLOSE' }]
    }
  },

  1921: {
    type: 'NOTISTACK',
    errorCode: 1921,
    notification: {
      message: 'Could not delete, Please try again'
    }
  },

  1080: {
    type: 'NAVIGATION',
    errorCode: 1080,
    navigation: {
      pathname: APP_ROUTES.SESSION_EXPIRED.pathname
    }
  },

  1081: {
    type: 'NAVIGATION',
    errorCode: 1081,
    navigation: {
      pathname: APP_ROUTES.SESSION_EXPIRED.pathname
    }
  },

  1303: {
    type: 'NOTISTACK',
    errorCode: 1303,
    notification: {
      message: 'You can only add 50 scrips to this watchlist'
    }
  },

  1951: {
    type: 'NOTISTACK',
    errorCode: 1951,
    notification: {
      message: 'emargin already activated'
    }
  }
}

export const PAGE_HANDLED_ERROR_CODES: number[] = [
  1032, 1035, 1036, 1037, 1038, 1039, 1040, 1041, 1042, 1043, 1044, 1045
]

export const REDUX_RESET_ERROR_CODES: number[] = []

const HOC_HANDLED_ERROR_CODES: number[] = []

for (let code of Object.keys(ERROR_MAP)) {
  if (!PAGE_HANDLED_ERROR_CODES.includes(Number(code))) {
    HOC_HANDLED_ERROR_CODES.push(Number(code))
  }
}

export { HOC_HANDLED_ERROR_CODES }

export const getErrorObj = (errorCode: ErrorCodes) => {
  return ERROR_MAP[errorCode]
}
