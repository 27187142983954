import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { ssoInitiateActions, ssoInitiateServiceName } from '../Actions'
import { getRequiredDataForHeaders } from '~/src/Utils/global'

export interface ssoInitiatePayload {
  type: string
  redirectURL: string
  metadata?: any
}

async function ssoInitiate(reqData: ssoInitiatePayload) {
  const options = {
    url: '/sso/initiate',
    method: 'POST',
    data: reqData,
    headers: getRequiredDataForHeaders()
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const ssoInitiateAction = serviceActionCreator(ssoInitiateActions, ssoInitiate)

export default ssoInitiateAction
export { ssoInitiateServiceName }
