export const NOTIFICATION_CONST: any = {
  Funds: {
    className: 'ri-wallet-2-line',
    background: 'var(--ds-colour-neutral2)',
    url: '/funds'
  },
  Alerts: {
    className: 'ri-information-line',
    background: 'var(--ds-colour-neutral1)',
    url: '/quotes/:scriptId'
  },
  Orders: {
    className: 'ri-arrow-left-right-line',
    background: 'var(--ds-colour-neutral1)',
    url: '/orderbook'
  }
}
