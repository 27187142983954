/** @ignore */
const DEFAULT_ERROR_MSG = 'Web Http Error';
/** @ignore */
const DEFAULT_ERROR_STATUS_CODE = -2;
/** @ignore */
const DEFAULT_ERROR_CODE = 'WebHttp::UNKWON';
/**
 * Error class whose instance is thrown in case of any error.
 *
 * @class
 * @typedef {WebHttpError}
 * @extends {Error}
 */
export class WebHttpError extends Error {
    /**
     * Flag to identify if error is a custom error.
     */
    _isCustomError = true;
    /**
     * Flag to identoify if error is a WebHttpError.
     */
    _isWebHttpError = true;
    /**
     * API Error's message string.
     */
    message;
    /**
     * API Error's HTTP status code.
     */
    statusCode;
    /**
     * API Error's error code as sent by backend.
     */
    errorCode;
    /**
     * API Error's error code as sent by backend.
     */
    code;
    /**
     * API Error's data object if sent with error.
     */
    data;
    /**
     * API Error's error object.
     */
    error;
    /**
     * Creates an instance of WebHttpError.
     *
     * @constructor
     * @param [e] AxiosError instance or error object from response body to wrap with WebHttpError.
     * @param [eMap] WebHttpErrorMap to rewrap error for better understanding.
     */
    constructor(e, eMap) {
        super();
        this.message = eMap?.message || e?.message || DEFAULT_ERROR_MSG;
        this.statusCode =
            eMap?.statusCode || e?.statusCode || DEFAULT_ERROR_STATUS_CODE;
        this.errorCode =
            eMap?.errorCode || e?.code || e?.errorCode || DEFAULT_ERROR_CODE;
        this.error = e?.error || e;
        this.data = e?.data || undefined;
    }
}
