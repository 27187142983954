import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { setEmarginActions, setEmarginServiceName } from '../Actions'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

async function setEmargin() {
  const options = {
    url: 'funds/e-margin/set-emargin',
    method: 'POST',
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux(),
      'X-sourcechannel': 'Web'
    }
  }
  const response = await asHttp.request(options)
  const { data: body } = response
  return body
}

const setEmarginAction = serviceActionCreator(setEmarginActions, setEmargin)

export default setEmarginAction
export { setEmarginServiceName }
