import { createSelector } from '@reduxjs/toolkit'
export const SLICE_NAME = 'news'

const select = (state: any) => state[SLICE_NAME]

export const getDashboardNewsSelector = createSelector(
  select,
  news => news.dashboardNews
)

export const getQuotesNewsSelector = createSelector(
  select,
  news => news.quotesNews
)
