import { asHttp } from '~/src/Configurations/WebHttp'

import { getRequiredMarginActions } from '../actions'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export interface getRequiredMarginPayload {
  scriptId: string
  segment: string
  orderPrice: number
  orderQuantity: number
  transactionType: string
  productType: string
  triggerPrice: number
}

async function getRequiredMargin(payload: getRequiredMarginPayload) {
  const options = {
    url: `/funds/inquiry/get-order-margin`,
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux(),
      'X-IPAddress': '192.168.65.1'
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getRequiredMarginAction = serviceActionCreator(
  getRequiredMarginActions,
  getRequiredMargin
)

export default getRequiredMarginAction
