import APP_ROUTES from '~/src/Constants/APP_ROUTES'

export const headerTabs = [
  { label: 'Dashboard', id: 0 },
  { label: 'Research', id: 1 },
  { label: 'Orders', id: 2 },
  { label: 'Portfolio', id: 3 }
  // { label: 'Products', id: 4 }
]

export const PARAM_ROUTES = {
  dashboard: 0,
  research: 1,
  orderbook: 2,
  portfolio: 3,
  product: 4
}

export const tabRoutes = [
  APP_ROUTES.DASHBOARD.pathname,
  APP_ROUTES.RESEARCH.pathname,
  APP_ROUTES.ORDERBOOK.pathname,
  APP_ROUTES.MULTIPLE_PORTFOLIO.pathname
]
