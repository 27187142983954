import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'
import { getAlertsActions, toggleAlertsFormActions } from './Actions'
import { SLICE_NAME } from './Selectors'
import { _map, _orderBy, _uniqBy } from '~/src/Utils/lodash'

const INITIAL_STATE = {
  data: [
    // {
    //   alertId: 'ed241d27-f01f-430c-a160-90cbff787413',
    //   subAccountId: 'AXS555',
    //   scriptId: '2222',
    //   value: 103,
    //   condition: 'GT',
    //   status: 'Processed',
    //   createdAt: '2024-06-05T04:59:01.342841Z',
    //   updatedAt: '2024-06-05T04:59:15.455977Z',
    //   scripName: 'TATAPOWER',
    //   exchange: 'NSE'
    // },
    // {
    //   alertId: 'de669432-c52f-4d7d-b97b-17d4bbcb0dc4',
    //   subAccountId: 'AXS555',
    //   scriptId: '2222',
    //   value: 103,
    //   condition: 'GT',
    //   status: 'Processed',
    //   createdAt: '2024-06-05T05:07:37.370257Z',
    //   updatedAt: '2024-06-05T05:08:18.088726Z'
    // }
  ],
  toggleAlertsForm: {
    toggle: false,
    scriptId: '',
    isModify: false,
    data: null
  }
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getAlertsActions.success, (state, { payload }) => {
      if (payload !== null) {
        state.data = reduceData(payload)
      }
    }),
      builder.addCase(toggleAlertsFormActions, (state, { payload }) => {
        state.toggleAlertsForm = payload
      })
  }
}

function reduceData(alertsData: any[]) {
  return _uniqBy(
    _map(alertsData, item => {
      const {
        alertId,
        scriptId,
        value,
        condition,
        createdAt,
        status,
        description,
        updatedAt
      } = item
      const { exchange, exchangeSymbol } =
        (window as any).secMaster.getByScriptId(scriptId) || {}
      return {
        alertId,
        scriptId,
        value,
        condition,
        createdAt,
        updatedAt,
        exchangeSymbol,
        exchange,
        description,
        status
      }
    }),
    'alertId'
  )
}

const slice = createSlice(sliceOptions)
export default slice.reducer
