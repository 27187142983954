import { jsx as _jsx } from "react/jsx-runtime";
import React, { PureComponent } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { DefaultActionBar } from './DefaultActionBar';
import { DefaultToolbar } from './DefaultToolbar';
import { DsDatePickerDefaultProps, DsDatePickerDefaultState } from './DsDatePicker.Types';
import { DateCalenderHeader } from './DateCalenderHeader';
import { DsIconButton } from '../DsIconButton';
import { DsInputAdornment } from '../DsInputAdornment';
import { DsRemixIcon } from '../DsRemixIcon';
import { DatePickerTextField } from './DatePickerTextField';
import { getDateFromValue, getErrorFromErrorMap, getValueTypeFromValue } from './utils';
export class DsDatePicker extends PureComponent {
    static defaultProps = DsDatePickerDefaultProps;
    state = DsDatePickerDefaultState;
    ref = React.createRef();
    setOpen = (open) => this.setState({ open });
    onOpen = () => this.setOpen(true);
    onClose = () => this.setOpen(false);
    handleChange = (value, context) => {
        const { onChange, onError, errorMap, name, valueType, format: formatType, views } = this.props;
        if (!views)
            this.setState({ views: ['day'] });
        if (context.validationError && typeof onError === 'function') {
            const error = getErrorFromErrorMap(errorMap, context.validationError, value);
            onError(name, error, context.validationError, getValueTypeFromValue(value, valueType, formatType));
            return;
        }
        if (typeof onChange === 'function' && formatType) {
            onChange(name, getValueTypeFromValue(value, valueType, formatType));
        }
    };
    handleError = (error, value) => {
        const { onError, errorMap, name } = this.props;
        if (error && typeof onError === 'function') {
            const errorMsg = getErrorFromErrorMap(errorMap, error, value);
            onError(name, errorMsg, error, value);
        }
    };
    handleViewChange = (value) => {
        this.setState({ views: [value, 'day'] });
    };
    render() {
        const { onChange, onError, defaultValue, value, valueType, format, onViewChange, views: propViews, disabled, ...restProps } = this.props;
        const { open, views } = this.state;
        return (_jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: _jsx(DatePicker, { ...restProps, slots: {
                    actionBar: DefaultActionBar,
                    toolbar: DefaultToolbar,
                    textField: DatePickerTextField,
                    calendarHeader: DateCalenderHeader,
                    ...this.props.slots
                }, slotProps: {
                    ...this.props.slotProps,
                    day: {
                        //commented to show current day border highlight
                        // disableHighlightToday: true,
                        ...this.props.slotProps?.day
                    },
                    textField: {
                        ...this.props.slotProps?.textField,
                        endAdornment: (_jsx(DsInputAdornment, { position: "end", disablePointerEvents: disabled, children: _jsx(DsIconButton, { disabled: disabled, onClick: this.onOpen, children: _jsx(DsRemixIcon, { className: "ri-calendar-line", fontSize: "bitterCold" }) }) }))
                    },
                    actionBar: ownerState => ({
                        actions: ownerState.view === 'day' ? ['clear', 'accept'] : [],
                        ...this.props.slotProps?.actionBar
                    }),
                    popper: {
                        anchorEl: this.ref.current,
                        //style to unset fixed width
                        sx: {
                            '.MuiMonthCalendar-root': {
                                width: '100%'
                            }
                        },
                        ...this.props.slotProps?.popper
                    }
                }, disabled: disabled, format: format, open: open, reduceAnimations: true, onOpen: this.onOpen, onClose: this.onClose, onChange: this.handleChange, onViewChange: this.handleViewChange, onError: this.handleError, views: propViews || views, value: getDateFromValue(value, valueType, format), defaultValue: getDateFromValue(defaultValue, valueType, format), inputRef: this.ref }) }));
    }
}
