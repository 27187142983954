import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreator'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'
import { getAlertsActions, getAlertsServiceName } from '../Actions'
import { NOTIFICATIONS_URL } from '~/src/Configurations/env'

async function getAlerts() {
  const options = {
    url: `/alerts`,
    method: 'GET',
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

export const getAlertsService = serviceActionCreator(
  getAlertsActions,
  getAlerts
)

export default getAlertsService
export { getAlertsServiceName }
