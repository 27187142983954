import { createSelector } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import {
  TReverseFeedResponse,
  alertColorMapper,
  alertDescriptionEndPartMapper,
  alertHeadingMapper,
  alertIconColorMapper,
  alertIconMapper,
  alertTransactionTypeMapper,
  scriptAlertDescriptionMapper
} from '~/src/Components/ReverseFeedAlert/ReverseFeedResponseMapper'
import AppStore from '~/src/Configurations/AppStore'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
export const SLICE_NAME = 'reverseFeed'
const select = (state: any) => state[SLICE_NAME]

export const getReverseFeedAlertIndicator = createSelector(
  select,
  reverseFeed => {
    return reverseFeed.reverseFeedAlertIndicator
  }
)

export const getReverseFeedOrderNo = createSelector(select, reverseFeed => {
  const { reverseFeedAlertData } = reverseFeed
  const { ORD_ORDER_NO } = reverseFeedAlertData
  return ORD_ORDER_NO
})

export const getReverseFeedAlertData = createSelector(select, reverseFeed => {
  const { reverseFeedAlertData, reverseFeedAlertIndicator } = reverseFeed
  const {
    NOTIFICATION_TYPE,
    ORD_ENTRY_DATE,
    ORD_STATUS,
    ORD_TRD_LAST_MODIFY_TIME,
    ALERT_SCRIPTID,
    ALERT_THRESHOLD,
    ALERT_CONDITION
  } = reverseFeedAlertData

  if (NOTIFICATION_TYPE === 'SCRIPT_ALERT') {
    const { stockMaster } = AppStore.getState()
    const stockData = stockMaster[ALERT_SCRIPTID]
    const { exchangeSymbol, coname, segment } = stockData || {}
    const alertDescription = `${segment == 'EQ' ? exchangeSymbol : coname} Alert has been triggered for LTP ${scriptAlertDescriptionMapper[ALERT_CONDITION]} ${ALERT_THRESHOLD}`
    return {
      alertTime: ORD_ENTRY_DATE,
      alertColor: alertColorMapper['C'],
      alertIcon: alertIconMapper['SCRIPT_ALERT'],
      alertIconColor: alertIconColorMapper['C'],
      alertHeading: alertHeadingMapper['SCRIPT_ALERT'],
      alertDescription,
      alertIndicator: reverseFeedAlertIndicator,
      alertNavigationButtonTypo: 'Trade Now',
      alertNavigationPath: `/quotes/${ALERT_SCRIPTID}`
    }
  }

  const alertColor = alertColorMapper[ORD_STATUS]
  const alertIcon = alertIconMapper[ORD_STATUS]
  const alertIconColor = alertIconColorMapper[ORD_STATUS]
  const alertHeading = alertHeadingMapper[ORD_STATUS]
  const alertDescriptionInitialPart =
    getInitialPartOfAlertDescription(reverseFeedAlertData)
  const alertDescriptionMiddlePart =
    getMiddlePartOfAlertDescription(reverseFeedAlertData)
  const ORD_TRD_MODIFY_TIME = format(
    new Date(Number(ORD_TRD_LAST_MODIFY_TIME)),
    'dd MMM yyyy'
  )
  const alertDescriptionLastPart =
    (ORD_STATUS === 'T' &&
      `${alertDescriptionEndPartMapper[ORD_STATUS]} ${ORD_TRD_MODIFY_TIME}!`) ||
    `${alertDescriptionEndPartMapper[ORD_STATUS]}`

  const alertDescription =
    alertDescriptionInitialPart +
    alertDescriptionMiddlePart +
    alertDescriptionLastPart

  return {
    alertTime: ORD_ENTRY_DATE,
    alertColor,
    alertIcon,
    alertIconColor,
    alertHeading,
    alertDescription,
    alertIndicator: reverseFeedAlertIndicator,
    alertNavigationButtonTypo: 'View Order Book',
    alertNavigationPath: `${APP_ROUTES.ORDERBOOK.pathname}`
  }
})

const getInitialPartOfAlertDescription = (
  reverseFeedAlertData: TReverseFeedResponse
) => {
  const {
    NOTIFICATION_TYPE,
    ORD_BUY_SELL_IND,
    ORD_SYMBOL_SERIES,
    ORD_SEM_SYMBOL
  } = reverseFeedAlertData
  const alertTransactionType = alertTransactionTypeMapper[ORD_BUY_SELL_IND]
  if (NOTIFICATION_TYPE === 'EQ_ORDERS') {
    return `${alertTransactionType} ${ORD_SYMBOL_SERIES} `
  }
  if (NOTIFICATION_TYPE === 'DRV_ORDERS') {
    return `${alertTransactionType} ${ORD_SEM_SYMBOL} `
  }
  if (NOTIFICATION_TYPE === 'CDX_ORDERS') {
    return `${alertTransactionType} ${ORD_SEM_SYMBOL} `
  }

  return ''
}

const getMiddlePartOfAlertDescription = (
  reverseFeedAlertData: TReverseFeedResponse
) => {
  const {
    NOTIFICATION_TYPE,
    ORD_SEM_EXPIRY_DATE,
    ORD_SEM_STRIKE_PRICE,
    ORD_SEM_OPTION_TYPE,
    ORD_QTY_ORIGINAL
  } = reverseFeedAlertData
  if (NOTIFICATION_TYPE === 'EQ_ORDERS') {
    return `of ${ORD_QTY_ORIGINAL} `
  }
  if (NOTIFICATION_TYPE === 'DRV_ORDERS') {
    const ORD_EXPIRY_DATE = format(
      new Date(Number(ORD_SEM_EXPIRY_DATE)),
      'dd MMM yyyy'
    )
    const ORD_OPTION_TYPE =
      (ORD_SEM_OPTION_TYPE === 'XX' && 'FUT') || ORD_SEM_OPTION_TYPE
    return `${ORD_EXPIRY_DATE} ${Number(ORD_SEM_STRIKE_PRICE).toFixed(2)} ${ORD_OPTION_TYPE} ${ORD_QTY_ORIGINAL} `
  }
  if (NOTIFICATION_TYPE === 'CDX_ORDERS') {
    const ORD_EXPIRY_DATE = format(
      new Date(Number(ORD_SEM_EXPIRY_DATE)),
      'dd MMM yyyy'
    )
    return `${ORD_EXPIRY_DATE} ${ORD_SEM_OPTION_TYPE} ${ORD_QTY_ORIGINAL} `
  }

  return ''
}

export const getReverseFeedAlertType = createSelector(select, reverseFeed => {
  const { reverseFeedAlertData } = reverseFeed
  const { NOTIFICATION_TYPE } = reverseFeedAlertData
  return NOTIFICATION_TYPE
})

export const getReverseFeedAlertScrips = createSelector(select, reverseFeed => {
  const { reverseFeedAlertData, reverseFeedAlertIndicator } = reverseFeed
  const {
    ALERT_THRESHOLD,
    ALERT_SCRIPTID,
    ALERT_CONDITION,
    ALERT_DESCRIPTION
  } = reverseFeedAlertData

  return {
    ALERT_THRESHOLD,
    ALERT_SCRIPTID,
    ALERT_CONDITION,
    ALERT_DESCRIPTION,
    reverseFeedAlertIndicator
  }
})
