import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
import {
  clearHistoricalDataAction,
  historicalDataActions
} from '~/src/Redux/HistoricalData/Actions'
import { SLICE_NAME } from '~/src/Redux/Customer/Selectors'

interface IHistoricalData {}

const data: any = [
  {
    co_code: '4325',
    tradedate: '2/23/2023 12:00:00 AM',
    last_price: '972.5',
    prevclose: '0',
    volume: '2835983',
    DayOpen: '973',
    DayHigh: '977.25',
    DayLow: '964.05',
    NiftyPrice: '17511.25',
    NiftyVolume: '240807918',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '2/24/2023 12:00:00 AM',
    last_price: '970.5',
    prevclose: '0',
    volume: '1823835',
    DayOpen: '970.6',
    DayHigh: '983.35',
    DayLow: '963.7',
    NiftyPrice: '17465.8',
    NiftyVolume: '209406363',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '2/27/2023 12:00:00 AM',
    last_price: '965.05',
    prevclose: '0',
    volume: '1548675',
    DayOpen: '970.5',
    DayHigh: '972.55',
    DayLow: '960.05',
    NiftyPrice: '17392.7',
    NiftyVolume: '246448847',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '2/28/2023 12:00:00 AM',
    last_price: '956.6',
    prevclose: '0',
    volume: '3819644',
    DayOpen: '969',
    DayHigh: '976',
    DayLow: '954',
    NiftyPrice: '17303.95',
    NiftyVolume: '420881259',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/1/2023 12:00:00 AM',
    last_price: '959.3',
    prevclose: '0',
    volume: '1862513',
    DayOpen: '951',
    DayHigh: '966.05',
    DayLow: '951',
    NiftyPrice: '17450.9',
    NiftyVolume: '272881409',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/2/2023 12:00:00 AM',
    last_price: '965.3',
    prevclose: '0',
    volume: '1510195',
    DayOpen: '960',
    DayHigh: '967',
    DayLow: '951.1',
    NiftyPrice: '17321.9',
    NiftyVolume: '310669094',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/3/2023 12:00:00 AM',
    last_price: '966.9',
    prevclose: '0',
    volume: '2215832',
    DayOpen: '967.05',
    DayHigh: '969.75',
    DayLow: '958.15',
    NiftyPrice: '17594.35',
    NiftyVolume: '356169518',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/6/2023 12:00:00 AM',
    last_price: '965.25',
    prevclose: '0',
    volume: '1989152',
    DayOpen: '970',
    DayHigh: '979.5',
    DayLow: '963.8',
    NiftyPrice: '17711.45',
    NiftyVolume: '362801096',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/8/2023 12:00:00 AM',
    last_price: '957.5',
    prevclose: '0',
    volume: '4734482',
    DayOpen: '960',
    DayHigh: '965',
    DayLow: '947',
    NiftyPrice: '17754.4',
    NiftyVolume: '267041402',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/9/2023 12:00:00 AM',
    last_price: '952.7',
    prevclose: '0',
    volume: '2994556',
    DayOpen: '957.5',
    DayHigh: '959.4',
    DayLow: '947.15',
    NiftyPrice: '17589.6',
    NiftyVolume: '262352056',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/10/2023 12:00:00 AM',
    last_price: '955.55',
    prevclose: '0',
    volume: '2001392',
    DayOpen: '946.5',
    DayHigh: '957.5',
    DayLow: '946.1',
    NiftyPrice: '17412.9',
    NiftyVolume: '235927570',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/13/2023 12:00:00 AM',
    last_price: '954.1',
    prevclose: '0',
    volume: '1412106',
    DayOpen: '955.1',
    DayHigh: '959.9',
    DayLow: '947.55',
    NiftyPrice: '17154.3',
    NiftyVolume: '254914657',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/14/2023 12:00:00 AM',
    last_price: '957.2',
    prevclose: '0',
    volume: '1879695',
    DayOpen: '953.5',
    DayHigh: '963.75',
    DayLow: '950.3',
    NiftyPrice: '17043.3',
    NiftyVolume: '290604200',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/15/2023 12:00:00 AM',
    last_price: '951.4',
    prevclose: '0',
    volume: '2189113',
    DayOpen: '952',
    DayHigh: '964.9',
    DayLow: '949.35',
    NiftyPrice: '16972.15',
    NiftyVolume: '248188970',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/16/2023 12:00:00 AM',
    last_price: '967.4',
    prevclose: '0',
    volume: '2228093',
    DayOpen: '955.25',
    DayHigh: '970',
    DayLow: '951.7',
    NiftyPrice: '16985.6',
    NiftyVolume: '349820607',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/17/2023 12:00:00 AM',
    last_price: '957.7',
    prevclose: '0',
    volume: '2218687',
    DayOpen: '968.55',
    DayHigh: '968.7',
    DayLow: '952.75',
    NiftyPrice: '17100.05',
    NiftyVolume: '408121255',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/20/2023 12:00:00 AM',
    last_price: '961.2',
    prevclose: '0',
    volume: '1955757',
    DayOpen: '950',
    DayHigh: '962.65',
    DayLow: '947.35',
    NiftyPrice: '16988.4',
    NiftyVolume: '241777405',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/21/2023 12:00:00 AM',
    last_price: '956.2',
    prevclose: '0',
    volume: '1687239',
    DayOpen: '965',
    DayHigh: '965',
    DayLow: '953.25',
    NiftyPrice: '17107.5',
    NiftyVolume: '246737873',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/22/2023 12:00:00 AM',
    last_price: '972.05',
    prevclose: '0',
    volume: '1626129',
    DayOpen: '957',
    DayHigh: '974.4',
    DayLow: '956.6',
    NiftyPrice: '17151.9',
    NiftyVolume: '185700457',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/23/2023 12:00:00 AM',
    last_price: '977.65',
    prevclose: '0',
    volume: '1857923',
    DayOpen: '975',
    DayHigh: '982.95',
    DayLow: '972',
    NiftyPrice: '17076.9',
    NiftyVolume: '219171517',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/24/2023 12:00:00 AM',
    last_price: '972.85',
    prevclose: '0',
    volume: '1534322',
    DayOpen: '977.6',
    DayHigh: '979',
    DayLow: '968',
    NiftyPrice: '16945.05',
    NiftyVolume: '228038299',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/27/2023 12:00:00 AM',
    last_price: '983.45',
    prevclose: '0',
    volume: '2473222',
    DayOpen: '980',
    DayHigh: '990.95',
    DayLow: '963',
    NiftyPrice: '16985.7',
    NiftyVolume: '218418670',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/28/2023 12:00:00 AM',
    last_price: '984.2',
    prevclose: '0',
    volume: '1394675',
    DayOpen: '987',
    DayHigh: '987.8',
    DayLow: '976.3',
    NiftyPrice: '16951.7',
    NiftyVolume: '238840919',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/29/2023 12:00:00 AM',
    last_price: '991.9',
    prevclose: '0',
    volume: '3045795',
    DayOpen: '985.65',
    DayHigh: '996.5',
    DayLow: '979',
    NiftyPrice: '17080.7',
    NiftyVolume: '345911087',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '3/31/2023 12:00:00 AM',
    last_price: '983.1',
    prevclose: '0',
    volume: '1822710',
    DayOpen: '995.2',
    DayHigh: '995.2',
    DayLow: '981.4',
    NiftyPrice: '17359.75',
    NiftyVolume: '263889033',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/3/2023 12:00:00 AM',
    last_price: '979.05',
    prevclose: '0',
    volume: '1671570',
    DayOpen: '981',
    DayHigh: '988',
    DayLow: '976.2',
    NiftyPrice: '17398.05',
    NiftyVolume: '230170687',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/5/2023 12:00:00 AM',
    last_price: '997.15',
    prevclose: '0',
    volume: '1544121',
    DayOpen: '985',
    DayHigh: '999',
    DayLow: '975.3',
    NiftyPrice: '17557.05',
    NiftyVolume: '248345193',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/6/2023 12:00:00 AM',
    last_price: '1012.1',
    prevclose: '0',
    volume: '1423990',
    DayOpen: '997.15',
    DayHigh: '1014',
    DayLow: '993.4',
    NiftyPrice: '17599.15',
    NiftyVolume: '242708337',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/10/2023 12:00:00 AM',
    last_price: '1008.6',
    prevclose: '0',
    volume: '1110974',
    DayOpen: '1015',
    DayHigh: '1016.95',
    DayLow: '1006.65',
    NiftyPrice: '17624.05',
    NiftyVolume: '254819382',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/11/2023 12:00:00 AM',
    last_price: '1009.25',
    prevclose: '0',
    volume: '1786844',
    DayOpen: '1008.6',
    DayHigh: '1022.25',
    DayLow: '1007.55',
    NiftyPrice: '17722.3',
    NiftyVolume: '304263454',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/12/2023 12:00:00 AM',
    last_price: '1016.7',
    prevclose: '0',
    volume: '3246327',
    DayOpen: '1015.5',
    DayHigh: '1020.2',
    DayLow: '1005.25',
    NiftyPrice: '17812.4',
    NiftyVolume: '232816318',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/13/2023 12:00:00 AM',
    last_price: '1003.5',
    prevclose: '0',
    volume: '1445693',
    DayOpen: '1022.3',
    DayHigh: '1023.85',
    DayLow: '1002',
    NiftyPrice: '17828',
    NiftyVolume: '285764798',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/17/2023 12:00:00 AM',
    last_price: '998.7',
    prevclose: '0',
    volume: '1768817',
    DayOpen: '999.45',
    DayHigh: '1009.9',
    DayLow: '995.05',
    NiftyPrice: '17706.85',
    NiftyVolume: '357401212',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/18/2023 12:00:00 AM',
    last_price: '1004.8',
    prevclose: '0',
    volume: '1217390',
    DayOpen: '999',
    DayHigh: '1006.25',
    DayLow: '988.3',
    NiftyPrice: '17660.15',
    NiftyVolume: '258856690',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/19/2023 12:00:00 AM',
    last_price: '995.5',
    prevclose: '0',
    volume: '2174563',
    DayOpen: '1003.5',
    DayHigh: '1009.4',
    DayLow: '993',
    NiftyPrice: '17618.75',
    NiftyVolume: '281463379',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/20/2023 12:00:00 AM',
    last_price: '987.75',
    prevclose: '0',
    volume: '1638094',
    DayOpen: '999.1',
    DayHigh: '1002.1',
    DayLow: '983.75',
    NiftyPrice: '17624.45',
    NiftyVolume: '215103396',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/21/2023 12:00:00 AM',
    last_price: '989.45',
    prevclose: '0',
    volume: '1216575',
    DayOpen: '994.3',
    DayHigh: '994.3',
    DayLow: '984.95',
    NiftyPrice: '17624.05',
    NiftyVolume: '230156645',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/24/2023 12:00:00 AM',
    last_price: '979.15',
    prevclose: '0',
    volume: '3485350',
    DayOpen: '975',
    DayHigh: '982.5',
    DayLow: '959.25',
    NiftyPrice: '17743.4',
    NiftyVolume: '255740393',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/25/2023 12:00:00 AM',
    last_price: '972.5',
    prevclose: '0',
    volume: '2377746',
    DayOpen: '980',
    DayHigh: '982.9',
    DayLow: '967',
    NiftyPrice: '17769.25',
    NiftyVolume: '297445999',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/26/2023 12:00:00 AM',
    last_price: '971.25',
    prevclose: '0',
    volume: '2911028',
    DayOpen: '975.8',
    DayHigh: '976.8',
    DayLow: '963.2',
    NiftyPrice: '17813.6',
    NiftyVolume: '233008677',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/27/2023 12:00:00 AM',
    last_price: '978.7',
    prevclose: '0',
    volume: '2777173',
    DayOpen: '968',
    DayHigh: '981.6',
    DayLow: '963.55',
    NiftyPrice: '17915.05',
    NiftyVolume: '241434660',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '4/28/2023 12:00:00 AM',
    last_price: '987.65',
    prevclose: '0',
    volume: '2009995',
    DayOpen: '984.95',
    DayHigh: '992.5',
    DayLow: '979.15',
    NiftyPrice: '18065',
    NiftyVolume: '290263674',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/2/2023 12:00:00 AM',
    last_price: '973.05',
    prevclose: '0',
    volume: '3152556',
    DayOpen: '987.65',
    DayHigh: '988.9',
    DayLow: '970.3',
    NiftyPrice: '18147.65',
    NiftyVolume: '265216308',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/3/2023 12:00:00 AM',
    last_price: '963.6',
    prevclose: '0',
    volume: '3087472',
    DayOpen: '970.5',
    DayHigh: '972.4',
    DayLow: '959.5',
    NiftyPrice: '18089.85',
    NiftyVolume: '226171254',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/4/2023 12:00:00 AM',
    last_price: '974',
    prevclose: '0',
    volume: '1197830',
    DayOpen: '967.65',
    DayHigh: '975.9',
    DayLow: '965.15',
    NiftyPrice: '18255.8',
    NiftyVolume: '226643581',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/5/2023 12:00:00 AM',
    last_price: '970.45',
    prevclose: '0',
    volume: '1062645',
    DayOpen: '974',
    DayHigh: '977.25',
    DayLow: '967.85',
    NiftyPrice: '18069',
    NiftyVolume: '239702311',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/8/2023 12:00:00 AM',
    last_price: '961.85',
    prevclose: '0',
    volume: '2676958',
    DayOpen: '962',
    DayHigh: '969',
    DayLow: '961',
    NiftyPrice: '18264.4',
    NiftyVolume: '228399379',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/9/2023 12:00:00 AM',
    last_price: '957.45',
    prevclose: '0',
    volume: '6902170',
    DayOpen: '961.9',
    DayHigh: '966.85',
    DayLow: '949.5',
    NiftyPrice: '18265.95',
    NiftyVolume: '245368649',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/10/2023 12:00:00 AM',
    last_price: '953.8',
    prevclose: '0',
    volume: '2180917',
    DayOpen: '957',
    DayHigh: '959.95',
    DayLow: '951.6',
    NiftyPrice: '18315.1',
    NiftyVolume: '220450874',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/11/2023 12:00:00 AM',
    last_price: '961.05',
    prevclose: '0',
    volume: '1296607',
    DayOpen: '953.8',
    DayHigh: '963.9',
    DayLow: '952',
    NiftyPrice: '18297',
    NiftyVolume: '248932544',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/12/2023 12:00:00 AM',
    last_price: '956',
    prevclose: '0',
    volume: '2014927',
    DayOpen: '961',
    DayHigh: '963.1',
    DayLow: '952',
    NiftyPrice: '18314.8',
    NiftyVolume: '241702589',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/15/2023 12:00:00 AM',
    last_price: '953',
    prevclose: '0',
    volume: '1814239',
    DayOpen: '953',
    DayHigh: '955.75',
    DayLow: '948.05',
    NiftyPrice: '18398.85',
    NiftyVolume: '216088535',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/16/2023 12:00:00 AM',
    last_price: '940.95',
    prevclose: '0',
    volume: '2625163',
    DayOpen: '953',
    DayHigh: '962.55',
    DayLow: '938',
    NiftyPrice: '18286.5',
    NiftyVolume: '219490491',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/17/2023 12:00:00 AM',
    last_price: '935.95',
    prevclose: '0',
    volume: '2956985',
    DayOpen: '941.25',
    DayHigh: '945.3',
    DayLow: '928.85',
    NiftyPrice: '18181.75',
    NiftyVolume: '229898510',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/18/2023 12:00:00 AM',
    last_price: '929.65',
    prevclose: '0',
    volume: '3014019',
    DayOpen: '943',
    DayHigh: '943',
    DayLow: '922.45',
    NiftyPrice: '18129.95',
    NiftyVolume: '272103515',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/19/2023 12:00:00 AM',
    last_price: '925.85',
    prevclose: '0',
    volume: '1309343',
    DayOpen: '932',
    DayHigh: '934.4',
    DayLow: '924.3',
    NiftyPrice: '18203.4',
    NiftyVolume: '260898407',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/22/2023 12:00:00 AM',
    last_price: '937.45',
    prevclose: '0',
    volume: '1382485',
    DayOpen: '925.85',
    DayHigh: '942.75',
    DayLow: '922.85',
    NiftyPrice: '18314.4',
    NiftyVolume: '262560942',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/23/2023 12:00:00 AM',
    last_price: '931.6',
    prevclose: '0',
    volume: '3057684',
    DayOpen: '937.5',
    DayHigh: '956.2',
    DayLow: '929.1',
    NiftyPrice: '18348',
    NiftyVolume: '270354216',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/24/2023 12:00:00 AM',
    last_price: '952.15',
    prevclose: '0',
    volume: '2953574',
    DayOpen: '935.5',
    DayHigh: '954.8',
    DayLow: '932.6',
    NiftyPrice: '18285.4',
    NiftyVolume: '232621507',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/25/2023 12:00:00 AM',
    last_price: '945',
    prevclose: '0',
    volume: '2331464',
    DayOpen: '949.7',
    DayHigh: '951.7',
    DayLow: '942.7',
    NiftyPrice: '18321.15',
    NiftyVolume: '235915651',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/26/2023 12:00:00 AM',
    last_price: '969.9',
    prevclose: '0',
    volume: '4137189',
    DayOpen: '943.95',
    DayHigh: '976.7',
    DayLow: '937',
    NiftyPrice: '18499.35',
    NiftyVolume: '198668359',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/29/2023 12:00:00 AM',
    last_price: '968.45',
    prevclose: '0',
    volume: '5344765',
    DayOpen: '969.95',
    DayHigh: '973.25',
    DayLow: '946.4',
    NiftyPrice: '18598.65',
    NiftyVolume: '265323978',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/30/2023 12:00:00 AM',
    last_price: '959.6',
    prevclose: '0',
    volume: '2624815',
    DayOpen: '965',
    DayHigh: '967.45',
    DayLow: '956.3',
    NiftyPrice: '18633.85',
    NiftyVolume: '234391323',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '5/31/2023 12:00:00 AM',
    last_price: '975.35',
    prevclose: '0',
    volume: '5595631',
    DayOpen: '965',
    DayHigh: '979.3',
    DayLow: '960.75',
    NiftyPrice: '18534.4',
    NiftyVolume: '696495765',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/1/2023 12:00:00 AM',
    last_price: '987.7',
    prevclose: '0',
    volume: '3261906',
    DayOpen: '979.8',
    DayHigh: '990.45',
    DayLow: '975',
    NiftyPrice: '18487.75',
    NiftyVolume: '265630759',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/2/2023 12:00:00 AM',
    last_price: '999.6',
    prevclose: '0',
    volume: '3734815',
    DayOpen: '986.55',
    DayHigh: '1003.7',
    DayLow: '981',
    NiftyPrice: '18534.1',
    NiftyVolume: '270923679',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/5/2023 12:00:00 AM',
    last_price: '1008.75',
    prevclose: '0',
    volume: '3629780',
    DayOpen: '1005.35',
    DayHigh: '1017.7',
    DayLow: '998.5',
    NiftyPrice: '18593.85',
    NiftyVolume: '256111496',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/6/2023 12:00:00 AM',
    last_price: '1013.8',
    prevclose: '0',
    volume: '1632644',
    DayOpen: '1008.9',
    DayHigh: '1015.95',
    DayLow: '1004',
    NiftyPrice: '18599',
    NiftyVolume: '220789926',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/7/2023 12:00:00 AM',
    last_price: '1014.6',
    prevclose: '0',
    volume: '1608613',
    DayOpen: '1015',
    DayHigh: '1017.5',
    DayLow: '1010',
    NiftyPrice: '18726.4',
    NiftyVolume: '260109671',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/8/2023 12:00:00 AM',
    last_price: '987',
    prevclose: '0',
    volume: '3395935',
    DayOpen: '1015',
    DayHigh: '1016',
    DayLow: '983.25',
    NiftyPrice: '18634.55',
    NiftyVolume: '286468927',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/9/2023 12:00:00 AM',
    last_price: '983.95',
    prevclose: '0',
    volume: '2306206',
    DayOpen: '984',
    DayHigh: '990.7',
    DayLow: '981',
    NiftyPrice: '18563.4',
    NiftyVolume: '221760885',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/12/2023 12:00:00 AM',
    last_price: '985.45',
    prevclose: '0',
    volume: '593319',
    DayOpen: '987.9',
    DayHigh: '989.9',
    DayLow: '981.05',
    NiftyPrice: '18601.5',
    NiftyVolume: '179463966',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/13/2023 12:00:00 AM',
    last_price: '988.1',
    prevclose: '0',
    volume: '2413311',
    DayOpen: '990',
    DayHigh: '996',
    DayLow: '985.55',
    NiftyPrice: '18716.15',
    NiftyVolume: '233167883',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/14/2023 12:00:00 AM',
    last_price: '986.7',
    prevclose: '0',
    volume: '2139387',
    DayOpen: '991.6',
    DayHigh: '995',
    DayLow: '984.25',
    NiftyPrice: '18755.9',
    NiftyVolume: '261354575',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/15/2023 12:00:00 AM',
    last_price: '988.4',
    prevclose: '0',
    volume: '2731724',
    DayOpen: '988.5',
    DayHigh: '994.8',
    DayLow: '985.7',
    NiftyPrice: '18688.1',
    NiftyVolume: '263004991',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/16/2023 12:00:00 AM',
    last_price: '992',
    prevclose: '0',
    volume: '3611214',
    DayOpen: '990',
    DayHigh: '994.65',
    DayLow: '987.2',
    NiftyPrice: '18826',
    NiftyVolume: '272771256',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/19/2023 12:00:00 AM',
    last_price: '1001.8',
    prevclose: '0',
    volume: '1327862',
    DayOpen: '995.15',
    DayHigh: '1006.7',
    DayLow: '993.75',
    NiftyPrice: '18755.45',
    NiftyVolume: '219815214',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/20/2023 12:00:00 AM',
    last_price: '991.95',
    prevclose: '0',
    volume: '1445688',
    DayOpen: '1007.65',
    DayHigh: '1007.75',
    DayLow: '989.6',
    NiftyPrice: '18816.7',
    NiftyVolume: '211649257',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/21/2023 12:00:00 AM',
    last_price: '991.9',
    prevclose: '0',
    volume: '1396686',
    DayOpen: '991.95',
    DayHigh: '993.85',
    DayLow: '983.6',
    NiftyPrice: '18856.85',
    NiftyVolume: '217481345',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/22/2023 12:00:00 AM',
    last_price: '990.3',
    prevclose: '0',
    volume: '5384143',
    DayOpen: '987.1',
    DayHigh: '996.2',
    DayLow: '987.1',
    NiftyPrice: '18771.25',
    NiftyVolume: '252697871',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/23/2023 12:00:00 AM',
    last_price: '991.45',
    prevclose: '0',
    volume: '1218237',
    DayOpen: '994',
    DayHigh: '995.95',
    DayLow: '977.3',
    NiftyPrice: '18665.5',
    NiftyVolume: '210562119',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/26/2023 12:00:00 AM',
    last_price: '994.95',
    prevclose: '0',
    volume: '2066932',
    DayOpen: '994',
    DayHigh: '1003.8',
    DayLow: '991.45',
    NiftyPrice: '18691.2',
    NiftyVolume: '171271325',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/27/2023 12:00:00 AM',
    last_price: '1001.8',
    prevclose: '0',
    volume: '4647465',
    DayOpen: '999.95',
    DayHigh: '1003.35',
    DayLow: '986',
    NiftyPrice: '18817.4',
    NiftyVolume: '222464605',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/28/2023 12:00:00 AM',
    last_price: '1021.8',
    prevclose: '0',
    volume: '5296330',
    DayOpen: '995',
    DayHigh: '1025.55',
    DayLow: '995',
    NiftyPrice: '18972.1',
    NiftyVolume: '398782019',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '6/30/2023 12:00:00 AM',
    last_price: '1051.6',
    prevclose: '0',
    volume: '4765060',
    DayOpen: '1021.75',
    DayHigh: '1055',
    DayLow: '1020.05',
    NiftyPrice: '19189.05',
    NiftyVolume: '247906587',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/3/2023 12:00:00 AM',
    last_price: '1032.7',
    prevclose: '0',
    volume: '2338356',
    DayOpen: '1053',
    DayHigh: '1053.65',
    DayLow: '1030',
    NiftyPrice: '19322.55',
    NiftyVolume: '226050219',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/4/2023 12:00:00 AM',
    last_price: '1048.8',
    prevclose: '0',
    volume: '1549174',
    DayOpen: '1040',
    DayHigh: '1053.9',
    DayLow: '1029.9',
    NiftyPrice: '19389',
    NiftyVolume: '226916635',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/5/2023 12:00:00 AM',
    last_price: '1044.7',
    prevclose: '0',
    volume: '1840716',
    DayOpen: '1055',
    DayHigh: '1057.7',
    DayLow: '1042.65',
    NiftyPrice: '19398.5',
    NiftyVolume: '224418797',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/6/2023 12:00:00 AM',
    last_price: '1042.5',
    prevclose: '0',
    volume: '2551103',
    DayOpen: '1040',
    DayHigh: '1056.6',
    DayLow: '1040',
    NiftyPrice: '19497.3',
    NiftyVolume: '268261285',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/7/2023 12:00:00 AM',
    last_price: '1035.7',
    prevclose: '0',
    volume: '1721224',
    DayOpen: '1033.5',
    DayHigh: '1051.2',
    DayLow: '1028.75',
    NiftyPrice: '19331.8',
    NiftyVolume: '237102566',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/10/2023 12:00:00 AM',
    last_price: '1045',
    prevclose: '0',
    volume: '1221769',
    DayOpen: '1036',
    DayHigh: '1051',
    DayLow: '1031.1',
    NiftyPrice: '19355.9',
    NiftyVolume: '268246132',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/11/2023 12:00:00 AM',
    last_price: '1072.25',
    prevclose: '0',
    volume: '4369937',
    DayOpen: '1052',
    DayHigh: '1074.75',
    DayLow: '1049.05',
    NiftyPrice: '19439.4',
    NiftyVolume: '251263638',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/12/2023 12:00:00 AM',
    last_price: '1078.75',
    prevclose: '0',
    volume: '5088473',
    DayOpen: '1075',
    DayHigh: '1083.75',
    DayLow: '1070',
    NiftyPrice: '19384.3',
    NiftyVolume: '327941559',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/13/2023 12:00:00 AM',
    last_price: '1075.65',
    prevclose: '0',
    volume: '1541690',
    DayOpen: '1081.9',
    DayHigh: '1081.9',
    DayLow: '1071.7',
    NiftyPrice: '19413.75',
    NiftyVolume: '310442517',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/14/2023 12:00:00 AM',
    last_price: '1072.95',
    prevclose: '0',
    volume: '2641580',
    DayOpen: '1079.95',
    DayHigh: '1080',
    DayLow: '1065',
    NiftyPrice: '19564.5',
    NiftyVolume: '291165841',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/17/2023 12:00:00 AM',
    last_price: '1077.6',
    prevclose: '0',
    volume: '1254025',
    DayOpen: '1073.7',
    DayHigh: '1081.05',
    DayLow: '1070.5',
    NiftyPrice: '19711.45',
    NiftyVolume: '268707894',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/18/2023 12:00:00 AM',
    last_price: '1067.15',
    prevclose: '0',
    volume: '2238739',
    DayOpen: '1078.45',
    DayHigh: '1078.9',
    DayLow: '1064.35',
    NiftyPrice: '19749.25',
    NiftyVolume: '286353897',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/19/2023 12:00:00 AM',
    last_price: '1081.85',
    prevclose: '0',
    volume: '2311282',
    DayOpen: '1067.15',
    DayHigh: '1086',
    DayLow: '1063.85',
    NiftyPrice: '19833.15',
    NiftyVolume: '259660464',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/20/2023 12:00:00 AM',
    last_price: '1097.65',
    prevclose: '0',
    volume: '5218991',
    DayOpen: '1086.05',
    DayHigh: '1104.5',
    DayLow: '1082.3',
    NiftyPrice: '19979.15',
    NiftyVolume: '274698867',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/21/2023 12:00:00 AM',
    last_price: '1097.15',
    prevclose: '0',
    volume: '1879610',
    DayOpen: '1103.4',
    DayHigh: '1104.2',
    DayLow: '1090.35',
    NiftyPrice: '19745',
    NiftyVolume: '312461795',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/24/2023 12:00:00 AM',
    last_price: '1100.4',
    prevclose: '0',
    volume: '2130036',
    DayOpen: '1094',
    DayHigh: '1107.35',
    DayLow: '1092.5',
    NiftyPrice: '19672.35',
    NiftyVolume: '265562151',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/25/2023 12:00:00 AM',
    last_price: '1095.05',
    prevclose: '0',
    volume: '1702900',
    DayOpen: '1110',
    DayHigh: '1110',
    DayLow: '1090.8',
    NiftyPrice: '19680.6',
    NiftyVolume: '369653496',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/26/2023 12:00:00 AM',
    last_price: '1112',
    prevclose: '0',
    volume: '1321572',
    DayOpen: '1097.05',
    DayHigh: '1114.4',
    DayLow: '1094.1',
    NiftyPrice: '19778.3',
    NiftyVolume: '244747425',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/27/2023 12:00:00 AM',
    last_price: '1134.95',
    prevclose: '0',
    volume: '6366612',
    DayOpen: '1128',
    DayHigh: '1155.35',
    DayLow: '1116.3',
    NiftyPrice: '19659.9',
    NiftyVolume: '334891351',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/28/2023 12:00:00 AM',
    last_price: '1138.55',
    prevclose: '0',
    volume: '2677679',
    DayOpen: '1129.95',
    DayHigh: '1143.9',
    DayLow: '1121.65',
    NiftyPrice: '19646.05',
    NiftyVolume: '258671382',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '7/31/2023 12:00:00 AM',
    last_price: '1143.4',
    prevclose: '0',
    volume: '1718336',
    DayOpen: '1140',
    DayHigh: '1145',
    DayLow: '1127',
    NiftyPrice: '19753.8',
    NiftyVolume: '295053318',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/1/2023 12:00:00 AM',
    last_price: '1143.45',
    prevclose: '0',
    volume: '1448567',
    DayOpen: '1143.4',
    DayHigh: '1152.65',
    DayLow: '1136',
    NiftyPrice: '19733.55',
    NiftyVolume: '298048161',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/2/2023 12:00:00 AM',
    last_price: '1135.25',
    prevclose: '0',
    volume: '1731918',
    DayOpen: '1143.95',
    DayHigh: '1144.3',
    DayLow: '1126.45',
    NiftyPrice: '19526.55',
    NiftyVolume: '290666798',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/3/2023 12:00:00 AM',
    last_price: '1141',
    prevclose: '0',
    volume: '8460399',
    DayOpen: '1130.25',
    DayHigh: '1169.7',
    DayLow: '1122.35',
    NiftyPrice: '19381.65',
    NiftyVolume: '315688612',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/4/2023 12:00:00 AM',
    last_price: '1138.95',
    prevclose: '0',
    volume: '3527805',
    DayOpen: '1129',
    DayHigh: '1145',
    DayLow: '1121.05',
    NiftyPrice: '19517',
    NiftyVolume: '280799561',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/7/2023 12:00:00 AM',
    last_price: '1160.8',
    prevclose: '0',
    volume: '3353219',
    DayOpen: '1138.95',
    DayHigh: '1166.75',
    DayLow: '1133.5',
    NiftyPrice: '19597.3',
    NiftyVolume: '216586074',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/8/2023 12:00:00 AM',
    last_price: '1148.65',
    prevclose: '0',
    volume: '3073468',
    DayOpen: '1162.2',
    DayHigh: '1164.6',
    DayLow: '1139.95',
    NiftyPrice: '19570.85',
    NiftyVolume: '260294052',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/9/2023 12:00:00 AM',
    last_price: '1152.8',
    prevclose: '0',
    volume: '1306791',
    DayOpen: '1151.15',
    DayHigh: '1159.3',
    DayLow: '1140.3',
    NiftyPrice: '19632.55',
    NiftyVolume: '266495074',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/10/2023 12:00:00 AM',
    last_price: '1150.3',
    prevclose: '0',
    volume: '1443067',
    DayOpen: '1150.6',
    DayHigh: '1157.9',
    DayLow: '1143.6',
    NiftyPrice: '19543.1',
    NiftyVolume: '312193785',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/11/2023 12:00:00 AM',
    last_price: '1132.35',
    prevclose: '0',
    volume: '1756630',
    DayOpen: '1150.05',
    DayHigh: '1151',
    DayLow: '1130.5',
    NiftyPrice: '19428.3',
    NiftyVolume: '235524048',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/14/2023 12:00:00 AM',
    last_price: '1134.25',
    prevclose: '0',
    volume: '1824622',
    DayOpen: '1131',
    DayHigh: '1142',
    DayLow: '1120.5',
    NiftyPrice: '19434.55',
    NiftyVolume: '243850815',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/16/2023 12:00:00 AM',
    last_price: '1143.65',
    prevclose: '0',
    volume: '1224080',
    DayOpen: '1134.95',
    DayHigh: '1145.5',
    DayLow: '1121.85',
    NiftyPrice: '19465',
    NiftyVolume: '226627230',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/17/2023 12:00:00 AM',
    last_price: '1144.8',
    prevclose: '0',
    volume: '1964341',
    DayOpen: '1143.65',
    DayHigh: '1148.95',
    DayLow: '1137.5',
    NiftyPrice: '19365.25',
    NiftyVolume: '268663456',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/18/2023 12:00:00 AM',
    last_price: '1134.9',
    prevclose: '0',
    volume: '1473774',
    DayOpen: '1141',
    DayHigh: '1149.3',
    DayLow: '1129',
    NiftyPrice: '19310.15',
    NiftyVolume: '256142364',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/21/2023 12:00:00 AM',
    last_price: '1137.4',
    prevclose: '0',
    volume: '941384',
    DayOpen: '1129.95',
    DayHigh: '1143.9',
    DayLow: '1127.15',
    NiftyPrice: '19393.6',
    NiftyVolume: '187873827',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/22/2023 12:00:00 AM',
    last_price: '1137.55',
    prevclose: '0',
    volume: '1438879',
    DayOpen: '1135',
    DayHigh: '1147',
    DayLow: '1130',
    NiftyPrice: '19396.45',
    NiftyVolume: '201422956',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/23/2023 12:00:00 AM',
    last_price: '1123.7',
    prevclose: '0',
    volume: '3050418',
    DayOpen: '1128',
    DayHigh: '1130.45',
    DayLow: '1110.6',
    NiftyPrice: '19444',
    NiftyVolume: '220925945',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/24/2023 12:00:00 AM',
    last_price: '1118.3',
    prevclose: '0',
    volume: '1575291',
    DayOpen: '1123.6',
    DayHigh: '1133.1',
    DayLow: '1113.2',
    NiftyPrice: '19386.7',
    NiftyVolume: '268256859',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/25/2023 12:00:00 AM',
    last_price: '1106.3',
    prevclose: '0',
    volume: '1515282',
    DayOpen: '1118',
    DayHigh: '1123.3',
    DayLow: '1100.25',
    NiftyPrice: '19265.8',
    NiftyVolume: '466518217',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/28/2023 12:00:00 AM',
    last_price: '1117',
    prevclose: '0',
    volume: '1959645',
    DayOpen: '1110.05',
    DayHigh: '1124.15',
    DayLow: '1105.15',
    NiftyPrice: '19306.05',
    NiftyVolume: '248178769',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/29/2023 12:00:00 AM',
    last_price: '1111.6',
    prevclose: '0',
    volume: '1749707',
    DayOpen: '1125.05',
    DayHigh: '1125.05',
    DayLow: '1106.45',
    NiftyPrice: '19342.65',
    NiftyVolume: '307388116',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/30/2023 12:00:00 AM',
    last_price: '1114.9',
    prevclose: '0',
    volume: '1543173',
    DayOpen: '1117.5',
    DayHigh: '1119.15',
    DayLow: '1110.5',
    NiftyPrice: '19347.45',
    NiftyVolume: '233004568',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '8/31/2023 12:00:00 AM',
    last_price: '1111.6',
    prevclose: '0',
    volume: '4782404',
    DayOpen: '1118.85',
    DayHigh: '1118.85',
    DayLow: '1101.7',
    NiftyPrice: '19253.8',
    NiftyVolume: '562557215',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/1/2023 12:00:00 AM',
    last_price: '1109',
    prevclose: '0',
    volume: '2715249',
    DayOpen: '1112',
    DayHigh: '1117.5',
    DayLow: '1105.75',
    NiftyPrice: '19435.3',
    NiftyVolume: '372502191',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/4/2023 12:00:00 AM',
    last_price: '1108.9',
    prevclose: '0',
    volume: '1205294',
    DayOpen: '1114',
    DayHigh: '1114',
    DayLow: '1102',
    NiftyPrice: '19528.8',
    NiftyVolume: '396071122',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/5/2023 12:00:00 AM',
    last_price: '1131.9',
    prevclose: '0',
    volume: '1698118',
    DayOpen: '1110',
    DayHigh: '1136',
    DayLow: '1107.2',
    NiftyPrice: '19574.9',
    NiftyVolume: '303958622',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/6/2023 12:00:00 AM',
    last_price: '1142.35',
    prevclose: '0',
    volume: '2960249',
    DayOpen: '1137',
    DayHigh: '1149',
    DayLow: '1129.05',
    NiftyPrice: '19611.05',
    NiftyVolume: '512974083',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/7/2023 12:00:00 AM',
    last_price: '1133.05',
    prevclose: '0',
    volume: '1863837',
    DayOpen: '1140',
    DayHigh: '1141.45',
    DayLow: '1128.6',
    NiftyPrice: '19727.05',
    NiftyVolume: '304929822',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/8/2023 12:00:00 AM',
    last_price: '1130.45',
    prevclose: '0',
    volume: '1789037',
    DayOpen: '1133',
    DayHigh: '1138.05',
    DayLow: '1128.5',
    NiftyPrice: '19819.95',
    NiftyVolume: '288093693',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/11/2023 12:00:00 AM',
    last_price: '1136.9',
    prevclose: '0',
    volume: '2032253',
    DayOpen: '1130.45',
    DayHigh: '1140',
    DayLow: '1125.7',
    NiftyPrice: '19996.35',
    NiftyVolume: '248763130',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/12/2023 12:00:00 AM',
    last_price: '1145.25',
    prevclose: '0',
    volume: '3989578',
    DayOpen: '1142.05',
    DayHigh: '1153.8',
    DayLow: '1137.1',
    NiftyPrice: '19993.2',
    NiftyVolume: '328064239',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/13/2023 12:00:00 AM',
    last_price: '1148.75',
    prevclose: '0',
    volume: '2115524',
    DayOpen: '1149',
    DayHigh: '1156.7',
    DayLow: '1142.3',
    NiftyPrice: '20070',
    NiftyVolume: '251394599',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/14/2023 12:00:00 AM',
    last_price: '1143.8',
    prevclose: '0',
    volume: '2065163',
    DayOpen: '1148.75',
    DayHigh: '1150.9',
    DayLow: '1138.75',
    NiftyPrice: '20103.1',
    NiftyVolume: '275111881',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/15/2023 12:00:00 AM',
    last_price: '1149.8',
    prevclose: '0',
    volume: '2404169',
    DayOpen: '1144',
    DayHigh: '1153.8',
    DayLow: '1144',
    NiftyPrice: '20192.35',
    NiftyVolume: '438185104',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/18/2023 12:00:00 AM',
    last_price: '1147.65',
    prevclose: '0',
    volume: '962842',
    DayOpen: '1149',
    DayHigh: '1157',
    DayLow: '1142.55',
    NiftyPrice: '20133.3',
    NiftyVolume: '264870758',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/20/2023 12:00:00 AM',
    last_price: '1153.15',
    prevclose: '0',
    volume: '2303191',
    DayOpen: '1148',
    DayHigh: '1156',
    DayLow: '1139',
    NiftyPrice: '19901.4',
    NiftyVolume: '378065882',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/21/2023 12:00:00 AM',
    last_price: '1146.15',
    prevclose: '0',
    volume: '2088934',
    DayOpen: '1149',
    DayHigh: '1156.95',
    DayLow: '1141.15',
    NiftyPrice: '19742.35',
    NiftyVolume: '275132260',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/22/2023 12:00:00 AM',
    last_price: '1132.35',
    prevclose: '0',
    volume: '1585418',
    DayOpen: '1142.5',
    DayHigh: '1148',
    DayLow: '1128.3',
    NiftyPrice: '19674.25',
    NiftyVolume: '246410075',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/25/2023 12:00:00 AM',
    last_price: '1124.05',
    prevclose: '0',
    volume: '1009279',
    DayOpen: '1126.2',
    DayHigh: '1136.8',
    DayLow: '1121.65',
    NiftyPrice: '19674.55',
    NiftyVolume: '188398392',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/26/2023 12:00:00 AM',
    last_price: '1125.85',
    prevclose: '0',
    volume: '1966198',
    DayOpen: '1125',
    DayHigh: '1130',
    DayLow: '1118.5',
    NiftyPrice: '19664.7',
    NiftyVolume: '204869058',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/27/2023 12:00:00 AM',
    last_price: '1139.95',
    prevclose: '0',
    volume: '2332018',
    DayOpen: '1126.15',
    DayHigh: '1145.9',
    DayLow: '1123.2',
    NiftyPrice: '19716.45',
    NiftyVolume: '203559874',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/28/2023 12:00:00 AM',
    last_price: '1132.3',
    prevclose: '0',
    volume: '2685663',
    DayOpen: '1142.95',
    DayHigh: '1152',
    DayLow: '1128.85',
    NiftyPrice: '19523.55',
    NiftyVolume: '352810261',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '9/29/2023 12:00:00 AM',
    last_price: '1158.65',
    prevclose: '0',
    volume: '2288577',
    DayOpen: '1134.3',
    DayHigh: '1167.4',
    DayLow: '1134.3',
    NiftyPrice: '19638.3',
    NiftyVolume: '243508919',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/3/2023 12:00:00 AM',
    last_price: '1141.45',
    prevclose: '0',
    volume: '1946966',
    DayOpen: '1159.55',
    DayHigh: '1161.95',
    DayLow: '1139.25',
    NiftyPrice: '19528.75',
    NiftyVolume: '221719625',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/4/2023 12:00:00 AM',
    last_price: '1123.8',
    prevclose: '0',
    volume: '1803871',
    DayOpen: '1138.1',
    DayHigh: '1139',
    DayLow: '1113',
    NiftyPrice: '19436.1',
    NiftyVolume: '277148310',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/5/2023 12:00:00 AM',
    last_price: '1119.1',
    prevclose: '0',
    volume: '2348562',
    DayOpen: '1124.65',
    DayHigh: '1128.65',
    DayLow: '1111.8',
    NiftyPrice: '19545.75',
    NiftyVolume: '234865323',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/6/2023 12:00:00 AM',
    last_price: '1127.3',
    prevclose: '0',
    volume: '1056281',
    DayOpen: '1128',
    DayHigh: '1132.15',
    DayLow: '1123.1',
    NiftyPrice: '19653.5',
    NiftyVolume: '159051277',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/9/2023 12:00:00 AM',
    last_price: '1123.05',
    prevclose: '0',
    volume: '1080276',
    DayOpen: '1111',
    DayHigh: '1133.8',
    DayLow: '1109',
    NiftyPrice: '19512.35',
    NiftyVolume: '165094800',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/10/2023 12:00:00 AM',
    last_price: '1126.5',
    prevclose: '0',
    volume: '1092093',
    DayOpen: '1122',
    DayHigh: '1130.4',
    DayLow: '1119.8',
    NiftyPrice: '19689.85',
    NiftyVolume: '216559447',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/11/2023 12:00:00 AM',
    last_price: '1128.75',
    prevclose: '0',
    volume: '1456045',
    DayOpen: '1128',
    DayHigh: '1134.45',
    DayLow: '1122.35',
    NiftyPrice: '19811.35',
    NiftyVolume: '213729730',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/12/2023 12:00:00 AM',
    last_price: '1130.4',
    prevclose: '0',
    volume: '1221965',
    DayOpen: '1129',
    DayHigh: '1132.55',
    DayLow: '1121.2',
    NiftyPrice: '19794',
    NiftyVolume: '217904144',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/13/2023 12:00:00 AM',
    last_price: '1145.3',
    prevclose: '0',
    volume: '1934884',
    DayOpen: '1126.1',
    DayHigh: '1147.2',
    DayLow: '1122',
    NiftyPrice: '19751.05',
    NiftyVolume: '254955979',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/16/2023 12:00:00 AM',
    last_price: '1135.3',
    prevclose: '0',
    volume: '1144308',
    DayOpen: '1141.5',
    DayHigh: '1147.25',
    DayLow: '1133.05',
    NiftyPrice: '19731.75',
    NiftyVolume: '180964341',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/17/2023 12:00:00 AM',
    last_price: '1136.8',
    prevclose: '0',
    volume: '798807',
    DayOpen: '1138.65',
    DayHigh: '1144',
    DayLow: '1134',
    NiftyPrice: '19811.5',
    NiftyVolume: '185846716',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/18/2023 12:00:00 AM',
    last_price: '1153.4',
    prevclose: '0',
    volume: '2178517',
    DayOpen: '1137',
    DayHigh: '1161.8',
    DayLow: '1134.05',
    NiftyPrice: '19671.1',
    NiftyVolume: '198942937',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/19/2023 12:00:00 AM',
    last_price: '1141.5',
    prevclose: '0',
    volume: '1765065',
    DayOpen: '1147.4',
    DayHigh: '1150',
    DayLow: '1135',
    NiftyPrice: '19624.7',
    NiftyVolume: '230312163',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/20/2023 12:00:00 AM',
    last_price: '1142.2',
    prevclose: '0',
    volume: '957910',
    DayOpen: '1138',
    DayHigh: '1145',
    DayLow: '1133.1',
    NiftyPrice: '19542.65',
    NiftyVolume: '198341255',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/23/2023 12:00:00 AM',
    last_price: '1122.45',
    prevclose: '0',
    volume: '702730',
    DayOpen: '1135',
    DayHigh: '1149.2',
    DayLow: '1118',
    NiftyPrice: '19281.75',
    NiftyVolume: '176044288',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  },
  {
    co_code: '4325',
    tradedate: '10/25/2023 12:00:00 AM',
    last_price: '1120.3',
    prevclose: '0',
    volume: '1800673',
    DayOpen: '1125',
    DayHigh: '1129.7',
    DayLow: '1112.8',
    NiftyPrice: '19122.15',
    NiftyVolume: '225291741',
    Companyname: 'Sun Pharma.Inds.',
    ISIN: 'INE044A01036'
  }
]
const formatCData = (response: any) => {
  var newQuotes = []
  if (response['Last Trade Time']) {
    var ts = response['Last Trade Time'].split(' ')
    var dt = ts[0].concat(
      ' ' +
        ts[1].substring(0, 2) +
        ':' +
        ts[1].substring(2, 4) +
        ':' +
        ts[1].substring(4, 6)
    )
    const decimalP = (x: any) => {
      var y = x.toString()
      var z = `${y.substring(0, 4)}.${y.substring(4, y.length)}`
      return parseFloat(z)
    }
    let newQuote: any = {}
    newQuote.Ask = parseFloat(response['Buy Price'])
    newQuote.Bid = parseFloat(response['Sell Price'])
    newQuote.Close = parseFloat(response['Last Trade Price'])
    newQuote.DT = new Date(response['tradedate']) // DT is a string in ISO format, make it a DT instance
    newQuote.Date = new Date(response['tradedate'])
    newQuote.High = decimalP(response['High Price'])
    newQuote.Low = decimalP(response['Low Price'])
    newQuote.Open = parseFloat(response['Open Price'])
    newQuote.Volume = parseFloat(response['Volume'])
    newQuotes.push(newQuote)
  } else {
    for (var i = 0; i < response.length; i++) {
      var newQuote: any = {}
      // newQuote.Date = new Date(response[i].tradedate) // DT is a string in ISO format, make it a Date instance
      newQuote.DT = `${new Date(response[i].tradedate)}`
      newQuote.Open = parseFloat(response[i].DayOpen)
      newQuote.High = parseFloat(response[i].DayHigh)
      newQuote.Low = parseFloat(response[i].DayLow)
      newQuote.Close = parseFloat(response[i].last_price)
      newQuote.Volume = parseFloat(response[i].volume)
      newQuotes.push(newQuote)
    }
    return newQuotes
  }
}
const INITIAL_STATE: IHistoricalData[] = []

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      historicalDataActions.success,
      (state, { payload }) => (state = payload)
    )
    builder.addCase(clearHistoricalDataAction, state => (state = INITIAL_STATE))
  },
  reducers: {}
})

export default slice.reducer
