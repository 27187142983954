import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import {
  createWatchlistActions,
  deleteWatchlistActions,
  fetchWatchlistsActions,
  renameWatchlistActions
} from './Actions'

export const preKeptNameEntries = [
  'Watchlist 1',
  'Watchlist 2',
  'Watchlist 3',
  'Watchlist 4',
  'Watchlist 5',
  'Watchlist 6',
  'Watchlist 7',
  'Watchlist 8',
  'Watchlist 9',
  'Watchlist 10'
]

export type T_SCRIPTS_OBJ = {
  scriptId: string
  sequenceNumber: number
}

export interface watchlistObj {
  watchlistId: string
  subAccountId: string
  watchlistName: string
  watchlistType: string
  watchlistSequenceNumber: number
  watchlistSecurities: T_SCRIPTS_OBJ[] | null
}

export interface WatchlistStore {
  isEdit: boolean
  selectedWatchlist: watchlistObj
  watchlistTab: number
  error: { code: number; message: string; isDispatch: boolean }
  watchlist: watchlistObj[]
  subscribe: boolean
  watchListIndex: {
    [key: string]: number
  }
  watchListToSecuritiesArrayMapping: {
    [key: string]: T_SCRIPTS_OBJ[]
  }
  addScriptToParticularWatchlist: boolean
  sortScriptSelectedOption: {
    [key: string]: number
  }
  watchlistPreKeptAvailableNames: {
    [key: string]: number
  }
  bookMarkScriptData: {
    scriptId: string | number
    stockAvailableInWatchListArray: string[]
    tempAddScriptToParticularWatchlist: boolean
  }
  scrollingScripts: { scriptId: string }[]
}

const INITIAL_STATE: WatchlistStore = {
  isEdit: false,
  selectedWatchlist: {} as watchlistObj,
  watchlistTab: 0,
  watchlist: [],
  error: { code: 0, message: '', isDispatch: true },
  subscribe: true,
  watchListIndex: {},
  watchListToSecuritiesArrayMapping: {},
  addScriptToParticularWatchlist: false,
  sortScriptSelectedOption: {},
  watchlistPreKeptAvailableNames: {
    'Watchlist 1': 1,
    'Watchlist 2': 1,
    'Watchlist 3': 1,
    'Watchlist 4': 1,
    'Watchlist 5': 1,
    'Watchlist 6': 1,
    'Watchlist 7': 1,
    'Watchlist 8': 1,
    'Watchlist 9': 1,
    'Watchlist 10': 1
  },
  bookMarkScriptData: {
    scriptId: '',
    stockAvailableInWatchListArray: [],
    tempAddScriptToParticularWatchlist: false
  },
  scrollingScripts: [
    { scriptId: '9926000' },
    { scriptId: '9919000' },
    { scriptId: '9926009' }
  ]
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    watchlistEdit: (state, action) => {
      state.isEdit = action?.payload.isEdit
    },
    selectWatchlist: (state, action) => {
      state.selectedWatchlist = action?.payload
    },
    selectWatchlistTab: (state, action) => {
      state.watchlistTab = action?.payload.watchlistTab
    },
    setWatchlist: (state, action) => {
      state.watchlist = action?.payload.Watchlist
    },
    setSecurityArray: (state, action) => {
      const { watchlistId, securitiesArray } = action?.payload
      state.subscribe = false
      const watchListNumber = state.watchListIndex[watchlistId]
      state.watchlist[watchListNumber - 1].watchlistSecurities = securitiesArray
    },
    setWatchlistIndex: (state, action) => {
      state.watchListIndex = action?.payload.watchListIndex
    },
    setAddScriptToParticularWatchlist: (state, action) => {
      state.addScriptToParticularWatchlist = action?.payload
    },
    subscribe: (state, action) => {
      state.subscribe = action?.payload
    },
    setWatchlistToSecuritiesArray: (state, action) => {
      const { watchlistId, securitiesArray } = action?.payload
      state.watchListToSecuritiesArrayMapping[watchlistId] = securitiesArray
    },
    setSortScriptSelectedOption: (state, action) => {
      const { watchlistId, sortScriptOption } = action?.payload
      state.sortScriptSelectedOption[watchlistId] = sortScriptOption
    },
    deleteUsedPreKeptName: (state, action) => {
      const { watchlistName } = action?.payload
      delete state.watchlistPreKeptAvailableNames[watchlistName]
    },
    insertPreKeptName: (state, action) => {
      const { watchlistName } = action?.payload
      state.watchlistPreKeptAvailableNames[watchlistName] = 1
    },
    setBookMarkScriptData: (state, action) => {
      const { bookMarkScriptData } = action?.payload
      state.bookMarkScriptData = bookMarkScriptData
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(fetchWatchlistsActions.success, (state, { payload }) => {
      if (payload.length === 1) {
        const [watchList] = payload
        state.watchListIndex[watchList.watchlistId] =
          watchList.watchlistSequenceNumber
        state.sortScriptSelectedOption[watchList.watchlistId] = 0

        const securitiesArrayPostSanity = getSecuritiesArrayPostSanity(
          watchList.watchlistSecurities
        )

        const newWatchList = {
          ...watchList,
          watchlistSecurities: securitiesArrayPostSanity
        }

        state.watchListToSecuritiesArrayMapping[watchList.watchlistId] =
          securitiesArrayPostSanity

        state.watchlist = [newWatchList]
        return
      }

      const sortByWatchListSequence = [...payload].sort(
        (a: watchlistObj, b: watchlistObj) => {
          return a.watchlistSequenceNumber - b.watchlistSequenceNumber
        }
      )

      const newWatchListArray: watchlistObj[] = []

      sortByWatchListSequence.forEach((watchListObject: watchlistObj) => {
        const {
          watchlistId,
          watchlistSequenceNumber,
          watchlistSecurities,
          watchlistName
        } = watchListObject

        if (state.watchlistPreKeptAvailableNames[watchlistName]) {
          delete state.watchlistPreKeptAvailableNames[watchlistName]
        }

        state.watchListIndex[watchlistId] = watchlistSequenceNumber
        state.sortScriptSelectedOption[watchlistId] = 0

        const securitiesArrayPostSanity =
          getSecuritiesArrayPostSanity(watchlistSecurities)

        const newWatchList = {
          ...watchListObject,
          watchlistSecurities: securitiesArrayPostSanity
        }

        newWatchListArray.push(newWatchList)

        state.watchListToSecuritiesArrayMapping[watchlistId] =
          securitiesArrayPostSanity
      })

      state.watchlist = newWatchListArray
    })

    // createWatchlistActions
    builder.addCase(createWatchlistActions.success, (state, { payload }) => {
      const { watchlistId, watchlistSequenceNumber, watchlistName } = payload
      state.subscribe = false
      state.watchlist = [...state.watchlist, payload]
      state.watchListIndex[watchlistId] = watchlistSequenceNumber
      state.sortScriptSelectedOption[watchlistId] = 0
      state.watchListToSecuritiesArrayMapping[watchlistId] = []
      if (state.watchlistPreKeptAvailableNames[watchlistName]) {
        delete state.watchlistPreKeptAvailableNames[watchlistName]
      }
    })

    // deleteWatchlistActions
    builder.addCase(deleteWatchlistActions.success, (state, { payload }) => {
      const { reqData } = payload
      const { watchlistId } = reqData
      const newWatchlistArray: watchlistObj[] = []
      let isDeletedWatchListFound = false
      state.watchlist.forEach((item: watchlistObj) => {
        const {
          watchlistId: WATCHLISTID,
          watchlistSequenceNumber,
          watchlistName
        } = item
        if (WATCHLISTID === watchlistId) {
          isDeletedWatchListFound = true
          delete state.watchListIndex[WATCHLISTID]
          delete state.sortScriptSelectedOption[WATCHLISTID]
          delete state.watchListToSecuritiesArrayMapping[WATCHLISTID]
          if (preKeptNameEntries.includes(watchlistName)) {
            state.watchlistPreKeptAvailableNames[watchlistName] = 1
          }
          return
        }
        state.watchListIndex[WATCHLISTID] = !isDeletedWatchListFound
          ? watchlistSequenceNumber
          : watchlistSequenceNumber - 1

        newWatchlistArray.push(
          !isDeletedWatchListFound
            ? item
            : {
                ...item,
                watchlistSequenceNumber: watchlistSequenceNumber - 1
              }
        )
      })
      state.watchlist = newWatchlistArray
    })

    // renameWatchlistActions
    builder.addCase(renameWatchlistActions.success, (state, { payload }) => {
      const { watchlistName, watchlistId } = payload
      let prevWatchlistName = ''
      state.watchlist = state.watchlist.map((item: watchlistObj) => {
        const {
          watchlistId: PREV_WATCHLISTID,
          watchlistName: PREV_WATCHLISTNAME
        } = item
        if (PREV_WATCHLISTID === watchlistId) {
          prevWatchlistName = PREV_WATCHLISTNAME
          return payload
        }
        return item
      })
      if (state.watchlistPreKeptAvailableNames[watchlistName]) {
        delete state.watchlistPreKeptAvailableNames[watchlistName]
      }
      if (preKeptNameEntries.includes(prevWatchlistName)) {
        state.watchlistPreKeptAvailableNames[prevWatchlistName] = 1
      }
    })
  }
}

const watchlistSlice = createSlice(sliceOptions)

export const {
  watchlistEdit,
  selectWatchlist,
  selectWatchlistTab,
  setWatchlist,
  subscribe,
  setSecurityArray,
  setWatchlistIndex,
  setWatchlistToSecuritiesArray,
  setAddScriptToParticularWatchlist,
  setSortScriptSelectedOption,
  deleteUsedPreKeptName,
  setBookMarkScriptData
} = watchlistSlice.actions

export default watchlistSlice.reducer

const getSecuritiesArrayPostSanity = (
  securitiesArray: T_SCRIPTS_OBJ[] | null
) => {
  if (!securitiesArray?.length) {
    return securitiesArray
  }

  const securitiesArrayPostSanity = securitiesArray.filter(
    (scriptObj: T_SCRIPTS_OBJ) => {
      const { scriptId } = scriptObj
      return (window as any).secMaster._isScriptAvailableInSecurityMaster(
        +scriptId
      )
    }
  )

  return securitiesArrayPostSanity
}
