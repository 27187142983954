import {
  DsBox,
  DsCheckbox,
  DsTypography,
  withBreakpoints
} from '@am92/react-design-system'
import React, { Component } from 'react'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'

export interface ITermsandConditionsProps extends IWithRouterProps {
  accepted: boolean
  setAccepted: (item: boolean) => any
}

export class TermsandConditions extends Component<ITermsandConditionsProps> {
  handleChange = () => {
    const { accepted, setAccepted } = this.props
    setAccepted(!accepted)
  }

  render() {
    const { accepted, navigateTo } = this.props
    return (
      <DsBox
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 'var(--ds-spacing-glacial)',
          alignItems: 'center',
          background: '#F1F4F7',
          padding: 'var(--ds-spacing-frostbite)',
          borderRadius: 'var(--ds-spacing-quickFreeze)',
          width: '100%'
        }}
      >
        <DsCheckbox
          name='isTermsAndConditionsAccepted'
          onChange={this.handleChange}
          // onBlur={handleBlur}
          checked={accepted}
          sx={{ p: 'var(--ds-spacing-zero)' }}
        />
        <DsTypography variant='bodyRegularSmall'>
          I have read and accept the{' '}
          <DsTypography
            color={'var(--ds-colour-actionSecondary)'}
            variant='bodyRegularSmall'
            sx={{
              cursor: 'pointer'
            }}
            onClick={() =>
              navigateTo(`${APP_ROUTES.SIP_TERMS.pathname}?header=true`)
            }
          >
            terms and conditions
          </DsTypography>
        </DsTypography>
      </DsBox>
    )
  }
}
export default withBreakpoints(withRouter(TermsandConditions))
