import { AS_REVERSE_FEED_WSS_URL } from '~/src/Configurations/env'
import {
  TReverseFeedResponse,
  reverseFeedResponse,
  reverseFeedResponseMapper
} from './ReverseFeedResponseMapper'
import AppStore from '~/src/Configurations/AppStore'
import { updateReverseFeedAlertData } from '~/src/Redux/ReverseFeed/Reducer'

class ReverseFeedSocket {
  headers: string
  connection: WebSocket | null
  reverseFeedResponse: TReverseFeedResponse
  constructor(headers: string) {
    this.headers = headers
    this.reverseFeedResponse = reverseFeedResponse
    this.connection = new WebSocket(AS_REVERSE_FEED_WSS_URL as string, [
      'notification',
      headers
    ])
    this.connection.onmessage = this.onMessage
    this.connection.onopen = this.onOpen
    this.connection.onclose = this.onClose
    this.connection.onerror = this.onError
  }

  onMessage = (e: MessageEvent) => {
    this.decodeReverseFeedResponse(e.data)
  }

  onOpen = (e: Event) => {
    setInterval(() => {
      this.send('HeartBeat')
    }, 5000)
  }

  send = (data: any) => {
    this.connection?.send(data)
  }

  onClose = () => {
    this.connection?.close()
  }

  onError = (e: Event) => {
    console.log(e)
  }

  decodeReverseFeedResponse = (reverseFeedResponse: string) => {
    const reverseFeedResponseArray = reverseFeedResponse.split('|')
    reverseFeedResponseArray.forEach((reverseFeedResponseElement: string) => {
      const [notificationKey, notificationValue] =
        reverseFeedResponseElement.split('=')
      const reverseFeedResponseKey = reverseFeedResponseMapper[+notificationKey]
      this.reverseFeedResponse = {
        ...this.reverseFeedResponse,
        [reverseFeedResponseKey]: notificationValue
      }
    })

    const { NOTIFICATION_TYPE } = this.reverseFeedResponse
    AppStore.dispatch(
      updateReverseFeedAlertData({
        reverseFeedAlertData: {
          ...this.reverseFeedResponse,
          ...(NOTIFICATION_TYPE === 'SCRIPT_ALERT' && {
            ORD_ENTRY_DATE: new Date().getTime()
          })
          //NOTE - added timestamp explicitly for
          // NOTIFICATION_TYPE - SCRIPT_ALERT since we are not getting timestamp for script alert
        }
      })
    )
  }
}

export default ReverseFeedSocket
