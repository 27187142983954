import { format as formatDate, isValid, parse } from 'date-fns';
import { DsDatePickerDefaultProps } from './DsDatePicker.Types';
export const getDateFromValue = (value, valueType, format = DsDatePickerDefaultProps.format) => {
    if (!value) {
        return;
    }
    switch (valueType) {
        case 'date': {
            if (isValid(value)) {
                return value;
            }
            else {
                console.warn(`Expected the value to be of Date type but recived ${value}. Please check the value passed in your integration.`);
                return undefined;
            }
        }
        case 'formattedValue': {
            const parsedValue = parse(value, format, new Date());
            if (isValid(parsedValue)) {
                return parsedValue;
            }
            else {
                console.warn(`Expected the value to be of ${format} type but recived ${value}. Please check the value passed in your integration.`);
                return undefined;
            }
        }
    }
};
export const getValueTypeFromValue = (value, valueType, format = DsDatePickerDefaultProps.format) => {
    if (!value) {
        return undefined;
    }
    switch (valueType) {
        case 'date': {
            return value;
        }
        case 'formattedValue': {
            const formattedDate = formatDate(value, format);
            return formattedDate;
        }
    }
};
export const getErrorFromErrorMap = (errorMap, error, value) => {
    if (!errorMap) {
        return error || 'Unkown Error';
    }
    if (!error) {
        return 'Unkown Error';
    }
    const selectedDate = `${value}` || '';
    const errorMessage = (errorMap[error] || error).replace('${selectedDate}', selectedDate);
    return errorMessage;
};
