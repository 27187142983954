import { DsDrawer } from '@am92/react-design-system'
import React, { Component } from 'react'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import AlertsForm from './AlertsForm'
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { ThunkDispatch } from '@reduxjs/toolkit'
import LTPTicker from '../SortingTable/LTPTicker'

type ActionTypes = {

}

interface IAlertsFormModalProps extends IWithRouterProps {
  params: any
  alertFormDetails: any
  actions: ActionTypes
}

export class AlertsFormModal extends Component<IAlertsFormModalProps> {
  constructor(props: IAlertsFormModalProps) {
    super(props)
  }
  render() {
    const { alertFormDetails } = this.props
    const { toggle } = alertFormDetails
    return (
      <DsDrawer
        PaperProps={{
          sx: {
            top: {
              md: '5.3rem',
              lg: '5.4rem',
              xs: '0%'
            },
            height: {
              md: 'calc(100% - 4.2rem)',
              lg: 'calc(100% - 5.4rem)',
              xs: '100%'
            },
            width: { md: 'auto', sm: '100%' },
            boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.08)'
          }
        }}
        keepMounted={false}
        sx={theme => ({ position: 'absolute', zIndex: theme.zIndex.drawer })}
        anchor="right"
        open={toggle}
        variant="persistent"
        hideBackdrop
      >
        <AlertsForm alertFormDetails={alertFormDetails} LTP={<LTPTicker scriptId={alertFormDetails.scriptId} />} />
      </DsDrawer>
    )
  }
}

const mapStateToProps = (state: any,) => ({})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(AlertsFormModal)