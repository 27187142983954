import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { PureComponent } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { DsTypography } from '../DsTypography';
import { DsLink } from '../DsLink';
export class DsTooltip extends PureComponent {
    renderTitle = () => {
        const { heading, description, buttonGroup } = this.props;
        const tooltipButtonGroup = buttonGroup
            ? React.cloneElement(buttonGroup, {
                size: 'small',
                fullWidth: true,
                noPadding: true,
                sx: { bgcolor: 'transparent', mt: 'var(--ds-spacing-bitterCold)' }
            })
            : false;
        return (_jsxs(_Fragment, { children: [heading && (_jsx(DsTypography, { component: "div", variant: "bodyBoldMedium", sx: { mb: 'var(--ds-spacing-glacial)' }, children: heading })), description && (_jsx(DsTypography, { component: "div", variant: "bodyRegularMedium", children: description })), tooltipButtonGroup] }));
    };
    render() {
        const { heading, description, buttonGroup, children, ...tooltipProps } = this.props;
        return (_jsx(Tooltip, { title: this.renderTitle(), ...tooltipProps, children: _jsx(DsLink, { component: "span", underline: "always", color: "inherit", variant: "inherit", sx: { textDecorationColor: 'inherit' }, children: children }) }));
    }
}
