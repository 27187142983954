import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import {
  updateWatchlistsSequenceActions,
  updateWatchlistsSequenceServiceName
} from '../Actions'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export interface updateWatchListSequenceObj {
  watchlistId: string
  sequenceNumber: number
}
export interface updateWatchlistsSequencePayload {
  watchlists: updateWatchListSequenceObj[]
}

async function updateWatchlistsSequence(
  reqData: updateWatchlistsSequencePayload
) {
  const options = {
    url: '/watch-lists/update-sequence',
    method: 'POST',
    data: reqData,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const updateWatchlistsSequenceAction = serviceActionCreator(
  updateWatchlistsSequenceActions,
  updateWatchlistsSequence
)

export default updateWatchlistsSequenceAction
export { updateWatchlistsSequenceServiceName }
