import * as React from 'react'
import {
  DsBox,
  DsButton,
  DsButtonGroup,
  DsButtonGroupProps,
  DsButtonProps,
  DsDivider,
  DsLink,
  DsStack,
  DsTypography,
  DsRemixIcon,
  DsPopup
} from '@am92/react-design-system'

interface IReachOut {
  contactNumbers: string[]
  emails: string[]
}

export interface IBottomStaggerProps {
  title: string
  descriptions: string[]
  primaryButtonLabel: string
  primaryButtonClick: () => void
  primaryButtonIcon?: string

  reachOut?: IReachOut
  buttonGroupSeperator?: string
  secondaryButtonLabel?: string
  secondaryButtonIcon?: string

  secondaryButtonClick?: () => void
  tertiaryButtonLabel?: string
  tertiaryButtonClick?: () => void
  onClose?: () => void

  buttonGroupProps?: DsButtonGroupProps
  primaryButtonProps?: Omit<DsButtonProps, 'color' | 'ref' | 'onClick'>
  secondaryButtonProps?: Omit<DsButtonProps, 'color' | 'ref' | 'onClick'>
}

const DBottomStaggerProps = {
  buttonGroupProps: {},
  primaryButtonProps: {},
  secondaryButtonProps: {},
  onClose: () => undefined
}

export default class BottomStagger extends React.PureComponent<IBottomStaggerProps> {
  renderButtonGroup = () => {
    const {
      primaryButtonLabel,
      primaryButtonIcon,
      primaryButtonProps,
      primaryButtonClick,
      secondaryButtonLabel,
      secondaryButtonIcon,
      secondaryButtonProps,
      secondaryButtonClick
    } = this.props

    return (
      <DsButtonGroup fullWidth noPadding>
        {secondaryButtonLabel && (
          <DsButton
            color='secondary'
            {...secondaryButtonProps}
            onClick={secondaryButtonClick}
            {...(secondaryButtonIcon && {
              endIcon: <DsRemixIcon className={secondaryButtonIcon} />
            })}
            {...(primaryButtonIcon && {
              sx: { width: '70%' }
            })}
          >
            {secondaryButtonLabel}
          </DsButton>
        )}
        <DsButton
          color='primary'
          {...primaryButtonProps}
          onClick={primaryButtonClick}
          {...(primaryButtonIcon && {
            endIcon: <DsRemixIcon className={primaryButtonIcon} />
          })}
          {...(secondaryButtonIcon && {
            sx: { width: '70%' }
          })}
        >
          {primaryButtonLabel}
        </DsButton>
      </DsButtonGroup>
    )
  }

  renderDescriptions = () => {
    const { descriptions } = this.props
    return descriptions.map(description => {
      return (
        <DsTypography
          color='text.secondary'
          variant='bodyRegularSmall'
          component={'div'}
        >
          {description.split('/').map(e => {
            return e.charAt(0) === '&' ? <b>{e.substring(1)}</b> : e
          })}
        </DsTypography>
      )
    })
  }

  renderReachOut = () => {
    const { reachOut } = this.props

    if (!reachOut) return null

    const { contactNumbers, emails } = reachOut
    const contactNumberJSX = contactNumbers.map((contactNumber, index) => (
      <>
        <DsLink underline='none' href={`tel:${contactNumber}`}>
          {contactNumber}
        </DsLink>
        {index < contactNumbers.length - 1 && (
          <DsTypography
            sx={{
              mx: 'var(--ds-spacing-glacial)'
            }}
            variant='bodyRegularSmall'
          >
            |
          </DsTypography>
        )}
      </>
    ))
    const emailJSX = emails.map((email, index) => (
      <>
        <DsLink underline='none' href={`mailto:${email}`}>
          {email}
        </DsLink>
        {index < emails.length - 1 && (
          <DsTypography
            sx={{
              mx: 'var(--ds-spacing-frostbite)'
            }}
            variant='bodyRegularSmall'
          >
            |
          </DsTypography>
        )}
      </>
    ))
    return (
      <DsStack direction='row' spacing='var(--ds-spacing-frostbite)'>
        <DsStack spacing='var(--ds-spacing-quickFreeze)' sx={{ flexGrow: 1 }}>
          <DsTypography color='text.secondary' variant='supportRegularMetadata'>
            Contact number
          </DsTypography>
          <DsBox sx={{ display: 'flex' }}>{contactNumberJSX}</DsBox>
        </DsStack>
        <DsStack spacing='var(--ds-spacing-quickFreeze)' sx={{ flexGrow: 1 }}>
          <DsTypography color='text.secondary' variant='supportRegularMetadata'>
            Email Address
          </DsTypography>
          <DsTypography variant='bodyRegularSmall'>{emailJSX}</DsTypography>
        </DsStack>
      </DsStack>
    )
  }

  renderButtonGroupSeperator = () => {
    const { buttonGroupSeperator } = this.props
    if (!buttonGroupSeperator) return null

    return (
      <DsDivider>
        <DsBox sx={{ px: 'var(--ds-spacing-frostbite)' }}>
          <DsTypography variant='supportRegularMetadata'>
            {buttonGroupSeperator}
          </DsTypography>
        </DsBox>
      </DsDivider>
    )
  }

  renderTertiaryButton = () => {
    const { tertiaryButtonLabel, tertiaryButtonClick } = this.props
    if (!tertiaryButtonLabel) return null

    return (
      <DsBox>
        <DsButton
          variant='text'
          color='secondary'
          onClick={tertiaryButtonClick}
        >
          {tertiaryButtonLabel}
        </DsButton>
      </DsBox>
    )
  }

  render() {
    const { title, onClose } = this.props

    return (
      <DsPopup
        open
        onClose={onClose}
        sx={{
          '.MuiDialogContent-root': {
            marginTop: 'var(--ds-spacing-zero)'
          },
          display: { xs: 'block', md: 'none' }
        }}
        showClose={true}
      // DsDialogProps={{
      //   open: true,
      //   onClose: onClose,
      //   sx: {
      //     '.MuiDialogContent-root': {
      //       marginTop: 'var(--ds-spacing-zero)'
      //     },
      //     display: { xs: 'block', md: 'none' }
      //   }
      // }}
      >
        <DsStack spacing='var(--ds-spacing-mild)' sx={{ textAlign: 'left' }}>
          <DsTypography variant='headingBoldMedium' component={'div'}>
            {title}
          </DsTypography>
          <DsBox
            sx={{
              p: 'var(--ds-spacing-glacial)',
              backgroundColor: 'var(--ds-colour-surfaceSecondary)',
              borderRadius: 'var(--ds-radius-glacial)'
            }}
          >
            <DsStack spacing='var(--ds-spacing-cool)'>
              {this.renderDescriptions()}
            </DsStack>
          </DsBox>
          {this.renderReachOut()}
          {this.renderButtonGroupSeperator()}
          {this.renderButtonGroup()}
        </DsStack>
      </DsPopup>
    )
  }
}
