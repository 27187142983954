import { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
export { AxiosError };
/**
 * Default WebHttpAxiosConfig for WebHttp instance.
 */
export const DefaultWebHttpAxiosConfig = {
    retryDelay: axiosRetry.exponentialDelay,
    retries: 0
};
/**
 * Default WebHttpConfig for WebHttp instance.
 */
export const DefaultWebHttpConfig = {
    disableCrypto: false,
    disableHeaderInjection: false
};
