export const DsDatePickerDefaultProps = {
    orientation: 'portrait',
    closeOnSelect: false,
    format: 'dd/MM/yyyy',
    name: '',
    valueType: 'date'
};
export const DsDatePickerDefaultState = {
    open: false,
    views: ['day']
};
