import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import { I_SCRIPT_SEARCH_STRUCTURE } from '~/src/Constants/SEC_MASTER'
import {
  createRecentSearchActions,
  deleteRecentSearchActions,
  getRecentSearchActions
} from './Actions'

type searchType = {
  searchData: I_SCRIPT_SEARCH_STRUCTURE[]
  recentSearchData: I_SCRIPT_SEARCH_STRUCTURE[]
}

const INITIAL_STATE: searchType = {
  searchData: [],
  recentSearchData: []
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    search: (state, action) => {
      // TODO: filter result based on types
      state.searchData = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getRecentSearchActions.success, (state, { payload }) => {
      console.log('recent', payload)
      state.recentSearchData = payload?.recentSearches || []
    }),
      builder.addCase(
        createRecentSearchActions.success,
        (state, { payload }) => {
          // state.recentSearch = payload.recentSearches
        }
      ),
      builder.addCase(
        deleteRecentSearchActions.success,
        (state, { payload }) => {
          state.recentSearchData = []
        }
      )
  }
}

const searchSlice = createSlice(sliceOptions)

export const { search } = searchSlice.actions

export default searchSlice.reducer
