import { jsx as _jsx } from "react/jsx-runtime";
import { PureComponent } from 'react';
import Chip from '@mui/material/Chip';
import { DsChipDefaultProps } from './DsChip.Types';
export class DsChip extends PureComponent {
    static defaultProps = DsChipDefaultProps;
    render() {
        return _jsx(Chip, { ...this.props, "ds-variant": "chip" });
    }
}
