import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import {
  getCustomersPortfolioDetailsActions,
  getHoldingsDetailsActions,
  getHoldingsSummaryActions,
  getTradingHistoryActions
} from './Actions'
import { THoldingsDocument } from '~/src/Pages/Dashboard/Components/HoldingSummaryCard/HoldingSummaryCard'
import { getRecommendationByScripIdActions } from '../ResearchAndRecommendation/Actions'
import _ from 'lodash'

export const DEFAULT_VALUES = {
  selectedSortBy: 'INVESTED_HIGH_TO_LOW',
  selectedSortByPledge: 'HAIRCUT_HIGH_TO_LOW'
}
interface RecommendationCalls {
  isin: string;
  calls: string[];
}
export type IRecommendationCalls = RecommendationCalls[];

const INITIAL_STATE = {
  // Holding Summary Redux values
  HOLDING_SUMMARY_SUMMED_INVESTED_VALUE: 0,
  HOLDING_SUMMARY_SCRIPTS_IN_HOLDING: [],
  HOLDING_SUMMARY_HOLDINGS_OBJECT: {},

  // Holding Details Redux values
  SUMMED_INVESTED_VALUE: 0,
  SCRIPTS_IN_HOLDING: [],
  holdingDetails: [],
  holdingsFilter: {
    stocksFilter: [],
    sortBy: 'INVESTED_HIGH_TO_LOW'
  },
  pledgeFilter: {
    stocksFilter: [],
    sortBy: 'HAIRCUT_HIGH_TO_LOW'
  },
  isDefaultFiltersEnabled: true,
  scriptsArray: [],
  selectedFilterTabInHolding: 'All',

  // Pledge Details Redux values
  pledgeDetails: [],
  pledgeData: [],
  isPledgeDefaultFiltersEnabled: true,

  tradingHistory: [],
  recommendationDataByScripId: []
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    holdingsFilter: (state, action) => {
      state.holdingsFilter = action?.payload
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isDefaultFiltersEnabled = true
      } else {
        state.isDefaultFiltersEnabled = false
      }
    },
    setHoldings: (state, action) => {
      state.SCRIPTS_IN_HOLDING = action?.payload
    },
    pledgeFilter: (state, action) => {
      state.pledgeFilter = action?.payload

      if (checkIfPledgeDefaultFiltersAreSet(state)) {
        state.isPledgeDefaultFiltersEnabled = true
      } else {
        state.isPledgeDefaultFiltersEnabled = false
      }
    },
    setPledge: (state, action) => {
      state.pledgeDetails = action?.payload
    },
    changeSelectedFilterTabInHolding: (state, action) => {
      state.selectedFilterTabInHolding = action?.payload
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getCustomersPortfolioDetailsActions.success,
      (state, { payload }) => {
        state.Portfolio = payload.data
      }
    )

    builder.addCase(
      getRecommendationByScripIdActions.success,
      (state, { payload }) => {
        state.recommendationDataByScripId = payload || []
        const updatedData = _.map(state.SCRIPTS_IN_HOLDING, item => {
        const match = _.find(state.recommendationDataByScripId, {
          isin: item.isinCode
        })
        return match ? _.merge({}, item, match) : item
      })
      state.SCRIPTS_IN_HOLDING = updatedData
      }
    )

    builder.addCase(getTradingHistoryActions.success, (state, { payload }) => {
      state.tradingHistory = payload
    })

    builder.addCase(getHoldingsSummaryActions.success, (state, { payload }) => {
      const { holdingDetails } = payload
      let HOLDING_SUMMARY_SUMMED_INVESTED_VALUE: number = 0
      let HOLDING_SUMMARY_SCRIPTS_IN_HOLDING: THoldingsDocument[] = []

      holdingDetails?.forEach((item: any) => {
        const {
          investedValue,
          holdingQty,
          securityInfo,
          isinCode,
          avgPrice,
          unrealizedGain,
          collateralValue
        } = item

        const { scriptId, symbol, haircutPct } = securityInfo[0]
        if (scriptId !== '0') {
          HOLDING_SUMMARY_SUMMED_INVESTED_VALUE =
            HOLDING_SUMMARY_SUMMED_INVESTED_VALUE + Number(investedValue || 0)
          HOLDING_SUMMARY_SCRIPTS_IN_HOLDING.push({
            scriptId,
            holdingQty,
            investedValue,
            avgPrice,
            symbol,
            isinCode,
            haircutPct,
            collateralValue,
            calls: []
          })
        }

        for (let i = 0; i < securityInfo.length; i++) {
          const { scriptId } = securityInfo[i]
          state.HOLDING_SUMMARY_HOLDINGS_OBJECT[scriptId] = {
            holdings: holdingQty,
            avgPrice: avgPrice,
            investedValue: investedValue,
            unrealizedGain
          }
        }
      })
      state.HOLDING_SUMMARY_SUMMED_INVESTED_VALUE =
        HOLDING_SUMMARY_SUMMED_INVESTED_VALUE
      let newArray = [...HOLDING_SUMMARY_SCRIPTS_IN_HOLDING]
      newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
        const { investedValue: investedValueA } = ITEM_A

        const { investedValue: investedValueB } = ITEM_B

        return investedValueB - investedValueA
      })

      state.HOLDING_SUMMARY_SCRIPTS_IN_HOLDING = newArray
    })

    builder.addCase(getHoldingsSummaryActions.error, (state, { payload }) => {
      state.HOLDING_SUMMARY_SUMMED_INVESTED_VALUE =
        INITIAL_STATE.HOLDING_SUMMARY_SUMMED_INVESTED_VALUE
      state.HOLDING_SUMMARY_SCRIPTS_IN_HOLDING =
        INITIAL_STATE.HOLDING_SUMMARY_SCRIPTS_IN_HOLDING
      state.HOLDING_SUMMARY_HOLDINGS_OBJECT =
        INITIAL_STATE.HOLDING_SUMMARY_HOLDINGS_OBJECT
    })

    builder.addCase(getHoldingsDetailsActions.success, (state, { payload }) => {
      const { holdingDetails, pledgeSummary } = payload
      state.pledgeData = pledgeSummary
      state.holdingDetails = holdingDetails
      state.pledgeDetails = holdingDetails
      let SUMMED_INVESTED_VALUE: number = 0
      let scriptIdArray : string[] = []
      let SCRIPTS_IN_HOLDING: THoldingsDocument[] = []
      holdingDetails?.forEach((item: any) => {
        const {
          investedValue: investedValue,
          holdingQty,
          securityInfo,
          totalFreeQty,
          avgPrice,
          realizedGain,
          interSettlementQty,
          dematHeldQty,
          collateralQty,
          emarginNetQuantity,
          isinCode,
          dmatfreeQty,
          collateralValue
        } = item

        const { scriptId, symbol, exchange = '', haircutPct } = securityInfo[0]
        const freeQty = Number(holdingQty) + dematHeldQty
        if (scriptId !== '0') {
          SUMMED_INVESTED_VALUE =
            SUMMED_INVESTED_VALUE + Number(investedValue || 0)
          scriptIdArray.push(scriptId)
          SCRIPTS_IN_HOLDING.push({
            scriptId,
            holdingQty: holdingQty,
            investedValue: investedValue ? investedValue : 0,
            avgPrice,
            symbol,
            exchange: exchange ? exchange.split('_')[0] : '',
            realizedGain,
            interSettlementQty,
            freeQty,
            collateralQty,
            emarginNetQuantity,
            isinCode,
            dmatfreeQty,
            collateralValue,
            haircutPct,
            totalFreeQty,
            initialDmatQty: dmatfreeQty,
            calls: []
          })
        } // TEMPO, REMOVE CASING
      })
      state.scriptsArray = scriptIdArray
      state.SUMMED_INVESTED_VALUE = SUMMED_INVESTED_VALUE
      let newArray = [...SCRIPTS_IN_HOLDING]
      newArray = newArray.sort((ITEM_A: any, ITEM_B: any) => {
        const { investedValue: investedValueA } = ITEM_A

        const { investedValue: investedValueB } = ITEM_B

        return investedValueB - investedValueA
      })

      state.SCRIPTS_IN_HOLDING = newArray
      if (checkIfDefaultFiltersAreSet(state)) {
        state.isPledgeDefaultFiltersEnabled = true
      } else {
        state.isPledgeDefaultFiltersEnabled = false
      }
    })

    builder.addCase(getHoldingsDetailsActions.error, (state, { payload }) => {
      state.SUMMED_INVESTED_VALUE = INITIAL_STATE.SUMMED_INVESTED_VALUE
      state.SCRIPTS_IN_HOLDING = INITIAL_STATE.SCRIPTS_IN_HOLDING
      state.isDefaultFiltersEnabled = INITIAL_STATE.isDefaultFiltersEnabled
    })
  }
}

function checkIfDefaultFiltersAreSet(state: any) {
  const { holdingsFilter } = state
  const { stocksFilter } = holdingsFilter
  const noFilters = stocksFilter.length === 0 ? true : false
  if (holdingsFilter.sortBy === DEFAULT_VALUES.selectedSortBy && noFilters) {
    return true
  }
  return false
}

function checkIfPledgeDefaultFiltersAreSet(state: any) {
  const { pledgeFilter } = state
  const { stocksFilter } = pledgeFilter
  const noFilters = stocksFilter.length === 0 ? true : false
  if (
    pledgeFilter.sortBy === DEFAULT_VALUES.selectedSortByPledge &&
    noFilters
  ) {
    return true
  }
  return false
}

const slice = createSlice(sliceOptions)
export const {
  holdingsFilter,
  setHoldings,
  changeSelectedFilterTabInHolding,
  pledgeFilter,
  setPledge
} = slice.actions
export default slice.reducer
