import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const getScreenersActiveConfigServiceName =
  'screeners/getScreenersActiveConfig'
export const getScreenersActiveConfigActions = traceActionsCreator(
  getScreenersActiveConfigServiceName
)

export const getScreenersByIdServiceName = 'screeners/getScreenersById'
export const getScreenersByIdActions = traceActionsCreator(
  getScreenersByIdServiceName
)

// selections
export const setSelectedExchangeAction = createAction<any, string>(
  'screeners/setSelectedExchange'
)

export const setSelectedGroupNameAction = createAction<any, string>(
  'screeners/setSelectedGroupName'
)

export const setSelectedAssetClassAction = createAction<any, string>(
  'screeners/setSelectedAssetClass'
)

export const setSelectedSubCategoryAction = createAction<any, string>(
  'screeners/setSelectedSubCategory'
)
export const selectedExpiryDateAction = createAction<any, string>(
  'screeners/selectedExpiryDate'
)
export const selectedSegmentAction = createAction<any, string>(
  'screeners/selectedSegment'
)
export const setSelectedCallPutAction = createAction<any, string>(
  'screeners/selectedCallPut'
)
export const setFilteredScreenersAction = createAction<any, any>(
  'screeners/filteredScreeners'
)

export const clearFiltersScreenersAction = createAction(
  'screeners/clearFiltersScreeners'
)
