import { DsStack, DsRemixIcon, DsTypography } from '@am92/react-design-system'
import React, { Component } from 'react'

interface IGtdNote {}

class GtdNote extends Component<IGtdNote> {
  render() {
    return (
      <DsStack
        sx={{
          flexDirection: 'row',
          border: '1px solid var(--ds-colour-strokeDefault)',
          borderRadius: 'var(--ds-spacing-glacial)',
          padding: 'var(--ds-spacing-bitterCold)',
          gap: 'var(--ds-spacing-frostbite)'
        }}
      >
        <DsStack
          sx={{
            border: '1px solid transperant',
            borderRadius: 'var(--ds-spacing-quickFreeze)',
            backgroundColor: 'var(--ds-colour-neutral2)',
            padding: 'var(--ds-spacing-glacial)',
            width: 'var(--ds-spacing-warm)',
            height: 'var(--ds-spacing-warm)'
          }}
        >
          <DsRemixIcon
            className='ri-lightbulb-flash-fill'
            fontSize='bitterCold'
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              color: 'var(--ds-colour-iconActionTertiary)'
            }}
          />
        </DsStack>
        <DsTypography variant='bodyRegularSmall'>
          GTD order remains active until your trigger price is reached or until
          expiry. Please ensure adequate funds for successful placement.
        </DsTypography>
      </DsStack>
    )
  }
}

export default GtdNote
