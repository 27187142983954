import { DsBox, DsStack, DsTypography } from '@am92/react-design-system'
import React, { Component } from 'react'
import StockTicker from './StockTicker'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { getHoldingAndAveragePrice } from '~/src/Redux/Dashboard/Selectors'
import BookmarkButton from '../../BookmarkButton'
import { To } from 'react-router-dom'
import { IWithRouterProps } from '~/src/Lib/withRouter'

interface IStockProps extends IWithRouterProps {
  scriptId: string
  stockDetails: T_SCRIPTDATA
  holdingsAndAveragePrice: {
    holdings: string
    avgPrice: string
  }
  showAddToWatchlist?: boolean
  showHoldings?: boolean
  handleOnClickOpenAddToWatchlist?: (scripId: string) => void
}

class StockData extends Component<IStockProps> {
  state = {}

  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }
  navigateToQuotes = (scriptId: any) => {
    this.navigateTo(`/quotes/${scriptId}`)
  }
  render() {
    const {
      handleOnClickOpenAddToWatchlist,
      showAddToWatchlist = false,
      stockDetails = {},
      scriptId,
      holdingsAndAveragePrice = {},
      showHoldings = true
    }: any = this.props
    const { coname, exchange, closeprice, label, segment } = stockDetails || {}
    const { holdings = '', avgPrice } = holdingsAndAveragePrice || {}
    return coname == null || coname == undefined ? (
      <DsTypography variant="bodyRegularMedium">Loading ...</DsTypography>
    ) : (
      <DsBox
        key={scriptId}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <DsBox sx={{ display: "flex", flexDirection: "row", width: "100%" }}
          onClick={() => this.navigateToQuotes(scriptId)}>
          <DsBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <DsTypography variant="bodyBoldSmall">{label}</DsTypography>
            <DsStack
              sx={{
                flexDirection: 'row',
                gap: 'var(--ds-spacing-glacial)'
              }}
            >
              <DsTypography
                sx={{ mt: 'var(--ds-spacing-deepFreeze)' }}
                color={'var(--ds-colour-typoTertiary)'}
                variant="supportRegularInfo"
              >
                {exchange}
              </DsTypography>
              {(showHoldings && holdings) && (
                <DsTypography
                  sx={{ mt: 'var(--ds-spacing-deepFreeze)' }}
                  color={'var(--ds-colour-typoTertiary)'}
                  variant="supportRegularInfo"
                >
                  Qty {holdings} @ {avgPrice}
                </DsTypography>
              )}
            </DsStack>
          </DsBox>
          <DsBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: 1,
              flexGrow: 1
            }}
          >
            <StockTicker
              scriptId={scriptId}
              closeprice={closeprice}
              segment={segment}
              type='watchlist'
            />
          </DsBox>
        </DsBox>
        {showAddToWatchlist && (
          <BookmarkButton
            handleOnClickBookmark={handleOnClickOpenAddToWatchlist}
            scripId={scriptId}
          />
        )}
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const stockDetails = getStockMasterData(scriptId)(state)
  const holdingsAndAveragePrice = getHoldingAndAveragePrice(scriptId)(state)

  return {
    stockDetails,
    holdingsAndAveragePrice
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps)(StockData)
