/** @ignore */
export const AES_ALGORITHM = 'AES-GCM';
/** @ignore */
export const AES_KEY_IMPORT_FORMAT = 'raw';
/** @ignore */
export const AES_KEY_EXPORT_FORMAT = 'raw';
/** @ignore */
export const AES_KEY_GEN_PARAMS = {
    name: AES_ALGORITHM,
    length: 256
};
/** @ignore */
export const AES_KEY_USAGE = ['encrypt', 'decrypt'];
/** @ignore */
export const AES_IV_LENGTH = 16;
/** @ignore */
export const AES_AUTH_TAG_LENGTH = 16;
/** @ignore */
export const AES_DATA_SEPARATOR = '.';
/** @ignore */
export const RSA_ALGORITHM = 'RSA-OAEP';
/** @ignore */
export const RSA_KEY_IMPORT_FORMAT = 'spki';
/** @ignore */
export const RSA_KEY_IMPORT_PARAMS = {
    name: RSA_ALGORITHM,
    hash: 'SHA-256'
};
/** @ignore */
export const RSA_KEY_USAGES = ['wrapKey'];
/** @ignore */
export const RSA_KEY_WRAP_FORMAT = 'raw';
/** @ignore */
export const RSA_KEY_WRAP_PARAMS = {
    name: RSA_ALGORITHM
};
