import {
  DsBox,
  DsButtonBase,
  DsDivider,
  DsRemixIcon,
  DsStack,
  DsTextField,
  enqueueNotistack
} from '@am92/react-design-system'
import React, { Component } from 'react'
import NumberFormatter from '../../NumberFormatter'

import withErrorConnect from '~/src/Lib/withErrorConnect'
import { tickerContext } from '~/src/Configurations/TickerStore'
import SipScriptsDetailsLeft from './SipScriptsDetailsLeft'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'

export interface ActionType {}

export interface ISipScriptsDetailsProps {
  isModifing: any
  actions: ActionType
  item: any
  sipInfo: any
  selectedSipScripts: any
  updateSelectedScriptsData: (item: any) => any
  stockPriceDetails: any
}
export class SipScriptsDetails extends Component<ISipScriptsDetailsProps> {
  handleChange = async (
    data: any,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      selectedSipScripts,
      sipInfo,
      updateSelectedScriptsData,
      stockPriceDetails,
      isModifing
    } = this.props
    const { basedOn } = sipInfo
    const { value } = e.target
    const { scriptId } = data
    const { LTP = 0 } = stockPriceDetails

    const formattedBasedOn =
      basedOn.toLowerCase() === 'quantity' ? 'quantity' : 'amount'
    const formattedSecondValue =
      formattedBasedOn === 'amount' ? 'quantity' : 'amount'

    const finalValue =
      formattedSecondValue === 'quantity'
        ? Number(value) / LTP
        : LTP * Number(value)

    let formattedData = {}

    if (isModifing) {
      formattedData = {
        [formattedBasedOn]: value,
        [formattedSecondValue]: finalValue
      }
    } else {
      formattedData = {
        [formattedBasedOn]: value,
        [formattedSecondValue]: finalValue
      }
    }

    const updatedSipScripts = selectedSipScripts.map((item: any) => {
      if (item.scriptId === scriptId) {
        return {
          ...item,
          ...formattedData
        }
      }
      return item
    })

    const updatedSipInfo = {
      ...sipInfo,
      selectedScripts: updatedSipScripts
    }

    await updateSelectedScriptsData(updatedSipInfo)
  }

  handleDelete = async (item: any) => {
    const { selectedSipScripts, sipInfo, updateSelectedScriptsData } =
      this.props
    const { scriptId, coname } = item

    const updatedSipScripts = selectedSipScripts.filter(
      (item: any) => item.scriptId !== scriptId
    )

    const updatedSipInfo = {
      ...sipInfo,
      selectedScripts: updatedSipScripts || []
    }

    await updateSelectedScriptsData(updatedSipInfo)

    enqueueNotistack({
      message: `${coname} removed`
    })
  }

  render() {
    const { item, sipInfo } = this.props

    const { basedOn } = sipInfo

    return (
      <DsBox key={item}>
        <DsBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SipScriptsDetailsLeft item={item} sipInfo={sipInfo} />
          <DsStack gap={'var(--ds-spacing-glacial)'} alignItems={'flex-end'}>
            <DsBox sx={{ display: 'flex', flexDirection: 'row' }}>
              <DsTextField
                name={item.scriptId}
                placeholder='10000'
                value={item[basedOn.toLowerCase()]}
                inputComponent={NumberFormatter}
                sx={{ flex: 1 }}
                onChange={e => this.handleChange(item, e)}
              />
            </DsBox>

            <DsButtonBase>
              <DsRemixIcon
                className='ri-delete-bin-line'
                onClick={() => this.handleDelete(item)}
              />
            </DsButtonBase>
          </DsStack>
        </DsBox>
        <DsDivider
          sx={{
            m: 'var(--ds-spacing-bitterCold) var(--ds-spacing-zero) '
          }}
        ></DsDivider>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { item } = ownProps

  const stockPriceDetails = getTickerData(item.scriptId)(state)

  return { stockPriceDetails }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(SipScriptsDetails)
