import { DsRemixIcon, DsStack, DsTypography } from '@am92/react-design-system'
import React from 'react'

interface ProfileCardProps {
  type: string
  heading: string
  description: string
  onClick?: () => void
}

interface ProfileCardState {}

class ProfileCard extends React.Component<ProfileCardProps, ProfileCardState> {
  render() {
    const { type = '', heading = '', description = '', onClick } = this.props
    const color =
      type === 'active'
        ? 'var(--ds-colour-actionSecondary)'
        : type === 'disabled'
          ? 'var(--ds-colour-typoDisabled)'
          : 'var(--ds-colour-typoPrimary)'
    const icon =
      type === 'active' ? 'ri-checkbox-circle-line' : 'ri-arrow-right-s-line'
    const backgroundColor =
      type === 'disabled'
        ? 'var(--ds-colour-surfaceSecondary)'
        : 'var(--ds-colour-surfaceBackground)'
    return (
      <DsStack
        onClick={onClick}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: `0.5px solid ${color}`,
          borderRadius: 'var(--ds-spacing-glacial)',
          width: '100%',
          p: 'var(--ds-spacing-bitterCold)',
          boxShadow: 1,
          backgroundColor: backgroundColor,
          cursor: 'pointer'
        }}
      >
        <DsStack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <DsTypography variant='bodyBoldMedium' sx={{ color: color }}>
            {heading}
          </DsTypography>
          <DsRemixIcon className={icon} sx={{ color: color }} />
        </DsStack>
        <DsTypography
          variant='supportRegularInfo'
          sx={{ color: 'var(--ds-colour-typoSecondary)' }}
        >
          {description}
        </DsTypography>
      </DsStack>
    )
  }
}

export default ProfileCard
