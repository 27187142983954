import {
  DsStack,
  DsInputLabel,
  DsTypography,
  DsInputBase,
  DsFormControl,
  DsFormHelperText,
  DsRemixIcon
} from '@am92/react-design-system'
import React, { Component } from 'react'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import NumberFormatter from '../../NumberFormatter'

interface IDisclosedQty {
  handleChange: (e: React.ChangeEvent<any>) => void
  disclosedQty: number
  optionalLabel: boolean
  disclosedQtyError: boolean
  disclosedQtyHelperText: string | undefined
}

class DisclosedQty extends Component<IDisclosedQty, any> {
  constructor(props: IDisclosedQty) {
    super(props)
  }

  componentDidUpdate(prevProps: any): void {}

  render() {
    const {
      handleChange,
      disclosedQty,
      optionalLabel = true,
      disclosedQtyError,
      disclosedQtyHelperText
    } = this.props
    return (
      <>
        <DsFormControl error={disclosedQtyError}>
          <DsStack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <DsInputLabel
              label={
                <DsTypography variant='bodyRegularMedium'>
                  Disclosed Quantity
                </DsTypography>
              }
              {...(optionalLabel && {
                labelSupportText: '(Optional)'
              })}
              sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
            />
            <DsInputBase
              inputComponent={NumberFormatter}
              name='disclosedQty'
              onChange={handleChange}
              value={disclosedQty}
              placeholder='+ Add Quantity'
              inputProps={{
                inputMode: 'numeric'
              }}
            />
          </DsStack>
          <DsStack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 'var(--ds-spacing-quickFreeze)',
              marginLeft: 'var(--ds-spacing-quickFreeze)',
              marginTop: 'var(--ds-spacing-glacial)'
            }}
          >
            {(disclosedQtyError && (
              <DsRemixIcon
                className='ri-error-warning-fill'
                fontSize='small'
                sx={{ color: 'var(--ds-colour-supportNegative)' }}
              />
            )) ||
              null}
            <DsFormHelperText
              error={disclosedQtyError}
              sx={{
                marginTop: 'var(--ds-spacing-zero)',
                padding: 'var(--ds-spacing-zero)',
                minHeight: 'unset'
              }}
            >
              {disclosedQtyHelperText}
            </DsFormHelperText>
          </DsStack>
        </DsFormControl>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(DisclosedQty)
