import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import {  getEventsActions } from './actions'
import { SLICE_NAME } from './selectors'

const INITIAL_STATE = {
  events: []
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getEventsActions.success, (state, { payload }) => {
      state.events = payload
    })
  }
}

const slice = createSlice(sliceOptions)
export default slice.reducer
