import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from 'react';
import { DsHelperTextDefaultProps } from './DsHelperText.Types';
import { DsFormHelperText } from '../DsFormHelperText';
import { DsBox } from '../DsBox';
import { DsTypography } from '../DsTypography';
import { DsRemixIcon } from '../DsRemixIcon';
export class DsHelperText extends PureComponent {
    static defaultProps = DsHelperTextDefaultProps;
    render() {
        const { helperText, color, success, error, ...formHelperTextProps } = this.props;
        const customColor = success ? 'success' : color;
        const iconClassName = (error && 'ri-error-warning-fill') ||
            (success && 'ri-checkbox-circle-fill') ||
            false;
        return (helperText && (_jsx(DsFormHelperText, { component: "div", color: customColor, error: error, ...formHelperTextProps, children: _jsxs(DsBox, { sx: {
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    color: `${customColor}.main`
                }, children: [iconClassName && (_jsx(DsRemixIcon, { className: iconClassName, color: "inherit", fontSize: "cool", sx: {
                            mr: 'var(--ds-spacing-quickFreeze)',
                            padding: 'var(--ds-spacing-deepFreeze)'
                        } })), _jsxs(DsTypography, { variant: "bodyRegularSmall", color: "inherit", sx: { pl: 'var(--ds-spacing-deepFreeze)' }, children: [helperText, "\u200B"] })] }) })));
    }
}
