import secMaster from '~/src/Lib/SecMaster'
import { GLOBAL_SUCCESS_EVENT_NAME } from '../SecurityMaster/EVENT_CONTANTS'
import { WORKER_CONSTANTS_OPTIONS } from '~/src/Constants/WORKER_CONSTANTS'
import AppStore from '~/src/Configurations/AppStore'
import { search } from '~/src/Redux/Search/Reducer'

class SearchManager {
  STATE: 'INIT' | 'READY' = 'INIT'
  WORKER: Worker[] = []
  constructor() {
    const { __SEC_MASTER_LOADING__ } = (window as any) || {}

    if (__SEC_MASTER_LOADING__ === 'DONE') {
      this.initialize()
    } else {
      addEventListener(GLOBAL_SUCCESS_EVENT_NAME, this.initialize)
    }
  }

  initialize = () => {
    this.STATE = 'READY'

    // get Chunks from secmaster
    const chunks = secMaster.SEARCH_STRING_CHUNK

    // loop on chunks and add workers to WORKER
    chunks.forEach((chunk, index) => {
      const searchWorker = new Worker(
        new URL('./SearchWorker.ts', import.meta.url),
        {
          type: 'module'
        }
      )
      this.WORKER.push(searchWorker)

      // postmessage on workers to pass chunk data and index with initialize event
      searchWorker.postMessage({
        payload: chunk,
        index,
        actionType: WORKER_CONSTANTS_OPTIONS.init
      })
    })
  }

  search = async (searchString: string) => {
    return new Promise((resolve, reject) => {
      const workerResults: any[] = []
      let resultCount = 0
      // loop on workers and post messsage search event with search string
      this.WORKER.forEach(workerInstance => {
        workerInstance.postMessage({
          actionType: 'SEARCH',
          searchString
        })

        workerInstance.onmessage = (event: MessageEvent) => {
          const { data } = event
          ++resultCount
          const { workerIndex, searchResult } = data
          workerResults[workerIndex] = searchResult

          console.log(workerResults, 'workerResults')
          if (resultCount === this.WORKER.length) {
            const searchResult = this._flattenResult(workerResults)
            AppStore.dispatch(search(searchResult))
            console.log(searchResult, 'searchResult')
            resolve(searchResult)
          }
        }
      })
    })
  }

  _flattenResult = (workerResults: any[]) => {
    const searchResult: any[] = []

    workerResults.forEach(workerResult => {
      workerResult.forEach((resultItem: any) => {
        const script = secMaster._getAndMapFromMaster(resultItem.obj.data, {
          getSearchableStructure: false
        })
        searchResult.push({ ...script })
      })
    })
    return searchResult
  }
}

const searchManager = new SearchManager()
export default searchManager
