import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import {
  bulkUpdateWatchlistActions,
  bulkUpdateWatchlistServiceName
} from '../Actions'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export interface I_BULK_UPDATE_WATCHLIST_PAYLOAD {
  checkedWatchlistIds?: string[]
  uncheckedWatchlistIds?: string[]
  scriptId: string
}

async function bulkUpdateWatchlist(reqData: I_BULK_UPDATE_WATCHLIST_PAYLOAD) {
  const options = {
    url: '/watch-lists/update',
    method: 'POST',
    data: reqData,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }
  const response = await asHttp.request(options)
  const { data: body } = response
  return body
}

const bulkUpdateWatchlistAction = serviceActionCreator(
  bulkUpdateWatchlistActions,
  bulkUpdateWatchlist
)

export default bulkUpdateWatchlistAction
export { bulkUpdateWatchlistServiceName }
