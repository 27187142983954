import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'inquiry'

const select = (state: any) => state[SLICE_NAME]
const availableEquityLimitsSelect = (state: any) => state[SLICE_NAME]
const availableCommodityLimitsSelect = (state: any) => state[SLICE_NAME]
const getWithdrawEQLimitsSelect = (state: any) => state[SLICE_NAME]
const getWithdrawCOMMLimitsSelect = (state: any) => state[SLICE_NAME]
const getEqLimitsSelect = (state: any) => state[SLICE_NAME]
const getCommLimitsSelect = (state: any) => state[SLICE_NAME]
const getFundPayoutStatusSelect = (state: any) => state[SLICE_NAME]

export const getInquiryReducer = (state: any) => {
  return select
}

export const getAvailableEquityLimitsSelector = createSelector(
  availableEquityLimitsSelect,
  (availableEquityLimits: any) => availableEquityLimits
)

export const getAvailableCommodityLimitsSelector = createSelector(
  availableCommodityLimitsSelect,
  (availableCommodityLimits: any) => availableCommodityLimits
)

export const getFundPayoutStatusSelector = createSelector(
  getFundPayoutStatusSelect,
  (fundPayoutStatus: any) => fundPayoutStatus
)

export const getWithdrawEQLimitsSelector = createSelector(
  getWithdrawEQLimitsSelect,
  (withdrawEQLimits: any) => withdrawEQLimits.withdrawEQLimits
)

export const getWithdrawCOMMLimitsSelector = createSelector(
  getWithdrawCOMMLimitsSelect,
  (withdrawCOMMLimits: any) => withdrawCOMMLimits.withdrawCOMMLimits
)

export const getEqLimitsSelector = createSelector(
  getEqLimitsSelect,
  (eqLimits: any) => eqLimits.eqLimits
)

export const getCommLimitsSelector = createSelector(
  getCommLimitsSelect,
  (commLimits: any) => commLimits.commLimits
)

export const getEquityValueSelector = createSelector(
  availableEquityLimitsSelect,
  (availableEquityLimits: any) => handleGetEquityValue(availableEquityLimits)
)

const handleGetEquityValue = (data: any) => {
  const formattedEquity = data?.availableEquityLimits?.currentMarginAvailable
  const Equities = formattedEquity === 0 ? '0' : formattedEquity
  return Equities
}

export const getCommodityValueSelector = createSelector(
  availableCommodityLimitsSelect,
  (availableCommodityLimits: any) =>
    handleGetCommodityValue(availableCommodityLimits)
)

const handleGetCommodityValue = (data: any) => {
  const formattedCommodity =
    data?.availableCommodityLimits?.currentMarginAvailable
  const Equities = formattedCommodity === 0 ? '0' : formattedCommodity
  return Equities
}

// ODIN and TCS customer select
export const isTCSCustomerSelector = createSelector(
  getWithdrawEQLimitsSelector,
  (withdrawEQLimits: any) => _getIsThreeInOneTCSCustomer(withdrawEQLimits)
)

const _getIsThreeInOneTCSCustomer = (data: any) => {
  const { payoutAmount, releasableFunds } = data
  if (payoutAmount && releasableFunds) {
    return true
  }
  return false
}
