import { DsBox, DsSkeleton, DsStack, DsTypography } from "@am92/react-design-system";
import React, { ReactNode } from "react";

class ProfileSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <DsBox
        sx={{
          backgroundColor: 'var(--ds-colour-surfaceSecondary)',
          mb: "var(--ds-spacing-bitterCold)"
        }}
      >
        <DsTypography
          sx={{ display: { xs: 'none', md: 'flex' } }}
          variant="headingBoldLarge"
        >
          Profile
        </DsTypography>
        <DsStack spacing={"var(--ds-spacing-warm)"}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 'var(--ds-spacing-zero)', md: 'var(--ds-spacing-mild)' },
            backgroundColor: 'var(--ds-colour-surfaceBackground)',
            margin: { xs: 'var(--ds-spacing-zero)', md: 'var(--ds-spacing-bitterCold) var(--ds-spacing-zero)' },
            padding: { md: 'var(--ds-spacing-mild)' }
          }}>
          <DsStack spacing={"var(--ds-spacing-cool)"} sx={{ width: "100%" }}>
            <DsSkeleton variant="rounded" sx={{ height: "70px" }} />
          </DsStack>
          <DsStack spacing={"var(--ds-spacing-frostbite)"} sx={{ width: "100%" }}>
            <DsSkeleton variant="rounded" sx={{ height: "40px", width: "100%" }} />
            <DsSkeleton variant="rounded" sx={{ height: "40px", width: "100%" }} />
            <DsSkeleton variant="rounded" sx={{ height: "40px", width: "100%" }} />
            <DsSkeleton variant="rounded" sx={{ height: "40px", width: "100%" }} />

          </DsStack>
          <DsStack spacing={"var(--ds-spacing-cool)"} sx={{ width: "100%" }}>
            <DsSkeleton variant="rounded" sx={{ height: "70px" }} />
          </DsStack>
        </DsStack>
      </DsBox>
    )
  }
}

export default ProfileSkeleton