export const DsStepConnectorOverrides = {
    MuiStepConnector: {
        styleOverrides: {
            lineVertical: {
                borderLeftWidth: '2px',
                borderLeftStyle: 'solid',
                borderLeftColor: 'var(--ds-colour-strokeDefault)',
                minHeight: 'var(--ds-rules-stepperConnectorMinHeight)'
            },
            vertical: {
                marginLeft: '27px'
            }
        }
    }
};
