import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'

export const getDashboardNewsServiceName = 'news/getDashboardNews'
export const getDashboardNewsActions = traceActionsCreator(
  getDashboardNewsServiceName
)

export const clearDashboardNewsServiceName = 'news/clearDashboardNews'
export const clearDashboardNewsActions = createAction(
  clearDashboardNewsServiceName
)

export const getQuotesNewsServiceName = 'news/getQuotesNews'
export const getQuotesNewsActions = traceActionsCreator(
  getQuotesNewsServiceName
)

export const clearQuotesNewsServiceName = 'news/clearQuotesNews'
export const clearQuotesNewsActions = createAction(clearQuotesNewsServiceName)
