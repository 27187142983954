import { createSelector } from '@reduxjs/toolkit'
export const SLICE_NAME = 'stockWatchlistMaster'
const select = (state: any) => state[SLICE_NAME]

export const getStockWatchlistMaster = createSelector(
  select,
  stockWatchlistMaster => stockWatchlistMaster.data
)

export const getTempStockWatchlistMaster = createSelector(
  select,
  stockWatchlistMaster => stockWatchlistMaster.temporaryData
)
