import { asHttp } from '~/src/Configurations/WebHttp'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'
import { getHoldingsSummaryActions } from '../Actions'

export interface getHoldingsSummaryPayload {
  segment: string
}

async function getHoldingsSummary(payload: getHoldingsSummaryPayload) {
  const options = {
    url: '/trading/portfolio/get-holdings-summary',
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux()
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getHoldingsSummaryAction = serviceActionCreator(
  getHoldingsSummaryActions,
  getHoldingsSummary
)

export default getHoldingsSummaryAction
