import { asHttp } from '~/src/Configurations/WebHttp'
import { getTaxAndChargesActions } from '../actions'
import serviceActionCreator from '~/src/Redux/serviceActionCreatorWithTokenRotation'
import { getSubAccountIdFromRedux } from '~/src/Utils/global'

export interface T_GET_TAX_CHARGES_PAYLOAD {
  scriptId: string
  orderQuantity: number
  orderValue: number
  segment: string
  productType: string
  transactionType: string
}

async function getTaxAndCharges(payload: T_GET_TAX_CHARGES_PAYLOAD) {
  const options = {
    url: `/funds/inquiry/get-order-brokerage`,
    method: 'POST',
    data: payload,
    headers: {
      'X-SubAccountID': getSubAccountIdFromRedux(),
      'X-IPAddress': '192.168.65.1'
    }
  }

  const response = await asHttp.request(options)
  const { data: body } = response
  const { data } = body
  return data
}

const getTaxAndChargesAction = serviceActionCreator(
  getTaxAndChargesActions,
  getTaxAndCharges
)

export default getTaxAndChargesAction
