import React, { Component } from 'react'
// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { tickerContext } from '~/src/Configurations/TickerStore'
import { DsStack, DsTypography } from '@am92/react-design-system'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'
import { ITicker } from '~/src/Redux/StockTicker/Reducer'
type ActionTypes = {}
export interface IDPRPopUpProps extends IWithRouterProps {
  priceBoxFocused: boolean
  scriptId: string
  stockPriceDetails: ITicker
}
type IDPRPopUpState = {}
class DPRPopUp extends Component<IDPRPopUpProps, IDPRPopUpState> {
  constructor(props: IDPRPopUpProps) {
    super(props)
  }
  componentDidUpdate(prevState: any): void { }
  render() {
    const { priceBoxFocused, stockPriceDetails } = this.props
    const { DAILY_PRICE_RANGE } = stockPriceDetails || {}
    const separatePriceRange = (range: string) => {
      const [min, max] = range.split('-').map(value => value.trim())
      return { min, max }
    }
    const { min: minPrice, max: maxPrice } = separatePriceRange(DAILY_PRICE_RANGE || '')
    return (
      <>
        {priceBoxFocused && (
          <DsStack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              background: 'var(--ds-colour-surfaceSecondary)',
              p: 'var(--ds-spacing-frostbite) var(--ds-spacing-mild)'
            }}
            color="var(--ds-colour-typoSecondary)"
          >
            <DsTypography variant="supportRegularInfo">
              Daily Price Range
            </DsTypography>
            <DsStack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 'var(--ds-spacing-mild)'
              }}
            >
              <DsTypography variant="supportRegularInfo">
                Min {minPrice}
              </DsTypography>
              <DsTypography variant="supportRegularInfo">
                Max {maxPrice}
              </DsTypography>
            </DsStack>
          </DsStack>
        )}
      </>
    )
  }
}
const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const stockPriceDetails = getTickerData(scriptId)(state)
  return {
    stockPriceDetails
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})
export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, {
  context: tickerContext
})(DPRPopUp)