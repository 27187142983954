import { DsSkeleton, DsTypography } from '@am92/react-design-system'
import React, { Component } from 'react'
import { tickerContext } from '~/src/Configurations/TickerStore'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { ITicker } from '~/src/Redux/StockTicker/Reducer'
import { getTickerData } from '~/src/Redux/StockTicker/Selectors'

interface ILTPTicker {
  scriptId: string
  tickerData: ITicker
  ltpVariant?: string
  withCurrencySymbol?: boolean
}

class LTPTicker extends Component<ILTPTicker> {
  state = {}

  render() {
    const { tickerData = {}, ltpVariant, withCurrencySymbol = true }: any = this.props
    const { LTP, CLOSE_PRICE } = tickerData || {}

    return (LTP == null || LTP == undefined) && !CLOSE_PRICE ? (<DsSkeleton width={"2rem"} variant="text" sx={{ fontSize: ltpVariant ? ltpVariant : 'bodyRegularMedium' }} />) :
      (
        withCurrencySymbol ?
          <DsTypography variant={ltpVariant ? ltpVariant : 'bodyRegularMedium'}>
            ₹ {LTP || CLOSE_PRICE}
          </DsTypography> :
          <DsTypography variant={ltpVariant ? ltpVariant : 'bodyRegularMedium'}>
            {LTP || CLOSE_PRICE}
          </DsTypography>
      )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const tickerData = getTickerData(scriptId)(state)
  return {
    tickerData
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(mapStateToProps, mapDispatchToProps, null, { context: tickerContext })(LTPTicker)
