import { DsSkeleton, DsStack } from '@am92/react-design-system'
import React, { ReactNode } from 'react'

class ReportsSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <>
        <DsStack sx={{ width: '100%' }}>
          <DsSkeleton variant='text' sx={{ height: '40px' }} />
          <DsSkeleton variant='text' sx={{ height: '60px' }} />
          <DsSkeleton variant='text' sx={{ height: '60px' }} />
          <DsSkeleton variant='text' sx={{ height: '60px' }} />
          <DsSkeleton variant='text' sx={{ height: '60px' }} />
        </DsStack>
      </>
    )
  }
}

export default ReportsSkeleton
