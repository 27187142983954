import { DsSkeleton, DsStack } from "@am92/react-design-system";
import React, { ReactNode } from "react";

class QuotesSkeleton extends React.PureComponent {
  render(): ReactNode {
    return (
      <>
        <DsStack spacing={"var(--ds-spacing-warm)"} sx={{ width: "100%" }}>
          <DsStack spacing={"var(--ds-spacing-cool)"} sx={{ width: "100%" }}>
            <DsSkeleton variant="rounded" sx={{ height: "120px" }} />
          </DsStack>
          <DsStack spacing={"var(--ds-spacing-frostbite)"} sx={{ width: "100%" }}>
            <DsSkeleton variant="text" sx={{ height: "20px", width: "120px" }} />
            <DsSkeleton variant="rounded" sx={{ height: "180px" }} />
          </DsStack>
          {/* MARKET DEPTH */}
          <DsStack sx={{ flexDirection: "row", gap: "12px", width: "100%", justifyContent: "space-between" }}>
            <DsStack sx={{ gap: "12px", width: "100%" }}>
              <DsSkeleton variant="text" sx={{ width: "120px" }} />
              <DsSkeleton variant="rounded" sx={{ height: "180px", width: "100%" }} />
            </DsStack>
            <DsStack sx={{ gap: "12px", width: "100%" }}>
              <DsSkeleton variant="text" sx={{ width: "120px" }} />
              <DsSkeleton variant="rounded" sx={{ height: "180px", width: "100%" }} />
            </DsStack>
          </DsStack>
          {/* NEWS AND EVENTS */}
          <DsStack sx={{ flexDirection: "row", gap: "12px", width: "100%", justifyContent: "space-between" }}>
            <DsStack sx={{ gap: "12px", width: "100%" }}>
              <DsSkeleton variant="text" sx={{ width: "120px" }} />
              <DsSkeleton variant="rounded" sx={{ height: "180px", width: "100%" }} />
            </DsStack>
            <DsStack sx={{ gap: "12px", width: "100%" }}>
              <DsSkeleton variant="text" sx={{ width: "120px" }} />
              <DsSkeleton variant="rounded" sx={{ height: "180px", width: "100%" }} />
            </DsStack>
          </DsStack>
        </DsStack>
      </>
    )
  }
}

export default QuotesSkeleton