import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import { DsStack, DsRadio } from '@am92/react-design-system'
import StockTicker from '../../Sidebar/Components/StockTicker'
import { getStockMasterData } from '~/src/Redux/StockMaster/Selectors'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { getOrderBuyOrSell } from '~/src/Redux/Orders/selectors'

type ActionTypes = {}

export interface IExchangeSelectionProps extends IWithRouterProps {
  actions: ActionTypes
  scriptId: string
  orderBuyOrSell: string
  stockDetails: T_SCRIPTDATA
  handleExchangeSelection: (exchange: string, scriptId: string) => void
}

class ExchangeSelection extends Component<IExchangeSelectionProps> {
  render() {
    const { stockDetails, orderBuyOrSell, scriptId, handleExchangeSelection } =
      this.props
    const isBUY = orderBuyOrSell === 'BUY'
    const orderColor = isBUY ? 'supportPositive' : 'supportNegative'
    const { exchange, closeprice, segment } = stockDetails || {}
    return (
      <>
        <DsStack sx={{ flexDirection: 'column', flex: 1 }} key={exchange}>
          <DsRadio
            sx={{
              '& .Mui-checked': {
                color: `var(--ds-colour-${orderColor})`
              }
            }}
            label={exchange}
            value={exchange}
            onChange={() => {
              handleExchangeSelection(exchange, scriptId)
            }}
          />
          <DsStack
            sx={{
              flexDirection: 'row',
              gap: 'var(--ds-spacing-quickFreeze)',
              alignItems: 'center',
              ml: 'var(--ds-spacing-warm)'
            }}
          >
            <StockTicker
              scriptId={scriptId}
              closeprice={closeprice}
              component="orders"
              ltpTypoVariant="bodyBoldSmall"
              segment={segment}
            />
          </DsStack>
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const { scriptId } = ownProps
  const orderBuyOrSell = getOrderBuyOrSell(state)
  const stockDetails = getStockMasterData(scriptId)(state)
  return {
    stockDetails,
    orderBuyOrSell
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {}
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeSelection)
