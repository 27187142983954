import React, { Component } from 'react'

// lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  DsFormControl,
  DsStack,
  DsInputBase,
  DsRemixIcon,
  DsFormHelperText,
  DsInputLabel,
  DsTypography,
  DsBox,
  DsRadio,
  DsRadioGroup
} from '@am92/react-design-system'
import { T_SCRIPTDATA } from '~/src/Redux/StockMaster/Reducer'
import { changeOrderFormData } from '~/src/Redux/Orders/reducer'
import MarketDepthModal from './MarketDepthModal'
import NumberFormatter from '../../NumberFormatter'

type ActionTypes = {
  changeOrderFormData: (payload: { key: string; data: any }) => Promise<any>
}

export interface IPriceAndMarketDepthSectionProps extends IWithRouterProps {
  actions: ActionTypes
  priceError: boolean
  priceHelperText: string | undefined
  handleChange: (e: React.ChangeEvent<any>) => void
  price: string | number
  marketDepth: string
  breakpoints: any
  stockDetails: T_SCRIPTDATA
  orderBuyOrSell: string
  handlePriceBoxFocus: (val: boolean) => void
  openMarketDepth: boolean
  handleOpenMarketDepth: (value: string) => void
  handleCloseMarketDepth: () => void
  orderScriptId: string
  sectionType: number
}

class PriceAndMarketDepthSection extends Component<IPriceAndMarketDepthSectionProps> {
  constructor(props: IPriceAndMarketDepthSectionProps) {
    super(props)
  }

  renderLabel = () => {
    const { stockDetails } = this.props
    const { ticksize } = stockDetails || {}

    return (
      <DsInputLabel
        label={<DsTypography variant='bodyRegularMedium'>Price</DsTypography>}
        sx={{ marginBottom: 'var(--ds-spacing-zero)' }}
        labelSupportText={
          <DsTypography variant='bodyRegularMedium'>
            Tick Price: {ticksize}
          </DsTypography>
        }
      />
    )
  }

  renderMarketDepth = () => {
    const {
      marketDepth,
      orderBuyOrSell,
      handleChange,
      handleOpenMarketDepth,
      handleCloseMarketDepth,
      openMarketDepth,
      orderScriptId,
      handlePriceBoxFocus
    } = this.props
    const isBUY = orderBuyOrSell === 'BUY'
    const orderColor = isBUY ? 'supportPositive' : 'supportNegative'
    return (
      <>
        <DsStack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <DsStack
            sx={{ flexDirection: 'column' }}
            onClick={() => handleOpenMarketDepth(orderBuyOrSell)}
          >
            <DsTypography
              color={`var(--ds-colour-${orderColor})`}
              variant='bodyRegularSmall'
              sx={{
                cursor: 'pointer'
              }}
            >
              Market Depth
            </DsTypography>
            <DsBox
              sx={{
                border: `1px dashed var(--ds-colour-${orderColor})`,
                height: '1px',
                width: '100%'
              }}
            ></DsBox>
          </DsStack>
          <DsRadioGroup
            row
            value={marketDepth}
            sx={{
              '.MuiFormControlLabel-root': {
                marginRight: 'var(--ds-spacing-zero)'
              }
            }}
            name='marketDepth'
            onChange={handleChange}
          >
            <DsRadio
              sx={{
                '& .Mui-checked': {
                  color: `var(--ds-colour-${orderColor})  !important`
                },
                marginRight: 'var(--ds-spacing-bitterCold) !important'
              }}
              label='Market'
              value='market'
              onClick={() => {
                handlePriceBoxFocus(false)
              }}
            />
            <DsRadio
              sx={{
                '& .Mui-checked': {
                  color: `var(--ds-colour-${orderColor}) !important`
                }
              }}
              label='Limit'
              value='limit'
              onClick={() => {
                handlePriceBoxFocus(true)
              }}
            />
          </DsRadioGroup>
        </DsStack>
        <MarketDepthModal
          handleCloseMarketDepth={handleCloseMarketDepth}
          openMarketDepth={openMarketDepth}
          orderScriptId={orderScriptId}
        />
      </>
    )
  }

  render() {
    const {
      priceError,
      priceHelperText,
      handleChange,
      price,
      marketDepth,
      handlePriceBoxFocus,
      sectionType
    } = this.props

    return (
      <>
        <DsStack
          spacing={4}
          sx={{
            paddingLeft: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-mild)'
            },
            paddingRight: {
              xs: 'var(--ds-spacing-bitterCold)',
              md: 'var(--ds-spacing-mild)'
            }
          }}
        >
          <DsFormControl error={priceError}>
            <DsStack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {this.renderLabel()}
              <DsInputBase
                inputComponent={NumberFormatter}
                name='price'
                onChange={handleChange}
                value={
                  marketDepth === 'market'
                    ? '0.00'
                    : Number(price)
                      ? Number(price)
                      : ''
                }
                disabled={marketDepth === 'market'}
                inputProps={{
                  inputMode: 'numeric'
                }}
              />
            </DsStack>
            <DsStack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: 'var(--ds-spacing-quickFreeze)',
                marginLeft: 'var(--ds-spacing-quickFreeze)',
                marginTop: 'var(--ds-spacing-glacial)'
              }}
            >
              {(priceError && (
                <DsRemixIcon
                  className='ri-error-warning-fill'
                  fontSize='small'
                  sx={{ color: 'var(--ds-colour-supportNegative)' }}
                />
              )) ||
                null}
              <DsFormHelperText
                error={priceError}
                sx={{
                  marginTop: 'var(--ds-spacing-zero)',
                  padding: 'var(--ds-spacing-zero)',
                  minHeight: 'unset'
                }}
              >
                {priceHelperText}
              </DsFormHelperText>
            </DsStack>
          </DsFormControl>
          {sectionType === 0 && this.renderMarketDepth()}
        </DsStack>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {}
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    changeOrderFormData: (payload: { key: string; data: any }) =>
      dispatch(changeOrderFormData(payload))
  }
})

export default withErrorConnect(
  mapStateToProps,
  mapDispatchToProps
)(PriceAndMarketDepthSection)
