import React from 'react'

// DS Components
import {
  DsAvatar,
  DsBox,
  DsSearchbar,
  withBreakpoints
} from '@am92/react-design-system'

// Lib
import { IWithRouterProps } from '~/src/Lib/withRouter'
import { To } from 'react-router-dom'
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import withErrorConnect from '~/src/Lib/withErrorConnect'
import {
  T_SCRIPTS_OBJ,
  setAddScriptToParticularWatchlist,
  watchlistObj
} from '~/src/Redux/WatchList/Reducer'
import { getWatchListLength } from '~/src/Redux/WatchList/Selectors'
import { T_StockWatchlistMasterData } from '~/src/Redux/StockWatchlistMaster/Reducer'
import fetchWatchlistsAction from '~/src/Redux/WatchList/Services/FetchWatchlists.Service'
import { updateStockWatchlistMasterAction } from '~/src/Redux/StockWatchlistMaster/Actions'
import { watchlistFetched } from '~/src/Redux/Indicators/Reducer'
import { DsTextField } from '@am92/react-design-system'
import { DsInputAdornment, DsRemixIcon } from '@am92/react-design-system'
import console from 'console'


type ActionTypes = {
  setAddScriptToParticularWatchlist: (reqData: boolean) => Promise<any>
  fetchWatchlist: () => Promise<any>
  updateStockWatchlistMaster: (
    requestData: T_StockWatchlistMasterData
  ) => Promise<any>
  watchlistFetched: (reqData: boolean) => Promise<any>
}

export interface IMobileHeaderProps extends IWithRouterProps {
  actions: ActionTypes
  watchListLength: number
  breakpoints: any
  handleError: (res: any) => void
}

class MobileHeader extends React.Component<IMobileHeaderProps> {
  navigateTo = (route: To) => {
    const { navigateTo } = this.props
    navigateTo(route)
  }

  handleFocus = () => {
    const { actions } = this.props
    actions.setAddScriptToParticularWatchlist(false)
    this.navigateTo(APP_ROUTES.MOBILE_SEARCH.pathname)
  }

  componentDidMount(): void {
    const { breakpoints = {}, watchListLength } = this.props
    const isDesktop =
      breakpoints?.md || breakpoints?.lg || breakpoints?.xl || false

    if (watchListLength === 0 && !isDesktop) {
      this.getWatchListData()
    }
  }

  getWatchListData = async () => {
    const { actions, handleError } = this.props
    const fetchWatchlistsResponse = await actions.fetchWatchlist()
    if (fetchWatchlistsResponse._isCustomError) {
      console.log('failed API - fetchWatchlist')
      handleError(fetchWatchlistsResponse)
      return
    }
    // actions.watchlistFetched(true)

    const stockWatchlistData: T_StockWatchlistMasterData = {}

    fetchWatchlistsResponse.forEach((watchlist: watchlistObj) => {
      const { watchlistSecurities, watchlistId, watchlistType } = watchlist
      if (watchlistType === 'PRE-DEFINED') {
        return false
      }
      watchlistSecurities?.forEach((stockObj: T_SCRIPTS_OBJ) => {
        const { scriptId } = stockObj
        if (!stockWatchlistData[scriptId]) {
          stockWatchlistData[scriptId] = [watchlistId]
          return
        }
        stockWatchlistData[scriptId] = [
          ...stockWatchlistData[scriptId],
          watchlistId
        ]
      })
    })

    await actions.updateStockWatchlistMaster(stockWatchlistData)
  }
  handleNotification = () => {
    this.navigateTo(APP_ROUTES.NOTIFICATION.pathname)
  }

  render() {
    return (
      <DsBox
        sx={{
          display: { xs: 'flex' },
          flexDirection: 'row',
          p: 'var(--ds-spacing-bitterCold)',
          gap: 'var(--ds-spacing-bitterCold)',
          backgroundColor: 'var(--ds-colour-surfaceBackground)',
          boxShadow:
            'var(--ds-spacing-zero) var(--ds-spacing-zero) var(--ds-spacing-deepFreeze) var(--ds-spacing-zero) rgba(0, 0, 0, 0.10)'
        }}
      >
        <DsAvatar
          onClick={() => {
            this.navigateTo('/profile-menu')
          }}
          sx={{
            flexGrow: 1
          }}
        />
        <DsTextField
          fullWidth
          onFocus={this.handleFocus}
          placeholder={'Search to buy/sell stocks'}
          sx={{
            '.MuiInputBase-root': {
              borderRadius: 'var(--ds-radius-pleasant)'
            }
          }}
          startAdornment={
            <DsInputAdornment position="start">
              <DsRemixIcon
                className="ri-search-line"
                color="disabled"
                fontSize="cool"
              />
            </DsInputAdornment>
          }
        />
        <DsBox sx={{
          borderRadius: "0.25rem",
          height: '2.5rem',
          width: '2.5rem',
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
          onClick={this.handleNotification}
        >
          <DsRemixIcon className='ri-notification-2-line' />
        </DsBox>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any) => {
  const watchListLength = getWatchListLength(state)
  return {
    watchListLength
  }
}

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  actions: {
    setAddScriptToParticularWatchlist: (requestData: boolean) =>
      dispatch(setAddScriptToParticularWatchlist(requestData)),
    fetchWatchlist: () => dispatch(fetchWatchlistsAction()),
    updateStockWatchlistMaster: (requestData: T_StockWatchlistMasterData) =>
      dispatch(updateStockWatchlistMasterAction(requestData)),
    watchlistFetched: (reqData: boolean) => dispatch(watchlistFetched(reqData))
  }
})

export default withBreakpoints(
  withErrorConnect(mapStateToProps, mapDispatchToProps)(MobileHeader)
)
